import { type Cause, type Either } from "effect";

export type HandleSomethingTag = "HANDLE_SUCCESS" | "HANDLE_ERROR";
export interface HandleSomething {
  _tag: HandleSomethingTag;
}

export interface HandleSuccess<V> extends HandleSomething {
  _tag: "HANDLE_SUCCESS";
  value: V;
}

export function HandleSuccess<V>(value: V): HandleSuccess<V> {
  return { _tag: "HANDLE_SUCCESS", value };
}

export function HandleError<V, E extends Error>(error: E): HandleError<E> {
  return { _tag: "HANDLE_ERROR", error };
}

export function isHandleSuccess<V>(x: HandleSomething): x is HandleSuccess<V> {
  return x._tag === "HANDLE_SUCCESS";
}

export function isHandleError<E extends Error>(
  x: HandleSomething
): x is HandleError<E> {
  return x._tag === "HANDLE_ERROR";
}

export interface HandleError<E extends Error> extends HandleSomething {
  _tag: "HANDLE_ERROR";
  error: E;
}

export type DoNothing = {
  _tag: "DO_NOTHING";
  defect?: Cause.Cause<unknown> | undefined;
};

export function isDoNothing(x: DoNothing | unknown): x is DoNothing {
  return (
    typeof x === "object" &&
    x !== null &&
    "_tag" in x &&
    x._tag === "DO_NOTHING"
  );
}

export type DoSomething<V, E extends Error> = {
  _tag: "DO_SOMETHING";
  handle: HandleSuccess<V> | HandleError<E>;
};

export function DoSomething<V, E extends Error>(
  handle: HandleSuccess<V> | HandleError<E>
): DoSomething<V, E> {
  return { _tag: "DO_SOMETHING", handle };
}

export function DoNothing(defect?: Cause.Cause<unknown>): DoNothing {
  return { _tag: "DO_NOTHING", defect };
}

export type HandleApiResponse<V, E extends Error> =
  | DoSomething<V, E>
  | DoNothing;
