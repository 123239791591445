import { Combobox } from "@headlessui/react";
import { useQuery } from "convex/react";
import { useUnit } from "effector-react";
import { SelectModalitiesVM } from "frontend-shared/src/users/hp/select-modality.vm";
import React, { useState } from "react";
import { api } from "shared/convex/_generated/api";

const mockFetchKnownModalities = async () => {
  return [
    {
      slug: "emdr",
      name: "EMDR",
    },
    { slug: "cbt", name: "CBT" },
    { slug: "dbt", name: "DBT" },
    { slug: "psychodynamic", name: "Psychodynamic" },
    { slug: "act", name: "ACT" },
    { slug: "couples", name: "Couples" },
  ];
};

type ModalityPillProps = {
  name: string;
};

const ModalityPill: React.FC<ModalityPillProps> = ({ name }) => {
  return (
    <div className="px-6 py-3 border border-vid-black-200 text-sm rounded-full  flex justify-center items-center">
      {name}
    </div>
  );
};

export const ModalitySelectorForm: React.FC<{
  vm: SelectModalitiesVM;
}> = ({ vm }) => {
  const allKnownModalities = useQuery(api.User.Hp.HpFns.listAllKnownModalities);

  React.useEffect(() => {
    if (allKnownModalities !== undefined) {
      vm.allKnownModalitiesLoaded([...allKnownModalities.modalities]);
    }
  }, [allKnownModalities]);

  const selectedModalities = useUnit(vm.$selectedModalities);
  const availableModalities = useUnit(vm.$allKnownModalities);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full">
      <Combobox
        as="div"
        className="relative max-w-full"
        value={selectedModalities}
        onChange={() => {}}
      >
        <Combobox.Button
          onClick={() => setIsOpen(!isOpen)}
          className={`
            w-full px-4 py-4 text-left bg-white border rounded-md shadow-sm hover:bg-gray-50 
            focus:outline-none focus:ring-2 focus:vid-purple
            `}
        >
          <div className="flex justify-between items-center overflow-hidden">
            <div className="flex flex-wrap items-center gap-2 min-w-0">
              {selectedModalities && selectedModalities.length > 0 ? (
                <>
                  {selectedModalities.map((modality) => (
                    <ModalityPill key={modality.slug} name={modality.name} />
                  ))}
                </>
              ) : (
                "Select Modalities"
              )}
            </div>
            <img src="/arrow-down.svg" alt="arrow down" />
          </div>
        </Combobox.Button>

        {isOpen && (
          <Combobox.Options
            static
            className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
          >
            <div className="p-1">
              {availableModalities.map((modality) => (
                <div
                  key={modality.slug}
                  className="w-full px-4 py-2 text-left flex items-center gap-2 rounded-md hover:bg-gray-100 cursor-pointer"
                  onClick={() => vm.selectModality(modality.slug)}
                >
                  {vm.isSelected(modality.slug) ? (
                    <BlackCheckboxSVG />
                  ) : (
                    <input
                      type="checkbox"
                      checked={false}
                      readOnly
                      className="rounded"
                    />
                  )}
                  <span>{modality.name}</span>
                </div>
              ))}
            </div>
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
};

const BlackCheckboxSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect width={20} height={20} rx={6} fill="#1D1626" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4994 6.68816C14.6318 6.82728 14.7062 7.01594 14.7062 7.21266C14.7062 7.40937 14.6318 7.59804 14.4994 7.73716L9.20571 13.2977C9.13575 13.3712 9.05269 13.4295 8.96128 13.4693C8.86987 13.5091 8.77189 13.5295 8.67295 13.5295C8.574 13.5295 8.47602 13.5091 8.38461 13.4693C8.2932 13.4295 8.21014 13.3712 8.14019 13.2977L5.51005 10.5355C5.44259 10.467 5.38879 10.3852 5.35177 10.2947C5.31476 10.2042 5.29528 10.1068 5.29446 10.0083C5.29365 9.9098 5.31152 9.81211 5.34703 9.72094C5.38254 9.62977 5.43498 9.54694 5.50129 9.47728C5.56761 9.40763 5.64646 9.35254 5.73326 9.31524C5.82006 9.27794 5.91306 9.25917 6.00684 9.26002C6.10062 9.26088 6.19329 9.28134 6.27946 9.32022C6.36563 9.3591 6.44356 9.41562 6.50871 9.48648L8.67271 11.7596L13.5003 6.68816C13.5659 6.61922 13.6437 6.56453 13.7295 6.52722C13.8152 6.48991 13.9071 6.4707 13.9998 6.4707C14.0926 6.4707 14.1845 6.48991 14.2702 6.52722C14.3559 6.56453 14.4338 6.61922 14.4994 6.68816Z"
      fill="white"
    />
  </svg>
);
