import { useUMgr } from "@pages/u/u.webstatemgr";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { Match, Option } from "effect";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useMemo, useState } from "react";
import { IoMdArrowUp, IoMdRemove } from "react-icons/io";
import { IoChevronBack, IoChevronDown, IoChevronUp } from "react-icons/io5";
import { ImageSrc } from "shared/types/miscellaneous.types";
import * as stream from "stream-chat";
import {
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelPreviewUIComponentProps,
  Chat,
  DefaultStreamChatGenerics,
  MessageInput,
  MessageList,
  Thread,
  useTranslationContext,
  Window,
} from "stream-chat-react";

interface Props {
  myProfile: { id: string; name: string; profilePhoto: string | null };
  onMinimizeClick: () => void;
  isMinimized: boolean;
  chatClient: stream.StreamChat;
}

type ChannelViewState =
  | { _tag: "LIST" }
  | { _tag: "CHANNEL"; channel: stream.Channel };

export const CommunityTabMessenger: React.FC<Props> = ({
  myProfile,
  onMinimizeClick,
  isMinimized,
  chatClient,
}) => {
  const uMgr = useUMgr();
  const [viewState, setViewState] = React.useState<ChannelViewState>({
    _tag: "LIST",
  });

  const onChannelListItemClick = (channel: stream.Channel) => {
    setViewState({ _tag: "CHANNEL", channel });
  };
  const unreadCountTracker = useObservableEagerState(
    uMgr.chatStateMgr.unreadCount$
  );

  return (
    <div className="flex flex-col ">
      <div className="flex justify-between items-center px-6  py-4 h-[80px] bg-vid-purple ">
        <div className="flex-1 flex items-center gap-4">
          <AvatarCircle
            size={56}
            mbProfilePhoto={ImageSrc.fromMbUrl(myProfile.profilePhoto)}
          />
          <div className="flex gap-9">
            <h4 className="text-white font-sans text-sm">Messaging</h4>
            {Option.isSome(unreadCountTracker) &&
              unreadCountTracker.value
                .totalChannelsWithAtLeastOneUnreadMessage > 0 && (
                <div className="w-6 h-6 bg-btn-red text-white font-sans text-sm  rounded-full flex items-center justify-center ">
                  {
                    unreadCountTracker.value
                      .totalChannelsWithAtLeastOneUnreadMessage
                  }
                </div>
              )}
          </div>
        </div>

        {Match.value(viewState).pipe(
          Match.tag("LIST", () => (
            <button
              onClick={() => {
                onMinimizeClick();
              }}
            >
              {viewState._tag === "CHANNEL" ? (
                <IoChevronBack size={24} color="white" />
              ) : isMinimized ? (
                <IoChevronUp size={24} color="white" />
              ) : (
                <IoChevronDown size={24} color="white" />
              )}
            </button>
          )),
          Match.tag("CHANNEL", () => (
            <button
              onClick={() => {
                setViewState({ _tag: "LIST" });
              }}
            >
              <IoChevronBack size={24} color="white" />
            </button>
          )),
          Match.exhaustive
        )}
        {/* <button onClick={() => onMinimizeClick()}>
          {isMinimized ? (
            <IoChevronUp size={24} color="white" />
          ) : (
            <IoChevronDown size={24} color="white" />
          )}
        </button> */}
      </div>
      <Chat client={chatClient}>
        {Match.value(viewState).pipe(
          Match.tag("LIST", () => (
            <ChannelList
              filters={{ members: { $in: [myProfile.id] } }}
              Preview={(props) => (
                <CustomChannelPreview
                  {...props}
                  onChannelListItemClick={onChannelListItemClick}
                />
              )}
            />
          )),
          Match.tag("CHANNEL", (res) => (
            <Channel channel={res.channel}>
              <Window>
                <ChannelHeader />
                <MessageList />
                <MessageInput />
              </Window>
              <Thread />
            </Channel>
          )),
          Match.exhaustive
        )}
      </Chat>
    </div>
  );
};

const CustomChannelPreview: React.FC<
  ChannelPreviewUIComponentProps<DefaultStreamChatGenerics> & {
    onChannelListItemClick: (channel: stream.Channel) => void;
  }
> = (props) => {
  const {
    channel,
    activeChannel,
    displayImage,
    displayTitle,
    latestMessage,
    setActiveChannel,
    onChannelListItemClick,
  } = props;
  const latestMessageAt = channel.state.last_message_at;
  const isSelected = channel.id === activeChannel?.id;
  const { userLanguage } = useTranslationContext();

  const timestamp = useMemo(() => {
    if (!latestMessageAt) {
      return "";
    }
    const formatter = new Intl.DateTimeFormat(userLanguage, {
      timeStyle: "short",
    });
    return formatter.format(latestMessageAt);
  }, [latestMessageAt, userLanguage]);

  const handleClick = () => {
    console.log("HANDLING CLICK! ", channel);
    setActiveChannel?.(channel);
    onChannelListItemClick(channel);
  };

  return (
    <button
      className={`
        flex items-center gap-4
        border w-full p-6 cursor-pointer
        ${isSelected ? "channel-preview_selected" : ""} `}
      // disabled={isSelected}
      onClick={handleClick}
    >
      <div className="w-[56px] h-[56px] rounded-full overflow-hidden border ">
        {displayImage ? (
          <img
            className="w-[56px] h-[56px] rounded-full"
            src={displayImage}
            alt=""
          />
        ) : (
          <div></div>
        )}
      </div>
      <div className="flex-1 flex flex-col items-start gap-2">
        <div className="font-bold text-sm">
          {props.channel.data?.name ?? displayTitle ?? ""}
        </div>
        <div className="channel-preview__message">{latestMessage}</div>
      </div>
      <time dateTime={latestMessageAt?.toISOString()} className="">
        {timestamp}
      </time>
    </button>
  );
};
