import { Effect, Match, Option } from "effect";
import type { UnknownException } from "effect/Cause";
import {
  initializeApp,
  type FirebaseApp,
  type FirebaseOptions,
} from "firebase/app";
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  getIdToken,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  updatePassword,
  type Auth,
  type User,
  type UserCredential,
} from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { pipe } from "fp-ts/function";
import * as Rx from "rxjs";
import { epipe, taskEitherToEff, TE } from "shared/base-prelude";
import type { AppEnv } from "shared/types/app-env.types";
import { createContextAndHook } from "./util";

type FirebaseCheckAuthState =
  | { _tag: "UNKNOWN" }
  | { _tag: "KNOWN"; authState: FirebaseAuthState };

type FirebaseAuthState =
  | { _tag: "LOGGED_IN"; user: User }
  | { _tag: "LOGGED_OUT" };

export type FirebaseAuthErrorCode =
  (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];

export interface FirebaseAuthError {
  code: FirebaseAuthErrorCode;
}

export type CreateUserWithEmailPasswordError = {
  _tag: "Error";
  error: FirebaseAuthError;
};

export class FirebaseJsMgr {
  config: FirebaseOptions;
  app: FirebaseApp;
  auth: Auth;
  firestore: Firestore;

  firebaseAuthState$ = new Rx.BehaviorSubject<FirebaseCheckAuthState>({
    _tag: "UNKNOWN",
  });

  constructor(
    appEnv: AppEnv,
    mkAuth: (app: FirebaseApp) => Auth,
    mbAppName?: "Vidalify" | "InsightLive"
  ) {
    this.config = configForAppEnv(appEnv, mbAppName);

    console.log("USING FIREBASE CONFIG! ", this.config);

    this.app = initializeApp(this.config);
    this.auth = mkAuth(this.app);
    this.firestore = getFirestore(this.app);

    onAuthStateChanged(this.auth, (user) => {
      this.firebaseAuthState$.next(
        user
          ? { _tag: "KNOWN", authState: { _tag: "LOGGED_IN", user } }
          : { _tag: "KNOWN", authState: { _tag: "LOGGED_OUT" } }
      );
    });
  }

  getKnownFirebaseAuthState(): Promise<FirebaseAuthState> {
    return new Promise((resolve) => {
      if (this.firebaseAuthState$.value._tag === "KNOWN") {
        resolve(this.firebaseAuthState$.value.authState);
      } else {
        const unsub = this.firebaseAuthState$.subscribe((authState) => {
          if (authState._tag === "KNOWN") {
            resolve(authState.authState);
            unsub.unsubscribe();
          }
        });
      }
    });
  }

  getFirebaseUser = async (): Promise<User | null> => {
    const authState = await this.getKnownFirebaseAuthState();
    return authState._tag === "LOGGED_IN" ? authState.user : null;
  };

  private getFirebaseAuthToken = async (
    forceRefreshToken?: boolean
  ): Promise<string | null> => {
    const mbUser = await this.getFirebaseUser();

    if (mbUser) {
      return getIdToken(mbUser, forceRefreshToken);
    }

    return null;
  };

  sendPasswordResetEmailEff = (email: string) =>
    epipe(
      Effect.tryPromise({
        try: () => sendPasswordResetEmail(this.auth, email),
        catch: (e) => e as unknown as { code?: FirebaseAuthErrorCode },
      })
    );

  getFirebaseAuthTokenTE = TE.tryCatch(
    this.getFirebaseAuthToken,
    (e) => e as Error
  );

  signinAnonymouslyTE = TE.tryCatch(
    () => signInAnonymously(this.auth),
    (e) => e as Error
  );

  updatePasswordTE = (user: User, password: string) =>
    TE.tryCatch(
      () => updatePassword(user, password),
      (e) => e as Error
    );

  getAssumedFirebaseTokenTE = pipe(
    this.getFirebaseAuthTokenTE,
    TE.chain((mbToken) => {
      if (mbToken) {
        return TE.right(mbToken);
      }

      return TE.left(new Error("No Firebase Token"));
    })
  );

  getFirebaseTokenEff: Effect.Effect<Option.Option<string>, UnknownException> =
    Effect.tryPromise(() => this.getFirebaseAuthToken()).pipe(
      Effect.map(Option.fromNullable)
    );

  createUserWithEmailAndPasswordTE = (p: { email: string; password: string }) =>
    TE.tryCatch(
      () => createUserWithEmailAndPassword(this.auth, p.email, p.password),
      (e) => e as FirebaseAuthError
    );

  createUserWithEmailAndPasswordEff = (p: {
    email: string;
    password: string;
  }): Effect.Effect<UserCredential, FirebaseAuthError, never> =>
    taskEitherToEff(this.createUserWithEmailAndPasswordTE(p));

  signinWithEmailPasswordEff = (p: { email: string; password: string }) =>
    Effect.tryPromise({
      try: () => signInWithEmailAndPassword(this.auth, p.email, p.password),
      catch: (e) => e as FirebaseAuthError,
    });

  signinWithCustomTokenEff = (token: string) =>
    Effect.tryPromise({
      try: () => signInWithCustomToken(this.auth, token),
      catch: (e) => e as FirebaseAuthError,
    });

  signOut() {
    return signOut(this.auth);
  }

  signinWithEmailLinkEff = (p: { email: string; href: string }) =>
    Effect.tryPromise({
      try: () => signInWithEmailLink(this.auth, p.email, p.href),
      catch: (e) => e as FirebaseAuthError, // TODO: better error handling
    });

  authTokenFetcher = (args: { forceRefreshToken: boolean }) =>
    this.getFirebaseAuthToken(args.forceRefreshToken);
}

export const [FirebaseJsContext, useFirebaseJs] =
  createContextAndHook<FirebaseJsMgr>();

// export const firebaseApp = initializeApp(prodFirebaseConfig);

export function isAlreadyCreatedError(error: FirebaseAuthError) {
  const validCodes = [
    AuthErrorCodes.EMAIL_EXISTS,
    AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE,
  ] as FirebaseAuthErrorCode[];
  return validCodes.includes(error.code);
}

export const messageForFirebaseAuthError = (
  code: FirebaseAuthErrorCode
): string =>
  Match.value(code).pipe(
    Match.when(AuthErrorCodes.EMAIL_EXISTS, () => "Email already exists"),
    Match.when(
      AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE,
      () => "Email already exists"
    ),
    Match.when(
      AuthErrorCodes.CREDENTIAL_MISMATCH,
      () => "Email or password is incorrect"
    ),
    Match.when(
      AuthErrorCodes.INVALID_LOGIN_CREDENTIALS,
      () => "Invalid login email or password"
    ),
    Match.when(AuthErrorCodes.INVALID_EMAIL, () => "Invalid email"),
    Match.when(AuthErrorCodes.INVALID_PASSWORD, () => "Invalid password"),
    Match.when(AuthErrorCodes.USER_DISABLED, () => "User is disabled"),
    Match.when(AuthErrorCodes.NULL_USER, () => "User not found"),
    Match.when(AuthErrorCodes.WEAK_PASSWORD, () => "Password is too weak"),
    Match.when(
      AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER,
      () => "Too many requests. Please try again later."
    ),
    Match.when(
      AuthErrorCodes.OPERATION_NOT_ALLOWED,
      () => "Operation not allowed"
    ),
    Match.when(AuthErrorCodes.INVALID_CODE, () => "Invalid code"),
    Match.orElse(() => {
      if ((code as string) === "auth/missing-password") {
        return "Missing password";
      }
      if ((code as string) === "auth/invalid-action-code") {
        return "Link has already been used or expired";
      }
      return `Authentication error. Please try again later. (code: ${code})`;
    })
  );

function configForAppEnv(
  appEnv: AppEnv,
  mbAppName?: "Vidalify" | "InsightLive"
): FirebaseOptions {
  return Match.value(appEnv).pipe(
    Match.when("prod", () =>
      mbAppName === "Vidalify" ? vidalifyProdFirebaseConfig : prodFirebaseConfig
    ),
    Match.when("dev", () =>
      mbAppName === "Vidalify" ? devFirebaseConfig : devIlFirebaseConfig
    ),
    Match.exhaustive
  );
}

const vidalifyProdFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCk6YF6j31i2Nxm55J7Ur4J1BTuP9KEpis",
  authDomain: "webapp-prod-deb9c.firebaseapp.com",
  projectId: "webapp-prod-deb9c",
  storageBucket: "webapp-prod-deb9c.appspot.com",
  messagingSenderId: "244231368260",
  appId: "1:244231368260:web:08408b86668af36c40d611",
  measurementId: "G-TV3RQ19WHE",
};

const prodFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCBwI45Ks5i_XUx-HqieQ63pIC1u5OwQEE",
  authDomain: "prod-46796.firebaseapp.com",
  projectId: "prod-46796",
  storageBucket: "prod-46796.appspot.com",
  messagingSenderId: "151843005587",
  appId: "1:151843005587:web:5c0008f426a1699861d966",
};

const devFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDSxb_ld5D8TFsa7U_idGfbpd9Sowfdw7Y",
  authDomain: "webapp-dev-b61bf.firebaseapp.com",
  projectId: "webapp-dev-b61bf",
  storageBucket: "webapp-dev-b61bf.appspot.com",
  messagingSenderId: "335821848330",
  appId: "1:335821848330:web:c15e46893bb972e0fd04f3",
};

const devIlFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDE7idT9SnGzwxlAJr06yt6kf-GAHz6Fpw",
  authDomain: "development-45e70.firebaseapp.com",
  projectId: "development-45e70",
  storageBucket: "development-45e70.appspot.com",
  messagingSenderId: "602409518410",
  appId: "1:602409518410:web:6c0a705b96a7ed43e06db3",
};
