import { HpLayout } from "@pages/u/hp/hp.layout";
import { USessionRtcPage } from "@pages/u/sessions/[sessionId]/rtc/rtc.page";
import { ULayout } from "@pages/u/u.layout";
import { type RouteObject } from "react-router-dom";
import { HPROUTES } from "shared/routes/routes";
import { UROUTES, USESSIONS_ROUTES } from "shared/routes/u.routes";
import { cpRoutes } from "./cp-route-views";
import { hpRoutes } from "./hp-route-views";

console.log("UROUTES", UROUTES.path);
console.log("HPROUTES", HPROUTES.path);

export const uRouteViews: RouteObject = {
  path: UROUTES.path,
  element: <ULayout />,
  children: [
    {
      element: <HpLayout />,
      children: hpRoutes,
    },
    cpRoutes,
    {
      path: USESSIONS_ROUTES.SESSION_ID.RTC.relativePath,
      element: <USessionRtcPage />,
    },
  ],
};
