import * as RD from "@devexperts/remote-data-ts";
import { Effect, pipe as epipe } from "effect";
import * as Arr from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as Rarr from "fp-ts/ReadonlyArray";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import { pipe } from "fp-ts/pipeable";

const erp = Effect.runPromise;

const ETP = Effect.tryPromise;

export function effectToTaskEither<E, V>(
  eff: Effect.Effect<V, E, never>
): TE.TaskEither<E, V> {
  return () =>
    erp(
      Effect.match(eff, {
        onFailure: (e) => E.left(e),
        onSuccess: (v) => E.right(v),
      })
    );
}

function runEff<V, E>(p: {
  eff: Effect.Effect<V, E, never>;
  onSuccess: (v: V) => void;
  onFailure: (e: E) => void;
}) {
  const vf = Effect.match(p.eff, {
    onFailure: p.onFailure,
    onSuccess: p.onSuccess,
  });

  Effect.runPromise(vf).catch();
}

export function taskEitherToEff<E, V>(
  te: TE.TaskEither<E, V>
): Effect.Effect<V, E, never> {
  return epipe(
    Effect.promise(te),
    Effect.flatMap((eres) =>
      E.isLeft(eres) ? Effect.fail(eres.left) : Effect.succeed(eres.right)
    )
  );
}

export async function runTaskEither<E, V>(te: TE.TaskEither<E, V>): Promise<V> {
  const er = await te();

  if (E.isLeft(er)) {
    throw er.left;
  }

  return er.right;
}

export {
  Arr,
  E,
  epipe,
  erp,
  effectToTaskEither as erte,
  ETP,
  O,
  pipe,
  Rarr,
  RD,
  runEff,
  T,
  TE,
};
