import { Match } from "effect";
import type { ChatStateMgr } from "frontend-shared/src/mgrs/state-mgrs/chat.statemgr";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useMemo } from "react";
import type { Id } from "shared/convex/_generated/dataModel";
import {
  CurrentOpenMenuST,
  MenuViewStateST,
} from "shared/schemas/session/ongoing/participant/participant-display.schemas";
import type { StreamChat } from "stream-chat";
import { RxO } from "../../../../prelude";
import { SettingsRightNav } from "../../live-session.components";
import { MainRoomToolMenu } from "../tool-menu.fc";

interface MainRoomRightNavProps {
  baseSessionId: Id<"sessionConfig">;
  chatStateMgr: ChatStateMgr<StreamChat>;
  mainRoomStateMgr: MainRoomStateMgr;
  settings: {
    shareableLink: string;
  };
}

export const MainRoomRightNav = ({
  baseSessionId,
  chatStateMgr,
  mainRoomStateMgr,
  settings,
}: MainRoomRightNavProps) => {
  const currentOpenMenuViewState$ = useOnce(() =>
    mainRoomStateMgr.stateSyncMgr.participantSyncMgr.openMenuMgr.state$.pipe(
      RxO.map((s) => s.viewState),
      RxO.startWith(null)
    )
  );

  const currentOpenMenuViewState = useObservableEagerState(
    currentOpenMenuViewState$
  );

  const toolMenuViewState = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.toolMenuViewStateSyncMgr.state$
  );

  const menuViewState = useMemo(
    () =>
      MenuViewStateST.from({
        toolMenuViewState,
        currentOpenMenu: CurrentOpenMenuST.from(currentOpenMenuViewState),
      }),
    [toolMenuViewState, currentOpenMenuViewState]
  );

  return Match.value(menuViewState.viewState).pipe(
    Match.when({ tag: "CLOSED" }, () => null),
    Match.when({ tag: "TOOLS" }, () => {
      return (
        <MenuContainer
          menuTitle={menuViewState.menuTitle}
          onClose={() => {
            mainRoomStateMgr.closePanel();
          }}
          onBackClick={
            menuViewState.backDestination
              ? () => {
                  mainRoomStateMgr.stateSyncMgr.setMenuViewState(
                    menuViewState.backDestination!
                  );
                }
              : undefined
          }
          content={
            <MainRoomToolMenu
              baseSessionId={baseSessionId}
              chatStateMgr={chatStateMgr}
              mainRoomRSM={mainRoomStateMgr.stateSyncMgr}
              emdrMgr={mainRoomStateMgr.tools.emdrMgr}
              meditationMgr={mainRoomStateMgr.tools.meditationMgr}
            />
          }
        />
      );
    }),
    Match.when({ tag: "SETTINGS" }, () => {
      return (
        <MenuContainer
          menuTitle="Settings"
          onClose={() => {
            mainRoomStateMgr.closePanel();
          }}
          content={
            <SettingsRightNav
              shareableLink={settings.shareableLink}
              setRemoteIsSharingAudioOnly={(isSharingAudioOnly) => {
                mainRoomStateMgr.stateSyncMgr.baseSessionConfigMgr.setIsAudioOnlySession(
                  isSharingAudioOnly
                );
              }}
            />
          }
        />
      );
    }),
    Match.exhaustive
  );
};

const MenuContainer: React.FC<{
  menuTitle: string;
  onBackClick?: () => void;
  onClose: () => void;
  content: React.ReactNode;
}> = ({ menuTitle, onBackClick, onClose, content }) => {
  return (
    <div className="flex-1 w-full md:w-[360px] bg-white rounded-[12px] flex flex-col overflow-y-auto">
      <div className="flex items-center px-4 py-6">
        <div className="w-8">
          {onBackClick && (
            <button
              className="w-8 h-8 flex items-center justify-center"
              onClick={onBackClick}
            >
              <img src="/arrow-left.svg" alt="Back" className="w-6 h-6" />
            </button>
          )}
        </div>
        <h4 className="flex-1 text-center text-[22px] font-sans font-normal leading-[120%] text-vid-black-900">
          {menuTitle}
        </h4>
        <button
          className="w-9 h-9 flex items-center justify-center"
          onClick={() => {
            onClose();
          }}
        >
          <img src="/close.svg" alt="Close" />
        </button>
      </div>
      <div className="flex-1 flex flex-col min-h-0 items-center px-4 overflow-y-auto pb-4">
        {content}
      </div>
    </div>
  );
};
