export const InsightLiveLogoWithText: React.FC<{}> = () => {
  return (
    <div className="flex items-center gap-2 ">
      <img src="/il-logo.svg" alt="InsightLive" />
    </div>
  );
};

export const InsightLiveSvgIcon: React.FC<{}> = () => {
  return <img src="/il-logo.svg" alt="InsightLive" />;
};
