import { combine, createEffect, createEvent, createStore } from "effector";
import type {
  FileLike,
  UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";

export interface MediaSelectDeps {
  // Updated to support both photo and video selection
  selectMedia: (type: "photo" | "video") => Promise<FileLike | null>;
  // Platform-specific function to get preview URL
  getPreviewUrl: (file: FileLike) => string;
  // Platform-specific upload implementation
  uploadToCloudinary: (
    file: FileLike,
    uploadUrl: string
  ) => Promise<UploadFileToCloudinaryResponse>;
}

// src/features/profilePhoto/ProfilePhotoModel.ts

export class MediaSelectAndUploadVM {
  // Updated events
  public readonly selectMediaRequested = createEvent<"photo" | "video">();
  public readonly setInitialProfilePhotoPublicId = createEvent<{
    publicId: string;
    downloadUrl: string;
  }>();
  public readonly reset = createEvent();

  // Updated effects
  private readonly selectMediaFx = createEffect<
    "photo" | "video",
    FileLike | null
  >();

  private readonly getPresignedUrlFx = createEffect<
    void,
    { uploadUrl: string }
  >();

  private readonly uploadToCloudinaryFx = createEffect<
    { file: FileLike; uploadUrl: string },
    UploadFileToCloudinaryResponse
  >();

  private readonly getProcessedImageUrlFx = createEffect<
    string, // public_id as input
    { processedUrl: string }
  >();

  private readonly preCacheImageFx = createEffect<string, void>();

  // New store for media type
  public readonly $mediaType = createStore<"photo" | "video" | null>(null)
    .on(this.selectMediaRequested, (_, type) => type)
    .reset(this.reset);

  // Updated stores
  public readonly $selectedFile = createStore<FileLike | null>(null)
    .on(this.selectMediaFx.doneData, (_, file) => file)
    .reset(this.reset);

  public readonly $previewUrl = createStore<string | null>(null)
    .on(this.$selectedFile, (_, file) => {
      console.log("getPreviewUrl", file);

      const previewUrl = file ? this.deps.getPreviewUrl(file) : null;
      console.log("DERIVED PREVIEW URL! ", previewUrl);
      return previewUrl;
    })
    .reset(this.reset);

  public readonly $uploadUrl = createStore<string | null>(null)
    .on(this.getPresignedUrlFx.doneData, (_, data) => data.uploadUrl)
    .reset(this.reset);

  public readonly $publicId = createStore<string | null>(null)
    .on(this.uploadToCloudinaryFx.doneData, (_, data) => data.public_id)
    .on(this.setInitialProfilePhotoPublicId, (_, data) => data.publicId)
    .reset(this.reset);

  public readonly $uploadedUrl = createStore<string | null>(null)
    .on(this.uploadToCloudinaryFx.doneData, (_, data) => data.secure_url)
    .reset(this.reset)
    .reset(this.selectMediaRequested);

  public readonly $processedImageUrl = createStore<string | null>(null)
    .on(this.getProcessedImageUrlFx.doneData, (_, data) => data.processedUrl)
    .on(this.setInitialProfilePhotoPublicId, (_, data) => data.downloadUrl)
    .reset(this.reset)
    .reset(this.selectMediaRequested);

  public readonly $isLoading = combine(
    this.selectMediaFx.pending,
    this.getPresignedUrlFx.pending,
    this.uploadToCloudinaryFx.pending,
    (isSelecting, isGettingUrl, isUploading) =>
      isSelecting || isGettingUrl || isUploading
  );

  public readonly $isProcessing = combine(
    this.getPresignedUrlFx.pending,
    this.uploadToCloudinaryFx.pending,
    this.getProcessedImageUrlFx.pending,
    this.preCacheImageFx.pending,
    this.$mediaType,
    (
      isGettingUrl,
      isUploading,
      isGettingProcessedUrl,
      isPreCaching,
      mediaType
    ) =>
      isGettingUrl ||
      isUploading ||
      isGettingProcessedUrl ||
      // Only include pre-caching in processing state for photos
      (mediaType === "photo" ? isPreCaching : false)
  );

  public readonly $displayPhotoUrl = combine(
    this.$previewUrl,
    this.$uploadedUrl,
    this.$processedImageUrl,
    this.$isProcessing,
    (previewUrl, uploadedUrl, processedUrl, isProcessing) => {
      console.log("IS PROCESSING", isProcessing);
      if (processedUrl) return processedUrl; // Show processed image if available
      if (uploadedUrl) return uploadedUrl; // Show uploaded image while waiting for processing
      if (previewUrl) return previewUrl; // Show preview before processing starts
      return null; // No image to show
    }
  );

  public readonly $error = createStore<string | null>(null)
    .on(this.selectMediaFx.failData, (_, error) => error.message)
    .on(this.getPresignedUrlFx.failData, (_, error) => error.message)
    .on(this.uploadToCloudinaryFx.failData, (_, error) => error.message)
    .reset(this.selectMediaRequested)
    .reset(this.reset);

  constructor(
    private readonly deps: MediaSelectDeps,
    readonly getPresignedUrl: () => Promise<{
      uploadUrl: string;
    }>,
    readonly getProcessedImageUrl: (
      publicId: string
    ) => Promise<{ processedUrl: string }>
  ) {
    // Configure effects
    this.selectMediaFx.use(async (type) => {
      return await this.deps.selectMedia(type);
    });

    this.getPresignedUrlFx.use(async () => {
      return await getPresignedUrl();
    });

    this.uploadToCloudinaryFx.use(async ({ file, uploadUrl }) => {
      return await this.deps.uploadToCloudinary(file, uploadUrl);
    });

    this.getProcessedImageUrlFx.use(async (publicId) => {
      return await getProcessedImageUrl(publicId);
    });

    this.preCacheImageFx.use(async (imageUrl) => {
      await fetch(imageUrl);
    });

    // Updated flow
    this.selectMediaRequested.watch((type) => {
      this.selectMediaFx(type);
    });

    // When photo is selected, get presigned URL
    this.$selectedFile.watch((file) => {
      if (file) {
        this.getPresignedUrlFx();
      }
    });

    // When we have both file and upload URL, start upload
    combine(this.$selectedFile, this.$uploadUrl, (file, uploadUrl) => ({
      file,
      uploadUrl,
    })).watch(({ file, uploadUrl }) => {
      if (file && uploadUrl) {
        this.uploadToCloudinaryFx({ file, uploadUrl });
      }
    });

    // When upload completes, fetch the processed image URL
    this.$publicId.watch((publicId) => {
      if (publicId) {
        this.getProcessedImageUrlFx(publicId);
      }
    });

    // Only pre-cache images, not videos
    combine(this.$processedImageUrl, this.$mediaType, (url, mediaType) => ({
      url,
      mediaType,
    })).watch(({ url, mediaType }) => {
      if (url && mediaType === "photo") {
        this.preCacheImageFx(url);
      }
    });
  }
}
