import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { useMe, useMyId } from "@pages/u/u.webstatemgr";
import { apiMgr, BE } from "@webapp/backend";
import {
  AvatarCircle,
  AvatarCirclesWithCountInfo,
} from "@webapp/components/avatar.tc";
import { PrimaryButton } from "@webapp/components/primitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { Effect, Match } from "effect";
import {
  CommunitySamContext,
  CommunityStateMgr,
  useCommunitySam,
  type CommunityPageTab,
} from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import { type CommunityInfo } from "shared/schemas/community/community.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { ScreenActions } from "shared/types/screen-action.types";
import { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { useConvexCli } from "src/convex-cli";
import { CommunityPostCvx } from "../components/community-post.fc";
import {
  MemberCard,
  MyCommunitiesProfileCard,
  TabView,
  TopBanner,
} from "../components/community.components";

export const HpDashboardCommunityPageLayout: React.FC = () => {
  const communitySlug = useParams()["community"]!;

  const communityInfo = useQuery(
    api.Community.CommunityScreenFns.getCommunityInfo,
    {
      communitySlug,
    }
  );

  if (communityInfo === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <LoadedView community={communityInfo} />;
};

const LoadedView: React.FC<{ community: CommunityInfo }> = ({ community }) => {
  const myId = useMyId();
  const convex = useConvexCli();
  const sam = useOnce(
    () => new CommunityStateMgr(community, apiMgr, convex, myId)
  );
  return (
    <CommunitySamContext.Provider value={sam}>
      <HpDashboardCommunityTabLayout />
    </CommunitySamContext.Provider>
  );
};

const HpDashboardCommunityTabLayout: React.FC = () => {
  const location = useLocation();
  const sam = useCommunitySam();
  const hpState = useHpState();
  const [{ action }] = useTypedSearchParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
  );
  const isJoiningOrUnjoining = useObservableEagerState(
    sam.isJoiningOrUnjoining$
  );
  const community = useObservableEagerState(sam.community$);

  const onJoinCommunityButtonClick = useMutation(
    api.Community.CommunityScreenFns.onJoinButtonClick
  );

  React.useEffect(() => {
    if (action) {
      const asActionObj =
        ScreenActions.CommunityScreen.decodeActionFromString(action);

      if (asActionObj.autoJoin === "true" && !community.amIJoined) {
        onJoinCommunityButtonClick({
          communitySlug: community.slug,
          joinAction: "join",
        }).catch();
      }
    }
  }, [action, community.amIJoined]);

  return (
    <div className="min-h-0 flex-1 p-[46px] flex flex-col gap-8 max-w-[1180px] relative overflow-y-auto">
      <TopBanner
        isPrivate={community.isPrivate}
        circleImageUrl={null}
        title={community.name}
        communityColorName={community.color}
        nextToTitleFc={
          <Link
            to={
              UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .MEMBERS.path
            }
          >
            <AvatarCirclesWithCountInfo
              sources={community.recentMembers
                .slice(0, 3)
                .map((m) => m.profilePhoto)}
              countText={`${community.recentMembers.length} Members`}
              size={40}
            />
          </Link>
        }
        rightView={
          <div>
            <button
              className="justify-center self-end px-10 py-4 lg:mt-16 text-base font-medium leading-5 text-center text-vid-purple whitespace-nowrap bg-white rounded-xl border border-solid border-vid-purple max-md:px-5"
              onClick={() => {
                const joinAction = community.amIJoined ? "unjoin" : "join";
                const action = onJoinCommunityButtonClick({
                  communitySlug: community.slug,
                  joinAction,
                });

                action.then((_) => {
                  hpState.dashboardState.showBottomToast({
                    msg: joinAction === "join" ? "Joined" : "Unjoined",
                    duration: { _tag: "SECONDS", seconds: 1 },
                    reload: true,
                  });
                });
              }}
            >
              {community.amIJoined ? "Joined" : "Join"}
            </button>
            {/* <button
              onClick={() => {
                sam.hardDeleteCommunity(() => {
                  window.location.href = "/";
                });
              }}
            >
              Delete
            </button> */}
          </div>
        }
      />
      {isJoiningOrUnjoining ? (
        <FullContainerLoadingSpinner />
      ) : (
        <div className="flex flex-col gap-8">
          <Tabs />
          <div className="flex gap-8 self-stretch">
            <div className="flex-1 flex flex-col min-w-0">
              {community.amIJoined ? (
                <Outlet />
              ) : (
                <div className="basis-[300px] grow-0 shrink-0 flex flex-col py-8 items-center">
                  Join to view the discussion
                </div>
              )}
            </div>
            {!location.pathname.includes("events") && <MyProfile />}
          </div>
        </div>
      )}
    </div>
  );
};

const Tab: React.FC<{ tab: CommunityPageTab; isCurrent?: boolean }> = ({
  tab,
}) => {
  const sam = useCommunitySam();
  const community = useObservableEagerState(sam.community$);
  const { pathname } = useLocation();
  const isCurrent = React.useMemo(
    () => pathname.toLowerCase().includes(tab.toLowerCase()),
    [pathname, tab]
  );

  return (
    <TabView
      title={tab}
      linkTo={Match.value(tab).pipe(
        Match.when("Discussion", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
            { community: community.slug }
          )
        ),
        Match.when("Learning", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.LEARNING.buildPath(
            { community: community.slug }
          )
        ),
        Match.when("Members", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS.buildPath(
            { community: community.slug }
          )
        ),
        Match.when("Events", () =>
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL.buildPath(
            { community: community.slug }
          )
        ),
        Match.exhaustive
      )}
      isCurrent={isCurrent}
      onClick={() => {}}
    />
  );
};

const Tabs: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-4">
      <Tab tab="Discussion" />
      <Tab tab="Events" />
      <Tab tab="Members" />
      <Tab tab="Learning" />
    </div>
  );
};

export const CommunityLearningTab: React.FC = () => {
  return <div>Learning!</div>;
};

export const CommunityDiscussionFeed: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col gap-8 overflow-y-auto overscroll-x-none">
      <WriteAPostSection />
      <PostsSection />
    </div>
  );
};

export const CommunityMembersTab: React.FC = () => {
  const sam = useCommunitySam();

  const allMembers = useQuery(
    api.Community.CommunityScreenFns.listAllJoinedMembers,
    {
      communitySlug: sam.community$.value.slug,
    }
  );

  if (allMembers === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <AllMembersTabContent members={allMembers} />;
};

const AllMembersTabContent: React.FC<{
  members: SimpleUserWithProfilePhoto[];
}> = ({ members }) => {
  const InviteSomeoneSection: React.FC = () => {
    const sam = useCommunitySam();
    const community = useObservableEagerState(sam.community$);
    const [email, setEmail] = React.useState("");
    return (
      <div className="flex flex-col border-2 min-h-[500px]">
        <input
          type="email"
          className="border-2"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PrimaryButton
          title="Invite someone"
          onClick={() => {
            Effect.match(
              BE.fetchEndpointEff((Api) =>
                Api.hp.community.sendEmailInvite.mutate({
                  communitySlug: community.slug,
                  email,
                })
              ),
              {
                onFailure: (e) => {
                  console.log("INVITE SOMEONE FAILURE: ", e);
                },
                onSuccess: (r) => {
                  console.log("INVITE SOMEONE SUCCESS: ", r);
                  alert("Invite sent!" + JSON.stringify(r));
                },
              }
            ).pipe(Effect.runSync);

            // nav(UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.INVITE_SOMEONE.buildPath({
            //   community: sam.community.slug
            // }))
          }}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-4 flex-wrap">
        {members.map((m, index) => {
          return (
            <MemberCard
              key={m.id}
              index={index}
              userId={m.id}
              name={m.name!}
              profilePhoto={m.profilePhoto}
              isAvailable={false}
              modalities={[]}
            />
          );
        })}
      </div>
      <InviteSomeoneSection />
    </div>
  );
};

const MyProfile: React.FC = () => {
  const hpState = useHpState();
  const me = useMe();
  const hpProfile = useObservableEagerState(hpState.myProfile$);
  const sam = useCommunitySam();

  const myCommunityProfile = useObservableEagerState(
    sam.myCommunityProfileRSM.state$
  );

  React.useEffect(() => {
    hpState.myProfile$.subscribe((mp) => {
      console.log("MY PROFILE ON MY PROFILE CARD! ", mp);
    });
  }, []);

  return (
    <div>
      <MyCommunitiesProfileCard
        name={me.name!}
        profilePhoto={ImageSrc.fromMbUrl(me.profilePhoto)}
        mode={{ _tag: "COMMUNITY", communitySam: sam }}
        bio={myCommunityProfile.bio}
        modalities={[...myCommunityProfile.modalities]}
      />
    </div>
  );
};

const WriteAPostSection: React.FC = () => {
  const hpState = useHpState();
  const sam = useCommunitySam();
  const community = useObservableEagerState(sam.community$);
  const me = useMe();
  const myId = useMyId();

  return (
    <div
      className="flex gap-4 justify-between px-5 py-4 bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:pl-5 cursor-pointer"
      onClick={() => {
        hpState.dashboardState.openRightNav({
          _tag: "CREATE_COMMUNITY_POST",
          posterId: myId,
          communitySlug: community.slug,
        });
      }}
    >
      <div className="flex gap-3.5 justify-between w-full">
        <div className="self-stretch flex flex-col justify-center">
          <AvatarCircle
            mbProfilePhoto={ImageSrc.fromMbUrl(me.profilePhoto!)}
            size={40}
          />
        </div>
        <div className="grow justify-center items-start py-5 pr-16 pl-7 text-base font-medium leading-5 text-start whitespace-nowrap bg-slate-50 rounded-[500px] text-neutral-400 max-md:px-5 max-md:max-w-full">
          Write a post
        </div>
      </div>
      <div className="flex gap-0 pr-3.5 my-auto text-sm font-medium leading-4 text-center text-violet-800 whitespace-nowrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/66b4435ae7ea40d1c30cb8991b7be7ea34172d0a6d6874a790170499abf47bbc?"
          className="my-auto w-6 aspect-square"
        />
        <div className="grow justify-center px-1.5 py-3 bg-white rounded-xl">
          Add photo/video{" "}
        </div>
      </div>
    </div>
  );
};

const PostsSection: React.FC = () => {
  const sam = useCommunitySam();
  const community = useObservableEagerState(sam.community$);

  const postsRes = useQuery(api.Community.CommunityScreenFns.getCommunityFeed, {
    communitySlug: community.slug,
  });

  if (postsRes === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-8">
        {postsRes.posts.map((post) => (
          <CommunityPostCvx key={post._id} enrichedPost={post} />
        ))}
      </div>
    </div>
  );
};
