import { Match } from "effect";
import type {
  BookendsMgr,
  BookmarksMgr,
} from "frontend-shared/src/mgrs/remote-state-mgrs/sessions/quickaction-tools.rsm";
import { useObservableState } from "observable-hooks";
import {
  BookendsST,
  BookmarksST,
  SessionBookendSchema,
  SessionBookmarkSchema,
} from "shared/schemas/session/annotation.schemas";

export const BookmarksCard: React.FC<{
  bookmarksMgr: BookmarksMgr;
  bookendsMgr: BookendsMgr;
}> = ({ bookmarksMgr, bookendsMgr }) => {
  const bookmarksST = useObservableState(
    bookmarksMgr.state$,
    BookmarksST.default()
  );
  const bookendsST = useObservableState(
    bookendsMgr.state$,
    BookendsST.default()
  );

  if (bookmarksST.bookmarks.length === 0 && bookendsST.bookends.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-[300px] gap-4 border rounded-lg p-4">
        No bookmarks saved for this session.
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 border rounded-lg p-4">
      <div className="flex flex-col gap-2">
        {bookmarksST.bookmarks.map((bookmark) => (
          <SavedBookmarkItem bookmark={bookmark} />
        ))}
      </div>
      <div className="flex flex-col gap-2">
        {bookendsST.bookends.map((bookend) => (
          <div>{bookend.label}</div>
        ))}
      </div>
    </div>
  );
};

export const SavedBookmarkItem: React.FC<{
  bookmark: SessionBookmarkSchema;
}> = ({ bookmark }) => {
  const color = bookmark.color ?? "gray";
  return (
    <div className="flex gap-2 items-center justify-between py-[7px]">
      <div className="flex gap-2 items-center">
        <BookmarkIcon color={color} />
        <div className="text-vid-black-900">{bookmark.label}</div>t{" "}
      </div>
      <div className="text-vid-black-500">{`${bookmark.displayTimeInfo}`}</div>
    </div>
  );
};

export const SavedBookendItem: React.FC<{
  bookend: SessionBookendSchema;
  onLabelChange: (bk: SessionBookendSchema, label: string) => void;
}> = ({ bookend, onLabelChange }) => {
  const color = bookend.color ?? "gray";
  return (
    <div className="flex gap-2 items-center justify-between py-[7px]">
      <div className="flex gap-2 items-center">
        <BookendIcon />
        <div
          className="text-vid-black-900"
          onClick={() => {
            const newLabel = prompt("Enter new label", bookend.label);
            if (newLabel !== null) {
              onLabelChange(bookend, newLabel);
            }
          }}
        >
          {bookend.label}
        </div>
      </div>
      <div className="text-vid-black-500">{`${bookend.displayTimeInfo}`}</div>
    </div>
  );
};

export const BookmarkIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M14.0168 2.16602H5.98351C4.20851 2.16602 2.76685 3.61602 2.76685 5.38268V17.1244C2.76685 18.6243 3.84185 19.2577 5.15851 18.5327L9.22518 16.2744C9.65851 16.0327 10.3585 16.0327 10.7835 16.2744L14.8502 18.5327C16.1668 19.266 17.2418 18.6327 17.2418 17.1244V5.38268C17.2335 3.61602 15.7918 2.16602 14.0168 2.16602Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BookendIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
    >
      <rect
        x="6.16699"
        y="0.833984"
        width="2.08333"
        height="18.3333"
        rx="1.04167"
        fill="#1D1626"
      />
      <rect
        x="0.333496"
        y="4.16797"
        width="2.08333"
        height="11.6667"
        rx="1.04167"
        fill="#1D1626"
      />
      <rect
        x="12"
        y="6.66797"
        width="2.08333"
        height="6.66667"
        rx="1.04167"
        fill="#1D1626"
      />
    </svg>
  );
};

function matchColor(color: string) {
  return Match.value(color).pipe(
    Match.when("red", () => "bg-red-500"),
    Match.when("blue", () => "bg-blue-500"),
    Match.when("green", () => "bg-green-500"),
    Match.when("yellow", () => "bg-yellow-500"),
    Match.when("purple", () => "bg-purple-500"),
    Match.when("orange", () => "bg-orange-500"),
    Match.when("pink", () => "bg-pink-500"),
    Match.when("teal", () => "bg-teal-500"),
    Match.orElse(() => `bg-gray-400`)
  );
}
