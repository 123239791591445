import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { pipe } from "fp-ts/lib/function";
import type { ChatStateMgr } from "frontend-shared/src/mgrs/state-mgrs/chat.statemgr";
import { useObservableEagerState } from "observable-hooks";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { epipe, O } from "shared/base-prelude";
import { ImageSrc } from "shared/types/miscellaneous.types";
import type { StreamChat } from "stream-chat";
import {
  InsightLiveLogoWithText,
  InsightLiveSvgIcon,
} from "../../assets/il-logo-with-text.fc";
import { FullContainerLoadingSpinner } from "../../loading";
import { AvatarCircle } from "../avatar.tc";
import {
  DashboardStateMgr,
  ShowBottomToastConfig,
  TopAlert,
} from "./dashboard.state";

type DashboardLayoutProps<RightNav extends {}> = {
  stateMgr: DashboardStateMgr<RightNav>;
  matchViewForRightNav: (rightNav: RightNav) => {
    view: React.ReactNode;
    width?: string;
  };
  navbar: {
    currentDashboardPage: string;
    middleSection: ReactNode;
    hamburger: {
      links: {
        to: string;
        name: string;
        icon?: (isSelected: boolean) => React.ReactNode;
      }[];
      onSignout: () => void;
    };
    profileButton: {
      to: string;
      profilePhoto: ImageSrc | null;
    };
    chatStateMgr: ChatStateMgr<StreamChat>;
  };
  mainContent: ReactNode;
  leftMenu: {
    extraTop?: ReactNode;
    topLinks: LeftMenuLinkProps[];
    onSignout: () => void;
    onLogoClick: () => void;
  };
};

export const DashboardLayout = <RightNav extends {}>({
  stateMgr,
  leftMenu,
  navbar,
  matchViewForRightNav,
  mainContent,
}: DashboardLayoutProps<RightNav>) => {
  const [showTopAlert, setShowTopAlert] = useState<O.Option<TopAlert>>(O.none);
  const rightNavState = useObservableEagerState(stateMgr.rightNav$);
  const showBottomToast = useObservableEagerState(stateMgr.showBottomToast$);
  const showReloading = useObservableEagerState(stateMgr.showReloading$);

  useEffect(() => {
    stateMgr.showTopAlert$.subscribe((alert) => {
      if (O.isSome(alert)) {
        setShowTopAlert(O.some(alert.value));

        if (alert.value.durationSeconds) {
          setTimeout(() => {
            setShowTopAlert(O.none);
          }, alert.value.durationSeconds * 1000);
        }
      } else {
        setShowTopAlert(O.none);
      }
    });
  }, []);

  useEffect(() => {
    if (O.isSome(showBottomToast)) {
      const toast = showBottomToast.value;
      if (toast.reload) {
        stateMgr.setShowReloading(true);
        setTimeout(() => {
          stateMgr.setShowReloading(false);
        }, 300);
      }

      if (toast.duration && toast.duration._tag === "INFINITE") {
        return;
      } else {
        setTimeout(
          () => {
            stateMgr.hideBottomToast();
          },
          toast.duration?.seconds ? toast.duration.seconds * 1000 : 3000
        );
      }

      if (toast.closeRightNav) {
        stateMgr.closeRightNav();
      }
    } else {
      stateMgr.hideBottomToast();
    }
  }, [showBottomToast]);

  return (
    <div className="w-screen h-screen flex flex-col md:flex-row relative">
      {O.isSome(showTopAlert) && (
        <div className="absolute top-0 left-0 right-0 h-[200px]  z-50 flex bg-orange-400">
          <div className="flex-1 h-full flex flex-col justify-center items-center relative">
            <div
              className="absolute top-4 right-4 h-[100px] aspect-square z-40 flex items-center justify-center rounded-full border border-white bg-orange-400 cursor-pointer"
              onClick={() => {
                showTopAlert.value.onClose
                  ? showTopAlert.value.onClose()
                  : stateMgr.hideTopAlert();
              }}
            >
              <img src="/close.svg" width={30} height={30} />
            </div>
            <h4 className="text-white font-bold text-2xl">
              {showTopAlert.value.msg}
            </h4>
            {showTopAlert.value.actionContent &&
              showTopAlert.value.actionContent}
          </div>
        </div>
      )}
      <div className="hidden md:flex md:flex-col absolute top-0 left-0 z-20 max-h-screen w-[230px]">
        <LeftMenu
          onLogoClick={() => {
            leftMenu.onLogoClick();
          }}
          onSignout={() => {
            leftMenu.onSignout();
          }}
          extraTop={leftMenu.extraTop}
          links={leftMenu.topLinks.map((l) => {
            return <LeftMenuLink {...l} />;
          })}
        />
      </div>
      <div className="flex-1 flex flex-col lg:pl-[230px]">
        <div className="self-end"></div>
        <div
          id="dashboard-content"
          className={`flex-1 flex flex-col bg-white relative 
          ${O.isSome(rightNavState) ? "overflow-y-hidden" : "overflow-y-auto"}
          overscroll-x-none
          `}
        >
          <RightNav
            dashboardState={stateMgr}
            matchViewForRightNav={matchViewForRightNav}
          />
          <Navbar {...navbar} />
          {showReloading ? <FullContainerLoadingSpinner /> : mainContent}
          {O.isSome(showBottomToast) && (
            <div className="fixed h-[100px] bottom-0 left-0 right-0 py-8 flex justify-center items-center animate-slideup">
              <BottomToast toast={showBottomToast.value} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RightNav = <RightNav extends {}>({
  dashboardState,
  matchViewForRightNav,
}: {
  dashboardState: DashboardStateMgr<RightNav>;
  matchViewForRightNav: (rightNav: RightNav) => {
    view: React.ReactNode;
    width?: string;
  };
}) => {
  const mbRightNavOpen = useObservableEagerState(dashboardState.rightNav$);
  const mbMatchingView = useMemo(() => {
    return epipe(mbRightNavOpen, O.map(matchViewForRightNav));
  }, [mbRightNavOpen]);

  return (
    <div
      className={`absolute top-0 right-0 bottom-0 flex flex-col overflow-y-auto z-50 bg-white transition-all duration-300 ease-in-out`}
      style={{
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
        width: epipe(
          mbMatchingView,
          O.fold(
            () => "0px",
            (v) => v.width ?? "400px"
          )
        ),
      }}
    >
      {pipe(
        mbMatchingView,
        O.map((v) => v.view),
        O.toNullable
      )}
    </div>
  );
};

export const HamburgerButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <button
      className="w-8 h-full flex flex-col justify-center items-center gap-1"
      onClick={onClick}
    >
      <div className="w-8 h-1 bg-black rounded-full"></div>
      <div className="w-8 h-1 bg-black rounded-full"></div>
      <div className="w-8 h-1 bg-black rounded-full"></div>
    </button>
  );
};

export const BottomToast: React.FC<{ toast: ShowBottomToastConfig }> = ({
  toast,
}) => {
  const { onUndo, confirmButton } = toast;
  return (
    <div className="bg-black  rounded-full px-8 py-4 flex items-center justify-between gap-8">
      <div className="text-white">{toast.msg}</div>
      {onUndo && (
        <div className="flex">
          <div
            className="text-white"
            onClick={() => {
              onUndo();
            }}
          >
            Undo
          </div>
        </div>
      )}
      {confirmButton && (
        <div
          className="bg-white p-4 rounded-lg cursor-pointer"
          onClick={() => {
            confirmButton.onClick();
          }}
        >
          {confirmButton.label}
        </div>
      )}
    </div>
  );
};

export const UNSELECTED_COLOR = "#161616";
export const SELECTED_COLOR = "#5500C2";

interface LeftMenuLinkProps {
  to: string;
  hash?: string;
  name: string;
  onClick?: () => void;
  // the icon is a react component that takes an isSelected prop
  icon?: (isSelected: boolean) => React.ReactNode;
}

export const LeftMenuLink: React.FC<LeftMenuLinkProps> = ({
  to,
  name,
  icon,
  onClick,
}) => {
  const location = useLocation();
  const isSelected = location.pathname + location.hash === to;
  return (
    <Link
      to={to}
      className="p-4 flex gap-4 items-center mx-4 rounded-xl"
      onClick={onClick}
      style={{
        backgroundColor: isSelected ? "#F8F8FF" : "white",
      }}
    >
      {icon && <div className="h-6 w-6">{icon(isSelected)}</div>}
      <div
        style={{
          color: isSelected ? SELECTED_COLOR : UNSELECTED_COLOR,
          fontWeight: 500,
        }}
      >
        {name}
      </div>
    </Link>
  );
};

const LogoutLeftMenuLink: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <LeftMenuLink
      to=""
      icon={(isSelected) => <LogoutIcon isSelected={isSelected} />}
      onClick={onClick}
      name="Logout"
    />
  );
};

type LeftMenuProps = {
  extraTop?: ReactNode;
  links: ReactNode[];
  onSignout: () => void;
  onLogoClick: () => void;
};
export const LeftMenu: React.FC<LeftMenuProps> = ({
  extraTop,
  links,
  onSignout,
  onLogoClick,
}) => {
  const hpState = useHpState();
  return (
    <div
      className="hidden min-h-screen w-[230px] lg:flex flex-col items-center justify-between py-2 bg-white"
      style={{
        boxShadow: "0px 3.141px 13.12px 0px rgba(99, 100, 113, 0.17)",
      }}
    >
      <div className="w-full flex-1 flex flex-col items-center gap-8 py-4">
        <div
          className="cursor-pointer self-stretch flex justify-center items-center"
          onClick={() => {
            onLogoClick();
          }}
        >
          <InsightLiveLogoWithText />
        </div>
        <div className="flex-1 w-full flex flex-col gap-3">
          {extraTop}
          {links.map((link, key) => (
            <div
              key={key}
              onClick={() => {
                hpState.dashboardState.closeRightNav();
              }}
            >
              {link}
            </div>
          ))}
        </div>
        <div className="basis-[200px] self-stretch flex flex-col justify-end">
          <LogoutLeftMenuLink
            onClick={() => {
              onSignout();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Navbar: React.FC<{
  chatStateMgr: ChatStateMgr<StreamChat>;
  currentDashboardPage: string;
  middleSection: ReactNode;
  hamburger: {
    links: {
      to: string;
      name: string;
      icon?: (isSelected: boolean) => React.ReactNode;
    }[];
    onSignout: () => void;
  };
  profileButton: {
    to: string;
    profilePhoto: ImageSrc | null;
  };
}> = ({
  chatStateMgr,
  currentDashboardPage,
  middleSection,
  hamburger,
  profileButton,
}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const unreadCount = useObservableEagerState(chatStateMgr.unreadCount$);

  useEffect(() => {
    console.log("unreadCount", unreadCount);
  }, [unreadCount]);

  return (
    <div className="flex justify-between py-4 px-8 border border-b-[1px] border-x-0 border-t-0">
      <div className="font-medium font-outfit text-4xl leading-relaxed	">
        {currentDashboardPage}
      </div>
      {middleSection}

      <div className="lg:hidden">
        <HamburgerButton
          onClick={() => {
            console.log("clicked");
            setIsHamburgerOpen((o) => !o);
          }}
        />
      </div>
      {isHamburgerOpen && (
        <HamburgerMenu
          isOpen={isHamburgerOpen}
          close={() => {
            setIsHamburgerOpen(false);
          }}
          links={[
            ...hamburger.links.map((l) => {
              return (
                <LeftMenuLink
                  to={l.to}
                  name={l.name}
                  icon={l.icon}
                  onClick={() => {
                    setIsHamburgerOpen(false);
                  }}
                />
              );
            }),
            <LogoutLeftMenuLink onClick={hamburger.onSignout} />,
          ]}
          onSignout={hamburger.onSignout}
        />
      )}
      <div className="flex items-center gap-4">
        {/* {unreadCount.messageCount !== undefined && (
          <div>{`${unreadCount.messageCount} new messages`}</div>
        )} */}
        <ProfileButton {...profileButton} />
      </div>
    </div>
  );
};

const ProfileButton: React.FC<{
  to: string;
  profilePhoto: ImageSrc | null;
}> = ({ to, profilePhoto }) => {
  return (
    <Link
      to={to}
      className="hidden lg:flex border rounded-lg w-fit p-4 h-[60px] items-center justify-between"
    >
      <img src="/dashboard/menu.svg" width={20} height={20} className="mr-4" />
      <AvatarCircle mbProfilePhoto={profilePhoto} size={30} />
    </Link>
  );
};

const HamburgerMenu: React.FC<{
  links: ReactNode[];
  onSignout: () => void;
  isOpen: boolean;
  close: () => void;
}> = ({ links, isOpen, close, onSignout }) => {
  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen bg-white z-50 border-4 border-blue-400 overflow-scroll ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex flex-col gap-4 grow-0 shrink-0 py-8">
        <div className="flex justify-end w-full px-8">
          <button onClick={close} className="text-3xl">
            <img src="/close.svg" width={18} height={18} />
          </button>
        </div>
        <div className="w-full flex justify-center">
          <InsightLiveSvgIcon />
        </div>
        <div className="flex flex-col justify-between min-h-screen">
          <div className="flex flex-col gap-8 mb-8">{links}</div>
          <LogoutLeftMenuLink
            onClick={() => {
              onSignout();
            }}
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export const LogoutIcon: React.FC<{ isSelected: boolean }> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.90002 7.56023C9.21002 3.96023 11.06 2.49023 15.11 2.49023H15.24C19.71 2.49023 21.5 4.28023 21.5 8.75023V15.2702C21.5 19.7402 19.71 21.5302 15.24 21.5302H15.11C11.09 21.5302 9.24002 20.0802 8.91002 16.5402"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12H3.62"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
