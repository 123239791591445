import { format } from "date-fns";
import { Match, Option } from "effect";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { ToolMenuViewStateST } from "shared/schemas/session/ongoing/participant/participant-display.schemas";
import { AvatarCircles } from "../../../avatar.tc";

interface StageTopNavProps {
  mainRoomStateMgr: MainRoomStateMgr;
}

export const StageTopNav: React.FC<StageTopNavProps> = ({
  mainRoomStateMgr,
}) => {
  const titleDisplaySection = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.mainRoomTitleDisplaySectionMgr.state$
  );

  return (
    <div className="flex gap-4">
      <TopLeftContainer>
        {Match.value(titleDisplaySection.viewState).pipe(
          Match.when(
            { tag: "TITLE_AND_PARTICIPANTS" },
            ({ participants, title }) => (
              <div className="flex items-center gap-2">
                <AvatarCircles
                  sources={participants.map((p) => p.profilePhoto)}
                  size={40}
                />
                {title && <div className="text-white">{title}</div>}
              </div>
            )
          ),
          Match.when({ tag: "APPROVE_USER_ENTERED" }, ({ userName }) => (
            <div>{userName}</div> // TODO
          )),
          Match.exhaustive
        )}
      </TopLeftContainer>
      <ClocksSection mainRoomStateMgr={mainRoomStateMgr} />
    </div>
  );
};

const TopLeftContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="flex-1 min-w-0 flex">
      <div className="border rounded-full flex items-center gap-2 text-white px-2 py-1">
        {children}
      </div>
    </div>
  );
};

const ClocksSection: React.FC<{
  mainRoomStateMgr: MainRoomStateMgr;
}> = ({ mainRoomStateMgr }) => {
  const clockMgr = mainRoomStateMgr.stateSyncMgr.countdownClockMgr;
  const specialClockMgr = mainRoomStateMgr.stateSyncMgr.specialClockDisplayMgr;

  const countdownClockState = useObservableEagerState(clockMgr.state$);
  const specialClockState = useObservableEagerState(specialClockMgr.state$);
  const mbDisplay = specialClockState.mbDisplay;

  return (
    <div className="flex gap-4">
      {Option.isSome(mbDisplay) && (
        <CountdownClock
          timeLeftSeconds={null}
          message={mbDisplay.value.message ?? undefined}
          onClick={() => {
            if (mbDisplay.value.type === "meditation") {
              mainRoomStateMgr.openPanel({
                state: "THERAPY_TOOLS",
                initialViewState:
                  ToolMenuViewStateST.asSelectedTool("MEDITATION"),
              });
            }
          }}
          backgroundColor="vid-purple"
          showSeconds={true}
        />
      )}
      {Match.value(countdownClockState.displayType).pipe(
        Match.when("WARN_NEAR_END", () => (
          <CountdownClock
            timeLeftSeconds={null}
            message={countdownClockState.message ?? undefined}
            onClick={() => {}}
            backgroundColor="vid-purple"
          />
        )),
        Match.when("DEFAULT_COUNTDOWN", () => (
          <CountdownClock
            timeLeftSeconds={null}
            message={countdownClockState.message ?? undefined}
            onClick={() => {}}
          />
        )),
        Match.when("None", () => <></>),
        Match.exhaustive
      )}
    </div>
  );
};

const CountdownClock: React.FC<{
  timeLeftSeconds: number | null;
  onClick?: () => void;
  backgroundColor?: string;
  showSeconds?: boolean;
  message?: string;
}> = ({ timeLeftSeconds, onClick, backgroundColor, showSeconds, message }) => {
  return (
    <div
      onClick={onClick}
      className={`border px-5 py-2 rounded-full text-white flex items-center gap-4 text-base cursor-pointer ${
        backgroundColor ? `bg-${backgroundColor}` : ""
      }`}
    >
      <img src="/alarm.svg" alt="Alarm icon" width={20} height={20} />
      {message ? (
        <div>{message}</div>
      ) : (
        timeLeftSeconds !== null &&
        (showSeconds
          ? format(new Date(timeLeftSeconds * 1000), "m:ss")
          : `${Math.floor(timeLeftSeconds / 60)}`)
      )}
    </div>
  );
};
