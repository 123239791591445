import { ConvexClient } from "convex/browser";
import type { FirebaseJsMgr } from "frontend-shared/src/firebase";
import { createContextAndHook, useOnce } from "frontend-shared/src/util";
import { useEffect } from "react";

const CONVEX_URL = import.meta.env["VITE_CONVEX_URL"]!;
console.log("CONVEX URL! ", CONVEX_URL);
export const webConvex = new ConvexClient(CONVEX_URL);

export function useSetupConvexCli(firebaseJs: FirebaseJsMgr) {
  const convexCli = useOnce(() => new ConvexClient(CONVEX_URL));

  useEffect(() => {
    convexCli.setAuth(firebaseJs.authTokenFetcher);
  }, []);

  return convexCli;
}

export const [ConvexCliProver, useConvexCli] =
  createContextAndHook<ConvexClient>();
