import * as S from "@effect/schema/Schema";
import type { Doc, Id } from "../../../../convex/_generated/dataModel";

export class ParticipantInfo extends S.Class<ParticipantInfo>(
  "ParticipantInfo"
)({
  baseUserIdStr: S.String,
  name: S.String,
  image: S.NullOr(S.String),
  chatToken: S.optional(
    S.NullOr(
      S.Struct({
        token: S.String,
        publicApiKey: S.String,
      })
    )
  ),
}) {
  get encoded() {
    return S.encodeUnknownSync(ParticipantInfo)(this);
  }

  static fromDoc = (p: { doc: Doc<"sessionParticipant"> }): ParticipantInfo =>
    ParticipantInfo.make({
      ...p.doc,
      baseUserIdStr: p.doc.baseUserId.toString(),
    });

  static default = (p: { baseUserId: Id<"users"> }) =>
    ParticipantInfo.make({
      baseUserIdStr: p.baseUserId.toString(),
      name: "", // TODO??
      image: null,
      chatToken: null,
    });
}
