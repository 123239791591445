import {
  createTRPCClient,
  httpBatchLink,
  TRPCClientError,
  type CreateTRPCClient,
  type inferRouterProxyClient,
} from "@trpc/client";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type {
  RootAuthedRouter,
  RootPublicRouter,
} from "backend/src/Routers/Root.Router";
import { Data } from "effect";
import { pipe } from "fp-ts/function";
import * as TE from "fp-ts/TaskEither";
import superjson from "superjson";

type ArgsOfHttpBachLink = Parameters<typeof httpBatchLink>;

export type FetchEsque = ArgsOfHttpBachLink[0]["fetch"];

export const uTrpc = (p: {
  API_URL: string;
  jwt: string;
  fetch?: FetchEsque;
}): CreateTRPCClient<RootAuthedRouter> =>
  createTRPCClient<RootAuthedRouter>({
    links: [
      httpBatchLink({
        transformer: superjson,
        url: p.API_URL,
        fetch: fetch,
        headers: {
          Authorization: `Bearer ${p.jwt}`,
        },
      }),
    ],
  });

export type UApiOutput = inferRouterOutputs<RootAuthedRouter>;
export type UApiInput = inferRouterInputs<RootAuthedRouter>;

export type PublicApiOutput = inferRouterOutputs<RootPublicRouter>;

export const publicTrpc = (p: { API_URL: string; fetch?: FetchEsque }) => {
  console.log("CREATING PUBLIC TRPC CLIENT, ", p);
  return createTRPCClient<RootPublicRouter>({
    links: [
      httpBatchLink({
        transformer: superjson,
        url: p.API_URL,
        fetch: p.fetch,
      }),
    ],
  });
};

export function trpcQueryToTE<V>(
  trpcQuery: () => Promise<V>
): TE.TaskEither<TrpcFetchError, V> {
  return pipe(
    TE.tryCatch(trpcQuery, (e) => {
      console.log("ERROR IN TRPC QUERY TO TE! ", e);
      if (isUnknownErrorTRPCErrorFromServer(e)) {
        return new ErrorFromTrpcServer({ error: e });
      }
      return new FailedToReachServerError({
        error: { message: "Failed to reach server" },
      });
    })
  );
}

export type TRPCFetchClientError = TRPCClientError<RootAuthedRouter>;

export class ErrorFromTrpcServer extends Data.TaggedError("ErrorFromServer")<{
  error: TRPCFetchClientError;
}> {}

export class FailedToReachServerError extends Data.TaggedError(
  "FailedToReachServer"
)<{
  error: { message: string };
}> {}

export type TrpcFetchError = ErrorFromTrpcServer | FailedToReachServerError;

function isUnknownErrorTRPCErrorFromServer(
  cause: unknown
): cause is TRPCClientError<RootAuthedRouter> {
  return (
    cause instanceof TRPCClientError &&
    (cause as any)["data"] !== undefined &&
    (cause as any)["data"]["code"] !== undefined
  );
}
