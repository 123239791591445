import type { KnownMediaFile } from "shared";
import type { AbstractAudioPlayer } from "../../../../media-player.statemgr";
import {
  BaseSessionToolStateMgr,
  type BaseSessionToolStateMgrParams,
} from "./base-session-tool.statemgr";

export class AudioMediaStateMgr extends BaseSessionToolStateMgr {
  // playingMediaFile$: Rx.Observable<KnownMediaFile | null>;

  sendPlayMediaFile(knownMediaFile: KnownMediaFile) {
    console.log("SENDING PLAY MEDIA FILE! ", knownMediaFile);
  }

  sendStopMediaFile() {}

  playLocallyOnly(knownMediaFile: KnownMediaFile) {
    this.audioPlayer.setSourceAndPlay(knownMediaFile);
  }

  pauseLocal() {
    this.audioPlayer.pause();
  }

  constructor(
    readonly baseParams: BaseSessionToolStateMgrParams,
    readonly audioPlayer: AbstractAudioPlayer
  ) {
    super(baseParams);

    // this.audioPlayer.setOnDone(() => {
    //   console.log("AUDIO PLAYER ON DONE CALLED!");
    //   this.remoteStateMgr.runUpdateRemoteState((_) => ({
    //     playingMediaFile: null,
    //   }));
    // });
  }
}
