import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/components/primitives/button";
import { AvatarCircles } from "@webapp/componentsavatar.tc";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import React from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useNavigate } from "react-router-dom";
import type { SimplestUserWithProfilePhoto } from "shared";
import { api } from "shared/convex/_generated/api";
import { HPROUTES } from "shared/routes/hp/hp.routes";
import { UROUTES } from "shared/routes/u.routes";
import {
  ClientsListContainer,
  ClientsListRowItemContainer,
} from "./clients-page.components";

export const HpMyDashboardClientsPage: React.FC = () => {
  const navigate = useNavigate();
  const hpState = useHpState();

  const myPendingClientRequests = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyPendingClientRequests,
    {}
  );

  const myActiveClients = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyActiveClients,
    {}
  );

  const myOngoingHcSessions = useQuery(
    api.Sessions.Hc.HcSessionFns.getMyOngoingHcSessionsForHpPortal,
    {}
  );

  const endHcSession = useMutation(
    api.Sessions.Hc.HcSessionFns.onHpEndsHcSession
  );

  if (myOngoingHcSessions === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (myPendingClientRequests === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (myActiveClients === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (
    myOngoingHcSessions.length === 0 &&
    myActiveClients.clients.length === 0 &&
    myPendingClientRequests.length === 0
  ) {
    return <EmptyState />;
  }

  return (
    <div className="p-8 relative">
      <div className="flex justify-between items-center pb-8">
        <h3 className="font-outfit font-[500] text-2xl lg:text-4xl text-vid-black-900">
          Clients
        </h3>
        <div>
          <button
            className="lg:p-buttons rounded-buttons text-vid-purple lg:border border-vid-purple "
            onClick={() => {
              hpState.dashboardState.openRightNav({
                _tag: "REGISTER_NEW_CLIENT",
              });
            }}
          >
            Invite a client
          </button>
        </div>
      </div>

      <div className="border border-vid-black-200 rounded-tr-[12px] rounded-tl-[12px]">
        {myOngoingHcSessions.length > 0 && (
          <ClientsListContainer
            title={"Live or ongoing"}
            rows={myOngoingHcSessions.map((ohc) => () => (
              <ClientsListRowItemContainer
                linkTo={HPROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.INFO.buildPath({
                  clientId: ohc.clientInfo.baseUserId,
                })}
                name={ohc.clientInfo.name}
                profilePhotoUrl={ohc.clientInfo.image}
                title="Live"
              >
                <div className="flex items-center gap-2">
                  <AvatarCircles
                    sources={[ohc.clientInfo.image, ohc.hpInfo.image]}
                  />
                  <InversePrimaryButton
                    title="End session"
                    mode="danger"
                    onClick={() => {
                      endHcSession({
                        hcSessionId: ohc.hcSessionId,
                      }).then((r) => {
                        console.log("RESULT OF ENDING HC SESSION! ", r);
                      });
                    }}
                  />
                  <PrimaryButton
                    title="Rejoin"
                    onClick={() => {
                      navigate(
                        UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                          sessionId: ohc.baseSessionId,
                        })
                      );
                    }}
                  />
                </div>
              </ClientsListRowItemContainer>
            ))}
          />
        )}
        {/* {r.upcomingApptClientsWithProfPhotos.length > 0 && (
          <ClientsListContainer
            title={"Upcoming"}
            rows={r.upcomingApptClientsWithProfPhotos.map((instance) => () => (
              <ClientsListRowItemContainer
                linkTo={HPROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.INFO.buildPath({
                  clientId: instance.privateSessionInfo.client.id,
                })}
                name={instance.privateSessionInfo.client.name}
                profilePhotoUrl={instance.clientProfilePhoto}
                title="Upcoming"
                appt={{
                  date: instance.startTime,
                  id: instance.instanceId,
                }}
                // sessionType="EMDR Therapy"
              >
                <div className="flex flex-col lg:flex-row items-center">
                  <StartSessionButton
                    client={{
                      ...instance.privateSessionInfo.client,
                      profilePhoto: instance.clientProfilePhoto,
                    }}
                  />
                </div>
              </ClientsListRowItemContainer>
            ))}
          />
        )} */}
        {myActiveClients.clients.length > 0 && (
          <ClientsListContainer
            title={"My Clients"}
            rows={myActiveClients.clients.map((c) => () => (
              <ClientsListRowItemContainer
                linkTo={UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.INFO.buildPath(
                  {
                    clientId: c.clientId,
                  }
                )}
                name={c.name!}
                profilePhotoUrl={c.profilePhoto?.thumb ?? null}
                title="Upcoming"
                // sessionType="EMDR Therapy"
              >
                <div className="lg:flex-1 flex items-center justify-center mt-4 lg:mt-0 w-full lg:w-fit">
                  <StartSessionButton
                    client={{
                      id: c.clientId,
                      name: c.name!,
                      email: c.email,
                      profilePhoto: c.profilePhoto?.thumb ?? null,
                    }}
                  />
                </div>
              </ClientsListRowItemContainer>
            ))}
          />
        )}

        {myPendingClientRequests.length > 0 && (
          <ClientsListContainer
            title="Pending"
            rows={myPendingClientRequests.map((c) => () => (
              <ClientsListRowItemContainer
                name={c.clientEmail}
                profilePhotoUrl={null}
                title="Pending"
              >
                <div className="flex flex-col lg:flex-row items-center">
                  <div className="flex-1"></div>
                  <InversePrimaryButton
                    title="Resend invitation"
                    onClick={() => {
                      BE.fetchEndpointTE((Api) =>
                        Api.hp.resendInviteClientEmail.mutate({
                          clientEmail: c.clientEmail,
                        })
                      )().then((er) => {
                        console.log("RESULT OF RESENDING INVITE! ", er);
                      });
                      hpState.dashboardState.showBottomToast({
                        msg: "Successfuly resent invitation",
                      });
                    }}
                  />
                </div>
              </ClientsListRowItemContainer>
            ))}
          />
        )}
        {/* {r.pastSessions.length > 0 && (
          <ClientsListContainer
            title={"Past"}
            rows={r.pastSessions.map((c) => () => (
              <ClientsListRowItemContainer
                linkTo={UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.REVIEW.buildPath(
                  {
                    privateSessionId: c.id,
                  }
                )}
                name={c.client.name}
                profilePhotoUrl={c.client.profilePhoto}
                title={`${format(c.startedAt, "MMM d, yyyy")}`}
              >
                <div className="lg:flex-1 flex lg:items-center">
                  <div className="flex-1"></div>
                </div>
              </ClientsListRowItemContainer>
            ))}
          />
        )} */}
      </div>
    </div>
  );
};

const StartSessionButton: React.FC<{
  client: SimplestUserWithProfilePhoto;
}> = ({ client }) => {
  const hpState = useHpState();
  return (
    <button
      onClick={() => {
        hpState.dashboardState.openRightNav({
          _tag: "START_CLIENT_SESSION",
          client,
        });
      }}
      className={`lg:min-w-[200px] w-full
      bg-white text-vid-purple border border-vid-purple rounded-buttons px-2 py-1 lg:p-buttons
      hover:bg-vid-purple hover:text-white
      `}
    >
      Start session
    </button>
  );
};

// const KebabMenuButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
//   return (
//     <button onClick={onClick} className="h-6 w-6 flex flex-col gap-[1px]">
//       <div className="h-1 w-1 rounded-full bg-gray-500"></div>
//       <div className="h-1 w-1 rounded-full bg-gray-500"></div>
//       <div className="h-1 w-1 rounded-full bg-gray-500"></div>
//     </button>
//   );
// };

const EmptyState: React.FC = () => {
  const hpState = useHpState();
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="flex gap-8">
        <AddAClientCard
          onClick={() => {
            hpState.dashboardState.openRightNav({
              _tag: "REGISTER_NEW_CLIENT",
            });
          }}
        />
      </div>
    </div>
  );
};

const AddAClientCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col justify-center items-center cursor-pointer p-8"
    >
      <h4 className="font-outfit font-medium text-default-purple text-4xl leading-9 mb-4">
        Invite your first client
      </h4>
      <p className="font-sans font-light text-md mb-4">
        We'll send an invitation and let you know when it's accepted.
      </p>
      <div className="bg-vid-purple p-buttons px-24 rounded-buttons text-white flex justify-center items-center">
        {`Invite`}
      </div>
    </div>
  );
};
