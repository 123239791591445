import * as S from "@effect/schema/Schema";
import type { Doc, Id } from "../../../../convex/_generated/dataModel";
import type { KnownToolTag } from "../known-tool.schemas";
import { SessionParticipantDisplayST } from "../participant/participant-display.schemas";
import { SessionParticipantAttendanceST } from "./participant-attendance.schemas";
import { ParticipantInfo } from "./participant-info.schemas";

export class BaseSessionParticipantState extends S.Class<BaseSessionParticipantState>(
  "BaseSessionParticipantState"
)({
  displayState: SessionParticipantDisplayST,
  statusState: SessionParticipantAttendanceST,
}) {
  static fromDocs = (p: {
    displayDoc: Doc<"sessionParticipantDisplays">;
    statusDoc: Doc<"sessionParticipantAttendance">;
  }): BaseSessionParticipantState =>
    BaseSessionParticipantState.make({
      displayState: SessionParticipantDisplayST.fromDoc({
        doc: p.displayDoc,
      }),
      statusState: SessionParticipantAttendanceST.fromDoc({
        doc: p.statusDoc,
      }),
    });

  static default = (p: {
    baseUserId: Id<"users">;
    toolMenuTools: KnownToolTag[];
  }) =>
    BaseSessionParticipantState.make({
      displayState: SessionParticipantDisplayST.default({
        baseUserId: p.baseUserId,
        toolMenuTools: p.toolMenuTools,
      }),
      statusState: SessionParticipantAttendanceST.default({
        baseUserId: p.baseUserId,
      }),
    });

  get encoded(): typeof BaseSessionParticipantState.Encoded {
    return S.encodeUnknownSync(BaseSessionParticipantState)(this);
  }
}

export class ExpectedParticipant extends S.Class<ExpectedParticipant>(
  "ExpectedParticipant"
)({
  info: ParticipantInfo,
  isInMainRoom: S.Boolean,
}) {
  get encoded() {
    return S.encodeUnknownSync(ExpectedParticipant)(this);
  }
}

export class ExpectedParticipants extends S.Class<ExpectedParticipants>(
  "ExpectedParticipants"
)({
  participants: S.Array(ExpectedParticipant),
}) {
  get encoded() {
    return S.encodeUnknownSync(ExpectedParticipants)(this);
  }

  static fromEncoded = (encoded: typeof ExpectedParticipants.Encoded) =>
    S.decodeUnknownSync(ExpectedParticipants)(encoded);

  static default = () => ExpectedParticipants.make({ participants: [] });
}
