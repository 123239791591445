import { hashValues, route, string } from "react-router-typesafe-routes/dom";

const COMMUNITY_ROUTES = route(
  "community",
  {},
  {
    GLOBAL: route(
      "global",
      {},
      {
        USER_PROFILE: route(
          "members",
          {},
          {
            USER_ID: route(
              ":userId",
              {
                params: { userId: string().defined() },
              },
              {
                ABOUT: route("about"),
                CHAT: route("chat"),
              }
            ),
          }
        ),
      }
    ),
    COMMUNITIES: route(
      "communities",
      {},
      {
        COMMUNITY: route(
          ":community",
          {
            params: { community: string().defined() },
            searchParams: {
              action: string(),
            },
          },
          {
            DISCUSSION: route("discussion"),
            LEARNING: route("learning"),
            MEMBERS: route("members"),
            EVENTS: route(
              "events",
              {},
              {
                ALL: route("all", {
                  searchParams: {
                    showEventRightNav: string(),
                    showEventInstanceRightNav: string(),
                  },
                }),
                EVENT_INSTANCE_ID: route(":eventInstanceId", {
                  params: { eventInstanceId: string().defined() },
                }),
              }
            ),
          }
        ),
        PRACTITIONER: route("practitioner"),
      }
    ),
  }
);

export const DASHBOARD_ROUTES = route(
  "dashboard",
  {},
  {
    CLIENTS: route(
      "clients",
      {},
      {
        CLIENT_ID: route(
          ":clientId",
          {
            params: { clientId: string().defined() },
          },
          {
            INFO: route("info"),
            CHAT: route("chat"),
          }
        ),
      }
    ),
    HOME: route("home"),
    COMMUNITY_TAB: COMMUNITY_ROUTES,
    INVOICES: route("invoices"),
    BILLING: route("billing"),
    CALENDAR: route("calendar", {
      searchParams: {
        action: string(),
        clientId: string(),
        clientName: string(),
        apptDate: string(),
        requestApptId: string(),
      },
    }),
    SETTINGS: route(
      "settings",
      {},
      {
        ACCOUNT: route("account", {
          hash: hashValues(
            "modalities",
            "practice-info",
            "extra",
            "invite-friends"
          ),
        }),
        CLIENT_AND_SCHEDULING: route("clients-and-scheduling"),
        BILLING: route("billing"),
      }
    ),
  }
);
