import * as S from "@effect/schema/Schema";
import { Effect } from "effect";
import { FileSchemas } from "../../schemas/file.schemas";
import { KnownRemoteFilePtrs } from "../known-remote-file-ptrs/known-remote-file-ptrs.schemas";

export const SimpleCommunityInfoSchema = S.Struct({
  name: S.String,
  slug: S.String,
  color: S.String,
});

export type SimpleCommunityInfo = S.Schema.Type<
  typeof SimpleCommunityInfoSchema
>;

export const KnownCommunityUserProfileSchema = S.Struct({
  is_available_for_practice: S.optional(S.Boolean),
});

export type KnownCommunityUserProfile = S.Schema.Type<
  typeof KnownCommunityUserProfileSchema
>;

export const CommunityInfoSchema = S.extend(
  SimpleCommunityInfoSchema,
  S.Struct({
    description: S.String,
    amIJoined: S.Boolean,
    isPrivate: S.Boolean,
    recentMembers: S.Array(
      S.Struct({
        id: S.String,
        name: S.String,
        profilePhoto: S.NullOr(S.String),
      })
    ),
    allMembers: S.Array(
      S.Struct({
        id: S.String,
        name: S.String,
        profilePhoto: S.NullOr(S.String),
      })
    ),
  })
);

export type CommunityInfo = S.Schema.Type<typeof CommunityInfoSchema>;

export class CommunityPostReplyInfo extends S.Class<CommunityPostReplyInfo>(
  "CommunityPostReplyInfo"
)({
  id: S.String,
  content: S.String,
  author: S.Struct({
    id: S.String,
    name: S.String,
    profilePhoto: S.NullOr(S.String),
  }),
  createdAt: S.Date,
}) {}

export const PostId = S.String.pipe(S.brand("PostId"));
export type PostId = S.Schema.Type<typeof PostId>;

export const CommunityPostStatus = S.Union(
  S.Literal("draft"),
  S.Literal("archived"),
  S.Literal("published")
  // S.Literal("DRAFT"),
  // S.Literal("SUBMITTED")
);
export type CommunityPostStatus = S.Schema.Type<typeof CommunityPostStatus>;

export class CommunityPostDto extends S.Class<CommunityPostDto>(
  "CommunityPostDto"
)({
  id: PostId,
  mainText: S.String,
  media: S.NullOr(
    S.Struct({
      mediaType: FileSchemas.MediaType,
      mainUrl: S.String,
      largeUrl: S.String,
    })
  ),
  communitySlug: S.String,
  postedAt: S.Date,
  author: S.Struct({
    id: S.String,
    name: S.String,
    profilePhoto: S.NullOr(S.String),
    isAvailableForPractice: S.Boolean,
  }),
}) {}

export class CommunityPostReactions extends S.Class<CommunityPostReactions>(
  "CommunityPostReactions"
)({
  likes: S.Number,
  haveILikedPost: S.Boolean,
  replies: S.Array(CommunityPostReplyInfo),
}) {
  withNewLike(isLiked: boolean) {
    return new CommunityPostReactions(
      {
        ...this,
        likes: isLiked ? this.likes + 1 : this.likes - 1,
        haveILikedPost: isLiked,
      },
      { disableValidation: true }
    );
  }
}

export class CommunityPostWithReactionsDto extends CommunityPostDto.extend<CommunityPostWithReactionsDto>(
  "CommunityPostWithReactionsDto"
)({
  reactions: CommunityPostReactions,
}) {
  withNewLike(isLiked: boolean) {
    return new CommunityPostWithReactionsDto(
      {
        ...this,
        reactions: this.reactions.withNewLike(isLiked),
      },
      { disableValidation: true }
    );
  }
}

const CommunitiesPageInfoSchema = S.Struct({
  timelineFeedPosts: S.Array(CommunityPostWithReactionsDto),
});

export type CommunitiesPageInfo = S.Schema.Type<
  typeof CommunitiesPageInfoSchema
>;

export const NewCommunityPost = S.Struct({
  mainTextContent: S.String,
  fileMetadata: S.NullOr(FileSchemas.FileMetadata),
  remoteFileEncodedPtr: S.NullOr(
    KnownRemoteFilePtrs.ConfirmedCloudinaryPtrEncoding
  ),
});

export type NewCommunityPost = S.Schema.Type<typeof NewCommunityPost>;

const SubmitPostNextAction = S.Union(
  S.TaggedStruct("UPLOAD_IMAGE_TO_S3", { secureUploadUrl: S.String }),
  S.TaggedStruct("UPLOAD_VIDEO_TO_CLOUDINARY", { secureUploadUrl: S.String }),
  S.TaggedStruct("NOTHING", {})
);

export type SubmitPostNextAction = S.Schema.Type<typeof SubmitPostNextAction>;

export class SubmitPostResult extends S.Class<SubmitPostResult>(
  "SubmitPostResult"
)({
  post: CommunityPostWithReactionsDto,
  nextAction: SubmitPostNextAction,
}) {
  static from = (p: {
    postInfo: CommunityPostWithReactionsDto;
    fileMetadata: FileSchemas.FileMetadata | null;
  }) =>
    Effect.gen(this, function* () {
      const nextAction = yield* this.determineNextAction(p);
      return SubmitPostResult.make({
        post: p.postInfo,
        postId: p.postInfo.id,
        nextAction,
      });
    });

  static determineNextAction = (p: {
    postInfo: CommunityPostWithReactionsDto;
    fileMetadata: FileSchemas.FileMetadata | null;
  }): Effect.Effect<SubmitPostNextAction> => {
    console.log("P! ", p);
    return Effect.succeed({ _tag: "NOTHING" }); // TODO?
  };
}
