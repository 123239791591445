/* SESSION PING CONSTANTS */
export const INTERVAL_SECONDS_TO_START_NEW_RECORDING = 60 * 5;

export const INTERVAL_SECONDS_TO_START_NEW_TRANSCRIPTION = 20;

export const RUN_PING_SECONDS_INTERVAL = 10;

export const RUN_NEW_LAMBDA_SECONDS_INTERVAL = 60 * 6;

export const STARTABLE_MINUTES_WINDOW_FOR_SESSION = 10;

/* TRANSCRIPT CONSTANTS */
export const ADJACENT_BEFORE_SECONDS_THRESHOLD = 3;

// export const INTERVAL_SECONDS_TO_START_NEW_RECORDING = 30;

// export const RUN_PING_SECONDS_INTERVAL = 20;

// export const RUN_NEW_LAMBDA_SECONDS_INTERVAL = 40;

// export const STARTABLE_MINUTES_WINDOW_FOR_SESSION = 10;
