import {
  hasAudio,
  hasScreenShare,
  ParticipantView,
  useParticipantViewContext,
  type StreamVideoParticipant,
  type VideoPlaceholderProps,
} from "@stream-io/video-react-sdk";
import type React from "react";
import { LiveSessionComponents } from "../live-session.components";

export const CustomParticipantView: React.FC<{
  participant: StreamVideoParticipant;
}> = ({ participant }) => {
  return (
    <ParticipantView
      participant={participant}
      trackType={
        hasScreenShare(participant) ? "screenShareTrack" : "videoTrack"
      }
      ParticipantViewUI={CustomParticipantViewUI}
      VideoPlaceholder={CustomVideoPlaceholder}
      mirror={false}
    />
  );
};

export const CustomParticipantViewUI = () => {
  const { participant } = useParticipantViewContext();
  const isMuted = !hasAudio(participant);

  return (
    <>
      <div className="absolute top-4 left-4">
        <div className=" text-white  font-semibold flex justify-center items-center gap-4">
          {participant.name}
        </div>
      </div>
      <div className="absolute top-4 left-1/2 -translate-x-1/2">
        {isMuted && <LiveSessionComponents.MutedIcon />}
      </div>
    </>
  );
};

export const CustomVideoPlaceholder: React.FC<VideoPlaceholderProps> = ({
  style,
}) => {
  const { participant } = useParticipantViewContext();

  return (
    <div
      className="absolute inset-0 flex-1 flex flex-col justify-center items-center gap-4 bg-vid-black-900 text-white border rounded-[20px]"
      style={style}
    >
      {participant.image && (
        <div className="w-[40px] h-[40px] max-w-[40px] max-h-[40px] rounded-full">
          <img
            className="w-full h-full aspect-square rounded-full object-cover border border-vid-purple"
            src={participant.image}
            alt={participant.name}
          />
        </div>
      )}
      <h4 className="font-bold text-xl">{participant.name}</h4>
    </div>
  );
};
