import type { AnnotationsPanelMgr } from "frontend-shared/src/mgrs/remote-state-mgrs/sessions/quickaction-tools.rsm";
import { createContextAndHook } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useMemo } from "react";
import { Rx } from "../../../../prelude";
import { SavedBookendItem, SavedBookmarkItem } from "../../bookmark.components";

const [AnnotationsPanelMgrContext, useAnnotationsPanelMgr] =
  createContextAndHook<AnnotationsPanelMgr>();

export const NoteToolsPanel: React.FC<{
  annotationsPanelMgr: AnnotationsPanelMgr;
}> = ({ annotationsPanelMgr }) => {
  return (
    <AnnotationsPanelMgrContext.Provider value={annotationsPanelMgr}>
      <div className="flex flex-col gap-4">
        <NotesPanelSection />
        <BookmarksSection />
        <RecordedBookends />
      </div>
    </AnnotationsPanelMgrContext.Provider>
  );
};

const SectionContainer: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-3 border border-vid-black-200 rounded-lg p-6">
      <div className="flex self-stretch justify-between">
        <h1 className="text-sm font-bold">{title}</h1>
      </div>
      {children}
    </div>
  );
};

const NotesPanelSection: React.FC = () => {
  const annotationsPanelMgr = useAnnotationsPanelMgr();
  const notesSchema = useObservableEagerState(
    annotationsPanelMgr.notesMgr.state$
  );
  const notes = notesSchema.notes;
  return (
    <SectionContainer title="Notes">
      <textarea
        className="flex-1 flex flex-col self-stretch border rounded-lg p-2"
        value={notesSchema.rawString}
        onChange={() => {
          // annotationsPanelMgr.notesMgr.updateNotes(e.target.value);
        }}
      />
    </SectionContainer>
  );
};

const BookmarksSection: React.FC = () => {
  const annotationsPanelMgr = useAnnotationsPanelMgr();
  const bookmarksST = useObservableEagerState(
    annotationsPanelMgr.bookmarksMgr.state$
  );

  return (
    <SectionContainer title="Bookmarks">
      <div className="flex flex-col gap-2 self-stretch">
        {bookmarksST.bookmarks.map((bk) => (
          <SavedBookmarkItem key={bk.id} bookmark={bk} />
        ))}
      </div>
    </SectionContainer>
  );
};

const RecordedBookends: React.FC = () => {
  const annotationsPanelMgr = useAnnotationsPanelMgr();
  const bookendsST = useObservableEagerState(
    annotationsPanelMgr.bookendsMgr.state$
  );
  const labelChangeSubject = useMemo(
    () => new Rx.Subject<{ bookendId: string; newLabel: string }>(),
    []
  );

  return (
    <SectionContainer title="Recorded sections">
      <div className="flex flex-col gap-2 self-stretch">
        {bookendsST.bookends.map((bk) => (
          <SavedBookendItem
            key={bk.id}
            bookend={bk}
            onLabelChange={(bk, newLabel) => {
              labelChangeSubject.next({ bookendId: bk.id, newLabel });
            }}
          />
        ))}
      </div>
    </SectionContainer>
  );
};
