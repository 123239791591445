import * as S from "@effect/schema/Schema";
import { RUN_PING_SECONDS_INTERVAL } from "../../../../constants";
import type { Doc, Id } from "../../../../convex/_generated/dataModel";
import { isNotNullOrUndefined } from "../../../../util";

const KnownSessionRoomTag = S.Union(
  S.Literal("MAIN"),
  S.Literal("WAITING"),
  S.Literal("ADMISSION_ROOM")
);
type KnownSessionRoomTag = typeof KnownSessionRoomTag.Type;

export class SessionParticipantAttendanceST extends S.Class<SessionParticipantAttendanceST>(
  "SessionParticipantAttendance"
)({
  baseUserId: S.String,
  lastPingedAt: S.NullOr(S.Number),
  lastEnteredAdmissionRoomAt: S.NullOr(S.Number),
  lastEnteredWaitingRoomAt: S.NullOr(S.Number),
  lastEnteredMainRoomAt: S.NullOr(S.Number),
  lastClickedLeaveSessionAt: S.NullOr(S.Number),
}) {
  static fromDoc = (p: {
    doc: Doc<"sessionParticipantAttendance">;
  }): SessionParticipantAttendanceST =>
    SessionParticipantAttendanceST.make({ ...p.doc });

  static default = (p: { baseUserId: Id<"users"> }) =>
    SessionParticipantAttendanceST.make({
      baseUserId: p.baseUserId,
      lastEnteredMainRoomAt: null,
      lastPingedAt: null,
      lastClickedLeaveSessionAt: null,
      lastEnteredWaitingRoomAt: null,
      lastEnteredAdmissionRoomAt: null,
    });

  get encoded(): typeof SessionParticipantAttendanceST.Encoded {
    return S.encodeUnknownSync(SessionParticipantAttendanceST)(this);
  }

  getAsConvexInsertable = (p: { baseSessionId: Id<"sessionConfig"> }) => {
    const { baseUserId, ...rest } = this.encoded;
    return {
      baseSessionId: p.baseSessionId,
      baseUserId: baseUserId as Id<"users">,
      ...rest,
    };
  };

  get currentRoomTag(): KnownSessionRoomTag | null {
    // First check if user is actively connected via recent ping
    const hasRecentPing =
      isNotNullOrUndefined(this.lastPingedAt) &&
      this.lastPingedAt >= Date.now() - RUN_PING_SECONDS_INTERVAL * 1000;

    if (!hasRecentPing) {
      return null;
    }

    // Check if user explicitly left the session
    const lastLeaveTime = this.lastClickedLeaveSessionAt ?? 0;

    // Get latest room entry time for each room
    const roomEntryTimes = {
      MAIN: this.lastEnteredMainRoomAt ?? 0,
      WAITING: this.lastEnteredWaitingRoomAt ?? 0,
      ADMISSION_ROOM: this.lastEnteredAdmissionRoomAt ?? 0,
    };

    // Find the most recent room entry
    const entries = Object.entries(roomEntryTimes) as [
      KnownSessionRoomTag,
      number,
    ][];
    const [mostRecentRoom, mostRecentTime] = entries.reduce(
      (latest, current) => {
        return current[1] > latest[1] ? current : latest;
      },
      ["ADMISSION_ROOM" as KnownSessionRoomTag, 0]
    );

    // If user hasn't entered any room or left after their last room entry
    if (mostRecentTime === 0 || lastLeaveTime > mostRecentTime) {
      return null;
    }

    return mostRecentRoom;
  }

  get isInMainRoom(): boolean {
    return this.currentRoomTag === "MAIN";
  }

  get isInWaitingRoom(): boolean {
    return this.currentRoomTag === "WAITING";
  }

  get isInWaitingOrMainRoom(): boolean {
    return this.isInMainRoom || this.isInWaitingRoom;
  }
}

export class SessionAttendingParticipantsST extends S.Class<SessionAttendingParticipantsST>(
  "SessionAttendingParticipants"
)({
  participants: S.Array(SessionParticipantAttendanceST),
}) {
  get asThoseInMainRoom(): (typeof SessionParticipantAttendanceST.Encoded)[] {
    return this.participants
      .filter((p) => p.isInMainRoom)
      .map((p) => p.encoded);
  }

  get asThoseInWaitingRoom(): (typeof SessionParticipantAttendanceST.Encoded)[] {
    return this.participants
      .filter((p) => p.isInWaitingRoom)
      .map((p) => p.encoded);
  }

  get asThoseInWaitingOrMainRoom(): (typeof SessionParticipantAttendanceST.Encoded)[] {
    return this.participants
      .filter((p) => p.isInWaitingOrMainRoom)
      .map((p) => p.encoded);
  }

  get encoded(): typeof SessionAttendingParticipantsST.Encoded {
    return S.encodeUnknownSync(SessionAttendingParticipantsST)(this);
  }

  static fromEncoded = (
    encoded: typeof SessionAttendingParticipantsST.Encoded
  ): SessionAttendingParticipantsST =>
    S.decodeUnknownSync(SessionAttendingParticipantsST)(encoded);

  static fromDocs = (
    docs: Doc<"sessionParticipantAttendance">[]
  ): SessionAttendingParticipantsST => {
    return SessionAttendingParticipantsST.make({
      participants: docs.map((d) =>
        SessionParticipantAttendanceST.fromDoc({ doc: d })
      ),
    });
  };
}
