import { Match } from "effect";
import type { AbstractAudioPlayer } from "frontend-shared/src/mgrs/media-player.statemgr";
import type { KnownMediaFile } from "shared";
import meditationMp3 from "./live-session/resources/meditation.mp3";
import simpleSingingBowlsMp3 from "./live-session/resources/simple-singing-bowl.mp3";

export class WebkitAudioPlayer implements AbstractAudioPlayer {
  private audioContext: AudioContext;
  private sourceNode: AudioBufferSourceNode | null = null;
  private gainNode: GainNode;
  private audioBuffer: AudioBuffer | null = null;

  constructor(private onDone: () => void) {
    this.audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);
  }

  setOnDone(onDone: () => void) {
    this.onDone = onDone;
  }

  async setMediaAudioBuffer(p: { source: string }) {
    const response = await fetch(p.source);
    const arrayBuffer = await response.arrayBuffer();
    this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
  }

  async setSource(source: KnownMediaFile) {
    const sourceUrl = this.sourceForKnownMediaFile(source);
    await this.setMediaAudioBuffer({ source: sourceUrl });
  }

  private sourceForKnownMediaFile(source: KnownMediaFile): string {
    return Match.value(source).pipe(
      Match.when("meditation.mp3", () => meditationMp3),
      Match.when("simple-singing-bowl.mp3", () => simpleSingingBowlsMp3),
      Match.exhaustive
    );
  }

  setSourceAndPlay(
    knownMediaFile: KnownMediaFile,
    options?: { autoStopAfterNSeconds?: number }
  ) {
    this.setSource(knownMediaFile).then((_) => {
      this.playAudioBuffer(options);
    });
  }

  playAudioBuffer(options?: { autoStopAfterNSeconds?: number }) {
    if (!this.audioBuffer) {
      console.error("No audio buffer set");
      return;
    }

    this.stop();

    this.sourceNode = this.audioContext.createBufferSource();
    this.sourceNode.buffer = this.audioBuffer;
    this.sourceNode.connect(this.gainNode);

    this.sourceNode.onended = () => {
      console.log("AUDIO PLAYER DONE!");
      this.onDone();
    };

    this.sourceNode.start();

    if (options?.autoStopAfterNSeconds) {
      setTimeout(() => {
        this.stop();
      }, options.autoStopAfterNSeconds * 1000);
    }
  }

  pause() {
    console.log("PAUSING AUDIO PLAYER!");
    this.audioContext.suspend();
  }

  stop() {
    if (this.sourceNode) {
      this.sourceNode.stop();
      this.sourceNode.disconnect();
      this.sourceNode = null;
    }
    this.audioContext.resume();
    this.onDone();
  }
}

// Remove the commented-out WebAudioPlayer class and usage example
