import { useMe, useMyId, useRtcMgr, useUMgr } from "@pages/u/u.webstatemgr";
import {
  BackgroundFiltersProvider,
  StreamCall,
  StreamVideo,
  useCall,
  useCallStateHooks,
  type Call,
} from "@stream-io/video-react-sdk";
import { WaitingRoomContainer } from "@webapp/componentslive-session/live-session.components";
import { MainRoomContainer } from "@webapp/componentslive-session/main-room/main-room-container";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { WebkitAudioPlayer } from "@webapp/mgrs/web-audio-player";
import { RD, Rx } from "@webapp/prelude";
import { useMutation, useQuery } from "convex/react";
import { Effect, Match } from "effect";
import {
  SessionRSM,
  SessionRSMProvider,
  useSessionRSM,
} from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type {
  RegisterUserEnteredSessionResultST,
  RtcAreaST,
} from "shared/convex/Sessions/Base/Session.Types";
import { useConvexCli } from "src/convex-cli";
import { PastSessionReviewSection } from "./waiting-room-page.fcs";
import { VideoSection } from "./waiting-room.page";

type RegisterEnteredResult = typeof RegisterUserEnteredSessionResultST.Encoded;

export const USessionRtcPage: React.FC = () => {
  const { sessionId } = useParams();
  const registerUserEnteredSession = useMutation(
    api.Sessions.Base.SessionFns.registerUserEnteredSession
  );

  const [enteredSessionResult, setEnteredSessionResult] =
    useState<RegisterEnteredResult | null>(null);

  useEffect(() => {
    if (sessionId) {
      registerUserEnteredSession({
        baseSessionId: sessionId as Id<"sessionConfig">,
      }).then((r) => {
        console.log("R! ", r);
        setEnteredSessionResult(r);
      });
    }
  }, [sessionId]);

  if (!enteredSessionResult) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col">
      <LoadedView registerEnteredResult={enteredSessionResult} />
    </div>
  );
};

const LoadedView: React.FC<{
  registerEnteredResult: RegisterEnteredResult;
}> = ({ registerEnteredResult }) => {
  const myId = useMyId();
  const convexCli = useConvexCli();
  const sessionRSM = useMemo(() => {
    return new SessionRSM({
      convexCli,
      ...registerEnteredResult.sessionConfig,
      userId: myId,
      baseSessionId: registerEnteredResult.sessionConfig
        .baseSessionId as Id<"sessionConfig">,
      startsAt: registerEnteredResult.sessionConfig.startTime,
    });
  }, [registerEnteredResult]);

  return (
    <SessionRSMProvider.Provider value={sessionRSM}>
      {Match.value(registerEnteredResult.buildingAreaToGoTo.area).pipe(
        Match.when({ tag: "ADMISSIONS_LOBBY" }, () => {
          return <div>Lobby!</div>;
        }),
        Match.when({ tag: "RTC" }, (rtcArea) => {
          return <RtcAreaView rtcArea={rtcArea} />;
        }),
        Match.exhaustive
      )}
    </SessionRSMProvider.Provider>
  );
};

const RtcAreaView: React.FC<{
  rtcArea: typeof RtcAreaST.Encoded;
}> = ({ rtcArea }) => {
  const rtcMgr = useRtcMgr();
  const [mbJoinedCall, setMbJoinedCall] = useState<Call | null>(null);

  useEffect(() => {
    const call = rtcMgr.client.call(
      rtcArea.channelConfig.channelType,
      rtcArea.channelConfig.channelId
    );
    Effect.runPromise(
      rtcMgr.lazyJoinChannel({
        channelType: rtcArea.channelConfig.channelType,
        channelId: rtcArea.channelConfig.channelId,
      })
    ).then((jc) => {
      console.log("JOIN CALL RESULT! ", jc);
      setMbJoinedCall(jc);
    });

    return () => {
      call.leave().catch(() => console.error("Failed to leave the call"));
      setMbJoinedCall(null);
    };
  }, [rtcArea.channelConfig]);

  if (!mbJoinedCall) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col">
      <StreamVideo client={rtcMgr.client}>
        <StreamCall call={mbJoinedCall}>
          <BackgroundFiltersProvider>
            {Match.value(rtcArea.area).pipe(
              Match.when({ tag: "WAITING_ROOM" }, () => {
                return <WaitingRoomAreaView />;
              }),
              Match.when({ tag: "LIVE" }, () => {
                return <MainRoomAreaView />;
              }),
              Match.exhaustive
            )}
          </BackgroundFiltersProvider>
        </StreamCall>
      </StreamVideo>
    </div>
  );
};

const WaitingRoomAreaView: React.FC = () => {
  const { useLocalParticipant } = useCallStateHooks();
  const meAsParticipant = useLocalParticipant();
  const call = useCall();
  const me = useMe();
  const sessionRSM = useSessionRSM();

  const setUserEnteredWaitingRoom = useMutation(
    api.Sessions.Participants.ParticipantAttendanceFns.setUserEnteredWaitingRoom
  );

  const setUserClickedJoinSessionFromWaitingRoom = useMutation(
    api.Sessions.Participants.Views.WaitingRoomFns.onJoinSessionClick
  );

  useEffect(() => {
    setUserEnteredWaitingRoom({
      baseSessionId: sessionRSM.p.baseSessionId as Id<"sessionConfig">,
      now: Date.now(),
    }).catch();
  }, []);

  const belowVideoDisplayInfoRes = useQuery(
    api.Screens.U.Sessions.Rtc.WaitingRoomScreenFns.getBelowVideoDisplayInfo,
    {
      baseSessionId: sessionRSM.p.baseSessionId,
    }
  );

  const extraDisplayInfoRes = useQuery(
    api.Screens.U.Sessions.Rtc.WaitingRoomScreenFns.getExtraDisplayInfo,
    {
      baseSessionId: sessionRSM.p.baseSessionId,
    }
  );

  if (belowVideoDisplayInfoRes === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  if (extraDisplayInfoRes === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  if (meAsParticipant === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col">
      <WaitingRoomContainer
        onBackClick={() => {
          call?.leave().then(() => {
            window.location.href = "/";
          });
        }}
        leftSide={
          <div className="flex flex-col gap-4 items-center justify-end">
            <VideoSection belowVideoDisplayInfo={belowVideoDisplayInfoRes} />
            {/* <div className="flex flex-row items-center gap-4">
              <h4 className="text-2xl">{belowVideoDisplayInfoRes.msg}</h4>
              {belowVideoDisplayInfoRes.userProfilePhotos.length > 0 && (
                <AvatarCircles
                  sources={belowVideoDisplayInfoRes.userProfilePhotos}
                />
              )}
            </div> */}
          </div>
        }
        rightSide={
          extraDisplayInfoRes.sessionType === "hc" ? (
            <PastSessionReviewSection
              pastSessionReviews={extraDisplayInfoRes.pastSessionNotes}
            />
          ) : null
        }
        gotoSession={{
          title: "Join session",
          onClick: () => {
            setUserClickedJoinSessionFromWaitingRoom({
              baseSessionId: sessionRSM.p.baseSessionId,
              now: Date.now(),
            }).then((_) => {
              window.location.reload();
            });
          },
        }}
      />
    </div>
  );
};

function useSetupMainRoom() {
  const sessionRSM = useSessionRSM();
  const me = useMe();
  const uMgr = useUMgr();
  const call = useCall()!;
  const { useLocalParticipant } = useCallStateHooks();
  const meAsParticipant = useLocalParticipant();
  const audioPlayer = useOnce(() => new WebkitAudioPlayer(() => {}));
  const registerEnteredMainRoom = useMutation(
    api.Sessions.Participants.ParticipantAttendanceFns.setUserEnteredMainRoom
  );

  const mainRoomStateMgr = useOnce(() => {
    return new MainRoomStateMgr({
      baseSessionId: sessionRSM.p.baseSessionId,
      sessionRSM,
      audioPlayer,
      chatStateMgr: uMgr.chatStateMgr,
      leaveChannel: async () => {
        await call.leave();
        window.location.href = "/";
      },
    });
  });

  useEffect(() => {
    mainRoomStateMgr.pingMgr.sendPing();

    registerEnteredMainRoom({
      baseSessionId: sessionRSM.p.baseSessionId as Id<"sessionConfig">,
      now: Date.now(),
    }).catch();

    mainRoomStateMgr.stateSyncMgr.subscribeSyncAll();

    return () => {
      mainRoomStateMgr.stateSyncMgr.disposeAll();
    };
  }, []);

  const rdClosedCallResult$ = useOnce(() => Rx.of(RD.initial));

  return {
    mainRoomStateMgr,
    rdClosedCallResult$,
    meAsParticipant,
    call,
    me,
  };
}

const MainRoomAreaView: React.FC = () => {
  const { mainRoomStateMgr, meAsParticipant } = useSetupMainRoom();

  if (!meAsParticipant) {
    return <FullScreenLoadingSpinner />;
  }

  const baseSessionConfig = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.baseSessionConfigMgr.state$
  );

  return (
    <MainRoomContainer
      mainRoomStateMgr={mainRoomStateMgr}
      settings={{
        shareableLink: baseSessionConfig.inviteLink,
      }}
    />
  );
};
