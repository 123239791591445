import * as S from "@effect/schema/Schema";
import { KnownCloudinaryPtrs } from "./known-cloudinary-ptrs.schemas";
import type { KnownS3Ptrs } from "./known-s3-ptrs";

export class UploadFileSuccess extends S.Class<UploadFileSuccess>(
  "UploadFileSuccess"
)({
  url: S.String,
}) {}

export namespace KnownRemoteFilePtrs {
  export type Ptr =
    | { _tag: "BucketKeyPtr"; ptr: KnownS3Ptrs.BucketKeyPtr }
    | { _tag: "CloudinaryPtr"; ptr: KnownCloudinaryPtrs.CloudinaryPtr };

  export type KnownConfirmedUploadedPtr =
    | { _tag: "ConfirmedBucketKeyPtr"; ptr: KnownS3Ptrs.ConfirmedBucketKeyPtr }
    | {
        _tag: "ConfirmedCloudinaryPtr";
        ptr: KnownCloudinaryPtrs.VirtualPtr;
      };

  export type BucketKeyPtr = KnownS3Ptrs.BucketKeyPtr;

  export const ConfirmedCloudinaryPtrEncoding =
    KnownCloudinaryPtrs.ConfirmedCloudinaryPtrEncoding;

  export type ConfirmedCloudinaryPtrEncoding =
    KnownCloudinaryPtrs.ConfirmedCloudinaryPtrEncoding;

  export type KnownConfirmedUploadedEncodedPtr =
    | KnownCloudinaryPtrs.ConfirmedCloudinaryPtrEncoding
    | KnownS3Ptrs.ConfirmedBucketKeyPtr; // todo
}
