import { route, string } from "react-router-typesafe-routes/dom";
import { UROUTES } from "./u.routes";

export * from "./hp/hp.routes";

export const ROUTES = route(
  "",
  {},
  {
    LOGIN: route("login", {
      searchParams: {
        prefilledEmail: string(),
        redirectTo: string(),
      },
    }),
    U: UROUTES,
    INVITES: route(
      "invites",
      {},
      {
        INVITE_ID: route(":inviteId", {
          params: { inviteId: string().defined() },
        }),
      }
    ),
  }
);

export const ANON_ROUTES = route(
  "anon",
  {},
  {
    CONFIRM_EMAIL_INVITE: route("confirm-email-invite", {
      searchParams: {
        email: string().defined(),
        redirectTo: string().defined(),
        action: string().defined(),
        source: string().defined(),
        clientId: string(),
      },
    }),

    MY_GLOBAL_PROFILE: route("global-profiles/:userId", {
      params: { userId: string().defined() },
    }),
  }
);
