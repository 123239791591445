import { Data, Match, Option } from "effect";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import type {
  FileSchemas,
  InMemoryFile,
  UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";
import type { KnownCloudinaryPtrs } from "shared/schemas/known-remote-file-ptrs/known-cloudinary-ptrs.schemas";

export type PreprocessedMediaState = Data.TaggedEnum<{
  INITIAL: {};
  UNPROCESSED: UnprocessedMediaState;
  PROCESSED: {
    uploadResult: UploadFileToCloudinaryResponse;
    originalFileMetadata: FileSchemas.FileMetadata;
    inMemoryFile: InMemoryFile;
  };
}>;

interface UnprocessedMediaState {
  inMemoryFile: InMemoryFile;
  state: Data.TaggedEnum<{
    DOCKED: {};
    PROCESSING: {};
  }>;
}

const {
  INITIAL,
  UNPROCESSED,
  PROCESSED,
  $is: isPreprocessedMediaState,
} = Data.taggedEnum<PreprocessedMediaState>();

export class PreprocessedMediaStateMgr {
  state$ = new Rx.BehaviorSubject<PreprocessedMediaState>(INITIAL());

  isProcessing$ = this.state$.pipe(
    RxO.map(
      (p) =>
        isPreprocessedMediaState("UNPROCESSED")(p) &&
        p.state._tag === "PROCESSING"
    )
  );

  // helper derived observable to easily access the in-memory file to show
  mbInMemoryFileToShow$: Rx.Observable<Option.Option<InMemoryFile>> =
    this.state$.pipe(
      RxO.map((p) => {
        console.log("CHECKING MB IN MEMORY FILE TO SHOW! ", p._tag);

        return Match.value(p).pipe(
          Match.tag("INITIAL", () => Option.none()),
          Match.tag("UNPROCESSED", (p) => {
            if (p.state._tag === "DOCKED") {
              return Option.some(p.inMemoryFile);
            }
            return Option.none();
          }),
          Match.tag("PROCESSED", (p) => Option.some(p.inMemoryFile)),
          Match.exhaustive
        );
      })
    );

  mbProcessedFile$: Rx.Observable<
    Option.Option<{
      originalFileMetadata: FileSchemas.FileMetadata;
    }>
  > = this.state$.pipe(
    RxO.map((p) => {
      if (isPreprocessedMediaState("PROCESSED")(p)) {
        return Option.some(p);
      }
      return Option.none();
    })
  );

  setProcessing = (p: { inMemoryFile: InMemoryFile }) => {
    this.state$.next(
      UNPROCESSED({
        inMemoryFile: p.inMemoryFile,
        state: { _tag: "PROCESSING" },
      })
    );
  };

  setProcessed = (p: {
    uploadResult: UploadFileToCloudinaryResponse;
    originalFileMetadata: FileSchemas.FileMetadata;
    inMemoryFile: InMemoryFile;
    confirmedUploadedPtrEncoding: KnownCloudinaryPtrs.ConfirmedCloudinaryPtrEncoding;
  }) => {
    this.state$.next(PROCESSED(p));
  };

  setUnprocessed = (p: { inMemoryFile: InMemoryFile }) => {
    this.state$.next(
      UNPROCESSED({
        inMemoryFile: p.inMemoryFile,
        state: { _tag: "DOCKED" },
      })
    );
  };
}
