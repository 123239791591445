import { DashboardRightDrawerContainer } from "@webapp/componentsdashboard/right-nav.components";
import { FetchRemoteDataView } from "@webapp/utils/utils";
import { Match } from "effect";
import React from "react";
import type { BaseNewPrivateSessionFormSubmit } from "shared";
import { TE } from "shared/base-prelude";
import { useCpState } from "./cpdashboard.state";

// TODO: Deprcate this file

export type CpDashboardRightNav = {
  _tag: "NEW_APPOINTMENT";
};

export const DashboardRightDrawer: React.FC<{
  rightNav: CpDashboardRightNav;
}> = ({ rightNav }) => {
  const cpState = useCpState();
  return Match.value(rightNav).pipe(
    Match.tag("NEW_APPOINTMENT", () => (
      <DashboardRightDrawerContainer
        closeRightNav={() => {
          cpState.dashboardMgr.closeRightNav();
        }}
        topView={
          <div className="flex flex-col justify-center items-center p-8">
            <img src="/pending.png" className="w-fit h-fit rounded-full" />
            <p className="font-sans font-light mt-2 text-center">
              Request an appointment with your practitioner
            </p>
          </div>
        }
        content={<div></div>}
      />
    )),

    Match.exhaustive
  );
};

export const CpSchedulePrivateSessionForm: React.FC<{
  onSuccessSave: () => void;
  mbHp?: { id: string; name: string };
  requestApptId?: string;
  initialDate?: Date;
}> = () => {
  return (
    <FetchRemoteDataView
      fetchTE={TE.right({} as Partial<BaseNewPrivateSessionFormSubmit>)}
      loadedView={() => <div></div>}
    />
  );
};
