import { useCallStateHooks } from "@stream-io/video-react-sdk";
import { CustomParticipantView } from "@webapp/componentslive-session/main-room/participant-view.fc";

export const PaginatedGridView: React.FC = () => {
  const { useParticipants } = useCallStateHooks();
  const [participantInSpotlight, ...otherParticipants] = useParticipants();

  switch (otherParticipants.length) {
    case 0:
      return (
        <div className="w-full h-full rounded-[12px] relative">
          <CustomParticipantView participant={participantInSpotlight} />
        </div>
      );
    case 1:
      return (
        <div className="flex-1 min-h-0 flex items-center gap-4">
          <div className="flex-1 min-h-0 relative aspect-video relative">
            <CustomParticipantView participant={participantInSpotlight} />
          </div>
          <div className="flex-1 min-h-0 relative aspect-video relative">
            <CustomParticipantView participant={otherParticipants[0]} />
          </div>
        </div>
      );
    default:
      if (otherParticipants.length === 2 || otherParticipants.length === 3) {
        return (
          <div className="flex-1 min-h-0 flex flex-col gap-4">
            <div className="flex-1 flex gap-4 min-h-0">
              <div className="flex-1 min-h-0 relative aspect-video relative">
                <CustomParticipantView participant={participantInSpotlight} />
              </div>
              <div className="flex-1 min-h-0 relative aspect-video relative">
                <CustomParticipantView participant={otherParticipants[0]} />
              </div>
            </div>
            <div className="flex-1 flex justify-center gap-4 min-h-0">
              <div className="basis-1/2 min-h-0 relative aspect-video relative">
                <CustomParticipantView participant={otherParticipants[1]} />
              </div>
              {otherParticipants.length === 3 && (
                <div className="flex-1 min-h-0 relative aspect-video relative">
                  <CustomParticipantView participant={otherParticipants[2]} />
                </div>
              )}
            </div>
          </div>
        );
      }
      return (
        <div
          className={`
          grid 
          grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8
        `}
        >
          {[participantInSpotlight, ...otherParticipants].map((participant) => {
            return (
              <div
                key={participant.userId}
                className="aspect-[16/9] rounded-[12px] overflow-hidden"
              >
                <div className="w-full h-full rounded-[12px] relative">
                  <CustomParticipantView participant={participant} />
                </div>
              </div>
            );
          })}
        </div>
      );
  }
};
