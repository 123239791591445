import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { Match } from "effect";
import {
  messageForFirebaseAuthError,
  useFirebaseJs,
} from "frontend-shared/src/firebase";
import {
  OnboardHpRegisterFlowStateMgr,
  OnboardHpRegisterFlowStateMgrContext,
  useOnboardHpRegisterFlowStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/auth/authenticate.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routes/routes";
import { UROUTES } from "shared/routes/u.routes";
import { useConvexCli } from "src/convex-cli";
import { apiMgr } from "../../../backend";
import { EyeIcon } from "../../../components/icons/eyeicon";

export const OnboardHpRegisterPage: React.FC = () => {
  const nav = useNavigate();
  const firebaseJs = useFirebaseJs();
  const convex = useConvexCli();
  const onboardHpRegisterFlowMgr = useOnce(
    () =>
      new OnboardHpRegisterFlowStateMgr({
        apiMgr: apiMgr,
        convex,
        firebaseJsMgr: firebaseJs,
      })
  );
  return (
    <OnboardHpRegisterFlowStateMgrContext.Provider
      value={onboardHpRegisterFlowMgr}
    >
      <div className="w-screen h-screen flex flex-col justify-center items-center">
        <AuthinCard
          onSuccessLogin={() => {
            nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
          }}
          onSuccessRegister={() => {
            const setProfilePath =
              UROUTES.HP.ONBOARD_CHOOSE_IMPORT_PROFILE_METHOD.path;
            const fullPath =
              setProfilePath + window.location.search + window.location.hash;
            nav(fullPath);
          }}
        />
      </div>
    </OnboardHpRegisterFlowStateMgrContext.Provider>
  );
};

type AuthinCardProps = {
  onSuccessRegister: () => void;
  onSuccessLogin: () => void;
};

const AuthinCard: React.FC<AuthinCardProps> = ({
  onSuccessRegister,
  onSuccessLogin,
}) => {
  const formMgr = useOnboardHpRegisterFlowStateMgr().emailPasswordMgr;
  const formData = useObservableEagerState(formMgr.formData$);
  const submitResult = useObservableEagerState(formMgr.submitResult$);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    console.log("SUBMIT RESULT! ", submitResult);
    if (submitResult.isSuccess()) {
      Match.value(submitResult.data).pipe(
        Match.tag("NEW_ACCOUNT_CREATED", () => {
          onSuccessRegister();
        }),
        Match.tag("SIGNED_IN_TO_EXISTING_ACCOUNT", () => {
          onSuccessLogin();
        }),
        Match.exhaustive
      );
      return;
    }
    if (submitResult.isFailure()) {
      console.log("SUBMIT RESULT! ", submitResult);
      Match.value(submitResult.reason).pipe(
        Match.tag("AUTO_ATTEMPTED_LOGIN_ERROR", ({ error }) => {
          // onSuccessLogin();
          console.log("AUTO_ATTEMPTED_LOGIN_ERROR! ", error);
        }),
        Match.tag("REGISTRATION_ERROR", (er) => {
          console.log("REGISTRATION ERROR! ", er);
        }),
        Match.exhaustive
      );
    }
  }, [submitResult]);

  return (
    <div className="p-8 flex flex-col items-center gap-8 mb-32">
      <h1 className="onboard-header">Create an account</h1>
      <form
        className="flex flex-col gap-5 max-w-screen"
        onSubmit={(e) => {
          e.preventDefault();
          formMgr.submit();
        }}
      >
        <input
          type="email"
          placeholder="Email"
          className="text-input"
          id="email"
          value={formData.email}
          autoComplete="email"
          autoCapitalize="none"
          autoCorrect="off"
          onChange={(e) => {
            formMgr.setFormValue("email", e.target.value);
          }}
        />
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="text-input pr-10 w-full"
            id="password"
            value={formData.password}
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            onChange={(e) => {
              formMgr.setFormValue("password", e.target.value);
            }}
          />
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={() => setShowPassword(!showPassword)}
          >
            <EyeIcon showPassword={showPassword} />
          </button>
        </div>

        {submitResult.isFailure() &&
          Match.value(submitResult.reason).pipe(
            Match.tag("REGISTRATION_ERROR", ({ error }) => {
              return Match.value(error).pipe(
                Match.tag("REGISTER_VIA_API_ERROR", ({ error }) => {
                  return <div className="text-red-500">{error}</div>;
                }),
                Match.tag("FirebaseCreateUserError", ({ error }) => {
                  return (
                    <div className="text-red-500">
                      {messageForFirebaseAuthError(error.code)}
                    </div>
                  );
                }),
                Match.exhaustive
              );
            }),
            Match.tag("AUTO_ATTEMPTED_LOGIN_ERROR", ({ error }) => {
              return (
                <div className="text-red-500">
                  {messageForFirebaseAuthError(error.error.code)}
                </div>
              );
            }),
            Match.exhaustive
          )}
        <PrimaryButton
          title={
            submitResult.isLoading() ? "Submitting..." : "Create an account"
          }
          type="submit"
        />
      </form>
      <Link
        to={ROUTES.LOGIN.path}
        className="underline px-8 py-0 rounded-lg font-light font-sans text-sm text-vid-gray"
      >
        Already have an account? Login
      </Link>
    </div>
  );
};
