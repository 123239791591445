import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";

export const HpDashboardClientMessagesPage: React.FC = () => {
  const cpId = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT
  ).clientId;

  console.log("cpId", cpId);

  return (
    <div className="flex-1 flex flex-col p-8">
      {/* <PrivateChatFC
        otherUserId={cpId}
        chatStateMgr={hpState.uMgr.chatStateMgr}
        setupChatEff={(p: { otherUserId: string }) =>
          BE.fetchEndpointEff((Api) =>
            Api.hp.clients.client.startPrivateChatWithCp.mutate({
              clientId: p.otherUserId,
            })
          )
        }
      /> */}
    </div>
  );
};
