import * as S from "@effect/schema/Schema";
import type { Doc } from "../../_generated/dataModel";

export class CommunityCardST extends S.Class<CommunityCardST>(
  "CommunityCardST"
)({
  community: S.Struct({
    slug: S.String,
    name: S.String,
    color: S.optional(S.String),
  }),
  members: S.Struct({
    countMsg: S.String,
    profilePhotos: S.Array(S.NullOr(S.String)),
  }),
  button: S.Struct({
    title: S.String,
  }),
}) {
  get encoded(): typeof CommunityCardST.Encoded {
    return S.encodeUnknownSync(CommunityCardST)(this);
  }

  static fromDocs(p: {
    community: Doc<"communityInfo">;
    members: Doc<"users">[];
  }): CommunityCardST {
    return CommunityCardST.make({
      community: {
        slug: p.community.slug,
        name: p.community.name,
        color: p.community.color,
      },
      members: {
        countMsg: `${p.members.length} members`,
        profilePhotos: p.members.map(
          (m) => m.cachedProfilePhotoUrls?.medium ?? null
        ),
      },
      button: { title: "View" },
    });
  }
}

export class MyProfileForCommunitiesST extends S.Class<MyProfileForCommunitiesST>(
  "MyProfileForCommunitiesST"
)({
  bio: S.String,
  modalities: S.Array(
    S.Struct({
      name: S.String,
      slug: S.String,
    })
  ),
}) {
  static default = MyProfileForCommunitiesST.make({
    bio: "",
    modalities: [],
  });

  get encoded(): typeof MyProfileForCommunitiesST.Encoded {
    return S.encodeUnknownSync(MyProfileForCommunitiesST)(this);
  }
}
