import {
  addDays,
  addMonths,
  format,
  getMonth,
  getYear,
  isSameDay,
  subDays,
  subMonths,
} from "date-fns";
import { Match } from "effect";
import { pipe } from "fp-ts/lib/function";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD, type TE } from "shared/base-prelude";
import type { KnownCalendarAppt } from "shared/convex/Calendar/Calendar.Types";
import {
  OneIndexedMonth,
  type CalendarEventsByDay,
  type NearbyCalendarMonths,
} from "shared/types/calendar.types";
import { CalendarUtils } from "shared/utils/calendar.utils";

type ViewState = { _tag: "DAY" } | { _tag: "MONTH" };

export class CalendarStateMgr {
  rdAppts$: Rx.BehaviorSubject<
    RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
  >;
  viewState$: Rx.BehaviorSubject<ViewState>;
  dayViewStateMgr: DayViewStateMgr;
  monthViewStateMgr: MonthViewStateMgr;

  fetchApptsTE: (
    dayInFocus: Date
  ) => TE.TaskEither<unknown, NearbyCalendarMonths<KnownCalendarAppt>>;

  constructor(p: {
    fetchApptsTE: (
      dayInFocus: Date
    ) => TE.TaskEither<unknown, NearbyCalendarMonths<KnownCalendarAppt>>;
    rdAppts$?: Rx.BehaviorSubject<
      RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
    >;
  }) {
    this.fetchApptsTE = p.fetchApptsTE;
    if (p.rdAppts$) {
      console.log("SETTING RD APPTS", p.rdAppts$.value);
      this.rdAppts$ = p.rdAppts$;
    } else {
      this.rdAppts$ = new Rx.BehaviorSubject<
        RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
      >(RD.initial);
    }

    this.viewState$ = new Rx.BehaviorSubject<ViewState>({ _tag: "MONTH" });
    this.dayViewStateMgr = new DayViewStateMgr(this.rdAppts$);
    this.monthViewStateMgr = new MonthViewStateMgr(this.rdAppts$);

    this.monthViewStateMgr.currentDayInFocus$.subscribe((day) => {
      console.log("CURRENT DAY IN FOCUS", day);
      this.fetchAndSetAppts(day);
    });
  }

  fetchAndSetAppts = (dayInFocus: Date) => {
    this.rdAppts$.next(RD.pending);
    console.log("FETCHING APPTS");
    this.fetchApptsTE(dayInFocus)().then((appts) =>
      this.rdAppts$.next(RD.fromEither(appts))
    );
  };

  setViewState(state: ViewState) {
    this.viewState$.next(state);
  }

  toggleViewState = () => {
    this.viewState$.next(
      this.viewState$.value._tag === "DAY" ? { _tag: "MONTH" } : { _tag: "DAY" }
    );
  };
}

class DayViewStateMgr {
  rdAppts$: Rx.BehaviorSubject<
    RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
  >;
  selectedDate$ = new Rx.BehaviorSubject(new Date());
  selectedDoy$ = this.selectedDate$.pipe(RxO.map(CalendarUtils.asDOY));
  appointmentsForCurrentDay$: Rx.Observable<
    RD.RemoteData<unknown, KnownCalendarAppt[]>
  >;

  constructor(
    rdAppts$: Rx.BehaviorSubject<
      RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
    >
  ) {
    this.rdAppts$ = rdAppts$;

    this.appointmentsForCurrentDay$ = Rx.combineLatest([
      this.selectedDate$,
      this.rdAppts$,
    ]).pipe(
      RxO.map(([curDay, rdAppts]) =>
        pipe(
          rdAppts,
          RD.map((appts) =>
            appts.thisMonth
              .flatMap((a) => a.events)
              .filter((a) => isSameDay(a.startTime, curDay))
          )
        )
      )
    );
  }

  nextDay = () => {
    this.selectedDate$.next(addDays(this.selectedDate$.value, 1));
  };

  prevDay = () => {
    this.selectedDate$.next(subDays(this.selectedDate$.value, 1));
  };
}

type RelativeMonthInFocus = "CURRENT" | "NEXT" | "PREV";

class MonthViewStateMgr {
  rdAppts$: Rx.BehaviorSubject<
    RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
  >;
  currentDayInFocus$ = new Rx.BehaviorSubject(new Date());
  private relativeMonthInFocus$: Rx.Observable<RelativeMonthInFocus> =
    this.currentDayInFocus$.pipe(
      RxO.map((curDay) => {
        console.log("CUR DAY IN FOCUS! ", curDay);
        const curMonthInFocus = OneIndexedMonth(getMonth(curDay) + 1);
        const curYearInFocus = getYear(curDay);
        const nearbyMonthsOfToday = CalendarUtils.getNearbyMonths({
          oiMonthIdx: curMonthInFocus,
          year: curYearInFocus,
        });

        if (curMonthInFocus === nearbyMonthsOfToday.thisMonth.oiMonthIdx) {
          return "CURRENT";
        } else if (
          curMonthInFocus === nearbyMonthsOfToday.nextMonth.oiMonthIdx
        ) {
          return "NEXT";
        } else {
          return "PREV";
        }
      })
    );

  appointmentsForNearbyMonths$: Rx.Observable<
    RD.RemoteData<unknown, CalendarEventsByDay<KnownCalendarAppt>[]>
  >;
  monthInFocusNameToDisplay$ = this.currentDayInFocus$.pipe(
    RxO.map((curDay) => format(curDay, "MMMM"))
  );

  constructor(
    rdAppts$: Rx.BehaviorSubject<
      RD.RemoteData<unknown, NearbyCalendarMonths<KnownCalendarAppt>>
    >
  ) {
    this.rdAppts$ = rdAppts$;

    this.appointmentsForNearbyMonths$ = Rx.combineLatest([
      this.relativeMonthInFocus$,
      this.rdAppts$,
    ]).pipe(
      RxO.map(([relativeMonth, rdAppts]) => {
        console.log("RD APPTS", rdAppts);
        console.log("GETTING APPTS FOR", relativeMonth);
        return pipe(
          rdAppts,
          RD.map((appts) =>
            Match.value(relativeMonth).pipe(
              Match.when("CURRENT", () => appts.thisMonth),
              Match.when("NEXT", () => appts.nextMonth),
              Match.when("PREV", () => appts.prevMonth),
              Match.exhaustive
            )
          )
        );
      })
    );
  }

  goToNextMonth = () => {
    this.currentDayInFocus$.next(addMonths(this.currentDayInFocus$.value, 1));
  };

  goToPreviousMonth = () => {
    this.currentDayInFocus$.next(subMonths(this.currentDayInFocus$.value, 1));
  };
}
