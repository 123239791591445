import { Menu } from "@headlessui/react";
import React from "react";

export interface MenuItem {
  label: string;
  onClick: () => void;
}

interface ThreeDotsMenuProps {
  menuItems: MenuItem[];
  size?: number;
}

const Dot: React.FC<{ size: number }> = ({ size }) => {
  return (
    <div
      className={`rounded-full bg-vid-black-900`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = ({
  menuItems,
  size = 60,
}) => {
  return (
    <div
      className="relative"
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <Menu>
        <Menu.Button
          className={`flex flex-col w-full h-full justify-center items-center rounded-full bg-white gap-1 border p-1`}
        >
          <Dot size={Math.max(4, size / 8)} />
          <Dot size={Math.max(4, size / 8)} />
          <Dot size={Math.max(4, size / 8)} />
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          {menuItems.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <div
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full rounded-md px-2 py-4 cursor-pointer`}
                  onClick={item.onClick}
                >
                  {item.label}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};
