import { ChatFC } from "@webapp/componentschat/chat.fc";
import { Match } from "effect";
import type { SessionRSM } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import type { ChatStateMgr } from "frontend-shared/src/mgrs/state-mgrs/chat.statemgr";
import type { MeditationStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/meditation.statemgr";
import type { EmdrSyncStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/shared/emdr.statemgr";
import { useObservableEagerState } from "observable-hooks";
import type React from "react";
import { useEffect, useState } from "react";
import type { Id } from "shared/convex/_generated/dataModel";
import {
  KnownTool,
  type KnownToolTag,
} from "shared/schemas/session/ongoing/known-tool.schemas";
import type { ChatChannelUserSetup } from "shared/types/chat.types";
import { isNotNullOrUndefined } from "shared/util";
import type { Channel, StreamChat } from "stream-chat";
import * as stream from "stream-chat-react";
import { EmdrToolsPanel } from "../right-nav/therapy-tools/emdr/emdr-tools";
import { MeditationToolsPanel } from "../right-nav/therapy-tools/meditation.tools";
import { NoteToolsPanel } from "../right-nav/therapy-tools/notes-tools-panel";
import { TherapyToolMenuButton } from "../right-nav/therapy-tools/therapy-tools.components";

export const MainRoomToolMenu: React.FC<{
  baseSessionId: Id<"sessionConfig">;
  chatStateMgr: ChatStateMgr<StreamChat>;
  mainRoomRSM: SessionRSM;
  emdrMgr: EmdrSyncStateMgr;
  meditationMgr: MeditationStateMgr;
}> = ({ baseSessionId, chatStateMgr, mainRoomRSM, emdrMgr, meditationMgr }) => {
  const toolMenuViewState = useObservableEagerState(
    mainRoomRSM.toolMenuViewStateSyncMgr.state$
  );

  const toolMenuTools = useObservableEagerState(
    mainRoomRSM.toolMenuViewStateSyncMgr.toolsSyncMgr.state$
  );

  return Match.value(toolMenuViewState.viewState).pipe(
    Match.when({ tag: "MENU" }, () => (
      <div className="flex-1 self-stretch flex flex-col gap-4">
        {toolMenuTools.tools.map((toolTag) => {
          const knownTool = KnownTool.fromTag(toolTag);
          return (
            <TherapyToolMenuButton
              key={toolTag}
              content={knownTool}
              onClick={() => {
                mainRoomRSM.toolMenuViewStateSyncMgr.onToolClicked(toolTag);
              }}
            />
          );
        })}
      </div>
    )),
    Match.when({ tag: "SELECTED_TOOL" }, ({ tool }) => (
      <div className="flex-1 self-stretch flex flex-col gap-4">
        {componentForToolTag(
          baseSessionId,
          tool,
          chatStateMgr,
          mainRoomRSM,
          emdrMgr,
          meditationMgr
        )}
      </div>
    )),
    Match.exhaustive
  );
};

function componentForToolTag(
  baseSessionId: Id<"sessionConfig">,
  toolTag: KnownToolTag,
  chatStateMgr: ChatStateMgr<StreamChat>,
  mainRoomRSM: SessionRSM,
  emdrMgr: EmdrSyncStateMgr,
  meditationMgr: MeditationStateMgr
) {
  return Match.value(toolTag).pipe(
    Match.when("EMDR", () => <EmdrToolsPanel emdrMgr={emdrMgr} />),
    Match.when("MEDITATION", () => (
      <MeditationToolsPanel meditationMgr={meditationMgr} />
    )),
    Match.when("CHAT", () => (
      <ChatPanel
        sessionId={baseSessionId}
        mainRoomRSM={mainRoomRSM}
        chatStateMgr={chatStateMgr}
      />
    )),
    Match.when("NOTES", () => (
      <NoteToolsPanel annotationsPanelMgr={mainRoomRSM.annotationsPanelMgr} />
    )),
    Match.when("REVIEW", () => <div>REVIEW</div>),
    Match.exhaustive
  );
}

const ChatPanel: React.FC<{
  sessionId: string;
  mainRoomRSM: SessionRSM;
  chatStateMgr: ChatStateMgr<StreamChat>;
}> = ({ sessionId, mainRoomRSM, chatStateMgr }) => {
  console.log("CHAT STATE MGR! ", chatStateMgr, sessionId);
  const chatSetup = useObservableEagerState(mainRoomRSM.chat.state$);
  const [mbChannel, setMbChannel] =
    useState<Channel<stream.DefaultStreamChatGenerics> | null>(null);

  useEffect(() => {
    async function setupChannelWatch(cus: ChatChannelUserSetup) {
      if (isNotNullOrUndefined(cus)) {
        const { channelConfig, credentials, memberIds } = cus.setup!;
        const cli = chatStateMgr.chatCli;
        const channel = cli.channel(
          channelConfig.channelType,
          channelConfig.channelId,
          {
            members: [...memberIds],
          }
        );
        await channel.watch();

        return channel;
      }

      return null;
    }

    if (chatSetup.setup) {
      setupChannelWatch(chatSetup).then(setMbChannel);
    }
  }, [chatSetup]);

  return <ChatFC chatCli={chatStateMgr.chatCli} userChatSetup={chatSetup} />;
};
