import { PubSubNotificationsRsm } from "frontend-shared/src/mgrs/remote-state-mgrs/users/me.rsm";
import type { ReactNode } from "react";
import { O, Rx } from "../../prelude";
import { isNotNullOrUndefined } from "shared/util";

export type TopAlert = {
  msg: string;
  onClose?: () => void;
  actionContent?: React.ReactNode;
  durationSeconds?: number;
};

export interface RightNavBaseInfo {
  topView: ReactNode;
  content: ReactNode;
}

export type ShowBottomToastConfig = {
  msg: string;
  duration?: { _tag: "INFINITE" } | { _tag: "SECONDS"; seconds: number };
  reload?: boolean;
  onUndo?: () => void;
  confirmButton?: { label: string; onClick: () => void };
  closeRightNav?: boolean;
};

export class DashboardStateMgr<RightNav extends {}> {
  showReloading$ = new Rx.BehaviorSubject(false);
  showTopAlert$ = new Rx.BehaviorSubject<O.Option<TopAlert>>(O.none);
  showBottomToast$ = new Rx.BehaviorSubject<O.Option<ShowBottomToastConfig>>(
    O.none
  );
  rightNav$ = new Rx.BehaviorSubject(O.none as O.Option<RightNav>);

  constructor(readonly p: { pubSubNotifDisplayRsm: PubSubNotificationsRsm }) {
    p.pubSubNotifDisplayRsm.state$.subscribe((pndisplay) => {
      console.log("PN DISPLAY RSM! ", pndisplay);

      if (isNotNullOrUndefined(pndisplay.notification)) {
        // todo
      }
    });
  }

  showTopAlert = (alert: TopAlert) => {
    this.showTopAlert$.next(O.some(alert));
  };

  hideTopAlert = () => {
    this.showTopAlert$.next(O.none);
  };

  closeRightNav = () => {
    this.rightNav$.next(O.none);
  };

  setShowReloading = (show: boolean) => {
    this.showReloading$.next(show);
  };

  openRightNav = (nav: RightNav) => {
    if (O.isSome(this.rightNav$.value)) {
      this.closeRightNav();
      setTimeout(() => {
        this.rightNav$.next(O.some(nav));
      }, 500);
    } else {
      // make the div with id dashboard-content scroll to the top of the window first
      const dashboardContent = document.getElementById("dashboard-content");
      if (dashboardContent) {
        dashboardContent.scrollTo(0, 0);
      }
      this.rightNav$.next(O.some(nav));
    }
  };

  showBottomToast = (toast: ShowBottomToastConfig) => {
    this.showBottomToast$.next(O.some(toast));
  };

  hideBottomToast = () => {
    this.showBottomToast$.next(O.none);
  };
}
