import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { apiMgr } from "@webapp/backend";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { LoadingSpinner } from "@webapp/loading";
import { erp, Rx } from "@webapp/prelude";
import { FileUploadUtils, FileUtils } from "@webapp/utils/file.utils";
import type { ConvexClient } from "convex/browser";
import { Match, Option } from "effect";
import { CreateCommunityPostFormMgr } from "frontend-shared/src/mgrs/state-mgrs/communities/create-community-post.formmgr";
import type { PreprocessedMediaStateMgr } from "frontend-shared/src/mgrs/state-mgrs/preprocessed-media.statemgr";
import { useKeyOfBehaviorSubjectAsState } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { FileSchemas, type InMemoryFile } from "shared/schemas/file.schemas";
import { useConvexCli } from "src/convex-cli";

class StateActions {
  formMgr: CreateCommunityPostFormMgr;

  localSavedImageOrVideo$: Rx.BehaviorSubject<File | null> =
    new Rx.BehaviorSubject<File | null>(null);

  constructor(
    readonly communitySlug: string,
    readonly posterId: string,
    readonly convex: ConvexClient,
    readonly onSuccessSubmit: () => void
  ) {
    this.formMgr = new CreateCommunityPostFormMgr({
      apiMgr: apiMgr,
      convex,
      communitySlug: communitySlug,
      posterUserId: posterId,
      uploadFileToCloudinary: (p) =>
        FileUploadUtils.uploadInMemoryFileToCloudinary(p),
    });
  }

  setMainTextContent = (content: string) => {
    this.formMgr.setFormValue("mainText", content);
  };

  setLocalSavedImageOrVideo = (file: File | null) => {
    this.localSavedImageOrVideo$.next(file);
  };

  submit = () => {
    console.log("COMMUNITY SLUG! ", this.formMgr.formData$.value);

    this.formMgr.submit(() => {
      this.onSuccessSubmit();
    });
  };
}

export const CreateCommunityPostFormTopView: React.FC = () => {
  return (
    <div className="flex flex-col gap-4 justify-center items-center">
      <div className="flex justify-center items-center p-4 rounded-full bg-bg-gray">
        <PencilIcon />
      </div>
      <div className="text-lg font-medium">Create a post</div>
    </div>
  );
};

export const CreateCommunityPostForm: React.FC<{
  communitySlug: string;
  posterId: string;
}> = ({ communitySlug, posterId }) => {
  const hpState = useHpState();
  const convex = useConvexCli();
  const stateMgr = React.useMemo(
    () =>
      new StateActions(communitySlug, posterId, convex, () => {
        hpState.dashboardState.closeRightNav();

        setTimeout(() => {
          hpState.dashboardState.showBottomToast({
            msg: "Post created",
            reload: true,
          });
        }, 300);
      }),
    [posterId]
  );

  const rdSubmitResult = useObservableEagerState(
    stateMgr.formMgr.submitResult$
  );
  const uploadedImageOrVideo = useObservableEagerState(
    stateMgr.localSavedImageOrVideo$
  );

  return (
    <div className="flex-1 flex flex-col px-6 pt-8 pb-16">
      <form
        className="flex-1 flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault();
          stateMgr.submit();
        }}
      >
        <div className="flex-1 flex flex-col gap-4">
          <AddMediaSection stateMgr={stateMgr} />
          <EnterTextSection formMgr={stateMgr.formMgr} />
          <PreviewMediaSection
            preprocessedMediaState={stateMgr.formMgr.preprocessedMediaStateMgr}
          />
          {rdSubmitResult.ifFailure(
            (reason) => {
              return (
                <div className="text-red-500 font-bold text-lg self-center">
                  {reason.error}
                </div>
              );
            },
            <></>
          )}
        </div>
        <PrimaryButton
          title="Submit"
          disabled={rdSubmitResult.isLoading()}
          isLoading={rdSubmitResult.isLoading()}
          dims={{ height: 16 }}
        />
      </form>
    </div>
  );
};

const PreviewMediaSection: React.FC<{
  preprocessedMediaState: PreprocessedMediaStateMgr;
}> = ({ preprocessedMediaState }) => {
  const state = useObservableEagerState(preprocessedMediaState.state$);

  return Match.value(state).pipe(
    Match.tag("INITIAL", () => <></>),
    Match.tag("UNPROCESSED", ({ inMemoryFile, state }) => (
      <div className="w-full h-[400px] relative">
        {state._tag === "PROCESSING" && (
          <div className="absolute inset-0 z-30 bg-vid-black-900 opacity-80 flex flex-col items-center justify-center gap-1">
            <LoadingSpinner />
            <h4 className="text-white">Processing...</h4>
          </div>
        )}
        {Match.value(inMemoryFile.fileMetadata.mediaType).pipe(
          Match.when("image", () => (
            <img
              src={`${inMemoryFile.base64String}`}
              alt="Uploaded Image"
              className="mt-2 rounded-lg max-w-full h-auto"
            />
          )),
          Match.when("video", () => (
            <div className="w-full">
              <video
                src={inMemoryFile.base64String}
                className="w-full h-auto border-4 object-contain"
              />
            </div>
          )),
          Match.orElse(() => null)
        )}
      </div>
    )),
    Match.tag("PROCESSED", ({ uploadResult, originalFileMetadata }) =>
      Match.value(originalFileMetadata.mediaType).pipe(
        Match.when("image", () => (
          <img
            src={uploadResult.secure_url}
            alt="Uploaded Image"
            className="mt-2 rounded-lg max-w-full h-auto"
          />
        )),
        Match.when("video", () => (
          <div className="w-full">
            <video
              src={uploadResult.secure_url}
              controls
              className="w-full h-auto border-4 object-contain"
            />
          </div>
        )),
        Match.when("audio", () => null),
        Match.exhaustive
      )
    ),
    Match.exhaustive
  );
};

const AddMediaSection: React.FC<{
  stateMgr: StateActions;
}> = ({ stateMgr }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      className="flex items-center gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100"
      onClick={() => fileInputRef.current?.click()}
    >
      <AddMediaIcon />
      <span className="text-sm text-vid-black-900">Add a photo or video</span>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*,video/*"
        className="hidden"
        onChange={(e) => {
          const file = e.target.files ? e.target.files[0] : null;
          console.log("GOT FILE! ", file);
          if (file) {
            stateMgr.setLocalSavedImageOrVideo(file);
            const mbFileMetadata =
              FileSchemas.FileMetadata.decodeOptionFromFile(file);
            if (Option.isSome(mbFileMetadata)) {
              console.log("GOT FILE METADATA! ", mbFileMetadata);
              erp(FileUtils.fileAsBase64(file)).then((b64) => {
                console.log("GOT B64! ", b64.length);
                const inMemoryFile: InMemoryFile = {
                  base64String: b64,
                  fileMetadata: mbFileMetadata.value,
                };
                stateMgr.formMgr.onLocalMediaChange({
                  inMemoryFile: inMemoryFile,
                  fileMetadata: mbFileMetadata.value,
                });
              });
            } else {
              alert("Invalid file type"); // TODO!
            }
          }
        }}
      />
    </div>
  );
};

const EnterTextSection: React.FC<{
  formMgr: CreateCommunityPostFormMgr;
}> = ({ formMgr }) => {
  const mainTextContent = useKeyOfBehaviorSubjectAsState(
    formMgr.formData$,
    "mainText"
  );
  return (
    <div className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100">
      <div className="flex items-center gap-2">
        <LeftIndentTextIcon />
        <h4 className="text-vid-black-900">Write something</h4>
      </div>
      <textarea
        value={mainTextContent}
        onChange={(e) => formMgr.setFormValue("mainText", e.target.value)}
        className="w-full border rounded-lg px-4 py-2 h-[96px] bg-vid-black-100"
      />
    </div>
  );
};

/*
Icons
*/

const PencilIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M22.918 4.16699H18.7513C8.33464 4.16699 4.16797 8.33366 4.16797 18.7503V31.2503C4.16797 41.667 8.33464 45.8337 18.7513 45.8337H31.2513C41.668 45.8337 45.8346 41.667 45.8346 31.2503V27.0837"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.415 6.29195L16.9983 22.7086C16.3733 23.3336 15.7483 24.5628 15.6233 25.4586L14.7274 31.7294C14.3941 34.0003 15.9983 35.5836 18.2691 35.2711L24.5399 34.3753C25.4149 34.2503 26.6441 33.6253 27.29 33.0003L43.7066 16.5836C46.54 13.7503 47.8733 10.4586 43.7066 6.29195C39.54 2.12528 36.2483 3.45862 33.415 6.29195Z"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.0625 8.64551C32.4583 13.6247 36.3542 17.5205 41.3542 18.9372"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AddMediaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 5H21.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.5 7.75V2.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2.67188 18.9496L7.60187 15.6396C8.39187 15.1096 9.53187 15.1696 10.2419 15.7796L10.5719 16.0696C11.3519 16.7396 12.6119 16.7396 13.3919 16.0696L17.5519 12.4996C18.3319 11.8296 19.5919 11.8296 20.3719 12.4996L22.0019 13.8996"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LeftIndentTextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 4.5H21"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.5H12.47"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 14.5H21"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 19.5H12.47"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
