import { Match, type Data } from "effect";
import React, { useState } from "react";

type ButtonSelectedView = Data.TaggedEnum<{
  TEXT: { text: string };
  CUSTOM: { view: React.ReactNode };
}>;

type ButtonView<V> =
  | { _tag: "CUSTOM"; view: React.ReactNode }
  | {
      _tag: "DEFAULT";
      label: string;
      selectedView: (v: V | null) => ButtonSelectedView;
      downArrow?: boolean;
    };

function viewForButton<V>(
  b: ButtonView<V>,
  selection: V | null
): React.ReactNode {
  return Match.value(b).pipe(
    Match.tag("CUSTOM", (res) => res.view),
    Match.tag("DEFAULT", (res) => (
      <div className="flex flex-col">
        <h1 className="font-sans font-light text-sm flex w-full justify-start text-vid-purple">
          {res.label}
        </h1>
        <div className="flex">
          {selection
            ? Match.value(res.selectedView(selection)).pipe(
                Match.tag("TEXT", (res) => (
                  <h5 className="font-sans text-sm ">{res.text}</h5>
                )),
                Match.tag("CUSTOM", (res) => res.view),
                Match.exhaustive
              )
            : null}
        </div>
      </div>
    )),
    Match.exhaustive
  );
}

interface FormDisclosureContainerProps<V> {
  buttonView: ButtonView<V>;
  selection: V | null;
  dropdownView: (close: () => void) => React.ReactNode;
  height?: number;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}

export const FormDisclosureContainer = <V,>({
  buttonView,
  selection,
  dropdownView,
  height,
  isOpen,
  onToggle,
}: FormDisclosureContainerProps<V>): React.ReactElement => {
  return (
    <div className="relative flex flex-col">
      <div className="relative flex flex-col">
        <button
          className="py-4 px-6 border flex justify-between items-center rounded-xl"
          onClick={() => onToggle(!isOpen)}
        >
          <div className="flex">{viewForButton(buttonView, selection)}</div>
          <DownArrowSvg />
        </button>
        {isOpen && (
          <div
            className={`absolute top-full -left-2 -right-2 rounded-lg bg-vid-black-100 border z-20 min-h-[100px] ${
              height ? `h-${height}` : ""
            }`}
          >
            {dropdownView(() => onToggle(false))}
          </div>
        )}
      </div>
    </div>
  );
};

function DownArrowSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
        stroke="#1D1626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="rotate(90 12 12)"
      />
    </svg>
  );
}
