import * as S from "@effect/schema/Schema";
import { Option } from "effect";

const MeditationPlayStateSchema = S.Union(
  S.Literal("PLAYING"),
  S.Literal("PAUSED"),
  S.Literal("OFF"),
  S.Literal("COMPLETE"),
  S.Literal("READY")
);
export type MeditationPlayState = S.Schema.Type<
  typeof MeditationPlayStateSchema
>;

const IN_PROGRESS_PLAY_STATES: Array<MeditationPlayState> = [
  "PLAYING",
  "READY",
  "PAUSED",
];

const PAUSED_OR_STOPPED_PLAY_STATES: Array<MeditationPlayState> = [
  "PAUSED",
  "OFF",
  "COMPLETE",
];

const MeditationVisualSchema = S.Union(
  S.Literal("Ocean"),
  S.Literal("Flame"),
  S.Literal("Stream"),
  S.Literal("None")
);
export type MeditationVisual = S.Schema.Type<typeof MeditationVisualSchema>;

export type MeditationStateData = typeof MeditationStateSchema.Encoded;

export class MeditationStateSchema extends S.Class<MeditationStateSchema>(
  "MeditationStateSchema"
)({
  timerMinutesSetting: S.NullOr(S.Number),
  startTimerSeconds: S.NullOr(S.Number),
  visual: MeditationVisualSchema,
  playState: MeditationPlayStateSchema,
  playSound: S.NullOr(S.String),
}) {
  get mbShowSpecialClock(): Option.Option<{ message: string }> {
    if (this.isInProgress) {
      return Option.none();
    }

    return Option.none();
  }

  get formattedStartTimerSeconds() {
    if (this.startTimerSeconds === null) {
      return "0:00";
    }
    const minutes = Math.floor(this.startTimerSeconds / 60);
    const seconds = this.startTimerSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }

  withVisual = (visual: MeditationVisual) =>
    MeditationStateSchema.make(
      {
        ...this,
        visual,
      },
      { disableValidation: true }
    );

  withTimerMinutesSetting = (timerMinutesSetting: number | null) =>
    MeditationStateSchema.make(
      {
        ...this,
        timerMinutesSetting,
        startTimerSeconds: timerMinutesSetting
          ? timerMinutesSetting * 60
          : null,
      },
      { disableValidation: true }
    );

  withStartTimerSeconds = (startTimerSeconds: number | null) =>
    MeditationStateSchema.make(
      {
        ...this,
        startTimerSeconds,
      },
      { disableValidation: true }
    );

  get isInProgress() {
    return IN_PROGRESS_PLAY_STATES.includes(this.playState);
  }

  get isPausedOrStopped() {
    return PAUSED_OR_STOPPED_PLAY_STATES.includes(this.playState);
  }

  static default = MeditationStateSchema.make(
    {
      timerMinutesSetting: null,
      startTimerSeconds: null,
      visual: "None",
      playState: "OFF",
      playSound: null,
    },
    { disableValidation: true }
  );

  get encoded() {
    return S.encodeUnknownSync(MeditationStateSchema)(this);
  }
}
