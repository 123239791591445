import { FullContainerLoadingSpinner } from "@webapp/loading";
import {
  Authenticated,
  AuthLoading,
  Unauthenticated,
  useConvexAuth,
  useMutation,
} from "convex/react";
import { useEffect, useState } from "react";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { ROUTES } from "shared/routes/routes";

export const InvitePage: React.FC = () => {
  const { isLoading, isAuthenticated } = useConvexAuth();
  const { inviteId } = useTypedParams(ROUTES.INVITES.INVITE_ID); // or route.params for RN
  const validateInvite = useMutation(
    api.Invite.TrackInviteFns.validateAndTrackInvite
  );
  const [inviteRes, setInviteRes] =
    useState<Awaited<ReturnType<typeof validateInvite>>>();

  useEffect(() => {
    if (inviteId) {
      validateInvite({ inviteId: inviteId as Id<"inviteLinks"> }).then(
        ({ invite, redemptionId, redirectPath }) => {
          console.log("invite", invite);
          console.log("redemptionId", redemptionId);
          console.log("redirectPath", redirectPath);
          setInviteRes({ invite, redemptionId, redirectPath });
        }
      );
    }
  }, [inviteId]);

  if (inviteRes === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div>
      <Authenticated>Logged in</Authenticated>
      <Unauthenticated>Logged out</Unauthenticated>
      <AuthLoading>Still loading</AuthLoading>
      <h4>{JSON.stringify(inviteRes, null, 2)}</h4>
    </div>
  );
};
