import { Link } from "react-router-dom";

export const PortalSelectionPage: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <h3 className="font-outfit font-medium text-[54px] leading-[160%] text-center text-vid-purple w-[750px]">
        What is your role?
      </h3>
      <div className="flex flex-col md:flex-row gap-2 pb-48 mx-12">
        <CardContainer to={`/onboard/hp/register`}>
          <div className="flex-1 bg-vid-black-100 flex flex-col justify-center items-center rounded-full">
            <TherapistCoachBriefcaseIcon />
            <div className="rounded-md text-default-purple flex justify-center items-center py-4 font-sans">
              Therapist
            </div>
          </div>
        </CardContainer>
        <CardContainer to={`/onboard-cp/create-account`}>
          <div className="flex-1 bg-vid-black-100 flex flex-col justify-center items-center rounded-full">
            <ImAClientIcon />
            <div className="text-default-purple flex justify-center items-center py-4 font-sans">
              Client
            </div>
          </div>
        </CardContainer>
      </div>
    </div>
  );
};

const CardContainer: React.FC<{ to: string; children: React.ReactNode }> = ({
  to,
  children,
}) => (
  <Link
    to={to}
    className="w-[220px] h-[220px] flex flex-col gap-4 mx-12 lg:mx-0 p-4 cursor-pointer"
  >
    {children}
  </Link>
);

const TherapistCoachBriefcaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
  >
    <path
      d="M36 36C44.2843 36 51 29.2843 51 21C51 12.7157 44.2843 6 36 6C27.7157 6 21 12.7157 21 21C21 29.2843 27.7157 36 36 36Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2305 66C10.2305 54.39 21.7805 45 36.0005 45C38.8805 45 41.6705 45.39 44.2805 46.11"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66 54C66 56.25 65.37 58.38 64.26 60.18C63.63 61.26 62.82 62.22 61.89 63C59.79 64.89 57.03 66 54 66C49.62 66 45.81 63.66 43.74 60.18C42.63 58.38 42 56.25 42 54C42 50.22 43.74 46.83 46.5 44.64C48.57 42.99 51.18 42 54 42C60.63 42 66 47.37 66 54Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.3203 54L52.2903 56.97L58.6803 51.0601"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ImAClientIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
  >
    <path
      d="M54.422 64.8593C51.782 65.6393 48.662 65.9993 45.002 65.9993H27.002C23.342 65.9993 20.222 65.6393 17.582 64.8593C18.242 57.0593 26.252 50.9092 36.002 50.9092C45.752 50.9092 53.762 57.0593 54.422 64.8593Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45 6H27C12 6 6 12 6 27V45C6 56.34 9.42 62.55 17.58 64.86C18.24 57.06 26.25 50.9099 36 50.9099C45.75 50.9099 53.76 57.06 54.42 64.86C62.58 62.55 66 56.34 66 45V27C66 12 60 6 45 6ZM36 42.5099C30.06 42.5099 25.26 37.6801 25.26 31.7401C25.26 25.8001 30.06 21 36 21C41.94 21 46.74 25.8001 46.74 31.7401C46.74 37.6801 41.94 42.5099 36 42.5099Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.7398 31.7401C46.7398 37.6801 41.9398 42.5099 35.9998 42.5099C30.0598 42.5099 25.2598 37.6801 25.2598 31.7401C25.2598 25.8001 30.0598 21 35.9998 21C41.9398 21 46.7398 25.8001 46.7398 31.7401Z"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
