import { useMe } from "@pages/u/u.webstatemgr";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";

export const OnboardCompletePage: React.FC = () => {
  const navigate = useNavigate();
  const destination = useQuery(
    api.Invite.TrackInviteFns.getPostOnboardingDestination
  );
  const completeInvite = useMutation(
    api.Invite.TrackInviteFns.markInviteComplete
  );

  useEffect(() => {
    if (destination) {
      // If there was an invite, mark it as completed
      if (destination.inviteId) {
        completeInvite({ inviteId: destination.inviteId });
      }

      setTimeout(() => {
        navigate(destination.path);
      }, 800);
    }
  }, [destination, navigate]);

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <FullScreenLoadingSpinner />
    </div>
  );
};
