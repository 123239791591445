import { useObservableEagerState } from "observable-hooks";
import { O, Rx } from "../../prelude";
import { Listbox, Transition } from "@headlessui/react";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";
import { Fragment } from "react/jsx-runtime";

export const DurationPicker: React.FC<{
  durationInMinutes$: Rx.BehaviorSubject<O.Option<number>>;
}> = ({ durationInMinutes$ }) => {
  const selectedDuration = useObservableEagerState(durationInMinutes$);
  const selectedDurationText = O.isSome(selectedDuration)
    ? `${selectedDuration.value} minutes`
    : "Select duration";

  return (
    <Listbox
      value={O.toNullable(selectedDuration)}
      onChange={(value) => {
        durationInMinutes$.next(O.fromNullable(value));
      }}
    >
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate text-vid-purple">
            {selectedDurationText}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <IoChevronDown
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {[60, 90, 120].map((duration) => (
              <Listbox.Option
                key={duration}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                  }`
                }
                value={duration}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {duration} minutes
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <IoCheckmark className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
