import * as S from "@effect/schema/Schema";
import { Array, Order } from "effect";
import type { Doc, Id } from "../_generated/dataModel";

export class PubSubNotificationST extends S.Class<PubSubNotificationST>(
  "PubSubNotificationST"
)({
  _id: S.String,
  type: S.String,
  triggeredFor: S.String,
  triggeredAt: S.Number,
  confirmedReceivedAt: S.NullOr(S.Number),
}) {
  static from(data: typeof PubSubNotificationST.Encoded): PubSubNotificationST {
    return PubSubNotificationST.make(data, { disableValidation: true });
  }

  static fromDoc(doc: Doc<"pubSubNotifications">): PubSubNotificationST {
    return PubSubNotificationST.make({ ...doc }, { disableValidation: true });
  }

  get docId(): Id<"pubSubNotifications"> {
    return this._id as Id<"pubSubNotifications">;
  }

  get encoded(): typeof PubSubNotificationST.Encoded {
    return S.encodeUnknownSync(PubSubNotificationST)(this);
  }
}

export class MyMbCurrentPubSubNotifToDisplayST extends S.Class<MyMbCurrentPubSubNotifToDisplayST>(
  "MyMbCurrentPubSubNotifToDisplayST"
)({
  notification: S.NullOr(PubSubNotificationST),
}) {
  get encoded(): typeof MyMbCurrentPubSubNotifToDisplayST.Encoded {
    return S.encodeUnknownSync(MyMbCurrentPubSubNotifToDisplayST)(this);
  }
}

export class MyPubSubNotificationsST extends S.Class<MyPubSubNotificationsST>(
  "MyPubSubNotificationsST"
)({
  notifications: S.Array(PubSubNotificationST),
}) {
  static from(p: {
    doc: Doc<"pubSubNotifications">[];
  }): MyPubSubNotificationsST {
    return MyPubSubNotificationsST.make(
      { notifications: p.doc.map((d) => PubSubNotificationST.fromDoc(d)) },
      { disableValidation: true }
    );
  }

  static default = MyPubSubNotificationsST.from({ doc: [] });

  get sortedByTriggeredAtDesc(): MyPubSubNotificationsST {
    const byTriggeredAt = Order.mapInput(
      Order.number,
      (n: PubSubNotificationST) => n.triggeredAt
    );
    const sorted = Array.sortBy(byTriggeredAt)(this.notifications);
    return MyPubSubNotificationsST.make(
      { notifications: sorted },
      { disableValidation: true }
    );
  }

  get encoded(): typeof MyPubSubNotificationsST.Encoded {
    return S.encodeUnknownSync(MyPubSubNotificationsST)(this);
  }
}
