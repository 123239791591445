import * as S from "@effect/schema/Schema";
import { ConvexClient } from "convex/browser";
import type {
  DefaultFunctionArgs,
  FunctionArgs,
  FunctionReference,
} from "convex/server";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import type { Id } from "shared/convex/_generated/dataModel";

export abstract class RemoteStateSyncMgr<RemoteState, TState> {
  syncedRemoteState$: Rx.BehaviorSubject<RemoteState>;
  state$: Rx.BehaviorSubject<TState>;
  private subscription: (() => void) | null = null;
  protected schema?: S.Schema<TState, RemoteState>;

  constructor(p: {
    initialState: RemoteState;
    schema?: S.Schema<TState, RemoteState>;
  }) {
    this.schema = p.schema;
    this.syncedRemoteState$ = new Rx.BehaviorSubject(p.initialState);
    this.state$ = new Rx.BehaviorSubject(
      this.convertRemoteToState(p.initialState)
    );

    this.syncedRemoteState$
      .pipe(RxO.map((rs) => this.convertRemoteToState(rs)))
      .subscribe((s) => {
        this.state$.next(s);
      });
  }

  protected abstract convertRemoteToState(remoteState: RemoteState): TState;

  protected abstract createSubscription(
    onNext: (state: RemoteState) => void
  ): () => void;

  protected subscribeToRemoteStateChanges(): void {
    console.log("SUBSCRIBE TO REMOTE STATE CHANGES!", this.subscription);
    if (this.subscription) {
      this.subscription();
    }

    this.subscription = this.createSubscription((remoteState: RemoteState) => {
      this.syncedRemoteState$.next(remoteState);
      this.onStateChanged(remoteState);
    });
  }

  protected onStateChanged(state: RemoteState): void {
    console.log("STATE CHANGED! ", state);
    // Override this method in subclasses to perform additional actions when state changes
  }

  dispose(): void {
    if (this.subscription) {
      this.subscription();
      this.subscription = null;
    }
  }
}

const CONVEX_URL = import.meta.env["VITE_CONVEX_URL"]!;
const convexClient = new ConvexClient(CONVEX_URL);

type SnapshotQuery<RemoteState> = FunctionReference<
  "query",
  "public",
  DefaultFunctionArgs,
  RemoteState
>;

export abstract class ConvexStateSyncMgr<
  RemoteState,
  TState extends Object,
> extends RemoteStateSyncMgr<RemoteState, TState> {
  readonly convex: ConvexClient;
  protected abstract snapshotQueryFunction: SnapshotQuery<RemoteState>;
  protected abstract snapshotQueryArgs(): FunctionArgs<
    SnapshotQuery<RemoteState>
  >;

  constructor(p: {
    convexCli: ConvexClient;
    initialState: RemoteState;
    schema: S.Schema<TState, RemoteState>;
  }) {
    super({
      initialState: p.initialState,
      schema: p.schema,
    });
    this.convex = p.convexCli;
  }

  protected convertRemoteToState(remoteState: RemoteState): TState {
    if (!this.schema) {
      throw new Error("Schema is not defined");
    }

    return S.decodeUnknownSync(this.schema)(remoteState);
  }

  public subscribeSync(): void {
    this.subscribeToRemoteStateChanges();
  }

  public optimisticSetState(p: {
    partialNewState: Partial<TState>;
    mutationFn: FunctionReference<
      "mutation",
      "public",
      DefaultFunctionArgs,
      any
    >;
    mutationArgs: FunctionArgs<
      FunctionReference<"mutation", "public", DefaultFunctionArgs, void>
    >;
  }): void {
    const newState = {
      ...this.state$.value,
      ...p.partialNewState,
    };
    this.state$.next(newState);

    this.convex.mutation(p.mutationFn, p.mutationArgs).catch((e) => {
      console.error("Error mutating state", e);
    });
  }

  public setLocalState(state: TState): void {
    this.state$.next(state);
  }

  protected createSubscription(
    onNext: (state: RemoteState) => void
  ): () => void {
    const queryArgs = this.snapshotQueryArgs();
    // Convex uses a different pattern for subscriptions
    // We'll create a custom subscription using the useQuery hook
    const subscription = this.convex.onUpdate(
      this.snapshotQueryFunction,
      queryArgs,
      (messages) => {
        const snapshot = messages;
        if (snapshot) {
          onNext(snapshot);
        }
      }
    );

    // Return a function to unsubscribe
    return () => {
      subscription.unsubscribe();
    };
  }

  // Optional: Override onStateChanged if needed
  protected onStateChanged(_: RemoteState): void {
    // Perform any additional side effects here
  }
}

export abstract class ConvexSessionStateSyncMgr<
  RemoteState,
  TState extends Object,
> extends ConvexStateSyncMgr<RemoteState, TState> {
  // protected readonly sessionId: string;
  protected readonly baseSessionId: Id<"sessionConfig">;
  protected readonly userId: Id<"users">;

  constructor(p: {
    convexCli: ConvexClient;
    // sessionId: string;
    baseSessionId: Id<"sessionConfig">;
    userId: Id<"users">;
    initialState: RemoteState;
    schema: S.Schema<TState, RemoteState>;
  }) {
    super({
      convexCli: p.convexCli,
      initialState: p.initialState,
      schema: p.schema,
    });
    // console.log("SESSION ID! ", p.sessionId);
    // this.sessionId = p.sessionId;
    this.baseSessionId = p.baseSessionId as Id<"sessionConfig">;
    this.userId = p.userId;
  }
}
