import { DashboardCard } from "@webapp/componentsdashboard/dashboard-card";
import { GetVidalifyProCard } from "@webapp/componentsdashboard/vidalify-pro-card";
import { WelcomeChecklist } from "@webapp/componentsdashboard/welcome-checklist";
import discover from "./mocks/discover";
import sessions from "./mocks/sessions";
import training from "./mocks/training";
import welcome from "./mocks/welcome";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const HpDashboardHomePage = () => {
  return (
    <div className="max-h-screen w-full">
      <div className="flex-1 flex flex-col ">
        <MainContent />
      </div>
    </div>
  );
};

const MainContent: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col gap-6 p-9 justify-between">
      <MainContentTopRow />
      <MainContentBottomRow />
    </div>
  );
};

const MainContentTopRow: React.FC = () => {
  return (
    <div className="flex-1 items-stretch flex gap-6">
      <div className="flex-1 flex flex-col min-h-[300px]">
        <Discover discoverList={discover} />
      </div>
      <div className="flex-1 flex flex-col border rounded-lg">
        <WelcomeChecklist welcomeList={welcome} />
      </div>
    </div>
  );
};

const MainContentBottomRow: React.FC = () => {
  return (
    <div className="flex-1 flex gap-6">
      <div className="flex-1 border rounded-lg max-h-[430px]">
        <DashboardCard
          title="Sessions"
          background="bg-transparent"
          list={sessions}
        />
      </div>
      <div className="flex-1 border rounded-lg max-h-[430px]">
        <DashboardCard
          title="Training"
          background="bg-vid-black-100"
          list={training}
        />
      </div>
      <div className="flex-1 max-h-[430px]">
        <GetVidalifyProCard />
      </div>
    </div>
  );
};

export default HpDashboardHomePage;

interface DiscoverItemCarousel {
  img: string;
  title: string;
  type: string;
  healer: string;
  healerImg: string;
  role: string;
}

interface DiscoverProps {
  discoverList: DiscoverItemCarousel[];
}

const Discover = ({ discoverList }: DiscoverProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Slider {...settings} className="w-[750px] h-[350px]">
      {discoverList.map((item, i) => (
        <div key={i} className="relative">
          <div className="absolute inset-0">
            <img
              className="h-full w-full object-cover bg-center rounded-lg"
              src={item.img}
              width={1357}
              height={641}
              alt={item.title}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gradient-purple opacity-50 rounded-lg"></div>
          </div>
          <div className="relative p-6 h-[350px]">
            <div className="flex flex-col h-full justify-between">
              <h2 className="text-xl font-light text-white">Discover</h2>
              <div className="flex flex-col">
                <div className="bg-white py-1 px-3 rounded-full w-fit text-xs font-semibold mb-2">
                  {item.type}
                </div>
                <div
                  className="text-xl shadow-black drop-shadow-xl text-white mb-2"
                  style={{
                    textShadow: "0px 1px 5px rgba(0, 0, 0, 0.70)",
                  }}
                >
                  {item.title}
                </div>
                <div className="flex items-center bg-black/50 rounded-full w-fit py-1 px-1">
                  <span className="flex h-6 w-6 rounded-full mr-2">
                    <img
                      className="aspect-square h-full w-full"
                      alt="Ana Mendieta"
                      src={item.healerImg}
                    />
                  </span>
                  <div className="text-sm text-white mr-1">
                    <div className=" font-medium">
                      {item.healer} •{" "}
                      <span className=" text-gray-300">{item.role}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};
