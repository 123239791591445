import { Option } from "effect";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { epipe } from "shared/base-prelude";
import type {
  MeditationStateData,
  MeditationVisual,
} from "shared/schemas/session/state/meditation-state.schemas";
import { distinctUntilChangedEquals } from "../../../../util";
import type { AbstractAudioPlayer } from "../../../media-player.statemgr";
import type { MeditationSessionRSM } from "../../../remote-state-mgrs/sessions/tools.rsm";
import { AudioMediaStateMgr } from "./shared/audio-media.statemgr";
import {
  BaseSessionToolStateMgr,
  type BaseSessionToolStateMgrParams,
} from "./shared/base-session-tool.statemgr";

type PlayState = MeditationStateData["playState"];

export class MeditationStateMgr extends BaseSessionToolStateMgr {
  playState$: Rx.Observable<PlayState>;

  localCountdownTimerSeconds$ = new Rx.BehaviorSubject<Option.Option<number>>(
    Option.none()
  );
  countdownTimerInterval: Timer | null = null;

  audioMediaStateMgr: AudioMediaStateMgr;

  mgr: MeditationSessionRSM;

  setInitialState() {}

  setPlayState(playState: PlayState) {
    this.mgr.setPlayState(playState);

    if (playState === "PAUSED") {
      this.setStartTimerSeconds(
        Option.getOrNull(this.localCountdownTimerSeconds$.value)
      );
    }
  }

  setRemoteTimerMinutes(timerMinutes: number | null): void {
    this.mgr.setTimerMinutes(timerMinutes);
  }

  setVisual(visual: MeditationVisual) {
    this.remoteBaseStateMgr.tools.meditation.setVisual(visual);
  }

  setStartTimerSeconds(startTimerSeconds: number | null): void {
    this.remoteBaseStateMgr.tools.meditation.setStartTimerSeconds(
      startTimerSeconds
    );
  }

  endAndClear() {
    this.clearLocalCountdownTimer();
  }

  constructor(
    readonly baseParams: BaseSessionToolStateMgrParams,
    readonly audioPlayer: AbstractAudioPlayer
  ) {
    super(baseParams);
    this.audioMediaStateMgr = new AudioMediaStateMgr(baseParams, audioPlayer);
    this.mgr = this.remoteBaseStateMgr.tools.meditation;

    this.playState$ = this.mgr.state$.pipe(
      RxO.map((rs) => rs.playState),
      distinctUntilChangedEquals()
    );

    const timerMinutesSetting$ = this.mgr.state$.pipe(
      RxO.map((rs) => rs.timerMinutesSetting),
      RxO.distinctUntilChanged()
    );

    timerMinutesSetting$.subscribe((ts) => {
      this.clearCountdownTimerInterval();
      this.localCountdownTimerSeconds$.next(
        epipe(
          Option.fromNullable(ts),
          Option.map((ts) => ts * 60)
        )
      );
    });

    const playSound$ = this.mgr.state$.pipe(
      RxO.map((rs) => rs.playSound),
      RxO.distinctUntilChanged()
    );

    playSound$.subscribe((ps) => {
      if (ps !== null) {
        this.audioMediaStateMgr.playLocallyOnly("simple-singing-bowl.mp3");
      } else {
        this.audioMediaStateMgr.pauseLocal();
      }
    });

    const startTimerSeconds$ = this.mgr.state$.pipe(
      RxO.map((rs) => rs.startTimerSeconds),
      RxO.distinctUntilChanged()
    );

    startTimerSeconds$.subscribe((sts) => {
      this.localCountdownTimerSeconds$.next(Option.fromNullable(sts));
    });
  }

  setMeditationStopped() {
    this.setPlayState("OFF");
    this.clearCountdownTimerInterval();
  }

  setLocalTimer(p: { timerMinutes: number | null }) {
    const { timerMinutes } = p;
    this.localCountdownTimerSeconds$.next(
      timerMinutes !== null ? Option.some(timerMinutes * 60) : Option.none()
    );
  }

  clearLocalCountdownTimer() {
    if (Option.isSome(this.localCountdownTimerSeconds$.value)) {
      this.localCountdownTimerSeconds$.next(Option.none());
    }
    this.clearCountdownTimerInterval();
  }

  clearCountdownTimerInterval() {
    if (this.countdownTimerInterval) {
      clearInterval(this.countdownTimerInterval);
    }
    this.countdownTimerInterval = null;
  }
}
