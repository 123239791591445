import * as S from "@effect/schema/Schema";
import { Array, Option, Order } from "effect";
import { epipe } from "../../../base-prelude";
import { SessionTimeLeftInfo } from "../../../schemas/session.schemas";
import type { MeditationStateSchema } from "../state/meditation-state.schemas";

export class CountdownClockState extends S.Class<CountdownClockState>(
  "CountdownClockState"
)({
  displayType: S.Union(
    S.Literal("DEFAULT_COUNTDOWN"),
    S.Literal("WARN_NEAR_END"),
    S.Literal("None")
  ),
  message: S.NullOr(S.String),
}) {
  get encoded() {
    return S.encodeUnknownSync(CountdownClockState)(this);
  }

  static default = CountdownClockState.make({
    displayType: "None",
    message: null,
  });

  static fromMbTimeLeftInfo = (p: {
    mbTimeLeftInfoData: Option.Option<typeof SessionTimeLeftInfo.Encoded>;
  }): CountdownClockState => {
    if (Option.isNone(p.mbTimeLeftInfoData)) {
      return CountdownClockState.default;
    }

    const timeLeftInfo = S.decodeUnknownSync(SessionTimeLeftInfo)(
      p.mbTimeLeftInfoData.value
    );

    return CountdownClockState.from({ timeLeftInfo });
  };

  static from = (p: {
    timeLeftInfo: SessionTimeLeftInfo;
  }): CountdownClockState => {
    if (p.timeLeftInfo.shouldShowingNearEndTimer) {
      return CountdownClockState.make({
        displayType: "WARN_NEAR_END",
        message: `${p.timeLeftInfo.minutesLeft} minutes left`,
      });
    }

    const mbCountdownClockState = Option.some(
      CountdownClockState.make({
        displayType: "DEFAULT_COUNTDOWN",
        message: `${p.timeLeftInfo.minutesLeft}`,
      })
    );

    if (Option.isNone(mbCountdownClockState)) {
      return CountdownClockState.make({
        displayType: "None",
        message: null,
      });
    }

    return mbCountdownClockState.value;
  };
}

const KnownSpecialClockTagSchema = S.Union(S.Literal("meditation"));
type KnownSpecialClockTag = S.Schema.Type<typeof KnownSpecialClockTagSchema>;

const KnownSpecialClockColorSchema = S.Union(S.Literal("purple"));
type KnownSpecialClockColor = S.Schema.Type<
  typeof KnownSpecialClockColorSchema
>;

export const SpecialClockDisplayInfo = S.Struct({
  type: KnownSpecialClockTagSchema,
  color: KnownSpecialClockColorSchema,
  message: S.NullOr(S.String),
});
export type SpecialClockDisplayInfo = S.Schema.Type<
  typeof SpecialClockDisplayInfo
>;

abstract class KnownSpecialClock {
  abstract _tag: KnownSpecialClockTag;
  abstract color: KnownSpecialClockColor;
  abstract priority: number;

  constructor(
    readonly p: {
      message: string;
    }
  ) {}
}

class MeditationClock extends KnownSpecialClock {
  readonly _tag = "meditation";
  readonly color = "purple";
  readonly priority = 1;

  static mbFrom = (p: {
    meditationState: MeditationStateSchema;
  }): Option.Option<MeditationClock> => {
    if (p.meditationState.isInProgress) {
      return Option.some(
        new MeditationClock({
          message: `${p.meditationState.formattedStartTimerSeconds}`,
          initialSeconds: p.meditationState.startTimerSeconds,
        })
      );
    }

    return Option.none();
  };

  constructor(
    readonly p: {
      message: string;
      initialSeconds: number | null;
    }
  ) {
    super(p);
  }
}

export class SpecialClockDisplayState extends S.Class<SpecialClockDisplayState>(
  "SpecialClockDisplayState"
)({
  mbDisplay: S.Option(SpecialClockDisplayInfo),
}) {
  get encoded() {
    return S.encodeUnknownSync(SpecialClockDisplayState)(this);
  }

  static default = SpecialClockDisplayState.make({
    mbDisplay: Option.none(),
  });

  get nullableInfo() {
    const info = this.mbDisplay.pipe(Option.getOrNull);
    return S.encodeUnknownSync(S.NullOr(SpecialClockDisplayInfo))(info);
  }

  static from = (p: {
    meditationState: MeditationStateSchema;
  }): SpecialClockDisplayState => {
    const mbMeditationClock = MeditationClock.mbFrom({
      meditationState: p.meditationState,
    });

    const sortByPriority = Order.mapInput(
      Order.number,
      (specialClock: KnownSpecialClock) => specialClock.priority
    );
    const mbSpecialClockToShow = Option.all([mbMeditationClock]).pipe(
      Option.flatMap((specialClocks) =>
        epipe(
          specialClocks,
          Array.sortBy(sortByPriority),
          Array.head,
          Option.map((specialClock) => {
            const displayInfo = SpecialClockDisplayInfo.make({
              type: specialClock._tag,
              color: specialClock.color,
              message: specialClock.p.message,
            });

            return SpecialClockDisplayState.make({
              mbDisplay: Option.some(displayInfo),
            });
          })
        )
      )
    );

    return mbSpecialClockToShow.pipe(
      Option.getOrElse(() => SpecialClockDisplayState.default)
    );
  };
}
