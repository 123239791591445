import type { ConvexClient } from "convex/browser";
import { Data, Effect, Option } from "effect";
import * as Rx from "rxjs";
import { ApiMgr } from "../../api.mgr";
import { BaseStateMgr } from "./base.statemgr";

export class ChatStateMgr<ChatCli extends GenericChatCli> extends BaseStateMgr {
  mySimpleProfile: { id: string; name: string };
  chatCli: ChatCli;
  unreadCount$ = new Rx.BehaviorSubject<Option.Option<UnreadChatCountTracker>>(
    Option.none()
  );

  chatEventListener: any | undefined = undefined;
  unsubEvent: { unsubscribe: () => void } | undefined = undefined;

  constructor(p: {
    apiMgr: ApiMgr;
    convex: ConvexClient;
    mySimpleProfile: { id: string; name: string };
    chatKeyAndToken: { apiKey: string; token: string };
    mkChatCli: (p: { apiKey: string }) => ChatCli;
  }) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    this.mySimpleProfile = p.mySimpleProfile;
    this.chatCli = p.mkChatCli({ apiKey: p.chatKeyAndToken.apiKey });

    this.unsubEvent = this.chatCli.on(this.onEvent);

    this.chatEventListener = this.chatCli.connectUser(
      {
        id: this.mySimpleProfile.id,
        name: this.mySimpleProfile.name,
      },
      p.chatKeyAndToken.token
    );

    console.log("CHAT CLI! ", this.chatCli.getUnreadCount);

    this.chatCli
      .getUnreadCount()
      .then((r) => {
        console.log("UNREAD COUNT! ", r);
      })
      .catch((e) => {
        console.log("ERROR GETTING UNREAD COUNT! ", e);
      });

    this.runFetchAndSetUnreadCount();
  }

  fetchAndSetUnreadCountEff = () =>
    Effect.promise(() => this.chatCli.getUnreadCount()).pipe(
      Effect.tap((x) => {
        console.log("UNREAD COUNT! ", x);
        const unreadCountTracker = new UnreadChatCountTracker(x);
        console.log(
          "UNREAD COUNT TRACKER! ",
          unreadCountTracker,
          unreadCountTracker.totalChannelsWithAtLeastOneUnreadMessage
        );
        this.unreadCount$.next(Option.some(new UnreadChatCountTracker(x)));
      })
    );

  runFetchAndSetUnreadCount = () =>
    Effect.runPromise(this.fetchAndSetUnreadCountEff()).catch();

  onDismount = () => {
    this.unsubEvent?.unsubscribe();
  };

  private onEvent = (event: ChatEvent) => {
    console.log("ON EVENT! ", event.total_unread_count);
    if (
      event.total_unread_count !== undefined ||
      event.unread_channels !== undefined
    ) {
      this.runFetchAndSetUnreadCount();
    }
  };
}

abstract class ChatEvent {
  abstract total_unread_count?: number;
  abstract unread_channels?: number;
}

export abstract class GenericChatCli {
  abstract connectUser: (
    user: { id: string; name: string },
    token: string
  ) => Promise<any>;
  abstract on: (callback: (event: ChatEvent) => void) => {
    unsubscribe: () => void;
  };
  abstract getUnreadCount: () => Promise<UnreadChatResponse>;
}

interface UnreadChatResponse {
  channels: {
    channel_id: string;
    unread_count: number;
  }[];
  threads: {
    parent_message_id: string;
    unread_count: number;
  }[];
  total_unread_count: number;
  total_unread_threads_count: number;
}

class UnreadChatCountTracker extends Data.Class<UnreadChatResponse> {
  get totalChannelsWithAtLeastOneUnreadMessage() {
    return this.channels.filter((c) => c.unread_count > 0).length;
  }
}
