import { Disclosure } from "@headlessui/react";
import upArrowSrc from "@public/up-arrow.svg";
import {
  useBackgroundFilters,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { AvatarCircle, AvatarCircles } from "@webapp/componentsavatar.tc";
import { ControlPanelComponents } from "@webapp/componentslive-session/control-panel.components";
import { CustomParticipantView } from "@webapp/componentslive-session/main-room/participant-view.fc";
import type { MenuItem } from "@webapp/componentsmenus/three-dots.menu";
import { ThreeDotsMenu } from "@webapp/componentsmenus/three-dots.menu";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { format } from "date-fns";
import { useSessionRSM } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";
import {
  useKeyOfObservable,
  useKeyOfObservableAsState,
} from "frontend-shared/src/util";
import type { HcSessionShortReview } from "shared/convex/Sessions/SessionReview.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";

export const PastSessionReviewSection: React.FC<{
  pastSessionReviews: HcSessionShortReview[];
}> = ({ pastSessionReviews }) => {
  if (pastSessionReviews.length === 0) {
    return <div className="text-vid-black-500">No past sessions</div>;
  }

  return (
    <div className="flex flex-col">
      {pastSessionReviews.map((p) => {
        return <PastHcSessionReviewDisclosureItem {...p} />;
      })}
    </div>
  );
};

const PastHcSessionReviewDisclosureItem: React.FC<HcSessionShortReview> = ({
  sessionInfo,
  shortReviewPoints,
  participantInfo,
}) => {
  return (
    <Disclosure key={sessionInfo.id}>
      <div className="flex flex-col border rounded-[24px]">
        <Disclosure.Button className="py-4 px-4 border-b flex">
          <div className="flex-1 self-stretch flex gap-4 items-center">
            <AvatarCircle
              mbProfilePhoto={
                participantInfo.client.profilePhoto
                  ? ImageSrc.fromURL(participantInfo.client.profilePhoto)
                  : null
              }
              size={40}
            />
            <div>
              <div className="rounded-full w-[170px] py-2 bg-vid-black-100 text-vid-black-900 text-center">
                {format(new Date(sessionInfo.endedAt), "MMM dd, yyyy")}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center px-8 py-4">
            <div className="w-8 h-8 flex justify-center items-center rounded-full border border-vid-black-200">
              <img src={upArrowSrc} className="w-4 h-4" />
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500">
          <div className="overflow-y-auto py-2 px-4  flex flex-col gap-2">
            {shortReviewPoints.map((s) => {
              return <p>{`* ${s}`}</p>;
            })}
          </div>
        </Disclosure.Panel>
      </div>
    </Disclosure>
  );
};

export const VideoSection: React.FC<{
  belowVideoDisplayInfo?: {
    userProfilePhotos: (string | null)[];
    msg: string;
  };
}> = ({ belowVideoDisplayInfo }) => {
  const sessionRSM = useSessionRSM();
  const { useLocalParticipant, useMicrophoneState, useCameraState } =
    useCallStateHooks();
  const { microphone, isMute: isAudioMute } = useMicrophoneState();
  const { camera, isMute: isVideoMute } = useCameraState();
  const me = useLocalParticipant();

  const { disableBackgroundFilter, applyBackgroundBlurFilter } =
    useBackgroundFilters();

  const displayState$ = useKeyOfObservable(
    sessionRSM.participantSyncMgr.state$,
    "displayState"
  );

  const isBackgroundBlurred = useKeyOfObservableAsState(
    displayState$,
    "isBackgroundBlurred",
    false
  );

  const handleToggleBackgroundBlur = () => {
    if (isBackgroundBlurred) {
      disableBackgroundFilter();
      sessionRSM.participantSyncMgr.setIsBackgroundBlurred(false);
    } else {
      applyBackgroundBlurFilter("high");
      sessionRSM.participantSyncMgr.setIsBackgroundBlurred(true);
    }
  };

  const menuItems: MenuItem[] = [
    {
      label: isBackgroundBlurred
        ? "Disable Background Blur"
        : "Enable Background Blur",
      onClick: handleToggleBackgroundBlur,
    },
    // Add more menu items here as needed
  ];

  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="self-stretch flex-1 flex justify-end pr-0 md:pr-[24px]">
      <div className="flex flex-col items-center min-w-[500px]  gap-4">
        <div className="basis-[500px] grow-0 shrink self-stretch relative rounded-xl overflow-hidden ">
          <CustomParticipantView participant={me} />
          <div className="absolute top-4 right-4 w-16 h-16 z-20">
            <ThreeDotsMenu menuItems={menuItems} />
          </div>
          <div className="absolute bottom-4 right-28 w-16 h-16 z-20">
            <div className="flex gap-8 items-center">
              <ControlPanelComponents.MuteAudioButton
                onClick={() => {
                  microphone.toggle();
                }}
                isMuted={isAudioMute}
              />
              <ControlPanelComponents.MuteVideoButton
                onClick={() => {
                  camera.toggle();
                }}
                isMuted={isVideoMute}
              />
            </div>
          </div>
        </div>
        {belowVideoDisplayInfo && (
          <div className="flex  gap-4 items-center">
            <AvatarCircles sources={belowVideoDisplayInfo.userProfilePhotos} />
            <h4 className="text-2xl">{belowVideoDisplayInfo.msg}</h4>
          </div>
        )}
      </div>
    </div>
  );
};
