import { pipe } from "fp-ts/function";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
import { Modality } from "shared/index";
import { UROUTES } from "shared/routes/u.routes";
import { RD, Rx } from "../../../prelude";

export const OnboardHpTherapyTypesPage: React.FC = () => {
  const [rdAllTherapyTypes, setRdAllTherapyTypes] = useState<
    RD.RemoteData<unknown, Modality[]>
  >(RD.initial);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="flex flex-col items-center gap-8">
        <h1 className="font-bold text-5xl text-default-purple self-center">
          {`What kinds of therapy do you practice`}
        </h1>
        <div className="flex flex-col max-w-[500px]">
          <h4 className="font-semibold text-lg mt-8 self-center">
            {`We'll use this to customize your experience`}
          </h4>
          <div className="self-stretch">
            {pipe(
              rdAllTherapyTypes,
              RD.fold3(
                () => <div>Loading...</div>,
                () => <div>Error</div>,
                (allTherapyTypes) => (
                  <FavoritesForm allTherapyTypes={allTherapyTypes} />
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FavoritesForm: React.FC<{ allTherapyTypes: Modality[] }> = ({
  allTherapyTypes,
}) => {
  const nav = useNavigate();
  const selectedTypes$ = React.useMemo(
    () => new BehaviorSubject<Modality[]>([]),
    []
  );
  return (
    <div className="flex flex-col gap-16">
      <TypeaheadSelect
        therapyTypes={allTherapyTypes}
        selectedTypes$={selectedTypes$}
      />
      <button className="bg-default-purple text-white px-4 py-2 rounded">
        Next
      </button>
      <button
        className={`
        bg-white text-vid-black-900 px-4 py-2 rounded
        hover:bg-vid-black-900 hover:text-white
        `}
        onClick={() => {
          nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
        }}
      >
        Skip
      </button>
    </div>
  );
};

//   const [therapyTypes, setTherapyTypes] = useState<TherapyType[]>([]);
type TypeaheadSelectProps = {
  therapyTypes: Modality[];
  selectedTypes$: Rx.BehaviorSubject<Modality[]>;
};
const TypeaheadSelect: React.FC<TypeaheadSelectProps> = ({
  therapyTypes,
  selectedTypes$,
}) => {
  const [inputValue, setInputValue] = useState("");
  const selectedTypes = useObservableEagerState(selectedTypes$);
  const [filteredTypes, setFilteredTypes] = useState<Modality[]>([]);

  useEffect(() => {
    // Filter therapy types based on the input value
    if (inputValue) {
      setFilteredTypes(
        therapyTypes.filter((type) =>
          type.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    } else {
      setFilteredTypes([]);
    }
  }, [inputValue, therapyTypes]);

  const handleSelect = (type: Modality) => {
    if (!selectedTypes.some((t) => t.slug === type.slug)) {
      selectedTypes$.next([...selectedTypes, type]);
      setInputValue("");
    }
  };

  const removePill = (typeId: string) => {
    selectedTypes$.next(selectedTypes.filter((type) => type.slug !== typeId));
  };

  return (
    <div className="py-4 relative self-stretch">
      <div className="flex flex-wrap gap-2 mb-4">
        {selectedTypes.map((type) => (
          <SelectedType
            key={type.slug}
            type={type}
            onClick={() => removePill(type.slug)}
          />
        ))}
        <input
          className="flex-1 border px-2 py-2 rounded-md"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="e.g. CBT, EMDR, ACT, Hakomi, etc."
        />
      </div>
      {inputValue && (
        <ul className="absolute border border--300 bg-white w-full z-10">
          {filteredTypes.map((type) => (
            <li
              key={type.slug}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(type)}
            >
              {type.slug}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const SelectedType: React.FC<{ type: Modality; onClick: () => void }> = ({
  type,
  onClick,
}) => {
  return (
    <div className="flex items-center bg-blue-500 text-white py-1 px-2 rounded">
      {type.name}
      <button className="ml-2 text-sm" onClick={onClick}>
        &times;
      </button>
    </div>
  );
};
