import { type StreamVideoParticipant } from "@stream-io/video-react-sdk";
import type { Rx } from "@webapp/prelude";
import type { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { CustomParticipantView } from "./participant-view.fc";

interface OneOnOneParticipantViewProps {
  meAsParticipant$: Rx.Observable<StreamVideoParticipant | undefined>;
  otherParticipants$: Rx.Observable<StreamVideoParticipant[]>;
  otherParticipantId: string;
  aspectRatio?: "16/9" | "4/3";
}

export const OneOnOneParticipantView: React.FC<
  OneOnOneParticipantViewProps
> = ({
  meAsParticipant$,
  otherParticipants$,
  otherParticipantId,
  aspectRatio,
}) => {
  const meAsParticipant = useObservableEagerState(meAsParticipant$);
  const otherParticipants = useObservableEagerState(otherParticipants$);

  const mbOtherParticipant = otherParticipants.find(
    (p) => p.userId === otherParticipantId
  );

  if (!mbOtherParticipant) {
    return null;
  }

  if (!meAsParticipant) {
    return null;
  }

  return (
    <div
      className={`
    flex-1 
    ${aspectRatio === "16/9" ? "aspect-16/9" : ""}
    min-w-0 min-h-0 relative border-2 rounded-[22px]
    `}
    >
      <CustomParticipantView participant={mbOtherParticipant} />
      <div className="absolute bottom-8 right-8 w-[200px] aspect-16/9 z-30 rounded-[22px] border-2 ">
        <CustomParticipantView participant={meAsParticipant} />
      </div>
    </div>
  );
};

export const MeOnlyParticipantView: React.FC<{
  meParticipant$: Rx.Observable<StreamVideoParticipant | undefined>;
}> = ({ meParticipant$ }) => {
  const meParticipant = useObservableEagerState(meParticipant$);

  if (!meParticipant) {
    return null;
  }

  return (
    <div className="flex-1 relative">
      <CustomParticipantView participant={meParticipant} />
    </div>
  );
};

export const MinimizedContentView: React.FC<{
  contentView: React.ReactNode;
  otherParticipants$: Rx.Observable<StreamVideoParticipant[]>;
  onCloseTopPreview: () => void;
  mainRoomStateMgr: MainRoomStateMgr;
}> = ({
  contentView,
  otherParticipants$,
  onCloseTopPreview,
  mainRoomStateMgr,
}) => {
  const otherParticipants = useObservableEagerState(otherParticipants$);

  return (
    <div className="flex flex-col gap-4 flex-1">
      <div className="flex-1 min-h-0 flex flex-col">
        <TopPreviewContainer
          content={contentView}
          onClose={() => {
            onCloseTopPreview();
          }}
          onMaximize={() => {
            mainRoomStateMgr.stateSyncMgr.maximizeTopPreview();
          }}
        />
      </div>
      <div className="flex-1 flex min-h-0 flex gap-4">
        {otherParticipants.slice(0, 4).map((participant) => {
          return (
            <div className="flex-1 min-h-0 flex relative">
              <CustomParticipantView participant={participant} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TopPreviewContainer: React.FC<{
  content: React.ReactNode;
  onClose: () => void;
  onMaximize: () => void;
}> = ({ content, onClose, onMaximize }) => {
  return (
    <div className="flex-1 bg-white rounded-[12px] p-12 overflow-none relative">
      <div className="absolute inset-12 flex">{content}</div>
      <div
        className="absolute top-4 right-4 w-[40px] h-[40px] rounded-full flex items-center justify-center cursor-pointer rounded-full border"
        onClick={onClose}
      >
        X
      </div>
      <div
        className="absolute bottom-4 right-4 w-[40px] h-[40px] bg-vid-black-900 rounded-full flex items-center justify-center cursor-pointer"
        onClick={onMaximize}
        // onClick={onClose}
      >
        <img src={"/maximize.svg"} className="w-[24px] h-[24px]" />
      </div>
    </div>
  );
};

export const MaximizedContentView: React.FC<{
  contentView: React.ReactNode;
  mainRoomStateMgr: MainRoomStateMgr;
  otherParticipants$: Rx.Observable<StreamVideoParticipant[]>;
}> = ({ contentView, mainRoomStateMgr, otherParticipants$ }) => {
  const otherParticipants = useObservableEagerState(otherParticipants$);

  return (
    <div className="flex-1 w-full h-full relative">
      <div className="absolute inset-0 w-full h-full rounded-[12px] overflow-hidden z-20 flex flex-col">
        {contentView}
      </div>
      <div
        className="absolute bottom-4 right-4 w-[40px] h-[40px] bg-vid-black-900 rounded-full flex items-center justify-center cursor-pointer z-40"
        onClick={() => {
          mainRoomStateMgr.stateSyncMgr.minimizeTopPreview();
        }}
      >
        <img src={"/maximize.svg"} className="w-[24px] h-[24px]" />
      </div>
      <div className="absolute bottom-20 left-20 w-[10px] h-[10px] z-0 flex">
        {otherParticipants.slice(0, 4).map((participant) => {
          return (
            <div className="flex-1 min-h-0 flex relative">
              <CustomParticipantView participant={participant} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
