import { Combobox } from "@headlessui/react";
import React, { useMemo, useState } from "react";

export interface SearchableDropdownItem {
  id: string;
  label: string;
}

interface SearchableDropdownProps {
  items: SearchableDropdownItem[];
  selectedItem: SearchableDropdownItem | null;
  onSelect: (item: SearchableDropdownItem | SearchableDropdownItem[]) => void;
  placeholder?: string;
  multiple?: boolean;
  alwaysShowDropdown?: boolean;
}

export const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  items,
  selectedItem,
  onSelect,
  placeholder = "Search...",
  multiple = false,
  alwaysShowDropdown = true,
}) => {
  const [query, setQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState<SearchableDropdownItem[]>(
    []
  );

  const filteredItems = useMemo(() => {
    return items
      .filter((item) => item.label.toLowerCase().includes(query.toLowerCase()))
      .slice(0, 10); // Limit to 10 items
  }, [items, query]);

  const handleSelect = (
    selection: SearchableDropdownItem | SearchableDropdownItem[]
  ) => {
    if (multiple) {
      const newSelection = Array.isArray(selection) ? selection : [selection];
      setSelectedItems(newSelection);
      onSelect(newSelection);
    } else {
      onSelect(selection as SearchableDropdownItem);
    }
  };

  const showOptions = alwaysShowDropdown || multiple || !selectedItem;

  return (
    <Combobox
      value={multiple ? selectedItems : selectedItem}
      onChange={handleSelect}
      as="div"
      className="relative"
    >
      <Combobox.Input
        className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        onChange={(event) => setQuery(event.target.value)}
        displayValue={(
          item: SearchableDropdownItem | SearchableDropdownItem[] | null
        ) =>
          multiple
            ? (item as SearchableDropdownItem[])
                ?.map((i) => i.label)
                .join(", ") || ""
            : (item as SearchableDropdownItem | null)?.label || ""
        }
        placeholder={placeholder}
      />
      {showOptions && (
        <Combobox.Options
          static
          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          {filteredItems.length === 0 && query !== "" ? (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
              Nothing found.
            </div>
          ) : (
            filteredItems.map((item) => (
              <Combobox.Option
                key={item.id}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  }`
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {item.label}
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                          active ? "text-white" : "text-indigo-600"
                        }`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      )}
    </Combobox>
  );
};

const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 20 20" fill="currentColor">
    <path
      fillRule="evenodd"
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);
