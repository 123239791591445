import { useBackgroundFilters } from "@stream-io/video-react-sdk";

import {
  useKeyOfObservable,
  useKeyOfObservableAsState,
} from "frontend-shared/src/util";

import { useCallStateHooks } from "@stream-io/video-react-sdk";
import { AvatarCircles } from "@webapp/componentsavatar.tc";
import { ControlPanelComponents } from "@webapp/componentslive-session/control-panel.components";
import { CustomParticipantView } from "@webapp/componentslive-session/main-room/participant-view.fc";
import {
  ThreeDotsMenu,
  type MenuItem,
} from "@webapp/componentsmenus/three-dots.menu";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useSessionRSM } from "frontend-shared/src/mgrs/remote-state-mgrs/main-room.rsm";

export const VideoSection: React.FC<{
  belowVideoDisplayInfo?: {
    userProfilePhotos: (string | null)[];
    msg: string;
  };
}> = ({ belowVideoDisplayInfo }) => {
  const sessionRSM = useSessionRSM();
  const { useLocalParticipant, useMicrophoneState, useCameraState } =
    useCallStateHooks();
  const { microphone, isMute: isAudioMute } = useMicrophoneState();
  const { camera, isMute: isVideoMute } = useCameraState();
  const me = useLocalParticipant();

  const { disableBackgroundFilter, applyBackgroundBlurFilter } =
    useBackgroundFilters();

  const displayState$ = useKeyOfObservable(
    sessionRSM.participantSyncMgr.state$,
    "displayState"
  );

  const isBackgroundBlurred = useKeyOfObservableAsState(
    displayState$,
    "isBackgroundBlurred",
    false
  );

  const handleToggleBackgroundBlur = () => {
    if (isBackgroundBlurred) {
      disableBackgroundFilter();
      sessionRSM.participantSyncMgr.setIsBackgroundBlurred(false);
    } else {
      applyBackgroundBlurFilter("high");
      sessionRSM.participantSyncMgr.setIsBackgroundBlurred(true);
    }
  };

  const menuItems: MenuItem[] = [
    {
      label: isBackgroundBlurred
        ? "Disable Background Blur"
        : "Enable Background Blur",
      onClick: handleToggleBackgroundBlur,
    },
    // Add more menu items here as needed
  ];

  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="self-stretch flex-1 flex justify-end pr-0 md:pr-[24px]">
      <div className="flex flex-col items-center min-w-[500px]  gap-4">
        <div className="basis-[500px] grow-0 shrink self-stretch relative rounded-xl overflow-hidden ">
          <CustomParticipantView participant={me} />
          <div className="absolute top-4 right-4 w-16 h-16 z-20">
            <ThreeDotsMenu menuItems={menuItems} />
          </div>
          <div className="absolute bottom-4 right-28 w-16 h-16 z-20">
            <div className="flex gap-8 items-center">
              <ControlPanelComponents.MuteAudioButton
                onClick={() => {
                  microphone.toggle();
                }}
                isMuted={isAudioMute}
              />
              <ControlPanelComponents.MuteVideoButton
                onClick={() => {
                  camera.toggle();
                }}
                isMuted={isVideoMute}
              />
            </div>
          </div>
        </div>
        {belowVideoDisplayInfo && (
          <div className="flex  gap-4 items-center">
            <AvatarCircles sources={belowVideoDisplayInfo.userProfilePhotos} />
            <h4 className="text-2xl">{belowVideoDisplayInfo.msg}</h4>
          </div>
        )}
      </div>
    </div>
  );
};
