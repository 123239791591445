import * as S from "@effect/schema/Schema";
import { Array, Brand, Option } from "effect";
import type { Eq } from "fp-ts/lib/Eq";
import { z } from "zod";

export type ZeroIndexedMonth = number & Brand.Brand<"ZeroIndexedMonth">;
export const ZeroIndexedMonth = Brand.nominal<ZeroIndexedMonth>();
export type OneIndexedMonth = number & Brand.Brand<"OneIndexedMonth">;
export const OneIndexedMonth = Brand.nominal<OneIndexedMonth>();

export function toOneIndexedMonth(p: {
  ziMonthIdx: ZeroIndexedMonth;
}): OneIndexedMonth {
  return OneIndexedMonth(p.ziMonthIdx + 1);
}

export function toZeroIndexedMonth(p: {
  oiMonthIdx: OneIndexedMonth;
}): ZeroIndexedMonth {
  return ZeroIndexedMonth(p.oiMonthIdx - 1);
}

export const AttendingStatus = S.Union(
  S.Literal("attending"),
  S.Literal("maybe"),
  S.Literal("declined"),
  S.Literal("cancelled")
);
export type AttendingStatus = S.Schema.Type<typeof AttendingStatus>;

const RecurrenceFrequency = S.Union(S.Literal("daily"), S.Literal("weekly"));
export type RecurrenceFrequency = S.Schema.Type<typeof RecurrenceFrequency>;

export const RecurrenceFrequencyChoice = S.Union(
  S.Literal("none"),
  RecurrenceFrequency
);
export type RecurrenceFrequencyChoice = S.Schema.Type<
  typeof RecurrenceFrequencyChoice
>;

export const SimpleRecurrenceRule = S.Struct({
  frequency: RecurrenceFrequency,
  interval: S.Number,
});

export type SimpleRecurrenceRule = S.Schema.Type<typeof SimpleRecurrenceRule>;

export type CreateNewCalendarEventData = {
  title: string;
  description?: string;
  eventType: CalendarEventType;
  startDate: Date;
  durationSeconds: number;
  recurrenceRule: SimpleRecurrenceRule | null;
  hostId: string;
  nonHostAttendingUserIds?: string[];
};

export const EventInstanceId = S.String.pipe(S.brand("EventInstanceId"));
export type EventInstanceId = S.Schema.Type<typeof EventInstanceId>;
export const EventTemplateId = S.String.pipe(S.brand("EventTemplateId"));
export type EventTemplateId = S.Schema.Type<typeof EventTemplateId>;

export type CalendarEventType =
  | {
      _tag: "Community";
      groupSessionId: string | null;
      communitySlug: string;
    }
  | {
      _tag: "PrivateSession";
      privateSessionInfo: {
        durationInMinutes: number;
        priceCents: number | null;
        clientId: string;
        hpId: string;
        sessionId: string | null;
      };
    };

export const PrivateSessionEventInstanceDtoInfo = S.Struct({
  sessionId: S.NullOr(S.String),
  priceCents: S.NullOr(S.Number),
  hp: S.Struct({
    id: S.String,
    email: S.String,
    name: S.String,
    profilePhotoUploadedAt: S.NullOr(S.Date),
  }),
  client: S.Struct({
    id: S.String,
    email: S.String,
    name: S.String,
    profilePhotoUploadedAt: S.NullOr(S.Date),
  }),
});
export type PrivateSessionEventInstanceDtoInfo = S.Schema.Type<
  typeof PrivateSessionEventInstanceDtoInfo
>;

export const BaseAttendeeUser = S.Struct({
  userId: S.String,
  name: S.String,
  email: S.String,
  profilePhotoUploadedAt: S.NullOr(S.Date),
  deviceToken: S.NullOr(S.String),
  attendingStatus: AttendingStatus,
});
export type BaseAttendeeUser = S.Schema.Type<typeof BaseAttendeeUser>;

export const AttendeeUser = S.extend(
  BaseAttendeeUser,
  S.Struct({
    profilePhoto: S.NullOr(S.String),
  })
);
export type AttendeeUser = S.Schema.Type<typeof AttendeeUser>;

export const BaseCalendarEventInstanceData = S.Struct({
  // calendarId: string;
  eventTemplateId: EventTemplateId,
  instanceId: EventInstanceId,
  title: S.String,
  description: S.NullOr(S.String),
  startTime: S.DateFromSelf,
  endTime: S.DateFromSelf,
  host: S.Struct({
    id: S.String,
    name: S.String,
    email: S.String,
    deviceToken: S.NullOr(S.String),
  }),
  recurrenceRule: S.NullOr(SimpleRecurrenceRule),
  attendees: S.Array(BaseAttendeeUser),
});
export type BaseCalendarEventInstanceData = S.Schema.Type<
  typeof BaseCalendarEventInstanceData
>;

export const CommunityEventInstanceInfoSchema = S.Struct({
  groupSessionId: S.NullOr(S.String),
  communitySlug: S.String,
  communityName: S.String,
});
export type CommunityEventInstanceInfo = S.Schema.Type<
  typeof CommunityEventInstanceInfoSchema
>;

const CommunityEventInstanceDTO = S.extend(
  BaseCalendarEventInstanceData,
  S.Struct({
    communityInfo: CommunityEventInstanceInfoSchema,
  })
);
export type CommunityEventInstanceDTO = S.Schema.Type<
  typeof CommunityEventInstanceDTO
>;

export const PrivateSessionEventInstance = S.extend(
  BaseCalendarEventInstanceData,
  S.Struct({
    privateSessionInfo: PrivateSessionEventInstanceDtoInfo,
  })
);
export type PrivateSessionEventInstance = S.Schema.Type<
  typeof PrivateSessionEventInstance
>;

const PrivateSessionEventInstanceDTO = S.extend(
  PrivateSessionEventInstance,
  S.Struct({
    clientProfilePhoto: S.NullOr(S.String),
  })
);
export type PrivateSessionEventInstanceDTO = S.Schema.Type<
  typeof PrivateSessionEventInstanceDTO
>;

export class TaggedCalendarEventInstance extends S.Class<TaggedCalendarEventInstance>(
  "TaggedCalendarEventInstance"
)({
  taggedInstance: S.Union(
    S.Struct({ _tag: S.tag("Community"), instance: CommunityEventInstanceDTO }),
    S.Struct({
      _tag: S.tag("Private"),
      instance: PrivateSessionEventInstance,
    })
  ),
}) {
  get instance(): CommunityEventInstanceDTO | PrivateSessionEventInstance {
    return this.taggedInstance.instance;
  }

  static mkAsCommunity(
    instance: CommunityEventInstanceDTO
  ): TaggedCalendarEventInstance {
    return new TaggedCalendarEventInstance(
      {
        taggedInstance: { _tag: "Community", instance: instance },
      },
      { disableValidation: true }
    );
  }

  static mkAsPrivate(
    instance: PrivateSessionEventInstance
  ): TaggedCalendarEventInstance {
    return new TaggedCalendarEventInstance(
      {
        taggedInstance: { _tag: "Private", instance: instance },
      },
      { disableValidation: true }
    );
  }

  asMbCommunity(): Option.Option<CommunityEventInstanceDTO> {
    if (this.taggedInstance._tag === "Community") {
      return Option.some(this.taggedInstance.instance);
    }
    return Option.none();
  }

  asMbPrivate(): Option.Option<PrivateSessionEventInstance> {
    if (this.taggedInstance._tag === "Private") {
      return Option.some(this.taggedInstance.instance);
    }
    return Option.none();
  }

  unsafeAsCommunity(): CommunityEventInstanceDTO {
    const mbAsCommunity = this.asMbCommunity();
    if (Option.isNone(mbAsCommunity)) {
      throw new Error("TaggedCalendarEventInstance is not a community");
    }
    return mbAsCommunity.value;
  }

  unsafeAsPrivate(): PrivateSessionEventInstance {
    const mbAsPrivate = this.asMbPrivate();
    if (Option.isNone(mbAsPrivate)) {
      throw new Error("TaggedCalendarEventInstance is not a private");
    }
    return mbAsPrivate.value;
  }

  static filterAllPrivate(p: {
    instances: TaggedCalendarEventInstance[];
  }): PrivateSessionEventInstance[] {
    return Array.filterMap(p.instances, (instance) => instance.asMbPrivate());
  }

  static filterAllCommunity(p: {
    instances: TaggedCalendarEventInstance[];
  }): CommunityEventInstanceDTO[] {
    return Array.filterMap(p.instances, (instance) => instance.asMbCommunity());
  }
}

export interface DayOfYear {
  oneIndexedMonth: OneIndexedMonth;
  year: number;
  day: number;
}

export interface MonthOfYear {
  oiMonthIdx: OneIndexedMonth;
  year: number;
}

export const MOYInputSchema = z.object({
  oiMonthIdx: z.number().int().positive().min(1).max(12),
  year: z.number().int().positive(),
});

export const eqDayOfYear: Eq<DayOfYear> = {
  equals: (a, b) =>
    a.year === b.year &&
    a.oneIndexedMonth === b.oneIndexedMonth &&
    a.day === b.day,
};

export type CalendarEventsByDay<T> = {
  doy: DayOfYear;
  events: T[];
};

export type NearbyCalendarMonths<Appt> = {
  prevMonth: CalendarEventsByDay<Appt>[];
  thisMonth: CalendarEventsByDay<Appt>[];
  nextMonth: CalendarEventsByDay<Appt>[];
};

export type CalendarEventInstanceIsh = {
  instanceId: EventInstanceId;
  title: string;
  startTime: Date;
  endTime: Date;
};

export interface CalendarEventTemplateDTO {
  id: EventTemplateId;
  durationInMinutes: number;
  title: string;
  description: string | null;
  startTime: Date;
  endTime: Date;
  recurrenceRule: SimpleRecurrenceRule | null;
}

export type EventInstanceTypeFilter =
  | { _tag: "IsPrivateSession"; clientId?: string; hpId?: string }
  | {
      _tag: "IsCommunityEvent";
      groupSessionId?: string;
      communitySlug?: string;
    };

export type EventInstancesFilters = {
  calendarId?: string;
  includesAttendeeIds?: string[];
  startsAtOccursBetween?: { start: Date; end: Date };
  eventInstanceId?: EventInstanceId;
  eventTemplateId?: EventTemplateId;
  instanceType?: EventInstanceTypeFilter;
};
