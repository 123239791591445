import { combine, createEvent, createStore } from "effector";

type KnownModality = {
  slug: string;
  name: string;
};

export class SelectModalitiesVM {
  // Events
  allKnownModalitiesLoaded = createEvent<KnownModality[]>();
  selectModality = createEvent<string>(); // Triggered when a modality is selected
  toggleDropdown = createEvent(); // Toggles the dropdown state
  closeDropdown = createEvent(); // Explicitly closes the dropdown
  openDropdown = createEvent(); // Explicitly opens the dropdown
  setInitialModalities = createEvent<string[]>(); // Sets the initial modalities

  // Stores
  $selectedModalities = createStore<KnownModality[] | null>(null); // Stores the selected modalities, null means not yet selected
  $allKnownModalities = createStore<KnownModality[]>([]); // Stores all known modalities
  $availableModalities = createStore<KnownModality[]>([]); // Stores the available modalities
  $isDropdownOpen = createStore<boolean>(false); // Tracks if the dropdown is open
  $isModalitySelected = combine(
    this.$selectedModalities,
    (selectedModalities) => (slug: string) =>
      selectedModalities?.some((m) => m.slug === slug) ?? false
  );

  constructor() {
    // Update available modalities when data is successfully fetched
    this.$allKnownModalities.on(
      this.allKnownModalitiesLoaded,
      (_, modalities) => modalities
    );

    this.$availableModalities.on(
      this.allKnownModalitiesLoaded,
      (_, modalities) => modalities
    );

    // Update available and selected modalities when one is selected
    this.$availableModalities.on(this.selectModality, (state, slug) =>
      state.filter((modality) => modality.slug !== slug)
    );

    this.$selectedModalities.on(this.selectModality, (state, slug) => {
      const selectedModality = this.$allKnownModalities
        .getState()
        .find((modality) => modality.slug === slug);

      // Handle initial null state
      if (state === null) {
        return selectedModality ? [selectedModality] : [];
      }

      // Toggle selection
      if (state.some((m) => m.slug === slug)) {
        return state.filter((m) => m.slug !== slug);
      }
      return selectedModality ? [...state, selectedModality] : state;
    });

    // Handle dropdown state
    this.$isDropdownOpen
      .on(this.toggleDropdown, (state) => !state)
      .on(this.openDropdown, () => true)
      .on(this.closeDropdown, () => false);

    // Handle initial modalities
    this.$selectedModalities.on(this.setInitialModalities, (_, slugs) => {
      const allModalities = this.$allKnownModalities.getState();
      const selectedModalities = slugs
        .map((slug) => allModalities.find((m) => m.slug === slug))
        .filter((m): m is KnownModality => m !== undefined);
      return selectedModalities;
    });

    // Update available modalities when initial modalities are set
    this.$availableModalities.on(this.setInitialModalities, (state, slugs) => {
      return state.filter((modality) => !slugs.includes(modality.slug));
    });
  }

  isSelected(slug: string): boolean {
    return (
      this.$selectedModalities.getState()?.some((m) => m.slug === slug) ?? false
    );
  }
}
