import type { ConvexClient } from "convex/browser";
import { api } from "shared/convex/_generated/api";
import { MyMbCurrentPubSubNotifToDisplayST } from "shared/convex/User/PubSub.Types";
import { ConvexStateSyncMgr } from "../remote-state-sync.mgr";

export class PubSubNotificationsRsm extends ConvexStateSyncMgr<
  typeof MyMbCurrentPubSubNotifToDisplayST.Encoded,
  MyMbCurrentPubSubNotifToDisplayST
> {
  userId: string;
  snapshotQueryFunction = api.User.PubSubFns.getMyCurrentMbPubSubNotifToDisplay;
  snapshotQueryArgs = () => ({});

  constructor(p: { userId: string; convexCli: ConvexClient }) {
    super({
      convexCli: p.convexCli,
      initialState: { notification: null },
      schema: MyMbCurrentPubSubNotifToDisplayST,
    });
    this.userId = p.userId;
  }
}
