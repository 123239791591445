import { UROUTES } from "shared/routes/u.routes";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { pipe } from "fp-ts/function";
import { useAuthedFetchTE } from "frontend-shared/src/api.mgr";
import { UApiOutput } from "frontend-shared/src/trpc-cli";
import React from "react";
import {
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { RD } from "shared/base-prelude";
import { TabView, TopBanner } from "../../../components/community.components";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { ImageSrc } from "shared/types/miscellaneous.types";

type MemberGlobalProfileResp =
  UApiOutput["hp"]["communities"]["getMemberGlobalProfile"];

export const GlobalMemberPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const curPath = useLocation().pathname;
  const rdMemberGlobalProfile = useAuthedFetchTE(
    (Api) =>
      Api.hp.communities.getMemberGlobalProfile.query({
        userId: userId!,
      }),
    []
  );

  return pipe(
    rdMemberGlobalProfile,
    RD.fold3(
      () => <FullContainerLoadingSpinner />,
      (err) => <div>{JSON.stringify(err)}</div>,
      ({ member }) => (
        <div className="flex flex-col p-8 gap-8">
          <TopBanner
            title={member.name!}
            communityColorName={"purple"}
            circleImageUrl={member.profilePhoto}
            rightView={<></>}
            nextToTitleFc={<></>}
          />
          <div className="flex gap-8">
            <TabView
              title="About"
              linkTo={UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
                { userId: userId! }
              )}
              isCurrent={
                curPath ===
                UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
                  { userId: userId! }
                )
              }
            />
            <TabView
              title="Chat"
              linkTo={UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.CHAT.buildPath(
                { userId: userId! }
              )}
              isCurrent={
                curPath ===
                UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.CHAT.buildPath(
                  { userId: userId! }
                )
              }
            />
          </div>
          <div className="flex-1 flex flex-col">
            <Outlet context={member} />
          </div>
        </div>
      )
    )
  );
};

export const GlobalMemberAboutTab: React.FC = () => {
  const z = useOutletContext<MemberGlobalProfileResp["member"]>();

  return (
    <div className="flex-1 flex flex-col gap-4">
      <AvatarCircle
        size={64}
        mbProfilePhoto={ImageSrc.fromMbUrl(z.profilePhoto)}
      />
      <h1 className="text-2xl font-bold">{z.name}</h1>
      <h4 className="text-lg font-normal">{z.email}</h4>
    </div>
  );
};
