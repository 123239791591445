import React from "react";
import { globalTopInfoToastMgr } from "src/global.state";

interface CopyLinkButtonProps {
  link: string;
  text?: string;
  className?: string;
  onCopied?: () => void;
}

export const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({
  text = "Copy room link",
  className = "text-vid-purple cursor-pointer flex items-center gap-2",
  link,
  onCopied,
}) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    globalTopInfoToastMgr.showCopiedToClipboardToast();
    onCopied?.();
  };

  return (
    <div className={className} onClick={handleCopy}>
      <img src="/copy-invite-link.svg" alt="Copy invite link" />
      {text}
    </div>
  );
};
