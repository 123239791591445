import { Match } from "effect";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import type { SharedToolSettings } from "shared/session-state/tools/shared-tool-settings";
import type { EmdrRSM } from "../../../../remote-state-mgrs/sessions/tools.rsm";
import {
  BaseSessionToolStateMgr,
  type BaseSessionToolStateMgrParams,
} from "./base-session-tool.statemgr";

export type EmdrMediaButtonState = "PLAY" | "PREPARING" | "PAUSE" | "STOP";

export class EmdrSyncStateMgr extends BaseSessionToolStateMgr {
  mediaButtonState$: Rx.Observable<EmdrMediaButtonState | null>;

  stateSyncMgr: EmdrRSM;

  constructor(readonly baseParams: BaseSessionToolStateMgrParams) {
    super(baseParams);

    this.stateSyncMgr = baseParams.remoteBaseStateMgr.tools.emdr;

    this.mediaButtonState$ = this.stateSyncMgr.state$.pipe(
      RxO.map((r) => EmdrSyncStateMgr.asMediaButtonState(r.playState))
    );
  }

  static asMediaButtonState(
    playState: SharedToolSettings.Emdr.PlayState
  ): EmdrMediaButtonState | null {
    return Match.value(playState).pipe(
      Match.when("off", () => "PLAY" as EmdrMediaButtonState),
      Match.when("preparing", () => "PREPARING" as EmdrMediaButtonState),
      Match.when("playing", () => "PAUSE" as EmdrMediaButtonState),
      Match.when("paused", () => "PLAY" as EmdrMediaButtonState),
      Match.exhaustive
    );
  }

  hideBouncingBall$ = new Rx.BehaviorSubject<boolean>(false);

  setStatePreparing() {
    this.stateSyncMgr.setPlayState("preparing");
  }

  setStatePaused() {
    this.stateSyncMgr.setPlayState("paused");
  }

  setStateOff() {
    this.stateSyncMgr.setPlayState("off");
  }

  hideBouncingBall(shouldHide: boolean) {
    this.hideBouncingBall$.next(shouldHide);
  }

  setBouncingBallFrequency(frequency: number) {
    this.stateSyncMgr.setBallFrequency(frequency);
    // this.runUpdateEmdrRemoteState({ ballFrequency: frequency });
  }
}
