import type { Rx } from "@webapp/prelude";
import meditationBeep from "frontend-shared/src/resources/meditation.mp3";
import { useKeyOfObservableAsState } from "frontend-shared/src/util";
import lottie, { type AnimationItem } from "lottie-web";
import { useEffect, useRef } from "react";
import type { SharedToolSettings } from "shared/session-state/tools/shared-tool-settings";
import EMDR_3DBall from "../../../../../assets/emdr/EMDR_3DBall.json";

interface EMDRComponentProps {
  state$: Rx.Observable<SharedToolSettings.Emdr.State>;
  soundType: "beep" | "click" | "white-noise";
  soundDuration?: number; // in seconds
}

export const EMDRBallStageView: React.FC<EMDRComponentProps> = ({
  state$,
  soundType,
  soundDuration = 0.07,
}) => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center relative">
      <EMDRBallVideo
        state$={state$}
        soundType={soundType}
        soundDuration={soundDuration}
      />
      {/* <img
        src={"/emdr-dots-bg.png"}
        className="absolute inset-0 w-full h-full"
      /> */}
      {/* <div className="absolute top-0 left-0  bg-blue-400 z-40">
        <h4>{frequency}</h4>
      </div> */}
    </div>
  );
};

export const EMDRBallVideo: React.FC<EMDRComponentProps> = ({
  state$,
  soundType,
  soundDuration = 0.07,
}) => {
  const frequency = useKeyOfObservableAsState(state$, "ballFrequency", 1);
  const playState = useKeyOfObservableAsState(state$, "playState", "off");
  const isPlaying = playState === "playing";
  const animationContainer = useRef<HTMLDivElement>(null);
  const animationInstance = useRef<AnimationItem | null>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const audioBuffer = useRef<AudioBuffer | null>(null);

  useEffect(() => {
    audioContext.current = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    loadAudio();

    if (animationContainer.current) {
      animationInstance.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: false,
        animationData: EMDR_3DBall, // Change 'path' to 'animationData'
      });

      animationInstance.current.addEventListener(
        "enterFrame",
        handleEnterFrame
      );
    }

    return () => {
      if (animationInstance.current) {
        animationInstance.current.removeEventListener(
          "enterFrame",
          handleEnterFrame
        );
        animationInstance.current.destroy();
      }
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (animationInstance.current) {
      animationInstance.current.setSpeed(frequency * 0.5);
    }
  }, [frequency]);

  useEffect(() => {
    loadAudio();
  }, [soundType]);

  useEffect(() => {
    if (isPlaying) {
      animationInstance.current?.play();
    } else {
      animationInstance.current?.pause();
    }
  }, [isPlaying]);

  const loadAudio = async () => {
    if (!audioContext.current) return;

    try {
      const response = await fetch(meditationBeep);
      const arrayBuffer = await response.arrayBuffer();
      audioBuffer.current =
        await audioContext.current.decodeAudioData(arrayBuffer);
      console.log("AUDIO BUFFER! ", audioBuffer.current);
    } catch (error) {
      console.error("Failed to load audio:", error);
    }
  };

  const handleEnterFrame = (event: any) => {
    if (!animationInstance.current) return;

    const progress = event.currentTime / animationInstance.current.totalFrames;

    const midPointProgress = progress > 0.47 && progress < 0.53;
    const finishPointProgress = progress > 0.97;
    if (midPointProgress || finishPointProgress) {
      playSound();
    }
  };

  const playSound = () => {
    if (!audioContext.current || !audioBuffer.current) return;

    const source = audioContext.current.createBufferSource();
    source.buffer = audioBuffer.current;

    // Create a gain node to control volume
    const gainNode = audioContext.current.createGain();
    source.connect(gainNode);
    gainNode.connect(audioContext.current.destination);

    // Start offset in seconds
    const startOffset = 0.4;

    // Set initial volume to 1 (full volume)
    gainNode.gain.setValueAtTime(1, audioContext.current.currentTime);

    // Fade out the sound over the specified duration
    gainNode.gain.linearRampToValueAtTime(
      0,
      audioContext.current.currentTime + soundDuration
    );

    // Start playing from the offset
    source.start(0, startOffset);

    // Stop the sound after the specified duration
    source.stop(audioContext.current.currentTime + soundDuration);
  };

  return (
    <div
      ref={animationContainer}
      className={`
        w-full  bg-vid-black-900 rounded-lg relative
        `}
    ></div>
  );
};
