import { combine, createEffect, createEvent, createStore } from "effector";
import {
  MediaSelectAndUploadVM,
  type MediaSelectDeps,
} from "../media/media-select-upload.vm";
import { SelectModalitiesVM } from "./hp/select-modality.vm";
import { useUnit } from "effector-react";
import { useAction, useMutation, useQuery } from "convex/react";
import { api } from "shared/convex/_generated/api";
import React, { useEffect } from "react";
import { Effect, Option } from "effect";
import type {
  FileLike,
  UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";

type UpdateSimpleProfileMutation = (args: {
  publicId: string | null;
  firstName: string;
  lastName: string;
  selectedModalitySlugs?: string[];
}) => Promise<void>;

type InitialValues = {
  firstName: string;
  lastName: string;
  profilePhoto: {
    publicId: string;
    downloadUrl: string;
  } | null;
  selectedModalitySlugs: string[];
};

export class MyProfileFormVM {
  readonly nameVM: NameVM;
  readonly profilePhotoVM: MediaSelectAndUploadVM;
  readonly modalitySelectorVM = new SelectModalitiesVM();

  submitFormEvt = createEvent();
  receivedInitialValues = createEvent<InitialValues | null>();

  submitFormFx = createEffect<void, void>();
  $isSubmitting = this.submitFormFx.pending;
  $isSubmitComplete = createStore<boolean>(false);

  constructor(p: {
    updateSimpleProfileMutation: UpdateSimpleProfileMutation;
    photoUploadDeps: MediaSelectDeps;
    getPresignedUploadUrl: () => Promise<{
      uploadUrl: string;
      // publicId: string;
    }>;
    getProcessedImageUrl: (publicId: string) => Promise<{
      processedUrl: string;
    }>;
  }) {
    this.nameVM = new NameVM();
    this.profilePhotoVM = new MediaSelectAndUploadVM(
      p.photoUploadDeps,
      p.getPresignedUploadUrl,
      p.getProcessedImageUrl
    );

    this.receivedInitialValues.watch((mbValues) => {
      if (mbValues) {
        this.nameVM.setInitialName(mbValues);

        console.log(
          "mbValues.selectedModalitySlugs",
          mbValues.selectedModalitySlugs
        );
        if (mbValues.selectedModalitySlugs) {
          this.modalitySelectorVM.setInitialModalities(
            mbValues.selectedModalitySlugs
          );
        }

        if (mbValues.profilePhoto) {
          this.profilePhotoVM.setInitialProfilePhotoPublicId(
            mbValues.profilePhoto
          );
        }
      }
    });

    const formData$ = combine({
      firstName: this.nameVM.$firstName,
      lastName: this.nameVM.$lastName,
      publicId: this.profilePhotoVM.$publicId,
    });

    this.submitFormFx.use(async () => {
      const data = formData$.getState();
      console.log("data", data);
      if (!data.firstName || !data.lastName) {
        throw new Error("Missing required fields");
      }

      const selectedModalitySlugs = this.modalitySelectorVM.$selectedModalities
        .getState()
        ?.map((m) => m.slug);

      await p.updateSimpleProfileMutation({
        publicId: data.publicId,
        firstName: data.firstName,
        lastName: data.lastName,
        selectedModalitySlugs,
      });
    });

    this.$isSubmitComplete.on(this.submitFormFx.done, () => true);

    this.submitFormEvt.watch(() => {
      console.log("submitFormEvt");
      this.submitFormFx();
    });
  }
}

class NameVM {
  setFirstName = createEvent<string>();
  setLastName = createEvent<string>();

  $firstName = createStore<string>("").on(
    this.setFirstName,
    (_, firstName) => firstName
  );
  $lastName = createStore<string>("").on(
    this.setLastName,
    (_, lastName) => lastName
  );

  setInitialName = (p: { firstName: string; lastName: string }) => {
    this.setFirstName(p.firstName);
    this.setLastName(p.lastName);
  };

  constructor() {
    this.$firstName = createStore<string>("").on(
      this.setFirstName,
      (_, firstName) => firstName
    );
    this.$lastName = createStore<string>("").on(
      this.setLastName,
      (_, lastName) => lastName
    );
  }
}

export function useSetupMyProfileForm(p: {
  selectPhoto: () => Promise<FileLike | null>;
  uploadFileLikeToCloudinary: (
    file: FileLike,
    uploadUrl: string
  ) => Promise<UploadFileToCloudinaryResponse>;
}) {
  const myCurrentProfile = useQuery(api.User.UserFns.getMyCurrentProfile);

  const getProfilePhotoUploadUrl = useAction(
    api.User.UserFns.getProfilePhotoUploadUrl
  );

  const getDownloadUrlsForProfilePhoto = useAction(
    api.User.UserFns.getDownloadUrlsForProfilePhoto
  );

  const saveSimpleProfileMutation = useMutation(
    api.User.Hp.HpFns.onSubmitMySimpleProfile
  );

  const model = React.useMemo(
    () =>
      new MyProfileFormVM({
        updateSimpleProfileMutation: async (p) => {
          // add artificial delay
          await new Promise((resolve) => setTimeout(resolve, 500));
          await saveSimpleProfileMutation({
            firstName: p.firstName,
            lastName: p.lastName,
            profilePhoto: p.publicId ? { publicId: p.publicId } : null,
            mySelectedModalitySlugs: p.selectedModalitySlugs,
          });
        },
        photoUploadDeps: {
          selectMedia: p.selectPhoto,
          uploadToCloudinary: p.uploadFileLikeToCloudinary,
          getPreviewUrl: (file) => file.uri ?? file.path ?? "",
        },
        getPresignedUploadUrl: async () => {
          console.log("getPresignedUploadUrl! ");
          const uploadUrl = await getProfilePhotoUploadUrl();
          console.log("uploadUrl!!", uploadUrl);
          return { uploadUrl };
        },
        getProcessedImageUrl: async (publicId) => {
          const { mediumUrl } = await getDownloadUrlsForProfilePhoto({
            publicId,
          });

          return { processedUrl: mediumUrl };
        },
      }),
    []
  );

  useEffect(() => {
    if (myCurrentProfile !== undefined) {
      model.receivedInitialValues(myCurrentProfile);
    }
  }, [myCurrentProfile, model]);

  const [displayPhotoUrl, isProcessing] = useUnit([
    model.profilePhotoVM.$displayPhotoUrl,
    model.profilePhotoVM.$isProcessing,
  ]);

  const [isSubmitting, isDoneSubmitting] = useUnit([
    model.submitFormFx.pending,
    model.$isSubmitComplete,
  ]);

  return {
    model,
    isSubmitting,
    isDoneSubmitting,
    displayPhotoUrl,
    isProcessing,
  };
}
