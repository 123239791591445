import { PaginatedGridView } from "@pages/u/group-sessions/[group-session-id]/calls/[callId]/main-room/layouts/paginated-grid.view";
import { MainRoomGroupSpeakerViewLayout } from "@pages/u/group-sessions/[group-session-id]/calls/[callId]/main-room/layouts/speaker-view";
import { useAssumedCurrentChannel } from "@pages/u/u.webstatemgr";
import { useCallStateHooks } from "@stream-io/video-react-sdk";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation } from "convex/react";
import { Match } from "effect";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import { RD } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import { ToolMenuViewStateST } from "shared/schemas/session/ongoing/participant/participant-display.schemas";
import type { ParticipantsViewMode } from "shared/schemas/session/state/stage-layout.schemas";
import {
  ControlPanelComponents,
  ControlPanelContainer,
  DefaultCallControlButtons,
  SettingsIcon,
} from "../control-panel.components";
import { LiveSessionComponents } from "../live-session.components";
import { EMDRBallStageView } from "../right-nav/therapy-tools/emdr/emdr-ball-vis";
import { MeditationPreview } from "../right-nav/therapy-tools/meditation.tools";
import { MainRoomRightNav } from "./components/main-room-right-nav.fc";
import { StageTopNav } from "./components/stage-top-nav.fc";
import {
  MaximizedContentView,
  MeOnlyParticipantView,
  MinimizedContentView,
  OneOnOneParticipantView,
} from "./participant-layouts";

export interface MainRoomContainerProps {
  mainRoomStateMgr: MainRoomStateMgr;

  settings: {
    shareableLink: string;
  };
}

export const MainRoomContainer = ({
  settings,
  mainRoomStateMgr,
}: MainRoomContainerProps) => {
  const { useMicrophoneState, useCameraState } = useCallStateHooks();
  const { isMute: isAudioMute } = useMicrophoneState();
  const { isMute: isVideoMute } = useCameraState();

  const channelMgr = useAssumedCurrentChannel();

  const isLeftNavToolsOpen = useObservableEagerState(
    mainRoomStateMgr.isLeftNavToolsOpen$
  );

  useEffect(() => {
    mainRoomStateMgr.stateSyncMgr.registerEnteredMainRoom();

    const cleanup = () => {
      mainRoomStateMgr.stateSyncMgr.registerClickedLeaveSession();
    };

    return () => {
      cleanup();
    };
  }, []);

  const sessionConfig = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.baseSessionConfigMgr.state$
  );

  const leavingSessionResult = useObservableEagerState(
    mainRoomStateMgr.leavingSessionResult$
  );

  useEffect(() => {
    console.log("SESSION CONFIG! ", sessionConfig);
  }, [sessionConfig]);

  return (
    <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden">
      {RD.isPending(leavingSessionResult) && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-50  flex items-center justify-center">
          <FullContainerLoadingSpinner />
        </div>
      )}
      <div className="flex-1 flex gap-8 min-h-0 py-8 px-2 md:px-[36px] max-w-full">
        {isLeftNavToolsOpen && (
          <div className="basis-[60px] self-stretch grow-0 shrink-0 relative hidden md:flex">
            <div className="absolute inset-0 z-20 flex flex-col-reverse items-start gap-4 py-8">
              <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
                arrowDirection="right"
                onClick={() => {
                  mainRoomStateMgr.toggleLeftToolsNav();
                }}
              />
              <QuickActionToolsPanel mainRoomStateMgr={mainRoomStateMgr} />
            </div>
          </div>
        )}
        <div className="flex-1 min-h-0 flex flex-col gap-4">
          <StageArea
            mainRoomStateMgr={mainRoomStateMgr}
            isLeftNavToolsOpen={isLeftNavToolsOpen}
            onCloseTopPreview={() => {
              mainRoomStateMgr.stateSyncMgr.stageLayoutMgr.closeContentViewForAllParticipants();
            }}
          />
        </div>
        <div className="absolute right-0 top-0 bottom-0 md:relative flex flex-col py-0">
          <MainRoomRightNav
            baseSessionId={mainRoomStateMgr.baseSessionId}
            chatStateMgr={mainRoomStateMgr.tools.chatMgr}
            mainRoomStateMgr={mainRoomStateMgr}
            settings={settings}
          />
        </div>
      </div>
      <div className="basis-[100px] grow-0 shrink-0 bg-white flex flex-col justify-center">
        <ControlPanelContainer
          centralControls={
            <DefaultCallControlButtons
              audioButton={{
                isMuted: isAudioMute,
                onClick: () => {
                  channelMgr.microphone
                    .toggle()
                    .then((r) => {
                      console.log("RESULT OF MICROPHONE TOGGLE! ", r);
                    })
                    .catch((e) => {
                      console.log("ERROR TOGGLE MICROPHONE! ", e);
                    });
                },
              }}
              videoButton={{
                isMuted: isVideoMute,
                onClick: () => {
                  channelMgr.camera
                    .toggle()
                    .then((r) => {
                      console.log("RESULT OF CAMERA TOGGLE! ", r);
                    })
                    .catch((e) => {
                      console.log("ERROR TOGGLE CAMERA! ", e);
                    });
                },
              }}
              onEndCallButtonClick={() => {
                mainRoomStateMgr.onCloseCallClick();
              }}
            />
          }
          rightControls={
            <div className="flex gap-4 items-center">
              <ControlPanelComponents.RoundButtonContainer
                onClick={() => {
                  mainRoomStateMgr.onToolsMenuClicked();
                }}
                bgColor="white"
              >
                <img src="/magicpen.svg" />
              </ControlPanelComponents.RoundButtonContainer>
              <ControlPanelComponents.RoundButtonContainer
                onClick={() => {
                  mainRoomStateMgr.onSettingsMenuClicked();
                }}
                bgColor="white"
              >
                <SettingsIcon />
              </ControlPanelComponents.RoundButtonContainer>
            </div>
          }
        />
      </div>
    </div>
  );
};

const QuickActionToolsPanel: React.FC<{
  mainRoomStateMgr: MainRoomStateMgr;
}> = ({ mainRoomStateMgr }) => {
  const quickActionToolsST = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.quickActionToolsMgr.state$
  );

  return quickActionToolsST.qaTools.toReversed().map((qaTool) =>
    Match.value(qaTool).pipe(
      Match.when("BOOKMARK", () => {
        return (
          <LiveSessionComponents.BottomLeftButtons.BookmarkButton
            onBookmarkClick={() => {
              mainRoomStateMgr.stateSyncMgr.annotationsPanelMgr.bookmarksMgr
                .saveBookmark()
                .then(() => {
                  mainRoomStateMgr.openPanel({
                    state: "THERAPY_TOOLS",
                    initialViewState:
                      ToolMenuViewStateST.asSelectedTool("NOTES"),
                  });
                });
            }}
          />
        );
      }),
      Match.when("BOOKEND", () => {
        return (
          <LiveSessionComponents.BottomLeftButtons.RecordSectionButton
            isRecording$={
              mainRoomStateMgr.stateSyncMgr.annotationsPanelMgr.isRecording$
            }
            onStartClick={() => {
              mainRoomStateMgr.stateSyncMgr.annotationsPanelMgr.bookendsMgr.runStartBookend();
            }}
            onStopClick={() => {
              mainRoomStateMgr.stateSyncMgr.annotationsPanelMgr.bookendsMgr.endInProgressBookend();
            }}
          />
        );
      }),
      Match.when("NOTES", () => {
        return (
          <LiveSessionComponents.BottomLeftButtons.NotesButton
            onNotesClick={() => {
              mainRoomStateMgr.openPanel({
                state: "THERAPY_TOOLS",
                initialViewState: ToolMenuViewStateST.asSelectedTool("NOTES"),
              });
            }}
          />
        );
      }),
      Match.when("QUICK_SCHEDULE", () => {
        return <div>Quick Schedule</div>;
      }),
      Match.exhaustive
    )
  );
};

const StageArea = ({
  mainRoomStateMgr,
  isLeftNavToolsOpen,
  onCloseTopPreview,
}: {
  mainRoomStateMgr: MainRoomStateMgr;
  isLeftNavToolsOpen: boolean;
  onCloseTopPreview: () => void;
}) => {
  const stageLayout = useObservableEagerState(
    mainRoomStateMgr.stateSyncMgr.stageLayoutMgr.state$
  );

  const channelMgr = useAssumedCurrentChannel();

  const otherParticipants$ = channelMgr.state.remoteParticipants$;

  return (
    <div className="flex-1 min-h-0 flex flex-col gap-4">
      <StageTopNav mainRoomStateMgr={mainRoomStateMgr} />
      <div className="flex-1 flex flex-col  min-w-0 min-h-0 gap-4 max-w-full relative">
        {Match.value(stageLayout.mode).pipe(
          Match.when(
            { tag: "CONTENT" },
            ({ content, contentScreensizeMode }) => {
              const contentView = Match.value(content).pipe(
                Match.when("EMDR", () => {
                  return (
                    <div className="flex-1 flex flex-col justify-center">
                      <EMDRBallStageView
                        state$={mainRoomStateMgr.stateSyncMgr.tools.emdr.state$}
                        soundType="beep"
                        soundDuration={0.05}
                      />
                    </div>
                  );
                }),
                // Match.tag("SESSION_SPECIFIC", (r) =>
                //   stageRenders.sessionSpecificContentView(r.tool)
                // ),
                Match.when("MEDITATION", () => {
                  return (
                    <MeditationPreview
                      meditationMgr={mainRoomStateMgr.tools.meditationMgr}
                    />
                  );
                }),
                Match.orElse(() => <></>)
              );
              return Match.value(contentScreensizeMode.mode).pipe(
                Match.when("FULL_SCREEN", () => {
                  return (
                    <MaximizedContentView
                      contentView={contentView}
                      otherParticipants$={otherParticipants$}
                      mainRoomStateMgr={mainRoomStateMgr}
                    />
                  );
                }),
                Match.when("TOP_PREVIEW", () => {
                  return (
                    <MinimizedContentView
                      contentView={contentView}
                      otherParticipants$={otherParticipants$}
                      onCloseTopPreview={() => {
                        onCloseTopPreview();
                      }}
                      mainRoomStateMgr={mainRoomStateMgr}
                    />
                  );
                }),
                Match.exhaustive
              );
            }
          ),
          Match.when(
            { tag: "PARTICIPANTS_VIEW" },
            ({ participantsViewMode }) => {
              return <ParticipantsView mode={participantsViewMode.mode} />;
            }
          ),
          Match.exhaustive
        )}
        {!isLeftNavToolsOpen && (
          <div className="absolute bottom-4 left-4 ">
            <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
              arrowDirection="left"
              onClick={() => {
                mainRoomStateMgr.toggleLeftToolsNav();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ParticipantsView = ({
  mode,
}: {
  mode: ParticipantsViewMode;
}): React.ReactNode => {
  const channelMgr = useAssumedCurrentChannel();
  const me$ = channelMgr.state.localParticipant$;
  const otherParticipants$ = channelMgr.state.remoteParticipants$;

  return Match.value(mode).pipe(
    Match.when({ tag: "SELF_VIEW" }, () => {
      return <MeOnlyParticipantView meParticipant$={me$} />;
    }),
    Match.when({ tag: "ONE_ON_ONE" }, ({ otherParticipantId }) => {
      return (
        <OneOnOneParticipantView
          meAsParticipant$={me$}
          otherParticipants$={otherParticipants$}
          otherParticipantId={otherParticipantId}
          aspectRatio="16/9"
        />
      );
    }),
    Match.when({ tag: "GROUP" }, ({ groupViewMode }) => {
      return Match.value(groupViewMode.mode).pipe(
        Match.when({ tag: "GALLERY" }, () => {
          return <PaginatedGridView />;
        }),
        Match.when({ tag: "SPOTLIGHT_SPEAKER" }, () => {
          return <MainRoomGroupSpeakerViewLayout />;
        }),
        Match.exhaustive
      );
    }),
    Match.exhaustive
  );
};
