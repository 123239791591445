import * as S from "@effect/schema/Schema";
import type { ConvexClient } from "convex/browser";
import { Effect, Option } from "effect";
import {
  PostId,
  type SubmitPostResult,
} from "shared/schemas/community/community.schemas";
import {
  FileSchemas,
  type InMemoryFile,
  type UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";
import { KnownCloudinaryPtrs } from "shared/schemas/known-remote-file-ptrs/known-cloudinary-ptrs.schemas";
import type { ApiMgr } from "../../../api.mgr";
import { createContextAndHook } from "../../../util";
import { StandardFormStateMgr } from "../base.statemgr";
import { PreprocessedMediaStateMgr } from "../preprocessed-media.statemgr";

export class NewCommunityPostInput extends S.Class<NewCommunityPostInput>(
  "NewCommunityPostInput"
)({
  posterUserId: S.String,
  mainText: S.String.pipe(S.minLength(10)),
  media: S.NullOr(
    S.Struct({
      fileMetadata: FileSchemas.FileMetadata,
      confirmedUploadedPtrEncoding:
        KnownCloudinaryPtrs.ConfirmedCloudinaryPtrEncoding,
    })
  ),
  mbSavedDraftPostId: S.NullOr(PostId),
  communitySlug: S.optional(S.String),
}) {
  static default = (p: { posterUserId: string }) =>
    NewCommunityPostInput.make(
      {
        posterUserId: p.posterUserId,
        mainText: "",
        media: null,
        mbSavedDraftPostId: null,
      },
      { disableValidation: true }
    );
}

export class CreateCommunityPostFormMgr extends StandardFormStateMgr<
  NewCommunityPostInput,
  SubmitPostResult,
  { error: string }
> {
  preprocessedMediaStateMgr = new PreprocessedMediaStateMgr();
  communitySlug: string;

  onSubmit = (formData: NewCommunityPostInput) =>
    Effect.gen(this, function* () {
      const remoteFileEncodedPtr =
        formData.media?.confirmedUploadedPtrEncoding ?? null;

      const mbSavedDraftPostId = formData.mbSavedDraftPostId;

      console.log("mbSavedDraftPostId!!! ", mbSavedDraftPostId);

      const res = yield* this.BE.fetchEndpointWithHandleError((Api) =>
        Api.hp.community.submitPost.mutate({
          communitySlug: formData.communitySlug ?? this.communitySlug,
          mainTextContent: formData.mainText,
          fileMetadata: formData.media?.fileMetadata ?? null,
          remoteFileEncodedPtr,
          mbSavedDraftPostId,
        })
      );

      return res;
    });

  constructor(
    readonly p: {
      apiMgr: ApiMgr;
      convex: ConvexClient;
      communitySlug: string;
      posterUserId: string;
      uploadFileToCloudinary: (p: {
        secureUploadUrl: string;
        file: InMemoryFile;
      }) => Effect.Effect<UploadFileToCloudinaryResponse>;
    }
  ) {
    super({
      apiMgr: p.apiMgr,
      convex: p.convex,
      mbInitialData: Option.none(),
      defaultData: NewCommunityPostInput.default({
        posterUserId: p.posterUserId,
      }),
    });

    this.communitySlug = p.communitySlug;
  }

  onLocalMediaChange = (p: {
    inMemoryFile: InMemoryFile;
    fileMetadata: FileSchemas.FileMetadata;
  }) => {
    Effect.runPromise(this.handleLocalMediaChange(p)).catch((e) => {
      console.error("Error handling local media change", e);
    });
  };

  handleLocalMediaChange = (p: {
    inMemoryFile: InMemoryFile;
    fileMetadata: FileSchemas.FileMetadata;
  }) =>
    Effect.gen(this, function* () {
      this.preprocessedMediaStateMgr.setProcessing({
        inMemoryFile: p.inMemoryFile,
      });
      const preprocessedMedia = yield* this.uploadToCloudinary({
        inMemoryFile: p.inMemoryFile,
        fileMetadata: p.fileMetadata,
      });

      this.preprocessedMediaStateMgr.setProcessed({
        uploadResult: preprocessedMedia.uploadResult,
        originalFileMetadata: p.fileMetadata,
        confirmedUploadedPtrEncoding:
          preprocessedMedia.confirmedUploadedPtr.encodeSelf(),
        inMemoryFile: p.inMemoryFile,
      });

      return preprocessedMedia;
    });

  uploadToCloudinary = (p: {
    inMemoryFile: InMemoryFile;
    fileMetadata: FileSchemas.FileMetadata;
  }): Effect.Effect<{
    uploadResult: UploadFileToCloudinaryResponse;
    confirmedUploadedPtr: KnownCloudinaryPtrs.CommunityPostMediaVirtualPtr;
    downloadUrl: string;
  }> => {
    return Effect.gen(this, function* () {
      const { uploadUrl, savedDraftPostId } =
        yield* this.BE.fetchSuccessOnlyEndpoint((Api) =>
          Api.hp.community.getMediaUploadInfo.query({
            communitySlug: this.p.communitySlug,
            fileMetadata: p.fileMetadata,
            mbPostId: null,
          })
        );

      this.setFormValue("mbSavedDraftPostId", savedDraftPostId);

      const uploadResult = yield* this.p.uploadFileToCloudinary({
        secureUploadUrl: uploadUrl,
        file: p.inMemoryFile,
      });

      const confirmedUploadedPtr =
        KnownCloudinaryPtrs.CommunityPostMediaVirtualPtr.from({
          public_id: uploadResult.public_id,
          asset_id: uploadResult.asset_id,
          mediaType: p.fileMetadata.mediaType,
        });

      const { downloadUrl } = yield* this.BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.hp.community.getPostMediaDownloadUrl.query({
          communitySlug: this.p.communitySlug,
          publicId: uploadResult.public_id,
          assetId: uploadResult.asset_id,
          mediaType: p.fileMetadata.mediaType,
        })
      );

      this.setFormValue("media", {
        fileMetadata: p.fileMetadata,
        confirmedUploadedPtrEncoding: confirmedUploadedPtr.encodeSelf(),
      });

      return {
        uploadResult,
        confirmedUploadedPtr,
        downloadUrl,
      };
    });
  };

  uploadFile = (p: {
    presignedUploadUrl: string;
    mimeType: FileSchemas.MimeTypeLit;
    file: any;
  }) => {
    return Effect.promise(() =>
      fetch(p.presignedUploadUrl, {
        method: "PUT",
        body: p.file,
      })
    );
  };
}

export const [
  CreateCommunityPostFormMgrContext,
  useCreateCommunityPostFormMgr,
] = createContextAndHook<CreateCommunityPostFormMgr>();
