import { Switch } from "@headlessui/react";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import {
  SelectUserSection,
  SetDurationSection,
  SetEndingSessionReminderSection,
} from "@webapp/componentssession/session-form.components";
import { RD, Rx } from "@webapp/prelude";
import { useMutation } from "convex/react";
import { Either } from "effect";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { type SimplestUserWithProfilePhoto } from "shared";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { isRight } from "shared/util";

type OpenDropdownId = "user" | "payment" | "duration" | "reminder";

export const StartASessionForm: React.FC<{
  onClose: () => void;
  forClient:
    | { _tag: "EXISTING"; client: SimplestUserWithProfilePhoto }
    | { _tag: "SELECT"; myClients: SimplestUserWithProfilePhoto[] };
}> = ({ forClient }) => {
  const nav = useNavigate();
  const onStartSession = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.onSubmitStartSession
  );
  const selectedUserId$ = useOnce(
    () =>
      new Rx.BehaviorSubject<string | null>(
        forClient._tag === "EXISTING" ? forClient.client.id : null
      )
  );
  const durationInMinutes$ = useOnce(
    () => new Rx.BehaviorSubject<number | null>(60)
  );
  const endingSessionReminderInMinutes$ = useOnce(
    () => new Rx.BehaviorSubject<number | null>(10)
  );
  const [audioOnly, setAudioOnly] = React.useState(false);
  const [rdSubmitResult, setRdSubmitResult] = React.useState<
    RD.RemoteData<{ msg: string }, { navNextUrl: string }>
  >(RD.initial);

  const selectedUserId = useObservableEagerState(selectedUserId$);
  const durationInMinutes = useObservableEagerState(durationInMinutes$);
  const endingSessionReminderInMinutes = useObservableEagerState(
    endingSessionReminderInMinutes$
  );

  const [openDropdownId, setOpenDropdownId] =
    React.useState<OpenDropdownId | null>(null);

  return (
    <div className="flex flex-col p-8">
      <div className="flex flex-col gap-12">
        {forClient._tag === "SELECT" && (
          <SelectUserSection
            label="Client"
            isOpen={openDropdownId === "user"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "user" : null)}
            selectedUserId$={selectedUserId$}
            users={forClient.myClients}
            onUserSelect={(client) => {
              selectedUserId$.next(client.id);
            }}
          />
        )}
        <SetDurationSection
          isOpen={openDropdownId === "duration"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "duration" : null)}
          durationInMinutes$={durationInMinutes$}
          onChange={(v) => {
            durationInMinutes$.next(v);
          }}
        />
        {/* <ClientsPageComponents.DurationRadioGroup
          sessionConfig$={sessionConfig$}
        /> */}
        {/* <ClientsPageComponents.SetTimeWarningRadioGroup
          sessionConfig$={sessionConfig$}
        /> */}
        <SetEndingSessionReminderSection
          isOpen={openDropdownId === "reminder"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "reminder" : null)}
          endingSessionReminder$={endingSessionReminderInMinutes$}
          onChange={(v) => {
            endingSessionReminderInMinutes$.next(v);
          }}
        />
        {/* <div className="flex gap-8 mt-8">
          <h4 className="font-bold">Send Invite Email?</h4>
          <Switch
            checked={sendInviteEmail}
            onChange={(v) => {
              stateMgr.setFormValue("sendInviteEmail", v);
            }}
            className={`${
              sendInviteEmail ? "bg-vid-purple" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Send invite email</span>
            <span
              className={`${
                sendInviteEmail ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div> */}
        <div className="flex gap-8 mt-8 mb-8">
          <h4 className="font-bold">Audio only?</h4>
          <Switch
            checked={audioOnly}
            onChange={(v) => {
              setAudioOnly(v);
            }}
            className={`${
              audioOnly ? "bg-vid-purple" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Audio only</span>
            <span
              className={`${
                audioOnly ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      </div>
      <PrimaryButton
        title={
          RD.isPending(rdSubmitResult) ? "Starting session..." : "Start session"
        }
        isLoading={RD.isPending(rdSubmitResult)}
        onClick={() => {
          setRdSubmitResult(RD.pending);
          onStartSession({
            withClientId: (selectedUserId as Id<"users">) ?? undefined,
            intendedDurationMinutes: durationInMinutes ?? undefined,
            endingSessionReminderInMinutes:
              endingSessionReminderInMinutes ?? undefined,
          }).then((er) => {
            console.log("er!!! ", er, Either.isRight(er));
            if (isRight(er)) {
              console.log("navigating to ", er.right.navNextUrl);
              nav(er.right.navNextUrl);
            } else {
              setRdSubmitResult(
                RD.failure(er.left) as RD.RemoteData<
                  { msg: string },
                  { navNextUrl: string }
                >
              );
            }
          });
        }}
      />
      {RD.isFailure(rdSubmitResult) && (
        <div className="text-red-500 font-bold self-center mt-4">
          {rdSubmitResult.error.msg}
        </div>
      )}
    </div>
  );
};
