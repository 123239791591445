import { Console, Effect, Match, Option } from "effect";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { FirebaseJsMgr } from "../firebase";

export function useAuthFromFirebase(p: { firebaseMgr: FirebaseJsMgr }): {
  isLoading: boolean;
  isAuthenticated: boolean;
  fetchAccessToken: () => Promise<string | null>;
} {
  const [isAuthenticated, setIsAuthenticated] = useState<undefined | boolean>(
    undefined
  );

  useEffect(() => {
    p.firebaseMgr.firebaseAuthState$.subscribe((authState) => {
      console.log("AUTH STATE! ", authState);
      Match.value(authState).pipe(
        Match.when({ _tag: "KNOWN" }, ({ authState }) =>
          Match.value(authState).pipe(
            Match.when({ _tag: "LOGGED_IN" }, () => setIsAuthenticated(true)),
            Match.when({ _tag: "LOGGED_OUT" }, () => setIsAuthenticated(false)),
            Match.exhaustive
          )
        ),
        Match.when({ _tag: "UNKNOWN" }, () => setIsAuthenticated(undefined)),
        Match.exhaustive
      );
    });
  }, []);

  const fetchAccessToken: () => Promise<string | null> = useCallback(() => {
    return Effect.runPromise(
      p.firebaseMgr.getFirebaseTokenEff.pipe(
        Effect.tap((r) => Console.log("GET FIREBAES TOKEN CALLED! ", r)),
        Effect.map(Option.getOrNull),
        Effect.catchAll((_) => Effect.die("Could not get token"))
      )
    );
  }, []);

  return useMemo(
    () => ({
      isLoading: isAuthenticated == null ? true : false,
      isAuthenticated: isAuthenticated ?? false,
      fetchAccessToken,
    }),
    [isAuthenticated, fetchAccessToken]
  );
}
