import * as S from "@effect/schema/Schema";
import { Array, Option } from "effect";
import { epipe } from "shared/base-prelude";
import type { Doc } from "../../convex/_generated/dataModel";

interface InsertBookmarkData {
  label: string;
  color: string;
}

interface BaseBookmarkData extends InsertBookmarkData {
  id: string;
}

const KnownColors = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "orange",
  "pink",
] as const;

export const pickRandomColor = (colorsToAvoid: string[] = []) => {
  let color: string;
  do {
    color = KnownColors[Math.floor(Math.random() * KnownColors.length)];
  } while (colorsToAvoid.includes(color));
  return color;
};

export class SessionBookmarkSchema
  extends S.Class<SessionBookmarkSchema>("SessionBookmarkSchema")({
    id: S.String,
    label: S.String,
    color: S.String,
    bookmarkedAt: S.Number,
    secondsIntoSession: S.Number,
  })
  implements BaseBookmarkData
{
  get displayTimeInfo() {
    return (
      Math.floor(this.secondsIntoSession / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      (this.secondsIntoSession % 60).toString().padStart(2, "0")
    );
  }

  static from = (
    p: BaseBookmarkData & {
      id: string;
      bookmarkedAt: number;
      sessionStartedAt: number;
    }
  ): SessionBookmarkSchema => {
    const secondsIntoSession = Math.floor(
      (p.bookmarkedAt - p.sessionStartedAt) / 1000
    );
    return SessionBookmarkSchema.make(
      {
        ...p,
        secondsIntoSession,
      },
      { disableValidation: true }
    );
  };
}

export const SessionBookmarksSchema = S.Array(SessionBookmarkSchema);

export class BookmarksST extends S.Class<BookmarksST>("BookmarksST")({
  bookmarks: SessionBookmarksSchema,
}) {
  nextColor(p: { nextLabel?: string } = {}) {
    const mbMatchingBookmark = epipe(
      Option.fromNullable(p.nextLabel),
      Option.flatMap((label) =>
        Option.fromNullable(this.hasMatchingLabel(label))
      )
    );

    if (Option.isSome(mbMatchingBookmark)) {
      return mbMatchingBookmark.value.color;
    }

    const latestBookmark = Array.last(this.bookmarks);
    if (Option.isSome(latestBookmark)) {
      return pickRandomColor([latestBookmark.value.color]);
    }
    return pickRandomColor();
  }

  hasMatchingLabel(label: string) {
    return this.bookmarks.find((b) => b.label === label);
  }

  static fromEncoded = (p: typeof BookmarksST.Encoded): BookmarksST => {
    return S.decodeUnknownSync(BookmarksST)(p);
  };

  static from = (p: {
    bookmarks: (typeof SessionBookmarkSchema.Encoded)[];
  }): BookmarksST => {
    const decodedBookmarks = p.bookmarks.map((b) =>
      S.decodeUnknownSync(SessionBookmarkSchema)({
        ...b,
      })
    );

    return BookmarksST.make(
      { bookmarks: decodedBookmarks },
      { disableValidation: true }
    );
  };

  static default = (): BookmarksST => {
    return BookmarksST.from({ bookmarks: [] });
  };

  get encoded() {
    return S.encodeUnknownSync(BookmarksST)(this);
  }

  createNextBookmarkData(p: {
    label?: string;
    bookmarkedAt: number;
    sessionStartedAt: number;
  }): InsertBookmarkData {
    const label = p.label ?? this.mkDefaultLabel();
    const nextColor = this.nextColor({ nextLabel: label });

    return {
      label,
      color: nextColor,
    };
  }

  mkDefaultLabel() {
    const numberOfBookmarks = this.bookmarks.length;
    return `Bookmark ${numberOfBookmarks + 1}`;
  }

  addNextBookmark(p: {
    id: string;
    userId: string;
    label: string;
    bookmarkedAt: number;
    sessionStartedAt: number;
  }): BookmarksST {
    const nextColor = this.nextColor({ nextLabel: p.label });

    const nextBookmark = SessionBookmarkSchema.from({
      ...p,
      color: nextColor,
    });

    const withNewBookmark = {
      bookmarks: [...this.bookmarks, nextBookmark],
    };

    return BookmarksST.make(withNewBookmark, {
      disableValidation: true,
    });
  }
}

export class SessionBookendSchema extends S.Class<SessionBookendSchema>(
  "SessionBookendSchema"
)({
  id: S.String,
  label: S.String,
  color: S.String,
  startedAt: S.Number,
  endedAt: S.NullOr(S.Number),
  secondsIntoStart: S.Number,
}) {
  static from = (
    p: BaseBookmarkData & {
      startedAt: number;
      endedAt: number | null;
      sessionStartedAt: number;
    }
  ): SessionBookendSchema => {
    const secondsIntoStart = Math.floor(
      (p.startedAt - p.sessionStartedAt) / 1000
    );
    return SessionBookendSchema.make(
      { ...p, secondsIntoStart },
      { disableValidation: true }
    );
  };

  get displayTimeInfo() {
    return (
      Math.floor(this.secondsIntoStart / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      (this.secondsIntoStart % 60).toString().padStart(2, "0")
    );
  }
}

export const SessionBookendsSchema = S.Array(SessionBookendSchema);

export class BookendsST extends S.Class<BookendsST>("BookendsST")({
  bookends: SessionBookendsSchema,
}) {
  static fromEncoded = (p: typeof BookendsST.Encoded): BookendsST => {
    return S.decodeUnknownSync(BookendsST)(p);
  };

  static fromDoc = (p: Doc<"sessionBookend">[]): BookendsST => {
    const decodedBookends = p.map((b) =>
      S.decodeUnknownSync(SessionBookendSchema)(b)
    );

    return BookendsST.make(
      { bookends: decodedBookends },
      { disableValidation: true }
    );
  };

  static default = (): BookendsST => {
    return BookendsST.make({ bookends: [] }, { disableValidation: true });
  };

  get encoded() {
    return S.encodeUnknownSync(BookendsST)(this);
  }

  mkDefaultLabel() {
    const numberOfBookends = this.bookends.length;
    return `Recording ${numberOfBookends + 1}`;
  }

  nextBookendData(): { label: string; color: string } {
    const latestBookend = Array.last(this.bookends);
    if (Option.isSome(latestBookend)) {
      return {
        label: latestBookend.value.label,
        color: latestBookend.value.color,
      };
    }
    return {
      label: this.mkDefaultLabel(),
      color: pickRandomColor(),
    };
  }
}

class SessionNoteSchema extends S.Class<SessionNoteSchema>("SessionNoteSchema")(
  {
    note: S.String,
    savedAt: S.Number,
  }
) {
  static from = (p: { note: string; savedAt: number }): SessionNoteSchema => {
    return SessionNoteSchema.make(
      { note: p.note, savedAt: p.savedAt },
      { disableValidation: true }
    );
  };
}

export class SessionNotesSchema extends S.Class<SessionNotesSchema>(
  "SessionNotesSchema"
)({
  notes: S.Array(SessionNoteSchema),
}) {
  static default = (): SessionNotesSchema => {
    return SessionNotesSchema.make({ notes: [] }, { disableValidation: true });
  };

  static from = (p: {
    rawNotes: { note: string; savedAt: number }[];
  }): SessionNotesSchema => {
    return SessionNotesSchema.make(
      {
        notes: p.rawNotes.map((n) => SessionNoteSchema.from(n)),
      },
      { disableValidation: true }
    );
  };

  get encoded() {
    return S.encodeUnknownSync(SessionNotesSchema)(this);
  }

  get rawString() {
    return this.notes.map((n) => n.note).join("\n");
  }
}
