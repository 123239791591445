import { Data } from "effect";
import * as Rx from "rxjs";
import { RD } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import type { UserPrimaryRole } from "shared/db";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { BaseStateMgr } from "../base.statemgr";

export type AuthState = Data.TaggedEnum<{
  AUTHORIZED: {
    mbPrimaryRole: UserPrimaryRole | null;
    me: SimpleUserWithProfilePhoto;
  };
  UNAUTHORIZED: {};
}>;

const { AUTHORIZED, UNAUTHORIZED } = Data.taggedEnum<AuthState>();

export class AuthStateMgr extends BaseStateMgr {
  authState$ = new Rx.BehaviorSubject<RD.RemoteData<unknown, AuthState>>(
    RD.initial
  );

  runCheckAuthState() {
    this.authState$.next(RD.pending);

    this.convex.query(api.User.UserFns.getAssumedSimpleMe, {}).then((me) => {
      this.authState$.next(RD.success(AUTHORIZED({ me, mbPrimaryRole: "hp" })));
    });
  }
}
