import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { PrivateChatFC } from "@webapp/components/chat/private-chat";
import { UApiOutput } from "frontend-shared/src/trpc-cli";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { useOutletContext } from "react-router-dom";
import "stream-chat-react/dist/css/v2/index.css";

type MemberGlobalProfileResp =
  UApiOutput["hp"]["communities"]["getMemberGlobalProfile"];

export const GlobalMemberChatTab: React.FC = () => {
  const dashboardState = useHpState();
  const myProfile = useObservableEagerState(dashboardState.myProfile$);
  const otherUser = useOutletContext<MemberGlobalProfileResp["member"]>();

  return (
    <div className="flex-1 flex flex-col">
      <PrivateChatFC
        chatStateMgr={dashboardState.uMgr.chatStateMgr}
        setupChatEff={({ otherUserId }) =>
          BE.fetchEndpointEff((Api) =>
            Api.hp.communities.startChatChannelWithMember.mutate({
              memberUserId: otherUserId,
            })
          )
        }
        otherUserId={otherUser.id}
      />
    </div>
  );
};
