import { RadioGroup } from "@headlessui/react";
import { useMyId } from "@pages/u/u.webstatemgr";
import { useMutation } from "convex/react";
import { Either } from "effect";
import { HpModalitiesMgr } from "frontend-shared/src/mgrs/remote-state-mgrs/users/hp/hp.rsm";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import React from "react";
import { type SimplestUserWithProfilePhoto } from "shared";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { useConvexCli } from "src/convex-cli";
import { Rx, RxO } from "../../prelude";
import { FormDisclosureContainer } from "../disclosures";
import { SearchableDropdown } from "../form/searchabel-dropdown.fc";
import { RadioGroupCircle } from "../primitives/radio-buttons";

export namespace SessionFormComponents {
  export const DurationRadioGroup: React.FC<{
    durationInMinutes$: Rx.Observable<number | null>;
    onChange: (v: number | null) => void;
  }> = ({ onChange, durationInMinutes$ }) => {
    const durationInMinutes = useObservableEagerState(durationInMinutes$);

    return (
      <RadioGroup
        value={durationInMinutes}
        onChange={(v) => {
          onChange(v);
        }}
        className={`flex flex-col gap-4 p-4`}
      >
        <RadioGroup.Label className="flex flex-col">
          <div className={`font-bold text-lg`}>Duration</div>
          <div className="italic">
            This will only be used to assist in sending notifications. It will
            not close the session.
          </div>
        </RadioGroup.Label>
        <div className="flex flex-col gap-2">
          <RadioGroup.Option value={90}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="90 mins" icon="clock" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={60}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="60 mins" icon="clock" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={30}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="30 mins" icon="clock" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="10 mins" icon="clock" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };

  export const EndingSessionReminder: React.FC<{
    endingSessionReminder$: Rx.Observable<number | null>;
    onChange: (v: number | null) => void;
  }> = ({ onChange, endingSessionReminder$ }) => {
    const endingSessionReminder = useObservableEagerState(
      endingSessionReminder$
    );

    return (
      <RadioGroup
        value={endingSessionReminder}
        onChange={(v) => {
          onChange(v);
        }}
        className={`flex flex-col gap-4 p-4`}
      >
        <div className="flex flex-col gap-2">
          <RadioGroup.Option value={5}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="5 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="10 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={15}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="15 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={20}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="20 mins" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };

  export const StartSessionButton: React.FC<{
    clientUserId: string;
    onSuccess: (p: { sessionId: string }) => void;
    sessionConfig: {
      durationInMinutes: number | null;
      minutesBeforeEndReminder: number | null;
    };
  }> = ({ clientUserId, sessionConfig }) => {
    const onStartSession = useMutation(
      api.Screens.Hp.Dashboard.ClientsScreenFns.onSubmitStartSession
    );

    return (
      <button
        className="bg-btn-purple p-4 rounded-xl text-white self-stretch mt-8"
        onClick={() => {
          console.log("STARTING SESSION FOR CLIENT! ", clientUserId);
          const { durationInMinutes, minutesBeforeEndReminder } = sessionConfig;

          onStartSession({
            withClientId: clientUserId as Id<"users">,
            intendedDurationMinutes: durationInMinutes ?? undefined,
            endingSessionReminderInMinutes:
              minutesBeforeEndReminder ?? undefined,
          }).then((er) => {
            if (Either.isRight(er)) {
              const navNextUrl = er.right.navNextUrl;

              window.location.href = navNextUrl;
            }
          });
        }}
      >
        Start session
      </button>
    );
  };
}

export const SetDurationSection: React.FC<{
  durationInMinutes$: Rx.Observable<number | null>;
  onChange: (v: number | null) => void;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}> = ({ durationInMinutes$, onChange, isOpen, onToggle }) => {
  const durationInMinutes = useObservableState(
    durationInMinutes$.pipe(RxO.map((v) => (v ? v.toString() : null))),
    ""
  );

  return (
    <div>
      <FormDisclosureContainer
        isOpen={isOpen}
        onToggle={onToggle}
        selection={durationInMinutes}
        buttonView={{
          _tag: "DEFAULT",
          label: "Duration",
          selectedView: (dim) => ({ _tag: "TEXT", text: `${dim} minutes` }),
        }}
        dropdownView={(close) => (
          <div>
            <SessionFormComponents.DurationRadioGroup
              durationInMinutes$={durationInMinutes$}
              onChange={(v) => {
                onChange(v);
                close();
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export const SetPrimaryTherapyTypeSection: React.FC<{
  therapyTypeSlug$: Rx.Observable<string | null>;
  onChange: (v: string | null) => void;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}> = ({ therapyTypeSlug$, onChange, isOpen, onToggle }) => {
  const therapyTypeSlug = useObservableEagerState(therapyTypeSlug$);
  const myId = useMyId();
  const convex = useConvexCli();

  const modalitiesMgr = useOnce(
    () =>
      new HpModalitiesMgr({
        userId: myId,
        convexCli: convex,
      })
  );

  const allModalitiesST = useObservableEagerState(
    modalitiesMgr.knownModalitiesRSM.state$
  );

  return (
    <div>
      <FormDisclosureContainer
        isOpen={isOpen}
        onToggle={onToggle}
        selection={therapyTypeSlug}
        buttonView={{
          _tag: "DEFAULT",
          label: "Session Type",
          selectedView: (tts) => ({
            _tag: "CUSTOM",
            view: (
              <div className="flex items-center">
                <img
                  src="/dashboard/training-2.png"
                  className="-ml-[10px] object-scale-down"
                  width={40}
                  height={40}
                />
                <p className="font-sans text-sm font-light">{tts}</p>
              </div>
            ),
          }),
        }}
        dropdownView={(closeFn) => (
          <div className="flex flex-col">
            {allModalitiesST.modalities.map((tt) => (
              <div
                className="cursor-pointer py-4 px-2"
                onClick={() => {
                  onChange(tt.slug);
                  closeFn();
                }}
              >
                {tt.name}
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
};

export const SetEndingSessionReminderSection: React.FC<{
  endingSessionReminder$: Rx.Observable<number | null>;
  onChange: (v: number | null) => void;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}> = ({ endingSessionReminder$, onChange, isOpen, onToggle }) => {
  const endingSessionReminder = useObservableEagerState(endingSessionReminder$);
  return (
    <div>
      <FormDisclosureContainer
        isOpen={isOpen}
        onToggle={onToggle}
        selection={endingSessionReminder}
        buttonView={{
          _tag: "DEFAULT",
          label: "Ending Session Reminder",
          selectedView: (esr) => ({ _tag: "TEXT", text: `${esr} minutes` }),
        }}
        dropdownView={(close) => (
          <div>
            <SessionFormComponents.EndingSessionReminder
              endingSessionReminder$={endingSessionReminder$}
              onChange={(v) => {
                onChange(v);
                close();
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

type SelectClientSectionProps = {
  selectedUserId$: Rx.Observable<string | null>;
  users: SimplestUserWithProfilePhoto[];
  onUserSelect: (user: SimplestUserWithProfilePhoto) => void;
  label: string;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  placeholder?: string;
};
export const SelectUserSection: React.FC<SelectClientSectionProps> = ({
  selectedUserId$,
  users,
  onUserSelect,
  isOpen,
  onToggle,
  label,
  placeholder,
}) => {
  const selectedUserId = useObservableState(selectedUserId$, null);
  const selectedUser = selectedUserId
    ? users.find((u) => u.id === selectedUserId)
    : null;
  return (
    <FormDisclosureContainer
      isOpen={isOpen}
      onToggle={onToggle}
      selection={selectedUser}
      buttonView={{
        _tag: "DEFAULT",
        label,
        selectedView: (su) => ({ _tag: "TEXT", text: su?.name ?? "" }),
      }}
      dropdownView={(close) => (
        <SearchableDropdown
          items={users.map((u) => ({ id: u.id, label: u.name }))}
          selectedItem={
            selectedUser
              ? { id: selectedUser.id, label: selectedUser.name }
              : null
          }
          onSelect={(user) => {
            if (!Array.isArray(user) && user) {
              const matchingUser = users.find((c) => c.id === user.id)!;
              onUserSelect(matchingUser!);
              close();
            }
          }}
          placeholder={placeholder ?? "Search for client"}
        />
      )}
    />
  );
};

export const SetPaymentSection: React.FC<{
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  priceCents$: Rx.Observable<number | null>;
  setPriceCents: (v: number | null) => void;
}> = ({ isOpen, onToggle, priceCents$, setPriceCents }) => {
  const priceCents = useObservableEagerState(priceCents$);
  const [inputValue, setInputValue] = React.useState(
    priceCents?.toString() || ""
  );

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const priceInDollarsStr = e.target.value;
    setInputValue(priceInDollarsStr);

    if (priceInDollarsStr === "") {
      setPriceCents(null);
    } else {
      const numericValue = parseFloat(priceInDollarsStr);
      if (!isNaN(numericValue) && numericValue >= 0) {
        setPriceCents(numericValue * 100);
      }
    }
  };

  return (
    <div>
      <FormDisclosureContainer
        isOpen={isOpen}
        onToggle={onToggle}
        selection={priceCents}
        buttonView={{
          _tag: "DEFAULT",
          label: "Payment",
          selectedView: (priceCents) => ({
            _tag: "TEXT",
            text: priceCents ? `$${priceCents / 100}` : "",
          }),
        }}
        dropdownView={() => (
          <div>
            <input
              type="text"
              className="text-input"
              value={inputValue}
              onChange={handlePriceChange}
              placeholder="Enter price"
            />
          </div>
        )}
      />
    </div>
  );
};
