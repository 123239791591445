import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { apiMgr, BE } from "@webapp/backend";
import { AvatarCircles } from "@webapp/componentsavatar.tc";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { ConvexClient } from "convex/browser";
import { useQuery } from "convex/react";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import { isLoadingState } from "frontend-shared/src/fetch";
import {
  MyCommunityProfileRSM,
  UpcomingEventsForMeRSM,
} from "frontend-shared/src/mgrs/remote-state-mgrs/community/community-tag.rsm";
import { BaseStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { CommunityCardST } from "shared/convex/Screens/Community/CommunityScreens.Types";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { useConvexCli } from "src/convex-cli";
import { useMe, useMyId } from "../../../../u.webstatemgr";
import { CommunityPost } from "../components/community-post.fc";
import {
  CommunityCardContainer,
  CommunityEventCardContainer,
  MyCommunitiesProfileCard,
} from "../components/community.components";

class CommunitiesLandingPageStateMgr extends BaseStateMgr {
  myProfileRSM: MyCommunityProfileRSM;
  upcomingEventsForMeRSM: UpcomingEventsForMeRSM;

  constructor(
    readonly p: { myUserId: string; apiMgr: ApiMgr; convex: ConvexClient }
  ) {
    super({
      convex: p.convex,
      apiMgr: p.apiMgr,
    });
    this.myProfileRSM = new MyCommunityProfileRSM({
      userId: p.myUserId,
      convexCli: p.convex,
    });

    this.upcomingEventsForMeRSM = new UpcomingEventsForMeRSM({
      userId: p.myUserId,
      convexCli: p.convex,
    });
  }
}

export const CommunitiesLandingPage: React.FC = () => {
  const myId = useMyId();
  const me = useMe();
  const convex = useConvexCli();

  const stateMgr = useOnce(
    () =>
      new CommunitiesLandingPageStateMgr({
        myUserId: myId,
        apiMgr: apiMgr,
        convex,
      })
  );

  useEffect(() => {
    stateMgr.myProfileRSM.subscribeSync();
    stateMgr.upcomingEventsForMeRSM.subscribeSync();

    return () => {
      stateMgr.myProfileRSM.dispose();
      stateMgr.upcomingEventsForMeRSM.dispose();
    };
  }, []);

  const myProfile = useObservableEagerState(stateMgr.myProfileRSM.state$);
  const upcomingEventsForMe = useObservableEagerState(
    stateMgr.upcomingEventsForMeRSM.state$
  );

  const communitiesForMeList = useQuery(
    api.Community.CommunitiesHomeScreenFns.getCommunitiesForMeList,
    {}
  );

  const rdLandingData = BE.useSuccessFetch(
    (Api) => Api.hp.communities.communitiesLandingHome.query(),
    []
  );

  if (isLoadingState(rdLandingData)) {
    return <FullContainerLoadingSpinner />;
  }

  if (
    communitiesForMeList === undefined ||
    upcomingEventsForMe === undefined ||
    myProfile === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  const { timelineFeedPosts } = rdLandingData.data;

  return (
    <div className="flex-1 flex flex-col gap-8 p-8 static lg:relative max-w-full">
      <div className="flex-1 flex gap-16">
        <div className="flex flex-col gap-4 max-w-full lg:w-[714px]">
          <h4 className="text-xl font-medium">Communities</h4>
          <CommunitiesListSection communityCards={communitiesForMeList} />
          {upcomingEventsForMe.cards.length > 0 ? (
            <div className="flex flex-col gap-4 mt-4">
              <h4 className="text-xl font-medium">Upcoming events</h4>
              <div className="flex gap-2 items-center overflow-x-auto">
                {upcomingEventsForMe.cards.map((e) => (
                  <CommunityEventCardContainer
                    key={e.instance._id}
                    initialCardData={e.encoded}
                    showCommunityName={true}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>No upcoming events</div>
          )}
          <div className="flex flex-col gap-4 mt-8">
            <h4 className="text-xl font-medium leading-6 ">Recent posts</h4>
            {timelineFeedPosts.map((post) => (
              <CommunityPost
                key={post.id}
                community={{
                  slug: post.communitySlug,
                  allMembers: [],
                }}
                post={post}
              />
            ))}
          </div>
        </div>
        <MyCommunitiesProfileCard
          name={me.name!}
          profilePhoto={ImageSrc.fromMbUrl(me.profilePhoto)}
          mode={{ _tag: "COMMUNITIES" }}
          bio={myProfile.bio}
          modalities={[...myProfile.modalities]}
        />
      </div>
    </div>
  );
};

const CommunitiesListSection: React.FC<{
  communityCards: (typeof CommunityCardST.Encoded)[];
}> = ({ communityCards }) => {
  const navigate = useNavigate();

  const hpState = useHpState();

  return (
    <div className="self-stretch flex justify-between gap-4 lg:min-w-0 overflow-x-auto">
      {communityCards.map((c) => (
        <CommunityCardContainer
          key={c.community.slug}
          headerBackgroundType={{
            _tag: "FIXED",
            color: c.community.color ?? "#690DFF",
          }}
          innerCircleComponent={
            <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
          }
          title={c.community.name}
          content={
            <div className="flex flex-col items-center gap-2 mt-2">
              {c.members.profilePhotos.length > 0 && (
                <AvatarCircles sources={[...c.members.profilePhotos]} />
              )}
              <div className="text-vid-black-400">{c.members.countMsg}</div>
            </div>
          }
          button={{
            title: "View",
            isDisabled: false,
          }}
          onClick={() => {
            navigate(
              UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                {
                  community: c.community.slug,
                }
              )
            );
          }}
        />
      ))}

      <CommunityCardContainer
        key={"create-community"}
        headerBackgroundType={{
          _tag: "FIXED",
          color: "#000000",
        }}
        innerCircleComponent={
          <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
        }
        title={"Create a community"}
        content={
          <div className="flex flex-col gap-2">
            <div></div>
            <div className="text-vid-black-400">members</div>
          </div>
        }
        onClick={() => {
          hpState.dashboardState.openRightNav({
            _tag: "CREATE_COMMUNITY",
          });
        }}
        button={{
          title: "Create",
        }}
      />
    </div>
  );
};
