import { CommunityPostCvx } from "@pages/u/hp/dashboard/community/components/community-post.fc";
import { TabView } from "@pages/u/hp/dashboard/community/components/community.components";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { ANON_ROUTES } from "shared/routes/routes";

export const MyGlobalProfilePage: React.FC = () => {
  const { userId } = useTypedParams(ANON_ROUTES.MY_GLOBAL_PROFILE);

  const posts = useQuery(
    api.Community.CommunityMemberScreenFns.onPostsTabMount,
    {
      baseUserId: userId as Id<"users">,
    }
  );

  if (posts === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className="w-screen h-screen flex flex-col gap-4 ">
      <h4>{userId}</h4>
      <div className="flex-1 flex flex-col gap-4">
        <TabView title="Posts" isCurrent={true} linkTo={""} />
        <div className="flex-1 min-h-0 overflow-y-auto flex flex-col gap-4 border-4 border-green-400">
          {posts.map((post) => (
            <CommunityPostCvx key={post._id} enrichedPost={post} />
          ))}
        </div>
      </div>
    </div>
  );
};
