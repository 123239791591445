import { Array, Effect, Option } from "effect";
import type { NonEmptyReadonlyArray } from "effect/Array";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { z } from "zod";

export async function ignorePromiseError<T>(promise: Promise<T>) {
  try {
    await promise;
  } catch (e) {
    console.log("IGNORED ERROR", e);
  }
}

export function isNullOrUndefined<T>(
  value: T | null | undefined
): value is null | undefined {
  return pipe(value, O.fromNullable, O.isNone);
}

export function isNotNullOrUndefined<T>(
  value: T | null | undefined
): value is NonNullable<T> {
  return !isNullOrUndefined(value);
}

export function logDebugEff(message: string, data?: any) {
  return Effect.sync(() => {
    console.log(message, data);
  });
}

export function partialUpdate<T extends object>(
  original: T,
  updates: Partial<T>
): T {
  console.log("ORIGINAL", original);
  console.log("UPDATES", updates);
  return { ...original, ...updates };
}

export function zodNullParse<T extends z.ZodObject<any, any, any, any, any>>(
  zod: T,
  value: unknown
): z.infer<T> | null {
  const parsed = zod.safeParse(value);
  return parsed.success ? parsed.data : null;
}

function toCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

type CamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<CamelCase<U>>}`
  : S;

type AsCamel<T> = {
  [K in keyof T as CamelCase<K & string>]: T[K];
};

export function mapKeysToCamelCase<T extends Record<string, any>>(
  obj: T
): AsCamel<T> {
  const result = {} as { [K in keyof AsCamel<T>]: T[keyof T] };

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = toCamelCase(key) as keyof AsCamel<T>;
      result[camelCaseKey] = obj[key];
    }
  }

  return result as unknown as AsCamel<T>;
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export function asMbNonEmptyArray<T>(
  arr: T[]
): Option.Option<NonEmptyReadonlyArray<T>> {
  if (Array.isNonEmptyArray(arr)) {
    return Option.some(arr);
  }
  return Option.none();
}

export const makeZodSchemaNullable = <
  T extends z.ZodObject<any, any, any, any, any>,
>(
  schema: T
) => {
  const nullableSchema: any = {};
  for (const key in schema.shape) {
    nullableSchema[key] = schema.shape[key].nullable();
  }
  return z.object(nullableSchema);
};

export class MoneyFmt {
  static formatCents(cents: number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(cents / 100);
  }
}

export const isRight = <T>(value: unknown): value is { right: T } => {
  return typeof value === "object" && value !== null && "right" in value;
};

export const isLeft = <T>(value: unknown): value is { left: T } => {
  return typeof value === "object" && value !== null && "left" in value;
};
