import { Effect } from "effect";
import { ApiMgrContext, useRunEffect } from "frontend-shared/src/api.mgr";
import { FirebaseJsContext } from "frontend-shared/src/firebase";
import { useObservableState } from "observable-hooks";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RD } from "shared/base-prelude";
import { ROUTES } from "shared/routes/routes";
import { AppConfig, firebaseMgr } from "./app-config";
import { mkWebApiMgr } from "./backend";
import { ConvexCliProver, useSetupConvexCli } from "./convex-cli";
import { globalErrorToastMgr, globalTopInfoToastMgr } from "./global.state";
import { FullScreenLoadingSpinner } from "./loading";
import { ONBOARD_ROUTES } from "./route-views/onboard-route-views";

export const IndexLayout: React.FC = () => {
  const location = useLocation();
  const nav = useNavigate();
  const convexCli = useSetupConvexCli(firebaseMgr);
  const apiMgr = useMemo(
    () => mkWebApiMgr({ apiUrl: AppConfig.apiUrl!, firebaseMgr, convexCli }),
    [AppConfig.apiUrl, firebaseMgr]
  );
  const mbShowGlobalErrorToast = useObservableState(
    globalErrorToastMgr.showGlobalErrorToast$,
    null
  );
  const mbShowGlobalTopInfoToast = useObservableState(
    globalTopInfoToastMgr.showToast$,
    null
  );
  const rdFirebaseUser = useRunEffect(
    Effect.promise(() => firebaseMgr.getFirebaseUser()),
    []
  );

  useEffect(() => {
    if (RD.isSuccess(rdFirebaseUser) && location.pathname === "/") {
      if (rdFirebaseUser.value) {
        console.log("NAV TO UROUTES! ", ROUTES.U.path);
        nav(ROUTES.U.path);
      } else {
        nav(ONBOARD_ROUTES.PORTAL_SELECTION.path);
      }
    }
  }, [rdFirebaseUser]);

  if (!RD.isSuccess(rdFirebaseUser)) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <ApiMgrContext.Provider value={apiMgr}>
      <FirebaseJsContext.Provider value={firebaseMgr}>
        <ConvexCliProver.Provider value={convexCli}>
          <div className="w-screen h-screen flex flex-col justify-center relative">
            <Outlet />
            {mbShowGlobalErrorToast && (
              <div className="absolute top-0 left-0 right-0 h-[100px] flex flex-col justify-center items-center bg-red-500 z-50">
                <div className="text-white p-4 rounded-md">
                  {mbShowGlobalErrorToast.title}
                </div>
                <div className="text-white p-4 rounded-md">
                  {mbShowGlobalErrorToast.message}
                </div>
              </div>
            )}
            {mbShowGlobalTopInfoToast && (
              <div className="absolute top-0 left-0 right-0 h-[100px] flex justify-center items-center">
                <div className="bg-vid-black-800 text-white p-4 rounded-md animate-slide-down-top-info-toast">
                  {mbShowGlobalTopInfoToast.title}
                </div>
              </div>
            )}
          </div>
        </ConvexCliProver.Provider>
      </FirebaseJsContext.Provider>
    </ApiMgrContext.Provider>
  );
};
