import type { ConvexClient } from "convex/browser";
import { Effect, Option } from "effect";
import { useOnce, useRunSuccessEffectO$ } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { type ApiMgr } from "../../../api.mgr";
import type { FirebaseJsMgr } from "../../../firebase";
import { PubSubNotificationsRsm } from "../../remote-state-mgrs/users/me.rsm";
import { BaseStateMgr } from "../base.statemgr";
import { ChatStateMgr, type GenericChatCli } from "../chat.statemgr";
import {
  GenericRtcMgr,
  type GenericRtcChannelMgr,
  type GenericRtcEngine,
} from "../rtc.statemgr";

/*
    Common states/actions for all users (hp, cp, etc)
*/

export abstract class UserStateMgr<
  StreamChatCli extends GenericChatCli,
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>,
> extends BaseStateMgr {
  pubSubNotifDisplayRsm: PubSubNotificationsRsm;

  me$: Rx.BehaviorSubject<SimpleUserWithProfilePhoto>;
  profilePhoto$: Rx.Observable<Option.Option<ImageSrc>>; // derivced value for easier access

  chatStateMgr: ChatStateMgr<StreamChatCli>;
  rtcMgr: RtcMgr;

  constructor(
    readonly p: {
      myId: Id<"users">;
      me: SimpleUserWithProfilePhoto;
      apiMgr: ApiMgr;
      convex: ConvexClient;
      chatKeyAndToken: {
        apiKey: string;
        token: string;
      };
      rtcCreds: RtcCredentials;
      firebaseMgr: FirebaseJsMgr;
      mkChatCli: (p: { apiKey: string }) => StreamChatCli;
      mkRtcMgr: (p: RtcCredentials) => RtcMgr;
    }
  ) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    this.me$ = new Rx.BehaviorSubject(p.me);
    this.profilePhoto$ = this.me$.pipe(
      RxO.map((me) =>
        Option.fromNullable(me.profilePhoto).pipe(
          Option.map((url) => new ImageSrc({ _tag: "URL", url }))
        )
      )
    );

    this.convex.onUpdate(api.User.UserFns.getAssumedSimpleMe, {}, (me) => {
      this.me$.next(me);
    });

    this.pubSubNotifDisplayRsm = new PubSubNotificationsRsm({
      userId: p.myId,
      convexCli: p.convex,
    });
    this.pubSubNotifDisplayRsm.subscribeSync();

    this.pubSubNotifDisplayRsm.state$.subscribe((notif) => {
      if (notif.notification !== null) {
        console.log("$$$$$$PUB SUB NOTIF DISPLAY RSM! ", notif);
      }
    });

    this.chatStateMgr = new ChatStateMgr<StreamChatCli>({
      apiMgr: p.apiMgr,
      convex: p.convex,
      mySimpleProfile: { id: p.myId, name: p.me.name },
      chatKeyAndToken: p.chatKeyAndToken,
      mkChatCli: p.mkChatCli,
    });

    this.rtcMgr = p.mkRtcMgr(p.rtcCreds);
  }

  signout = Effect.gen(this, function* () {
    const signoutRes = yield* Effect.promise(() =>
      this.p.firebaseMgr.signOut()
    );
    return signoutRes;
  });

  deleteAccount = Effect.gen(this, function* () {
    const deleteAccountRes = yield* this.BE.fetchSuccessOnlyEndpoint((Api) =>
      Api.u.me.softDeleteMyAccount.mutate()
    );
    const signoutRes = yield* this.signout;
    console.log("signoutRes", signoutRes);
    return deleteAccountRes;
  });
}

export function useSetupUStateMgr<
  StreamChatCli extends GenericChatCli,
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>,
  PlatUStateMgr extends UserStateMgr<
    StreamChatCli,
    RtcChannelMgr,
    RtcEngine,
    RtcMgr
  >,
>(
  apiMgr: ApiMgr,
  convex: ConvexClient,
  firebaseMgr: FirebaseJsMgr,
  me: SimpleUserWithProfilePhoto,
  baseUserId: Id<"users">,
  mkChatCli: (p: { apiKey: string }) => StreamChatCli,
  mkPlatUStateMgr: (p: {
    me: SimpleUserWithProfilePhoto;
    apiMgr: ApiMgr;
    convex: ConvexClient;
    firebaseMgr: FirebaseJsMgr;
    chatKeyAndToken: { apiKey: string; token: string };
    rtcCreds: RtcCredentials;
    mkChatCli: (p: { apiKey: string }) => StreamChatCli;
  }) => PlatUStateMgr
): Option.Option<PlatUStateMgr> {
  const mbChatAndRtcCreds$ = useRunSuccessEffectO$(
    Effect.all([
      apiMgr.fetchSuccessOnlyEndpoint((Api) => Api.u.getUserChatToken.query()),
      apiMgr.fetchSuccessOnlyEndpoint((Api) =>
        Api.u.createRtcCredentials.mutate({ baseUserIdStr: baseUserId })
      ),
    ]),
    []
  );

  const mbUStateMgr$ = useOnce(() => {
    return mbChatAndRtcCreds$.pipe(
      RxO.distinctUntilChanged((a, b) => {
        return Option.isSome(a) && Option.isSome(b) && a.value === b.value;
      }),
      RxO.map((mbChatAndRtcCreds) => {
        return mbChatAndRtcCreds.pipe(
          Option.map(([chatCreds, rdRtcCredentials]) => {
            return mkPlatUStateMgr({
              me,
              apiMgr,
              convex,
              firebaseMgr,
              chatKeyAndToken: {
                apiKey: chatCreds.apiKey,
                token: chatCreds.token,
              },
              rtcCreds: rdRtcCredentials,
              mkChatCli,
            });
          })
        );
      })
    );
  });

  const mbUStateMgr = useObservableEagerState(mbUStateMgr$);

  return mbUStateMgr;
}
