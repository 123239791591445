import { reflect } from "@effector/reflect";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { FileUploadUtils } from "@webapp/utils/file.utils";
import { Effect } from "effect";
import {
  MyProfileFormVM,
  useSetupMyProfileForm,
} from "frontend-shared/src/users/my-profile-form.vm";
import React, { useEffect, type FC } from "react";
import { useNavigate } from "react-router-dom";
import type { FileLike } from "shared/schemas/file.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { ModalitySelectorForm } from "src/resources/user/hp/modality-selector.form";

type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};
const Input: FC<InputProps> = ({ value, onChange, placeholder }) => {
  return (
    <input
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="border border-vid-black-200 rounded-buttons p-buttons"
    />
  );
};

class Views {
  public readonly FirstNameInput: React.FC;
  public readonly LastNameInput: React.FC;

  constructor(model: MyProfileFormVM) {
    this.FirstNameInput = reflect({
      view: Input,
      bind: {
        value: model.nameVM.$firstName,
        placeholder: "First name",
        onChange: model.nameVM.setFirstName.prepend((e) => e.target.value),
      },
    });

    this.LastNameInput = reflect({
      view: Input,
      bind: {
        value: model.nameVM.$lastName,
        placeholder: "Last name",
        onChange: model.nameVM.setLastName.prepend((e) => e.target.value),
      },
    });
  }
}

const selectPhoto = async (): Promise<FileLike | null> => {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0] || null;
      if (file) {
        resolve({
          size: file.size,
          type: file.type,
          name: file.name,
          lastModified: file.lastModified,
          uri: URL.createObjectURL(file),
          // localFile: file, // Additional property for internal use
        });
      } else {
        resolve(null);
      }
    };

    input.oncancel = () => resolve(null);
    input.click();
  });
};

export const MyProfileForm: React.FC<{
  onSuccessSubmit: () => void;
  button: {
    title: string;
  };
}> = ({ onSuccessSubmit, button }) => {
  const nav = useNavigate();

  const {
    model,
    isSubmitting,
    isDoneSubmitting,
    displayPhotoUrl,
    isProcessing,
  } = useSetupMyProfileForm({
    selectPhoto,
    uploadFileLikeToCloudinary: (file, uploadUrl) =>
      Effect.runPromise(
        FileUploadUtils.uploadFileLikeToCloudinary({
          secureUploadUrl: uploadUrl,
          file,
        })
      ),
  });

  const views = React.useMemo(() => new Views(model), [model]);

  useEffect(() => {
    if (isDoneSubmitting) {
      onSuccessSubmit();
    }
  }, [isDoneSubmitting]);

  if (isSubmitting) {
    return <FullContainerLoadingSpinner />;
  }

  if (isDoneSubmitting) {
    return <></>;
  }

  return (
    <div className="flex flex-col items-center gap-4 w-[544px]">
      <div onClick={() => model.profilePhotoVM.selectMediaRequested("photo")}>
        <div className="flex items-center justify-center">
          <div className="relative">
            {isProcessing ? (
              <FullContainerLoadingSpinner />
            ) : (
              <AvatarCircle
                mbProfilePhoto={
                  displayPhotoUrl ? ImageSrc.fromMbUrl(displayPhotoUrl) : null
                }
                size={125}
              />
            )}
            <div className="absolute bottom-0 right-0 w-[42px] h-[42px] rounded-full bg-white flex justify-center items-center">
              <img src={"/edit.svg"} alt="edit" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <views.FirstNameInput />
        <views.LastNameInput />
      </div>
      <ModalitySelectorForm vm={model.modalitySelectorVM} />
      <PrimaryButton
        title={button.title}
        onClick={() => model.submitFormEvt()}
      />
    </div>
  );
};
