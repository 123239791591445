import { O } from "@webapp/prelude";
import { useConvex, useQuery } from "convex/react";
import { pipe } from "fp-ts/function";
import { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { useOnce } from "frontend-shared/src/util";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { Arr, TE } from "shared/base-prelude";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import { isNotNullOrUndefined } from "shared/util";
import { FullCalendar } from "../../../../../components/calendar/full-calendar.fc";
import { useHpState } from "../../hp.webstatemgr";

export const HpDashboardCalendarPage: React.FC = () => {
  const queryParams = useTypedSearchParams(UROUTES.HP.MY.DASHBOARD.CALENDAR);
  const hpState = useHpState();
  const [_, setSearchParams] = useSearchParams();
  const webConvexReact = useConvex();

  const stateMgr = useOnce(
    () =>
      new CalendarStateMgr({
        fetchApptsTE: (dayInFocus) =>
          pipe(
            TE.fromTask(() =>
              webConvexReact.query(
                api.Screens.Hp.Dashboard.CalendarScreenFns
                  .getNearbyMonthCalendarAppts,
                {
                  hpUserId: hpState.myUserId,
                  now: dayInFocus.getTime(),
                }
              )
            ),
            TE.chainFirst((r) =>
              TE.fromIO(() => {
                console.log("fetchApptsTE result", r);
              })
            )
          ),
      })
  );

  const now = useOnce(() => Date.now());

  const myHcCalInstances = useQuery(
    api.Calendar.HcCalEventFns.getNearbyMonthCalInstances,
    {
      forUserId: hpState.myUserId,
      now,
    }
  );

  useEffect(() => {
    console.log("myHcCalInstances!!! ", myHcCalInstances);
  }, [myHcCalInstances]);

  const mbApptRequestToShow = useQuery(
    api.Calendar.AppointmentRequestFns.getAssumedAppointmentRequestById,
    isNotNullOrUndefined(queryParams[0].requestApptId)
      ? { id: queryParams[0].requestApptId as Id<"appointmentRequests"> }
      : "skip"
  );

  useEffect(() => {
    if (mbApptRequestToShow) {
      hpState.dashboardState.openRightNav({
        _tag: "CUSTOM",
        topView: <div></div>,
        content: <div></div>,
      });
    }
  }, [mbApptRequestToShow]);

  useEffect(() => {
    if (queryParams.length > 0) {
      const [{ action, clientId, clientName, requestApptId }] = queryParams;
      console.log("queryParams", action);

      switch (action) {
        case "new-appointment":
          const client = pipe(
            [clientId, clientName],
            Arr.map(O.fromNullable),
            Arr.sequence(O.option),
            O.map(([clientId, clientName]) => ({
              id: clientId,
              name: clientName,
            })),
            O.toUndefined
          );
          hpState.dashboardState.openRightNav({
            _tag: "NEW_APPOINTMENT",
            client,
            requestApptId,
          });
          break;
        case "view-appointment":
          console.log("view-appointment", requestApptId);
          break;
        default:
          break;
      }
    }
  }, [queryParams]);

  return (
    <FullCalendar
      stateMgr={stateMgr}
      fetchOnMount
      onDayClick={(day) => {
        console.log(day);
      }}
      onApptClick={(appt) => {
        console.log("appt clicked", appt);
      }}
      newApptButton={{
        onClick: () => {
          hpState.dashboardState.openRightNav({
            _tag: "NEW_APPOINTMENT",
            client: undefined,
            requestApptId: undefined,
          });
        },
        title: "New appointment",
      }}
    />
  );
};
