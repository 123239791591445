import type { SessionRSM } from "../../../../remote-state-mgrs/main-room.rsm";

export interface BaseSessionToolStateMgrParams {
  remoteBaseStateMgr: SessionRSM;
}

export class BaseSessionToolStateMgr {
  remoteBaseStateMgr: SessionRSM;

  constructor(params: BaseSessionToolStateMgrParams) {
    this.remoteBaseStateMgr = params.remoteBaseStateMgr;
  }
}
