import type { ConvexClient } from "convex/browser";
import { Match } from "effect";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import {
  CountdownClockState,
  SpecialClockDisplayState,
} from "shared/schemas/session/ongoing/clock.schemas";
import {
  KnownToolTag,
  QuickActionToolsST,
} from "shared/schemas/session/ongoing/known-tool.schemas";
import { BaseSessionParticipantState } from "shared/schemas/session/ongoing/participant/participant-derived.schemas";
import {
  CurrentOpenMenuST,
  MainRoomTitleDisplaySectionST,
  ToolMenuToolsST,
  ToolMenuViewStateST,
  type MenuViewStateST,
} from "shared/schemas/session/ongoing/participant/participant-display.schemas";
import { DisplayableTranscriptST } from "shared/schemas/session/review/transcript.schemas";
import { MeditationStateSchema } from "shared/schemas/session/state/meditation-state.schemas";
import { SessionInfoST } from "shared/schemas/session/state/session-state.schemas";
import { SessionStageLayoutST } from "shared/schemas/session/state/stage-layout.schemas";
import { SharedToolSettings } from "shared/session-state/tools/shared-tool-settings";
import { ChatChannelUserSetup } from "shared/types/chat.types";
import { createContextAndHook } from "../../util";
import { ConvexSessionStateSyncMgr } from "./remote-state-sync.mgr";
import { AnnotationsPanelMgr } from "./sessions/quickaction-tools.rsm";
import { EmdrRSM, MeditationSessionRSM } from "./sessions/tools.rsm";

interface BaseConvexRSMParams {
  convexCli: ConvexClient;
  baseSessionId: Id<"sessionConfig">;
  userId: Id<"users">;
}

class MainRoomRSM {
  convexCli: ConvexClient;
  baseSessionConfigMgr: SessionInfoMgr;

  countdownClockMgr: CountdownClockDisplaySyncMgr;
  specialClockDisplayMgr: SpecialClockDisplaySyncMgr;

  tools: {
    emdr: EmdrRSM;
    meditation: MeditationSessionRSM;
  };
  chat: ChatChannelSetupSyncMgr;

  annotationsPanelMgr: AnnotationsPanelMgr;
  stageLayoutMgr: StageLayoutRSM;

  mainRoomTitleDisplaySectionMgr: MainRoomTitleDisplaySectionMgr;

  participantSyncMgr: BaseSessionParticipantSyncMgr;
  quickActionToolsMgr: QuickActionToolsMgr;
  toolMenuViewStateSyncMgr: ParticipantToolMenuViewStateSyncMgr;

  reviewMgr: ReviewStateSyncMgr;

  constructor(
    readonly p: {
      convexCli: ConvexClient;
      baseSessionId: Id<"sessionConfig">;
      userId: Id<"users">;
      intendedDurationInMinutes: number;
      startsAt: number;
    }
  ) {
    this.convexCli = p.convexCli;

    const baseParams = {
      userId: p.userId,
      baseSessionId: p.baseSessionId,
      convexCli: p.convexCli,
    };

    this.baseSessionConfigMgr = new SessionInfoMgr({
      ...baseParams,
      initialState: SessionInfoST.default({
        baseSessionId: p.baseSessionId,
        intendedDurationInMinutes: p.intendedDurationInMinutes,
        minutesUntilEndReminder: null,
        startsAt: p.startsAt,
        inviteLink: "invalid",
      }).encoded,
      schema: SessionInfoST,
    });

    this.toolMenuViewStateSyncMgr = new ParticipantToolMenuViewStateSyncMgr(
      baseParams
    );

    this.chat = new ChatChannelSetupSyncMgr({
      ...baseParams,
      initialState: ChatChannelUserSetup.default().encoded,
      schema: ChatChannelUserSetup,
    });

    this.countdownClockMgr = new CountdownClockDisplaySyncMgr({
      ...baseParams,
      initialState: CountdownClockState.default,
      schema: CountdownClockState,
    });

    this.stageLayoutMgr = new StageLayoutRSM({
      ...baseParams,
      initialState: SessionStageLayoutST.default(),
      schema: SessionStageLayoutST,
    });

    this.quickActionToolsMgr = new QuickActionToolsMgr({
      ...baseParams,
      initialState: QuickActionToolsST.default().encoded,
      schema: QuickActionToolsST,
    });

    this.specialClockDisplayMgr = new SpecialClockDisplaySyncMgr({
      ...baseParams,
      initialState: SpecialClockDisplayState.default.encoded,
      schema: SpecialClockDisplayState,
    });

    this.mainRoomTitleDisplaySectionMgr = new MainRoomTitleDisplaySectionMgr({
      ...baseParams,
      initialState: MainRoomTitleDisplaySectionST.default(),
      schema: MainRoomTitleDisplaySectionST,
    });

    this.annotationsPanelMgr = new AnnotationsPanelMgr({
      ...baseParams,
    });

    const emdrSyncMgr = new EmdrRSM({
      ...baseParams,
      initialState: SharedToolSettings.Emdr.State.default,
      schema: SharedToolSettings.Emdr.State,
    });

    const meditationSyncMgr = new MeditationSessionRSM({
      ...baseParams,
      initialState: MeditationStateSchema.default.encoded,
      schema: MeditationStateSchema,
    });

    this.reviewMgr = new ReviewStateSyncMgr(baseParams);

    this.tools = {
      emdr: emdrSyncMgr,
      meditation: meditationSyncMgr,
    };
    this.participantSyncMgr = new BaseSessionParticipantSyncMgr(baseParams);
  }

  setMenuViewState(menuViewState: MenuViewStateST): void {
    Match.value(menuViewState.viewState).pipe(
      Match.when({ tag: "TOOLS" }, ({ toolMenuViewState }) => {
        Match.value(toolMenuViewState).pipe(
          Match.when({ tag: "SELECTED_TOOL" }, ({ tool }) => {
            this.toolMenuViewStateSyncMgr.setViewToolMenu(
              ToolMenuViewStateST.from({
                tag: "SELECTED_TOOL",
                tool,
              })
            );
          }),
          Match.when({ tag: "MENU" }, () => {
            this.toolMenuViewStateSyncMgr.setViewToolMenu(
              ToolMenuViewStateST.asMenu()
            );
          }),
          Match.exhaustive
        );
      }),
      Match.when({ tag: "SETTINGS" }, () => {
        this.participantSyncMgr.openMenuMgr.onSettingsMenuClicked();
      }),
      Match.when({ tag: "CLOSED" }, () => {
        this.participantSyncMgr.openMenuMgr.onCloseAnyMenu();
      }),
      Match.exhaustive
    );
  }

  registerEnteredMainRoom = (): void => {
    this.participantSyncMgr.registerEnteredMainRoom();
  };

  registerClickedLeaveSession = (): void => {
    this.participantSyncMgr.registerClickedLeaveSession();
  };

  minimizeTopPreview = (): void => {
    this.stageLayoutMgr.minimizeTopPreview();
  };

  maximizeTopPreview = (): void => {
    this.stageLayoutMgr.maximizeTopPreview();
  };

  subscribeSyncAll = () => {
    this.baseSessionConfigMgr.subscribeSync();
    this.stageLayoutMgr.subscribeSync();
    this.participantSyncMgr.subscribeSync();
    this.quickActionToolsMgr.subscribeSync();
    this.mainRoomTitleDisplaySectionMgr.subscribeSync();
    this.stageLayoutMgr.subscribeSync();
    this.reviewMgr.subscribeSync();
    this.toolMenuViewStateSyncMgr.subscribeSyncAll();
    this.chat.subscribeSync();

    this.countdownClockMgr.subscribeSync();
    this.specialClockDisplayMgr.subscribeSync();

    this.tools.emdr.subscribeSync();
    this.tools.meditation.subscribeSync();

    this.annotationsPanelMgr.subscribeSync();
  };

  disposeAll = () => {
    this.baseSessionConfigMgr.dispose();
    this.stageLayoutMgr.dispose();
    this.participantSyncMgr.dispose();
    this.quickActionToolsMgr.dispose();
    this.mainRoomTitleDisplaySectionMgr.dispose();
    this.stageLayoutMgr.dispose();
    this.reviewMgr.disposeAll();
    this.toolMenuViewStateSyncMgr.disposeAll();
    this.chat.dispose();

    this.countdownClockMgr.dispose();
    this.specialClockDisplayMgr.dispose();

    this.tools.emdr.dispose();
    this.tools.meditation.dispose();

    this.annotationsPanelMgr.disposeAll();
  };
}

export class SessionRSM extends MainRoomRSM {}

export const [SessionRSMProvider, useSessionRSM] =
  createContextAndHook<SessionRSM>();

class SessionInfoMgr extends ConvexSessionStateSyncMgr<
  typeof SessionInfoST.Encoded,
  SessionInfoST
> {
  snapshotQueryFunction =
    api.Sessions.Base.BaseSessionStateFns.publicGetAssumedBaseSession;
  snapshotQueryArgs = () => ({ baseSessionId: this.baseSessionId });

  setIsAudioOnlySession(isAudioOnlySession: boolean): void {
    this.convex.mutation(
      api.Sessions.Base.BaseSessionStateFns.setIsAudioOnlySession,
      {
        baseSessionId: this.baseSessionId as Id<"sessionConfig">,
        isAudioOnlySession,
      }
    );
  }
}

class ChatChannelSetupSyncMgr extends ConvexSessionStateSyncMgr<
  typeof ChatChannelUserSetup.Encoded,
  ChatChannelUserSetup
> {
  snapshotQueryFunction = api.Sessions.Base.SessionFns.getUserChatChannelSetup;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
    userId: this.userId,
  });
}

export class CountdownClockDisplaySyncMgr extends ConvexSessionStateSyncMgr<
  typeof CountdownClockState.Encoded,
  CountdownClockState
> {
  snapshotQueryFunction =
    api.Sessions.Participants.Views.ClockDisplayFns
      .getMainCountdownClockDisplayState;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
  schema = CountdownClockState;
}

export class SpecialClockDisplaySyncMgr extends ConvexSessionStateSyncMgr<
  typeof SpecialClockDisplayState.Encoded,
  SpecialClockDisplayState
> {
  snapshotQueryFunction =
    api.Sessions.Participants.Views.ClockDisplayFns.getSpecialClockDisplayState;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
  schema = SpecialClockDisplayState;
}

export class ReviewStateSyncMgr {
  transcriptMgr: TranscriptMgr;

  constructor(p: BaseConvexRSMParams) {
    this.transcriptMgr = new TranscriptMgr({
      ...p,
      initialState: DisplayableTranscriptST.default().encoded,
      schema: DisplayableTranscriptST,
    });
  }

  subscribeSync = () => {
    this.transcriptMgr.subscribeSync();
  };

  disposeAll = () => {
    this.transcriptMgr.dispose();
  };
}

class QuickActionToolsMgr extends ConvexSessionStateSyncMgr<
  typeof QuickActionToolsST.Encoded,
  QuickActionToolsST
> {
  snapshotQueryFunction =
    api.Sessions.Participants.ParticipantDisplayFns.getQuickActionTools;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
}

class TranscriptMgr extends ConvexSessionStateSyncMgr<
  typeof DisplayableTranscriptST.Encoded,
  DisplayableTranscriptST
> {
  snapshotQueryFunction =
    api.Sessions.Base.Transcript.TranscriptFns.getRunningProcessedTranscript;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
}

class MainRoomTitleDisplaySectionMgr extends ConvexSessionStateSyncMgr<
  typeof MainRoomTitleDisplaySectionST.Encoded,
  MainRoomTitleDisplaySectionST
> {
  snapshotQueryFunction =
    api.Sessions.Participants.Views.MainRoomFns.getTitleDisplaySection;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
}

class BaseSessionParticipantSyncMgr extends ConvexSessionStateSyncMgr<
  typeof BaseSessionParticipantState.Encoded,
  BaseSessionParticipantState
> {
  snapshotQueryFunction =
    api.Sessions.Participants.ParticipantFns.getAssumedParticipant;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });

  openMenuMgr: ParticipantOpenMenuStateSyncMgr;

  constructor(p: BaseConvexRSMParams) {
    super({
      ...p,
      initialState: BaseSessionParticipantState.default({
        baseUserId: p.userId as Id<"users">,
        toolMenuTools: [],
      }).encoded,
      schema: BaseSessionParticipantState,
    });

    this.openMenuMgr = new ParticipantOpenMenuStateSyncMgr({
      ...p,
      initialState: CurrentOpenMenuST.default(),
      schema: CurrentOpenMenuST,
    });
  }

  registerEnteredAdmissionRoom = () => {
    this.convex
      .mutation(
        api.Sessions.Participants.ParticipantAttendanceFns
          .setUserEnteredAdmissionRoom,
        {
          baseSessionId: this.baseSessionId,
          now: Date.now(),
        }
      )
      .catch((e) => {
        console.error("Error setting user entered admission room", e);
      });
  };

  registerEnteredMainRoom = () => {
    this.convex
      .mutation(
        api.Sessions.Participants.ParticipantAttendanceFns
          .setUserEnteredMainRoom,
        {
          baseSessionId: this.baseSessionId,
          now: Date.now(),
        }
      )
      .catch((e) => {
        console.error("Error setting user entered main room", e);
      });
  };

  registerEnteredWaitingRoom = () => {
    this.convex
      .mutation(
        api.Sessions.Participants.ParticipantAttendanceFns
          .setUserEnteredWaitingRoom,
        {
          baseSessionId: this.baseSessionId,
          now: Date.now(),
        }
      )
      .catch((e) => {
        console.error("Error setting user entered waiting room", e);
      });
  };

  registerClickedLeaveSession = () => {
    this.convex
      .mutation(api.Sessions.Participants.Views.MainRoomFns.onCloseCallClick, {
        baseSessionId: this.baseSessionId,
      })
      .catch((e) => {
        console.error("Error setting user left main room", e);
      });
  };

  setIsBackgroundBlurred(isBackgroundBlurred: boolean): void {
    this.convex.mutation(
      api.Sessions.Participants.ParticipantDisplayFns.setIsBackgroundBlurred,
      {
        baseSessionId: this.baseSessionId,
        isBackgroundBlurred,
      }
    );
  }
}

class ToolMenuToolsSyncMgr extends ConvexSessionStateSyncMgr<
  typeof ToolMenuToolsST.Encoded,
  ToolMenuToolsST
> {
  snapshotQueryFunction =
    api.Sessions.Participants.Views.MainRoomFns.getToolMenuTools;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
}

export class ParticipantToolMenuViewStateSyncMgr extends ConvexSessionStateSyncMgr<
  typeof ToolMenuViewStateST.Encoded,
  ToolMenuViewStateST
> {
  snapshotQueryFunction =
    api.Sessions.Participants.Views.MainRoomFns.getCurrentToolMenuViewState;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
  schema = ToolMenuViewStateST;

  toolsSyncMgr: ToolMenuToolsSyncMgr;

  constructor(p: BaseConvexRSMParams) {
    super({
      ...p,
      initialState: ToolMenuViewStateST.default(),
      schema: ToolMenuViewStateST,
    });

    this.toolsSyncMgr = new ToolMenuToolsSyncMgr({
      ...p,
      initialState: ToolMenuToolsST.from({ tools: [] }).encoded,
      schema: ToolMenuToolsST,
    });
  }

  subscribeSyncAll = () => {
    this.toolsSyncMgr.subscribeSync();
    this.subscribeSync();
  };

  disposeAll = () => {
    this.toolsSyncMgr.dispose();
    this.dispose();
  };

  private runSetToolMenuViewState(tmvs: ToolMenuViewStateST): void {
    this.optimisticSetState({
      partialNewState: { viewState: tmvs.viewState },
      mutationFn:
        api.Sessions.Participants.Views.MainRoomFns.setToolMenuViewState,
      mutationArgs: {
        baseSessionId: this.baseSessionId,
        viewState: tmvs.viewState,
      },
    });
  }

  setViewToolMenu(tmvs: ToolMenuViewStateST): void {
    this.runSetToolMenuViewState(tmvs);
  }

  onBackToToolMenu = () => {
    this.runSetToolMenuViewState(ToolMenuViewStateST.asMenu());
  };

  onToolClicked(tool: KnownToolTag): void {
    this.runSetToolMenuViewState(
      ToolMenuViewStateST.from({
        tag: "SELECTED_TOOL",
        tool,
      })
    );
  }
}

export class ParticipantOpenMenuStateSyncMgr extends ConvexSessionStateSyncMgr<
  typeof CurrentOpenMenuST.Encoded,
  CurrentOpenMenuST
> {
  snapshotQueryFunction =
    api.Sessions.Participants.Views.MainRoomFns.getCurrentOpenMenuViewState;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });
  schema = CurrentOpenMenuST;

  onSettingsMenuClicked = () => {
    this.optimisticSetState({
      partialNewState: { viewState: "SETTINGS" },
      mutationFn:
        api.Sessions.Participants.Views.MainRoomFns.onOpenSettingsMenu,
      mutationArgs: {
        baseSessionId: this.baseSessionId,
      },
    });
  };

  onToolsMenuClicked = () => {
    this.optimisticSetState({
      partialNewState: { viewState: "TOOLS" },
      mutationFn: api.Sessions.Participants.Views.MainRoomFns.onOpenToolsMenu,
      mutationArgs: {
        baseSessionId: this.baseSessionId,
      },
    });
  };

  onCloseAnyMenu = () => {
    console.log("onCloseAnyMenu");
    this.optimisticSetState({
      partialNewState: { viewState: null },
      mutationFn:
        api.Sessions.Participants.Views.MainRoomFns.onCloseCurrentOpenMenu,
      mutationArgs: {
        baseSessionId: this.baseSessionId,
      },
    });
  };
}

class StageLayoutRSM extends ConvexSessionStateSyncMgr<
  typeof SessionStageLayoutST.Encoded,
  SessionStageLayoutST
> {
  snapshotQueryFunction =
    api.Sessions.Participants.ParticipantFns.getDerivedSessionStageState;
  snapshotQueryArgs = () => ({
    baseSessionId: this.baseSessionId,
  });

  closeContentViewForAllParticipants = () => {
    this.convex
      .mutation(
        api.Sessions.Participants.ParticipantFns.onUserClickedCloseTopPreview,
        {
          baseSessionId: this.baseSessionId,
        }
      )
      .catch();
  };

  minimizeTopPreview = () => {
    this.convex.mutation(
      api.Sessions.Participants.ParticipantDisplayFns.setContentDisplayMode,
      {
        baseSessionId: this.baseSessionId,
        contentDisplayMode: "TOP_PREVIEW",
      }
    );
  };

  maximizeTopPreview = () => {
    this.convex.mutation(
      api.Sessions.Participants.ParticipantDisplayFns.setContentDisplayMode,
      {
        baseSessionId: this.baseSessionId,
        contentDisplayMode: "FULL_SCREEN",
      }
    );
  };
}
