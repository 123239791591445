import { apiMgr } from "@webapp/backend";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Rx, RxO } from "@webapp/prelude";
import {
  FileDownloadUtils,
  FileUploadUtils,
  FileUtils,
} from "@webapp/utils/file.utils";
import type { ConvexClient } from "convex/browser";
import { Effect, Option } from "effect";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import { BaseStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { PreprocessedMediaStateMgr } from "frontend-shared/src/mgrs/state-mgrs/preprocessed-media.statemgr";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useMemo, useState } from "react";
import Cropper, { type Area } from "react-easy-crop";
import { FileSchemas, InMemoryFile } from "shared/schemas/file.schemas";
import { KnownCloudinaryPtrs } from "shared/schemas/known-remote-file-ptrs/known-cloudinary-ptrs.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { useConvexCli } from "src/convex-cli";
import { getCroppedImg } from "../utils/image.utils";

export const DefaultAvatar: React.FC<{ size?: number }> = ({ size }) => {
  const d = size ?? 45;
  return (
    <div
      className="bg-vid-black-200 rounded-full p-2 flex flex-col justify-center items-center "
      style={{
        width: d,
        height: d,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        fill="none"
        viewBox={`0 0 45 56`}
      >
        <path
          stroke="#AD99FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M22.5 23a9.375 9.375 0 100-18.75 9.375 9.375 0 100 18.75zM38.606 41.75c0-7.256-7.219-13.125-16.106-13.125-8.888 0-16.106 5.869-16.106 13.125"
        ></path>
      </svg>
    </div>
  );
};

export const AvatarCircle: React.FC<{
  mbProfilePhoto: ImageSrc | null;
  size?: number;
}> = ({ mbProfilePhoto, size = 30 }) => {
  if (mbProfilePhoto) {
    return (
      <img
        src={mbProfilePhoto?.src}
        alt="profile photo"
        className={`rounded-full object-cover max-h-[100%] aspect-square min-w-[40px] min-h-[40px]`}
        style={{
          width: size ?? "100%",
          height: size ?? "100%",
        }}
      />
    );
  }
  return <DefaultAvatar size={size} />;
};

export const AvatarCircles: React.FC<{
  sources: (string | null)[];
  size?: number;
}> = ({ sources, size }) => {
  return (
    <div className="flex items-center gap-2">
      {sources.map((s, index) => (
        <div
          key={index}
          className={`rounded-full ${index > 0 ? "-ml-4" : ""}`}
          style={{
            width: size ?? 44,
            height: size ?? 44,
          }}
        >
          {s ? (
            <img
              src={s}
              alt={`avatar-${index}`}
              className="rounded-full object-cover w-full h-full"
            />
          ) : (
            <DefaultAvatar size={size ?? 44} />
          )}
        </div>
      ))}
    </div>
  );
};

type ProfileImageSelectorProps = {
  currentMbProfile: ImageSrc | null;
  size?: number;
};

type CroppableProfileImageSelectorProps = ProfileImageSelectorProps & {
  onCropActionComplete: (p: InMemoryFile) => void;
};

export const AvatarCirclesWithCountInfo: React.FC<{
  sources: (string | null)[];
  countText: string;
  size?: number;
}> = ({ sources, countText, size }) => {
  return (
    <div className="flex gap-2 p-1 my-auto text-sm leading-4 bg-slate-50 rounded-full text-zinc-700 cursor-pointer">
      <AvatarCircles sources={sources} size={size ?? 40} />
      <div className="grow my-auto">{`${countText}`}</div>
    </div>
  );
};

export const CroppableProfileImageSelector: React.FC<
  CroppableProfileImageSelectorProps
> = ({ currentMbProfile, size, onCropActionComplete }) => {
  const [mbCropperImage, setMbCropperImage] = useState<ImageSrc | null>(
    currentMbProfile
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [showCropper, setShowCropper] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [fileType, setFileType] = useState<FileSchemas.MimeType | null>(null);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <div className="flex items-center justify-center ">
      <label htmlFor="profileImage" className="cursor-pointer">
        <div className="relative">
          <AvatarCircle mbProfilePhoto={currentMbProfile} size={size ?? 125} />
          <div className="absolute bottom-0 right-0 w-[42px] h-[42px] rounded-full bg-white flex justify-center items-center">
            <img src={"/edit.svg"} alt="edit" />
          </div>
        </div>
        <input
          id="profileImage"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={(e) => {
            const file = e.target.files ? e.target.files[0] : null;
            console.log("file", file);
            if (file) {
              const fileMetadata =
                FileSchemas.FileMetadata.unsafeDecodeFromFile(file);
              const mimeType = FileSchemas.MimeType.fromString(file.type);
              setFileType(mimeType); // Store the file type
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64String = reader.result as string;
                if (base64String) {
                  setMbCropperImage(
                    new ImageSrc({
                      _tag: "InMemoryFile",
                      file: { base64String, fileMetadata },
                    })
                  );
                  setShowCropper(true);
                }
              };
              reader.readAsDataURL(file);
            }
          }}
        />
      </label>
      {showCropper && mbCropperImage && (
        <div className="absolute inset-0 justify-center items-center bg-vid-black-900 z-50">
          <div className="flex flex-col gap-2 bg-white p-8">
            <Cropper
              image={mbCropperImage?.src}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              aspect={1}
            />
            <div className="flex justify-center items-center gap-4 absolute bottom-0 right-0 left-0 h-[100px] bg-white">
              <button className="text-2xl" onClick={() => setZoom(zoom - 0.1)}>
                -
              </button>
              <button
                className="text-2xl cursor-pointer"
                onClick={() => {
                  const newZoom = zoom + 0.1;
                  console.log("newZoom", newZoom);
                  setZoom(newZoom);
                }}
              >
                +
              </button>
              <button
                className="text-2xl cursor-pointer"
                onClick={() => {
                  if (croppedAreaPixels) {
                    getCroppedImg(mbCropperImage, croppedAreaPixels).then(
                      (blob) => {
                        setShowCropper(false);
                        const mbFileMetadata =
                          FileSchemas.FileMetadata.decodeOptionFromFile(blob);
                        if (!fileType) {
                          return;
                        }
                        if (Option.isNone(mbFileMetadata)) {
                          return;
                        }
                        const fileMetadata = mbFileMetadata.value;
                        const inMemoryFile: InMemoryFile = {
                          base64String: URL.createObjectURL(blob),
                          fileMetadata,
                        };
                        setMbCropperImage(
                          new ImageSrc({
                            _tag: "InMemoryFile",
                            file: inMemoryFile,
                          })
                        );
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result as string;
                          onCropActionComplete({
                            base64String,
                            fileMetadata,
                          }); // Pass both base64 and file type
                        };
                        reader.readAsDataURL(blob);
                      }
                    );
                  }
                }}
              >
                Crop
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

class ProfileImageSelectorStateMgr extends BaseStateMgr {
  locallyUploadedImage$ = new Rx.BehaviorSubject<InMemoryFile | null>(null);

  preprocessedMediaStateMgr = new PreprocessedMediaStateMgr();

  imageToDisplay$: Rx.Observable<ImageSrc | null>;

  onImageSuccessfullyTransformed: (p: {
    inMemoryFile: InMemoryFile;
    publicId: string;
    assetId: string;
  }) => void;

  constructor(
    readonly p: {
      apiMgr: ApiMgr;
      mbInitialImage: ImageSrc | null;
      convex: ConvexClient;
      onImageSuccessfullyTransformed: (p: {
        inMemoryFile: InMemoryFile;
        publicId: string;
        assetId: string;
      }) => void;
    }
  ) {
    super({ apiMgr: p.apiMgr, convex: p.convex });
    this.onImageSuccessfullyTransformed = p.onImageSuccessfullyTransformed;

    console.log("p.mbInitialImage", p.mbInitialImage);

    this.imageToDisplay$ = Rx.combineLatest([
      Rx.of(p.mbInitialImage),
      this.preprocessedMediaStateMgr.mbInMemoryFileToShow$,
      this.locallyUploadedImage$,
    ]).pipe(
      RxO.map(
        ([mbInitialImage, mbInMemoryFileToShow, locallyUploadedImage]) => {
          console.log(
            "DETERMING IMAGE TO DISPLAY! ",
            mbInMemoryFileToShow,
            locallyUploadedImage,
            mbInitialImage
          );
          return Option.firstSomeOf([
            mbInMemoryFileToShow.pipe(
              Option.map((p) => ImageSrc.fromInMemoryFile(p))
            ),
            Option.fromNullable(locallyUploadedImage).pipe(
              Option.map((p) => ImageSrc.fromInMemoryFile(p))
            ),
            Option.fromNullable(mbInitialImage),
          ]).pipe(Option.getOrNull);
        }
      )
    );
  }

  handleImageSelectedEff = (imgSrc: ImageSrc) => {
    return Effect.gen(this, function* () {
      const inMemoryFile =
        yield* FileDownloadUtils.imgSrcAsInMemoryFile(imgSrc);
      return yield* this.startProcessingInMemoryFile(inMemoryFile);
    });
  };

  handleImageFileSelectedEff = (file: File) => {
    return Effect.gen(this, function* () {
      const inMemoryFile = yield* FileUtils.fileAsInMemoryFile(file);
      return yield* this.startProcessingInMemoryFile(inMemoryFile);
    });
  };

  startProcessingInMemoryFile = (inMemoryFile: InMemoryFile) =>
    Effect.gen(this, function* () {
      this.preprocessedMediaStateMgr.setProcessing({ inMemoryFile });

      const { presignedUploadUrl } = yield* this.BE.fetchSuccessOnlyEndpoint(
        (Api) => Api.u.me.getProfilePhotoUploadUrl.mutate()
      );

      const uploadToCloudinaryRes =
        yield* FileUploadUtils.uploadInMemoryFileToCloudinary({
          secureUploadUrl: presignedUploadUrl,
          file: inMemoryFile,
        });

      console.log("uploadToCloudinaryRes! ", uploadToCloudinaryRes);

      const { presignedDownloadUrl } = yield* this.BE.fetchSuccessOnlyEndpoint(
        (Api) =>
          Api.u.me.getProfilePhotoDownloadUrl.query({
            fromCloudinaryInfo: {
              publicId: uploadToCloudinaryRes.public_id,
              assetId: uploadToCloudinaryRes.asset_id,
            },
          })
      );

      console.log("presignedDownloadUrl! ", { presignedDownloadUrl });

      const inMemoryDownloadedFile = yield* FileDownloadUtils.downloadFile({
        presignedDownloadUrl: uploadToCloudinaryRes.secure_url,
      });

      this.preprocessedMediaStateMgr.setProcessed({
        uploadResult: uploadToCloudinaryRes,
        originalFileMetadata: inMemoryDownloadedFile.fileMetadata,
        confirmedUploadedPtrEncoding:
          new KnownCloudinaryPtrs.ProfilePhotoThumbVirtualPtr({
            public_id: uploadToCloudinaryRes.public_id,
            asset_id: uploadToCloudinaryRes.asset_id,
            mediaType: "image",
          }).encodeSelf(),
        inMemoryFile: inMemoryDownloadedFile,
      });

      return {
        inMemoryFile: inMemoryDownloadedFile,
        publicId: uploadToCloudinaryRes.public_id,
        assetId: uploadToCloudinaryRes.asset_id,
      };
    });

  onImageSelected = (file: File) => {
    Effect.runPromise(this.handleImageFileSelectedEff(file)).then((res) => {
      console.log("res! ", res);
      this.onImageSuccessfullyTransformed(res);
    });
  };
}

type ProfileImageViewProps = {
  isProcessingImage: boolean;
  locallyUploadedImage: ImageSrc | null;
  size?: number;
  onFileSelected: (file: File) => void;
};

export const ProfileImageView: React.FC<ProfileImageViewProps> = ({
  isProcessingImage,
  locallyUploadedImage,
  size,
  onFileSelected,
}) => {
  return (
    <div className="flex items-center justify-center">
      <label htmlFor="profileImage" className="cursor-pointer">
        <div className="relative">
          {isProcessingImage ? (
            <FullContainerLoadingSpinner />
          ) : (
            <AvatarCircle
              mbProfilePhoto={locallyUploadedImage}
              size={size ?? 125}
            />
          )}
          <div className="absolute bottom-0 right-0 w-[42px] h-[42px] rounded-full bg-white flex justify-center items-center">
            <img src={"/edit.svg"} alt="edit" />
          </div>
        </div>
        <input
          id="profileImage"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={(e) => {
            const file = e.target.files ? e.target.files[0] : null;
            if (file) {
              onFileSelected(file);
            }
          }}
        />
      </label>
    </div>
  );
};

export const ProfileImageSelector: React.FC<
  ProfileImageSelectorProps & {
    onImageSelectedAndTransformed: (p: {
      inMemoryFile: InMemoryFile;
      publicId: string;
      assetId: string;
    }) => void;
    autoTransform?: { imgSrc: ImageSrc };
  }
> = ({
  currentMbProfile,
  size,
  onImageSelectedAndTransformed,
  autoTransform,
}) => {
  const convex = useConvexCli();
  const stateMgr = useMemo(
    () =>
      new ProfileImageSelectorStateMgr({
        apiMgr: apiMgr,
        convex,
        mbInitialImage: currentMbProfile,
        onImageSuccessfullyTransformed: onImageSelectedAndTransformed,
      }),
    [currentMbProfile]
  );

  const locallyUploadedImage = useObservableEagerState(
    stateMgr.imageToDisplay$
  );

  const isProcessingImage = useObservableEagerState(
    stateMgr.preprocessedMediaStateMgr.isProcessing$
  );

  useEffect(() => {
    if (autoTransform) {
      Effect.runPromise(
        stateMgr.handleImageSelectedEff(autoTransform.imgSrc)
      ).then((res) => {
        onImageSelectedAndTransformed(res);
      });
    }
  }, [currentMbProfile, autoTransform]);

  return (
    <ProfileImageView
      isProcessingImage={isProcessingImage}
      locallyUploadedImage={locallyUploadedImage}
      size={size}
      onFileSelected={stateMgr.onImageSelected}
    />
  );
};
