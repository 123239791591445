import { FormDisclosureContainer } from "@webapp/componentsdisclosures";
import { addDays, endOfWeek, format, startOfWeek } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { createContextAndHook } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useMemo, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { Rx, RxO } from "../../prelude";

type ViewState = "DAY_VIEW" | "CLOCK";

interface Day {
  month: number;
  day: number;
  year: number;
}

function dateToDay(dt: Date): Day {
  return {
    month: dt.getMonth(),
    day: dt.getDate(),
    year: dt.getFullYear(),
  };
}

export class CalendarInputStateMgr {
  currentMonthView$: BehaviorSubject<number>;
  selectedDay$: BehaviorSubject<Day | null>;
  selectedHour$: BehaviorSubject<number | null>;
  selectedMinute$: BehaviorSubject<number | null>;
  selectedDateAsUtc$: Rx.Observable<Date | null>;
  daysForCurrentMonth$: BehaviorSubject<Date[]>;
  viewState$: BehaviorSubject<ViewState>; // Adding view state management

  currentSelectedDate = () => {
    const selectedDay = this.selectedDay$.value;
    const selectedHour = this.selectedHour$.value;
    const selectedMinute = this.selectedMinute$.value;

    console.log("selectedDay", selectedDay);
    console.log("selectedHour", selectedHour);
    console.log("selectedMinute", selectedMinute);

    if (
      selectedDay === null ||
      selectedHour === null ||
      selectedMinute === null
    ) {
      return null;
    }
    return new Date(
      selectedDay.year,
      selectedDay.month,
      selectedDay.day,
      selectedHour,
      selectedMinute
    );
  };

  constructor(
    readonly onSelection: (dt: Date) => void,
    p?: { initialDate: Date }
  ) {
    const initialDate = p?.initialDate ?? new Date();
    this.currentMonthView$ = new BehaviorSubject(initialDate.getMonth());
    this.selectedDay$ = new BehaviorSubject<Day | null>(dateToDay(initialDate));
    this.selectedHour$ = new BehaviorSubject<number | null>(null);
    this.selectedMinute$ = new BehaviorSubject<number | null>(null);
    this.daysForCurrentMonth$ = new BehaviorSubject(
      this.calculateDaysForMonth(initialDate.getMonth())
    );
    this.viewState$ = new BehaviorSubject<ViewState>("DAY_VIEW"); // Default to day view

    this.selectedDateAsUtc$ = Rx.combineLatest([
      this.selectedDay$,
      this.selectedHour$,
      this.selectedMinute$,
    ]).pipe(
      RxO.map(([day, hour, minute]) => {
        return this.selectedDateUtc({ day, hour, minute });
      })
    );

    this.selectedDateAsUtc$.subscribe((dt) => {
      if (dt) {
        this.onSelection(dt);
      }
    });
  }

  private selectedDateUtc = (p: {
    day: Day | null;
    hour: number | null;
    minute: number | null;
  }) => {
    if (p.day === null || p.hour === null || p.minute === null) return null;
    const dt = new Date(p.day.year, p.day.month, p.day.day, p.hour, p.minute);
    return fromZonedTime(dt, Intl.DateTimeFormat().resolvedOptions().timeZone);
  };

  monthAsString(monthNumber: number): string {
    return format(new Date(new Date().getFullYear(), monthNumber), "MMMM");
  }

  calculateDaysForMonth(month: number): Date[] {
    const year = new Date().getFullYear(); // Adjust this as necessary
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    // Find the start and end of the week for the current month view
    const startDay = startOfWeek(firstDayOfMonth);
    const endDay = endOfWeek(lastDayOfMonth);

    // Fill in the days for the current view
    const daysArray: Date[] = [];
    for (let day = startDay; day <= endDay; day = addDays(day, 1)) {
      daysArray.push(day);
    }

    return daysArray;
  }

  selectDay(day: Day): void {
    this.selectedDay$.next(day);
    this.viewState$.next("CLOCK");
  }

  selectHour(hour: number): void {
    this.selectedHour$.next(hour);
  }

  selectMinute(minute: number): void {
    this.selectedMinute$.next(minute);
    this.onSelection(this.currentSelectedDate()!);
  }

  closeCalendar(): void {
    this.viewState$.next("DAY_VIEW"); // Reset to day view or handle as needed
  }

  onNextMonthArrowClick = (): void => {
    const nextMonth = this.currentMonthView$.value + 1;
    this.currentMonthView$.next(nextMonth);
    this.daysForCurrentMonth$.next(this.calculateDaysForMonth(nextMonth));
  };

  onPrevMonthArrowClick = (): void => {
    const prevMonth = this.currentMonthView$.value - 1;
    this.currentMonthView$.next(prevMonth);
    this.daysForCurrentMonth$.next(this.calculateDaysForMonth(prevMonth));
  };
}

const [CalendarInputStateContext, useCalendarInputState] =
  createContextAndHook<CalendarInputStateMgr>();

export const CalendarInput: React.FC<{ onSelection: (dt: Date) => void }> = ({
  onSelection,
}) => {
  const stateMgr = useMemo(
    () => new CalendarInputStateMgr(onSelection),
    [onSelection]
  );
  const viewState = useObservableEagerState(stateMgr.viewState$);
  return (
    <CalendarInputStateContext.Provider value={stateMgr}>
      {viewState === "DAY_VIEW" ? <DayView /> : <ClockView />}
    </CalendarInputStateContext.Provider>
  );
};

const DayView: React.FC = () => {
  const stateMgr = useCalendarInputState();
  const currentMonthInView = useObservableEagerState(
    stateMgr.currentMonthView$
  );

  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-between w-full p-4">
        <button
          className="p-2 bg-gray-200 rounded hover:bg-gray-300"
          onClick={stateMgr.onPrevMonthArrowClick}
        >
          &lt;
        </button>
        <span>{stateMgr.monthAsString(currentMonthInView)}</span>
        <button
          className="p-2 bg-gray-200 rounded hover:bg-gray-300"
          onClick={stateMgr.onNextMonthArrowClick}
        >
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 text-sm font-medium text-center p-4">
        {["M", "T", "W", "T", "F", "S", "S"].map((day) => (
          <div key={day}>{day}</div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-1 p-4">
        {stateMgr.daysForCurrentMonth$.value.map((day, index) => (
          <div
            key={index}
            className={`p-4 border-b border-r cursor-pointer ${
              day.getMonth() === currentMonthInView
                ? "bg-gray-100"
                : "text-gray-400 bg-gray-50"
            }`}
            onClick={() => stateMgr.selectDay(dateToDay(day))}
          >
            {day.getDate()}
          </div>
        ))}
      </div>
    </div>
  );
};

const ClockView: React.FC = () => {
  const stateMgr = useCalendarInputState();
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 12 }, (_, i) => i * 5);

  const selectedHour = useObservableEagerState(stateMgr.selectedHour$);
  const selectedMinute = useObservableEagerState(stateMgr.selectedMinute$);

  return (
    <div className="flex gap-4 p-4 bg-white rounded-lg">
      <div className="flex flex-col items-center">
        <span className="text-lg font-semibold mb-2">Hours</span>
        <div className="overflow-y-auto h-64 w-20 bg-gray-100 rounded-lg p-2">
          {hours.map((hour) => (
            <div
              key={hour}
              className={`h-6 flex items-center justify-center cursor-pointer
                ${hour === selectedHour ? "bg-blue-100" : ""}
                `}
              onClick={() => stateMgr.selectHour(hour)}
            >
              {hour}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <span className="text-lg font-semibold mb-2">Minutes</span>
        <div className="overflow-y-auto h-64 w-20 bg-gray-100 rounded-lg p-2">
          {minutes.map((minute) => (
            <div
              key={minute}
              className={`
              h-6 flex items-center justify-center cursor-pointer
              ${minute === selectedMinute ? "bg-blue-100" : ""}
            `}
              onClick={() => stateMgr.selectMinute(minute)}
            >
              {minute}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const CalendarMenuInput: React.FC<{
  title: string;
  onSelection: (dt: Date) => void;
  initialDate?: Date;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}> = ({ onSelection, initialDate, title, isOpen, onToggle }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initialDate ?? null
  );
  return (
    <FormDisclosureContainer
      isOpen={isOpen}
      onToggle={onToggle}
      selection={selectedDate}
      buttonView={{
        _tag: "DEFAULT",
        label: title,
        selectedView: (dt) => ({
          _tag: "TEXT",
          text: `${dt ? format(dt, "P p") : ""}`,
        }),
      }}
      dropdownView={(close) => (
        <CalendarInput
          onSelection={(dt) => {
            setSelectedDate(dt);
            onSelection(dt);
            close();
          }}
        />
      )}
    />
  );
};
