import {
  useBackgroundFilters,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { CopyLinkButton } from "@webapp/componentsmiscellaneous";
import { Match } from "effect";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import { useEffect, useMemo, useState } from "react";
import scheduleNextIcon from "../../../public/schedule-next-icon.svg";
import bookmarkAddIcon from "../../assets/icons/bookmark-add.png";
import { InsightLiveLogoWithText } from "../../assets/il-logo-with-text.fc";
import { Rx } from "../../prelude";

export module LiveSessionComponents {
  export const MutedIcon: React.FC = () => {
    return (
      <div className="bg-gray-800 text-white px-4 py-2 rounded-full flex items-center">
        <svg
          className="w-4 h-4 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
            clipRule="evenodd"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2"
          />
        </svg>
        <span>Muted</span>
      </div>
    );
  };

  export module Notifications {
    export const DisplayTimeLeft: React.FC<{ message: string }> = ({
      message,
    }) => {
      return <div className="bg-blue-200 px-2 py-2 rounded-md">{message}</div>;
    };
  }

  interface CountdownTimerProps {
    initialMinutes: number;
    initialSeconds: number;
  }

  export const CountdownTimer: React.FC<CountdownTimerProps> = ({
    initialMinutes,
    initialSeconds,
  }) => {
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
      let interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [minutes, seconds]);

    return (
      <div>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </div>
    );
  };

  export namespace BottomLeftButtons {
    type ViewState = "collapsed" | "expanded" | "success";

    const ContainerView: React.FC<{
      icon: React.ReactNode;
      viewState$: Rx.BehaviorSubject<ViewState>;
      expandedContent: React.ReactNode;
      expandedButton: {
        title: string;
        onClick: () => void;
        width?: string;
      };
      successMessage: string;
      flashSignal$?: Rx.Subject<boolean>;
    }> = ({
      icon,
      viewState$,
      flashSignal$,
      expandedContent,
      expandedButton,
      successMessage,
    }) => {
      const viewState = useObservableEagerState(viewState$);
      const isFlashing$ = useMemo(
        () => flashSignal$ ?? new Rx.Subject<boolean>(),
        [flashSignal$]
      );
      const isFlashing = useObservableState(isFlashing$, false);

      useEffect(() => {
        if (isFlashing) {
          const timeout = setTimeout(() => {
            isFlashing$.next(false);
          }, 10000);
          return () => clearTimeout(timeout);
        }
      }, [isFlashing]);

      useEffect(() => {
        if (viewState === "success") {
          setTimeout(() => {
            viewState$.next("collapsed");
          }, 1800);
        }
      }, [viewState]);

      const roundIcon = () => (
        <div
          className={`w-[55px] aspect-square rounded-full flex justify-center items-center cursor-pointer
            ${isFlashing ? "animate-pulse bg-purple-500" : ""}
            `}
          onClick={() => {
            console.log("VIEW STATE! ", viewState);
            if (viewState === "expanded") {
              console.log("SETTING VIEW STATE TO COLLAPSED! ");
              viewState$.next("collapsed");
            } else {
              viewState$.next("expanded");
            }
          }}
        >
          <div className="w-[18px] aspect-square">
            {viewState === "success" ? (
              <img src="/bookmark-success-check.svg" />
            ) : (
              icon
            )}
          </div>
        </div>
      );

      return (
        <div
          className={`
       rounded-full text-white flex 
      border border-vid-black-800 bg-transparent-black
      h-[55px] ${viewState !== "collapsed" ? "pr-6" : "aspect-square"}
      `}
        >
          {roundIcon()}
          {Match.value(viewState).pipe(
            Match.when("collapsed", () => <></>),
            Match.when("expanded", () => (
              <div className="flex-1 flex gap-4 py-[7.5px]">
                <div className="flex items-center">{expandedContent}</div>
                <div className="self-stretch ">
                  <ActionButton {...expandedButton} />
                </div>
              </div>
            )),
            Match.when("success", () => (
              <div className="w-[180px] flex items-center">
                {successMessage}
              </div>
            )),
            Match.exhaustive
          )}
        </div>
      );
    };

    export const ToggleNavButton: React.FC<{
      onClick: () => void;
      arrowDirection: "left" | "right";
    }> = ({ onClick, arrowDirection }) => {
      return (
        <div
          className={`
     rounded-full text-white flex 
    border border-vid-black-800 bg-transparent-black
    h-[55px] 
    `}
        >
          <div
            className={`w-[55px] aspect-square rounded-full flex justify-center items-center cursor-pointer`}
            onClick={() => {
              onClick();
            }}
          >
            <div className="w-[18px] aspect-square">
              {arrowDirection === "left" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M11 5L6 10.5L11 16"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M9 16L14 10.5L9 5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      );
    };

    const ActionButton: React.FC<{
      title: string;
      onClick: () => void;
      width?: string;
    }> = ({ title, onClick }) => {
      return (
        <button
          className={`
          h-full min-h-0 min-w-0
          bg-white text-vid-black-900 px-[26px] rounded-full flex justify-center items-center font-medium
          w-[180px]
          `}
          onClick={onClick}
        >
          {title}
        </button>
      );
    };

    export const NextScheduleReminder: React.FC<{
      onScheduleClick: () => void;
      onDismissClick: () => void;
      isFlashingPurple$: Rx.Subject<boolean>;
    }> = ({ onScheduleClick, onDismissClick, isFlashingPurple$ }) => {
      const viewState$ = useMemo(
        () => new Rx.BehaviorSubject<ViewState>("collapsed"),
        []
      );

      return (
        <ContainerView
          icon={<img src={scheduleNextIcon} alt="S" />}
          flashSignal$={isFlashingPurple$}
          successMessage="Session scheduled"
          expandedButton={{
            title: "Schedule",
            onClick: () => {
              onScheduleClick();
              viewState$.next("collapsed");
            },
          }}
          expandedContent={
            <div className={`flex items-center gap-4`}>
              <h4 className="font-medium text-white">Schedule next session</h4>
              <button
                className="bg-transparent text-white px-[26px] py-[13px] rounded-lg flex justify-center items-center font-medium"
                onClick={() => {
                  onDismissClick();
                  viewState$.next("collapsed");
                }}
              >
                Dismiss
              </button>
            </div>
          }
          viewState$={viewState$}
        />
      );
    };

    export const BookmarkButton: React.FC<{
      onBookmarkClick: () => void;
    }> = ({ onBookmarkClick }) => {
      const viewState$ = useMemo(
        () => new Rx.BehaviorSubject<ViewState>("collapsed"),
        []
      );
      return (
        <ContainerView
          icon={<img src={bookmarkAddIcon} alt="Bookmark" />}
          viewState$={viewState$}
          expandedButton={{
            title: "Create Bookmark",
            onClick: () => {
              onBookmarkClick();
              viewState$.next("success");
            },
          }}
          expandedContent={<div>Bookmark</div>}
          successMessage="Bookmark created"
        />
      );
    };

    export const NotesButton: React.FC<{
      onNotesClick: () => void;
    }> = ({ onNotesClick }) => {
      const viewState$ = useMemo(
        () => new Rx.BehaviorSubject<ViewState>("collapsed"),
        []
      );
      return (
        <ContainerView
          icon={<img src={"/stickynote-white.svg"} alt="N" />}
          expandedButton={{
            title: "Add note",
            onClick: () => {
              onNotesClick();
              viewState$.next("collapsed");
            },
          }}
          expandedContent={<div>Notes</div>}
          successMessage="Note added"
          viewState$={viewState$}
        />
      );
    };

    export const RecordSectionButton: React.FC<{
      onStartClick: () => void;
      onStopClick: () => void;
      isRecording$: Rx.Observable<boolean>;
    }> = ({ onStartClick, onStopClick, isRecording$ }) => {
      const isRecording = useObservableState(isRecording$, false);
      const viewState$ = useMemo(
        () => new Rx.BehaviorSubject<ViewState>("collapsed"),
        []
      );

      return (
        <ContainerView
          icon={
            <img
              src={
                isRecording
                  ? "/bookendRecording.gif"
                  : "/live-record-section-static-icon.png"
              }
              className="cursor-pointer w-full h-full"
            />
          }
          viewState$={viewState$}
          expandedButton={{
            title: isRecording ? "Stop" : "Start",
            onClick: () => {
              if (isRecording) {
                onStopClick();
                viewState$.next("success");
              } else {
                onStartClick();
              }
            },
          }}
          expandedContent={<div>Recording</div>}
          successMessage="Recording captured"
        />
      );
    };
  }
}

export const WaitingRoomContainer: React.FC<{
  onBackClick: () => void;
  leftSide: React.ReactNode;
  rightSide: React.ReactNode;
  gotoSession: {
    title: string;
    onClick: () => void;
  };
}> = ({ onBackClick, leftSide, rightSide, gotoSession }) => {
  return (
    <div className="flex-1 flex flex-col min-h-0 border-4">
      <nav
        className="basis-[80px] grow-0 shrink-0 flex items-center justify-between px-8"
        style={{
          boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
        }}
      >
        <InsightLiveLogoWithText />
        <button
          className="text-vid-purple font-bold"
          onClick={() => {
            onBackClick();
          }}
        >
          Back to dashboard
        </button>
      </nav>
      <div className="grow shrink flex gap-2 px-4 justify-center  min-h-0 py-20">
        <div className="flex-1">{leftSide}</div>
        <div className="flex-1 hidden md:flex md:flex-col">{rightSide}</div>
        {/* <div className="basis-[300px] grow-0 shrink w-[100px] bg-blue-400 min-h-0" /> */}
      </div>
      <WaitingRoomFooter onBackClick={onBackClick} gotoSession={gotoSession} />
    </div>
  );
};

const WaitingRoomFooter: React.FC<{
  onBackClick: () => void;
  gotoSession: {
    title: string;
    onClick: () => void;
  };
}> = ({ onBackClick, gotoSession }) => {
  return (
    <footer className="basis-[100px] grow-0 shrink-0 self-stretch border-t border-gray-200 bg-white flex justify-between items-center gap-8 md:gap-24 relative">
      <img className="absolute inset-0" src={"/dot-pattern.png"} />
      <div className="absolute inset-0 flex justify-between items-center gap-24 px-8 md:px-48 py-9 ">
        <button
          className={`
      py-4 px-8 flex justify-center items-center rounded-lg border border-vid-black-900 text-vid-black-900
      hover:bg-vid-black-900 hover:text-white bg-white
      `}
          onClick={() => {
            onBackClick();
          }}
        >
          Back
        </button>
        <div
          className={`
      py-4 px-8 flex justify-center items-center rounded-lg 
      bg-vid-purple text-white cursor-pointer
      `}
          onClick={gotoSession.onClick}
        >
          {gotoSession.title}
        </div>
      </div>
    </footer>
  );
};

export const IsRecordingIcon: React.FC = () => {
  return <div className="bg-red-700 h-2 w-2 rounded-full" />;
};

const BlurBackgroundNav: React.FC = () => {
  const {
    disableBackgroundFilter, // disables the filter
    applyBackgroundBlurFilter, // applies the blur filter
    applyBackgroundImageFilter, // applies the image filter
    backgroundImages, // list of available images
  } = useBackgroundFilters();
  return (
    <div className="flex justify-center gap-4 border p-8">
      <button
        className="rounded-full p-4 bg-white border"
        onClick={disableBackgroundFilter}
      >
        Disable blur
      </button>
      <button
        className="rounded-full p-4 bg-white border"
        onClick={() => applyBackgroundBlurFilter("high")}
      >
        Enable blur
      </button>
      <ul>
        {backgroundImages?.map((image) => (
          <li key={image}>
            <img src={image} alt="background" />
            <button onClick={() => applyBackgroundImageFilter(image)}>
              Apply background
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ScreenshareNav: React.FC<{
  setRemoteIsSharingAudioOnly: (isSharingAudioOnly: boolean) => void;
}> = ({ setRemoteIsSharingAudioOnly }) => {
  const { useScreenShareState } = useCallStateHooks();
  const { screenShare, status } = useScreenShareState();

  useEffect(() => {
    console.log("screenShare.state", screenShare.state.audioEnabled);
  }, [screenShare.state]);

  return (
    <div className="flex flex-col gap-4">
      <button
        className="rounded-full p-4 bg-white border"
        onClick={() => {
          if (status === "enabled") {
            screenShare.disable();
          } else {
            screenShare.enable();
          }
        }}
      >
        {status === "enabled" ? "Stop screenshare" : "Start screenshare"}
      </button>
      <button
        className="rounded-full p-4 bg-white border"
        onClick={() => {
          console.log(
            "screenShare.state.audioEnabled",
            screenShare.state.audioEnabled
          );
          if (screenShare.state.status === "enabled") {
            screenShare.disableScreenShareAudio().catch((e) => {
              console.error(e);
            });
            screenShare.disable();
            setRemoteIsSharingAudioOnly(false);
          } else {
            screenShare.enableScreenShareAudio();
            screenShare.enable();
            setRemoteIsSharingAudioOnly(true);
          }
        }}
      >
        {screenShare.state.status === "enabled"
          ? "Stop share audio"
          : "Share audio"}
      </button>
    </div>
  );
};

export const SettingsRightNav: React.FC<{
  setRemoteIsSharingAudioOnly: (isSharingAudioOnly: boolean) => void;
  shareableLink: string;
  extra?: React.ReactNode;
}> = ({ setRemoteIsSharingAudioOnly, shareableLink, extra }) => {
  return (
    <div className="flex flex-col gap-6 p-4">
      <BlurBackgroundNav />
      <ScreenshareNav
        setRemoteIsSharingAudioOnly={setRemoteIsSharingAudioOnly}
      />
      <CopyLinkButton link={shareableLink} />
      {extra ?? <></>}
    </div>
  );
};
