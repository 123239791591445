import { Data, Either, Option } from "effect";

type RemoteDataState<Success, Failure> = Data.TaggedEnum<{
  Initial: {};
  Loading: {};
  Success: { data: Success };
  Failure: { reason: Failure };
}>;

interface RemoteDataDefinition extends Data.TaggedEnum.WithGenerics<2> {
  readonly taggedEnum: RemoteDataState<this["A"], this["B"]>;
}

const { Initial, Loading, Failure, Success, $match, $is } =
  Data.taggedEnum<RemoteDataDefinition>();

export class RemoteData<Success, Failure> {
  public readonly state: RemoteDataState<Success, Failure>;

  private constructor(state: RemoteDataState<Success, Failure>) {
    this.state = state;
  }

  static initial<Success, Failure>(): RemoteData<Success, Failure> {
    return new RemoteData(Initial());
  }

  static loading<Success, Failure>(): RemoteData<Success, Failure> {
    return new RemoteData(Loading());
  }

  static success<Success, Failure>(
    data: Success
  ): RemoteData<Success, Failure> {
    return new RemoteData(Success({ data }));
  }

  static failure<Success, Failure>(
    reason: Failure
  ): RemoteData<Success, Failure> {
    return new RemoteData(Failure({ reason }));
  }

  isLoading(): this is RemoteData<Success, Failure> {
    return $is("Loading")(this.state);
  }

  isSuccess(): this is RemoteData<Success, Failure> & { data: Success } {
    return $is("Success")(this.state);
  }

  isFailure(): this is RemoteData<Success, Failure> & {
    state: { _tag: "Failure"; reason: Failure };
  } {
    return $is("Failure")(this.state);
  }

  static isLoading = <S, F>(
    remoteData: RemoteData<S, F>
  ): remoteData is RemoteData<S, F> & { state: { _tag: "Loading" } } => {
    return remoteData.isLoading();
  };

  static isSuccess = <S, F>(
    remoteData: RemoteData<S, F>
  ): remoteData is RemoteData<S, F> & {
    state: { _tag: "Success"; data: S };
  } => {
    return remoteData.isSuccess();
  };

  static isFailure = <S, F>(
    remoteData: RemoteData<S, F>
  ): remoteData is RemoteData<S, F> & {
    state: { _tag: "Failure"; reason: F };
  } => {
    return remoteData.isFailure();
  };

  ifFailure = <V>(onFailure: (reason: Failure) => V, defaultValue: V): V => {
    return $match(this.state, {
      Initial: () => defaultValue,
      Loading: () => defaultValue,
      Success: () => defaultValue,
      Failure: ({ reason }) => onFailure(reason),
    }) as V;
  };

  get asMbSuccess(): Option.Option<Success> {
    return $match(this.state, {
      Initial: () => Option.none(),
      Loading: () => Option.none(),
      Success: ({ data }) => Option.some(data),
      Failure: () => Option.none(),
    });
  }

  get asMbFailure(): Option.Option<Failure> {
    return $match(this.state, {
      Initial: () => Option.none(),
      Loading: () => Option.none(),
      Success: () => Option.none(),
      Failure: ({ reason }) => Option.some(reason),
    });
  }

  static match = <Success, Failure, MatchRes = void>(
    remoteData: RemoteData<Success, Failure>,
    matchers: {
      onInitial: () => MatchRes;
      onLoading: () => MatchRes;
      onSuccess: (data: Success) => MatchRes;
      onFailure: (reason: Failure) => MatchRes;
    }
  ) => {
    const state = remoteData.state;

    return $match(state, {
      Initial: () => matchers.onInitial(),
      Loading: () => matchers.onLoading(),
      Success: (data) => matchers.onSuccess(data.data),
      Failure: (reason) => matchers.onFailure(reason.reason),
    });
  };

  static match3 = <Success, Failure, Result>(
    remoteData: RemoteData<Success, Failure>,
    matchers: {
      onPresubmit: () => Result;
      onSuccess: (data: Success) => Result;
      onFailure: (reason: Failure) => Result;
    }
  ) => {
    return this.match(remoteData, {
      onInitial: () => matchers.onPresubmit(),
      onLoading: () => matchers.onPresubmit(),
      onSuccess: (data) => matchers.onSuccess(data),
      onFailure: (reason) => matchers.onFailure(reason),
    });
  };

  static matchLoadingOrSuccess = <Success, Failure>(
    remoteData: RemoteData<Success, Failure>,
    matchers: {
      onLoading: () => void;
      onSuccess: (data: Success) => void;
    }
  ) => {
    return this.match(remoteData, {
      onInitial: () => matchers.onLoading(),
      onLoading: () => matchers.onLoading(),
      onSuccess: (data) => matchers.onSuccess(data),
      onFailure: (reason) => {
        console.log("unexpected failure on matchLoadingOrSuccess", reason);
      },
    });
  };

  static fromEither = <Success, Failure>(
    either: Either.Either<Success, Failure>
  ): RemoteData<Success, Failure> => {
    return either.pipe(
      Either.match({
        onLeft: (reason) => RemoteData.failure(reason),
        onRight: (data) => RemoteData.success(data),
      })
    );
  };

  get data(): Success {
    if (this.isSuccess()) {
      return (this.state as { _tag: "Success"; data: Success }).data;
    }
    throw new Error("Cannot access data on non-success RemoteData");
  }

  get reason(): Failure {
    if (this.isFailure()) {
      return (this.state as { _tag: "Failure"; reason: Failure }).reason;
    }
    throw new Error("Cannot access reason on non-failure RemoteData");
  }
}
