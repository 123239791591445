import * as S from "@effect/schema/Schema";
import { Match } from "effect";
import { FileSchemas } from "../file.schemas";

export class UploadFileSuccess extends S.Class<UploadFileSuccess>(
  "UploadFileSuccess"
)({
  url: S.String,
}) {}

type MediaType = FileSchemas.MediaType;
const MediaType = FileSchemas.MediaType;

export namespace KnownCloudinaryPtrs {
  const CloudinaryPtrTag = S.Union(
    S.Literal("PreprocessedCommunityPostVideoPtr"),
    S.Literal("PreprocessedCommunityPostAudioPtr"),
    S.Literal("PreprocessedCommunityPostImagePtr"),
    S.Literal("UnprocessedProfilePhotoPtr"),
    S.Literal("UnprocessedCommunityPostImagePtr"),
    S.Literal("UnprocessedCommunityPostVideoPtr"),
    S.Literal("UnprocessedCommunityPostAudioPtr"),
    S.Literal("CommunityPostImageVirtualPtr"),
    S.Literal("ProfilePhotoThumbVirtualPtr"),
    S.Literal("ProfilePhotoMediumVirtualPtr")
  );
  type CloudinaryPtrTag = typeof CloudinaryPtrTag.Type;

  export type KnownConfirmedUploadedPtr = VirtualPtr;

  namespace KnownTransformation {
    export abstract class KnownTransformation {
      abstract readonly transformation: any[];
    }
    export class PostVideoTransformation extends KnownTransformation {
      readonly transformation = [
        { format: "mp4" },
        { aspect_ratio: "16:9", width: 700, crop: "fill", quality: "auto" },
        { fetch_format: "auto" },
      ];
    }

    export class PostImageTransformation extends KnownTransformation {
      readonly transformation = [
        {
          width: 800,
          height: 450,
          crop: "fill",
          gravity: "auto",
          // format: "jpg",
          quality: "auto:good",
        },
        {
          fetch_format: "auto",
        },
      ];
    }

    export class PostAudioTransformation extends KnownTransformation {
      readonly transformation = [
        {
          format: "mp3",
          quality: "auto:best",
        },
      ];
    }

    export class NoTransformation extends KnownTransformation {
      readonly transformation = [];
    }

    export class ProfilePhotoThumbTransformation extends KnownTransformation {
      readonly transformation = [
        {
          width: 100,
          height: 100,
          crop: "fill",
          gravity: "face",
          quality: "auto",
        },
      ];
    }
  }

  // TODO: REMOVE CloudinaryLocation?
  const CloudinaryLocation = S.Struct({
    folder: S.String,
    itemId: S.String,
  });
  type CloudinaryLocation = S.Schema.Type<typeof CloudinaryLocation>;

  export const CloudinaryLocationEncoding = S.String.pipe(
    S.brand("CloudinaryLocationEncoding")
  );
  export type CloudinaryLocationEncoding =
    typeof CloudinaryLocationEncoding.Type;

  export const CloudinaryLocationToStr = S.transform(
    CloudinaryLocationEncoding,
    CloudinaryLocation,
    {
      decode: (r) => {
        return S.decodeUnknownSync(S.parseJson(CloudinaryLocation))(r);
      },
      encode: (r) =>
        S.decodeSync(CloudinaryLocationEncoding)(JSON.stringify(r)),
      strict: true,
    }
  );

  const ConfirmedCloudinaryPtrSchema = S.Struct({
    public_id: S.String,
    asset_id: S.String,
    mediaType: MediaType,
  });
  type ConfirmedCloudinaryPtrSchema = typeof ConfirmedCloudinaryPtrSchema.Type;
  export const ConfirmedCloudinaryPtrEncoding = S.String.pipe(
    S.brand("ConfirmedCloudinaryPtrEncoding")
  );
  export type ConfirmedCloudinaryPtrEncoding =
    typeof ConfirmedCloudinaryPtrEncoding.Type;

  export function decodeConfirmedCloudinaryPtr(
    ptr: KnownCloudinaryPtrs.ConfirmedCloudinaryPtrEncoding
  ): {
    public_id: string;
    asset_id: string;
    mediaType: MediaType;
  } {
    return S.decodeUnknownSync(S.parseJson(ConfirmedCloudinaryPtrSchema))(ptr);
  }

  export abstract class VirtualPtr {
    abstract readonly mediaType: MediaType;
    abstract readonly transformation: KnownTransformation.KnownTransformation;
    constructor(readonly p: ConfirmedCloudinaryPtrSchema) {}

    static serializeSchema = S.transform(
      ConfirmedCloudinaryPtrEncoding,
      ConfirmedCloudinaryPtrSchema,
      {
        decode: (r) => {
          return S.decodeUnknownSync(S.parseJson(ConfirmedCloudinaryPtrSchema))(
            r
          );
        },
        encode: (r): ConfirmedCloudinaryPtrEncoding =>
          S.decodeSync(ConfirmedCloudinaryPtrEncoding)(JSON.stringify(r)),
        strict: true,
      }
    );

    encodeSelf = (): ConfirmedCloudinaryPtrEncoding => {
      return ConfirmedCloudinaryPtrEncoding.make(
        S.encodeSync(VirtualPtr.serializeSchema)(this.p)
      );
    };
  }

  export abstract class CommunityPostMediaVirtualPtr extends VirtualPtr {
    static from = (p: {
      public_id: string;
      asset_id: string;
      mediaType: MediaType;
    }): CommunityPostMediaVirtualPtr => {
      return Match.value(p.mediaType).pipe(
        Match.when("video", () => new CommunityPostVideoVirtualPtr(p)),
        Match.when("image", () => new CommunityPostImageVirtualPtr(p)),
        Match.when("audio", () => new CommunityPostAudioVirtualPtr(p)),
        Match.exhaustive
      );
    };

    static unsafeDecodeStr = (str: string) => {
      const decoded = S.decodeUnknownSync(
        CommunityPostMediaVirtualPtr.serializeSchema
      )(str);
      return Match.value(decoded.mediaType!).pipe(
        Match.when("video", () => new CommunityPostVideoVirtualPtr(decoded)),
        Match.when("image", () => new CommunityPostImageVirtualPtr(decoded)),
        Match.when("audio", () => new CommunityPostAudioVirtualPtr(decoded)),
        Match.exhaustive
      );
    };
  }

  export class CommunityPostVideoVirtualPtr extends CommunityPostMediaVirtualPtr {
    readonly _tag = "CommunityPostVideoVirtualPtr";
    readonly transformation = new KnownTransformation.PostVideoTransformation();
    readonly mediaType = "video";
  }
  export class CommunityPostImageVirtualPtr extends CommunityPostMediaVirtualPtr {
    readonly _tag = "CommunityPostImageVirtualPtr";
    readonly mediaType = "image";
    readonly transformation = new KnownTransformation.PostImageTransformation();
  }
  export class CommunityPostAudioVirtualPtr extends CommunityPostMediaVirtualPtr {
    readonly _tag = "CommunityPostAudioVirtualPtr";
    readonly mediaType = "audio";
    readonly transformation = new KnownTransformation.PostAudioTransformation();
  }

  export abstract class CloudinaryPtr {
    abstract readonly _tag: CloudinaryPtrTag;
    abstract readonly transformation: KnownTransformation.KnownTransformation;
    abstract readonly mediaType: "video" | "image" | "audio";

    constructor(readonly location: { folder: string; itemId: string }) {}

    get publicId(): string {
      return `${this.location.folder}/${this.location.itemId}`;
    }

    get locationEncoding(): CloudinaryLocationEncoding {
      return S.encodeSync(CloudinaryLocationToStr)(
        this.location
      ) as CloudinaryLocationEncoding;
    }
  }

  export abstract class UnprocessedCommunityPostMediaPtr extends CloudinaryPtr {
    readonly transformation = new KnownTransformation.NoTransformation();
    constructor(p: { communitySlug: string; itemId: string }) {
      super({
        folder: `communities/${p.communitySlug}/posts`,
        itemId: p.itemId,
      });
    }

    static from = (p: {
      communitySlug: string;
      fileMetadata: FileSchemas.FileMetadata;
      itemId: string;
    }): UnprocessedCommunityPostMediaPtr => {
      return Match.value(p.fileMetadata.mediaType).pipe(
        Match.when("video", () => new UnprocessedCommunityPostVideoPtr(p)),
        Match.when("image", () => new UnprocessedCommunityPostImagePtr(p)),
        Match.when("audio", () => new UnprocessedCommunityPostAudioPtr(p)),
        Match.exhaustive
      );
    };
  }

  export class UnprocessedCommunityPostVideoPtr extends UnprocessedCommunityPostMediaPtr {
    readonly _tag = "UnprocessedCommunityPostVideoPtr";
    readonly mediaType = "video";
  }

  export class UnprocessedCommunityPostImagePtr extends UnprocessedCommunityPostMediaPtr {
    readonly _tag = "UnprocessedCommunityPostImagePtr";
    readonly mediaType = "image";
  }

  export class UnprocessedCommunityPostAudioPtr extends UnprocessedCommunityPostMediaPtr {
    readonly _tag = "UnprocessedCommunityPostAudioPtr";
    readonly mediaType = "audio";
  }

  export class UnprocessedProfilePhotoPtr extends CloudinaryPtr {
    readonly _tag = "UnprocessedProfilePhotoPtr";
    readonly mediaType = "image";
    readonly transformation = new KnownTransformation.NoTransformation();

    constructor(p: { userId: string; nowUnixEpochMs: number }) {
      const location = {
        folder: "profile-photos",
        itemId: `${p.userId}__${p.nowUnixEpochMs}`,
      };
      super(location);
    }
  }

  export class ProfilePhotoThumbVirtualPtr extends VirtualPtr {
    readonly _tag = "ProfilePhotoThumbVirtualPtr";
    readonly mediaType = "image";
    readonly transformation =
      new KnownTransformation.ProfilePhotoThumbTransformation();
  }

  export class ProfilePhotoMediumVirtualPtr extends VirtualPtr {
    readonly _tag = "ProfilePhotoMediumVirtualPtr";
    readonly mediaType = "image";
    readonly transformation = {
      transformation: [
        {
          width: 250,
          height: 250,
          crop: "fill",
          gravity: "face",
          quality: "auto:best",
        },
      ],
    };
  }

  export abstract class PreprocessedCommunityPostMediaPtr extends CloudinaryPtr {
    constructor(p: { communitySlug: string; itemId: string }) {
      const folder = `communities/${p.communitySlug}/posts`;
      super({ folder, itemId: p.itemId });
    }

    static make = (p: {
      communitySlug: string;
      fileMetadata: FileSchemas.FileMetadata;
    }) => {
      const itemId = `${Date.now()}`;
      const params = {
        communitySlug: p.communitySlug,
        itemId,
      };
      return Match.value(p.fileMetadata.mediaType).pipe(
        Match.when(
          "video",
          () => new PreprocessedCommunityPostVideoPtr(params)
        ),
        Match.when(
          "image",
          () => new PreprocessedCommunityPostImagePtr(params)
        ),
        Match.when(
          "audio",
          () => new PreprocessedCommunityPostAudioPtr(params)
        ),
        Match.exhaustive
      );
    };
  }

  export class PreprocessedCommunityPostVideoPtr extends PreprocessedCommunityPostMediaPtr {
    readonly _tag = "PreprocessedCommunityPostVideoPtr";
    readonly mediaType = "video";
    readonly transformation = new KnownTransformation.NoTransformation();
  }

  export class PreprocessedCommunityPostImagePtr extends PreprocessedCommunityPostMediaPtr {
    readonly _tag = "PreprocessedCommunityPostImagePtr";
    readonly mediaType = "image";
    readonly transformation = new KnownTransformation.NoTransformation();
  }

  export class PreprocessedCommunityPostAudioPtr extends PreprocessedCommunityPostMediaPtr {
    readonly _tag = "PreprocessedCommunityPostAudioPtr";
    readonly mediaType = "audio";
    readonly transformation = new KnownTransformation.NoTransformation();
  }
}
