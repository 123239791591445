import * as S from "@effect/schema/Schema";
import type { Doc, Id } from "../../../convex/_generated/dataModel";

const BaseContentTypes = S.Union(S.Literal("MEDITATION"), S.Literal("EMDR"));

const BaseContentViewMode = S.Union(
  S.Literal("TOP_PREVIEW"),
  S.Literal("FULL_SCREEN")
);
type BaseContentViewMode = typeof BaseContentViewMode.Type;

export type SessionContent = typeof BaseContentTypes.Type;

const SessionStageViewMode = S.Union(
  S.Struct({ tag: S.Literal("PARTICIPANTS_VIEW") }),
  S.Struct({
    tag: S.Literal("CONTENT"),
    content: S.Struct({
      viewMode: BaseContentViewMode,
      content: BaseContentTypes,
    }),
  })
);
export type SessionStageViewMode = S.Schema.Type<typeof SessionStageViewMode>;

const HcSessionStageViewMode = SessionStageViewMode;
export type HcSessionStageViewMode = S.Schema.Type<
  typeof HcSessionStageViewMode
>;

export class HcStageViewStateSchema extends S.Class<HcStageViewStateSchema>(
  "HcStageViewStateSchema"
)({
  mode: HcSessionStageViewMode,
}) {
  static default = () => {
    return HcStageViewStateSchema.make(
      {
        mode: { tag: "PARTICIPANTS_VIEW" },
      },
      { disableValidation: true }
    );
  };

  static asParticipantsView = () => {
    return HcStageViewStateSchema.make(
      {
        mode: {
          tag: "PARTICIPANTS_VIEW",
        },
      },
      { disableValidation: true }
    );
  };

  static asContentMode = (p: {
    viewMode: BaseContentViewMode;
    content: "MEDITATION" | "EMDR";
  }) => {
    return HcStageViewStateSchema.make(
      {
        mode: {
          tag: "CONTENT",
          content: {
            viewMode: p.viewMode,
            content: p.content,
          },
        },
      },
      { disableValidation: true }
    );
  };

  get encoded() {
    return S.encodeUnknownSync(HcStageViewStateSchema)(this);
  }
}

export class SessionInfoST extends S.Class<SessionInfoST>("SessionInfoST")({
  baseSessionIdStr: S.String,
  startsAt: S.Number,
  intendedDurationInMinutes: S.Number,
  minutesUntilEndReminder: S.NullOr(S.Number),
  isAudioOnlySession: S.Boolean,
  title: S.optional(S.String),
  description: S.optional(S.String),
  inviteLink: S.String,
}) {
  static default = (p: {
    baseSessionId: Id<"sessionConfig">;
    startsAt: number;
    intendedDurationInMinutes: number;
    minutesUntilEndReminder: number | null;
    title?: string;
    description?: string;
    inviteLink: string;
  }) => {
    console.log("intendedDurationInMinutes", p.intendedDurationInMinutes);
    return SessionInfoST.make({
      baseSessionIdStr: p.baseSessionId,
      startsAt: p.startsAt,
      intendedDurationInMinutes: p.intendedDurationInMinutes,
      minutesUntilEndReminder: p.minutesUntilEndReminder,
      isAudioOnlySession: false,
      inviteLink: p.inviteLink,
      title: p.title,
      description: p.description,
    });
  };

  get baseSessionId(): Id<"sessionConfig"> {
    return this.baseSessionIdStr as Id<"sessionConfig">;
  }

  static fromDoc = (p: { doc: Doc<"sessionConfig">; inviteLink: string }) => {
    const { doc, inviteLink } = p;
    return SessionInfoST.make({
      baseSessionIdStr: doc._id,
      startsAt: doc.startsAt ?? null,
      intendedDurationInMinutes: doc.intendedDurationInMinutes,
      minutesUntilEndReminder: doc.minutesUntilEndReminder,
      isAudioOnlySession: doc.isAudioOnlySession,
      inviteLink,
      title: doc.title,
      description: doc.description,
    });
  };

  get intendedDurationInMs() {
    return this.intendedDurationInMinutes * 60 * 1000;
  }

  get endsAt() {
    return this.startsAt + this.intendedDurationInMs;
  }

  get encoded() {
    return S.encodeUnknownSync(SessionInfoST)(this);
  }
}

export class SessionStateSchema extends S.Class<SessionStateSchema>(
  "SessionStateSchema"
)({
  lastStartedNewRecordingAt: S.NullOr(S.Number),
  lastSyncedSecondsIntoSession: S.NullOr(S.Number),
  lastStartedTranscriptionAt: S.NullOr(S.Number),
  lastPingedAt: S.NullOr(S.Number),
  cronPingerId: S.NullOr(S.String),
  cronPingerDeletedAt: S.NullOr(S.Number),
  endedByUserAt: S.NullOr(S.Number),
  endedBySystemAt: S.NullOr(S.Number),
  startedAt: S.optional(S.NullOr(S.Number)),
}) {
  static default = () => {
    return SessionStateSchema.make({
      lastStartedNewRecordingAt: null,
      lastSyncedSecondsIntoSession: 1,
      lastStartedTranscriptionAt: null,
      lastPingedAt: null,
      cronPingerId: null,
      cronPingerDeletedAt: null,
      endedByUserAt: null,
      endedBySystemAt: null,
      startedAt: null,
    });
  };

  get encoded() {
    return S.encodeUnknownSync(SessionStateSchema)(this);
  }
}
