import { Match } from "effect";
import {
  KnownTool,
  KnownToolTag,
} from "shared/schemas/session/ongoing/known-tool.schemas";
import type { ToolMenuViewStateST } from "shared/schemas/session/ongoing/participant/participant-display.schemas";

export const TherapyToolMenuButton: React.FC<{
  content: {
    title: string;
    description: string;
    iconName?: string;
  };
  onClick: () => void;
}> = ({ content, onClick }) => {
  const { title, description, iconName } = content;
  return (
    <div
      className="h-[70px] w-[312px] flex items-center rounded-[12px] px-4 border border-vid-black-200 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex-1 h-[40px] flex items-center gap-5">
        <div className="w-[40px] h-[40px] bg-gray-200 rounded-full flex items-center justify-center">
          {iconName && <img src={`/${iconName}.svg`} alt={title} />}
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="text-sm text-vid-black-800 ">{title}</div>
          <div className="text-xs text-vid-black-600">{description}</div>
        </div>
      </div>
    </div>
  );
};

export const TherapyToolMenuContainer: React.FC<{
  knownTools: KnownToolTag[];
  toolMenuViewState: ToolMenuViewStateST;
  onToolClick: (tool: KnownToolTag) => void;
  componentForToolTag: (toolTag: KnownToolTag) => React.ReactNode;
}> = ({ knownTools, toolMenuViewState, onToolClick, componentForToolTag }) => {
  return Match.value(toolMenuViewState.viewState).pipe(
    Match.when({ tag: "MENU" }, () => (
      <div className="self-stretch flex flex-col gap-4">
        {knownTools.map((toolTag) => {
          const knownTool = KnownTool.fromTag(toolTag);
          return (
            <TherapyToolMenuButton
              key={toolTag}
              content={knownTool}
              onClick={() => {
                onToolClick(toolTag);
              }}
            />
          );
        })}
      </div>
    )),
    Match.when({ tag: "SELECTED_TOOL" }, ({ tool }) => (
      <div className="self-stretch flex-1 flex flex-col gap-4">
        {componentForToolTag(tool)}
      </div>
    )),
    Match.exhaustive
  );
};
