import { Menu } from "@headlessui/react";
import { useObservableEagerState } from "observable-hooks";
import { Rx } from "../../prelude";

export const TextInput = ({
  label,
  value,
  onChange,
  type = "text",
  placeholder,
  disabled = false,
  maxLength,
  autoFocus = false,
  required = false,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: "text" | "email" | "password";
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  required?: boolean;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        autoFocus={autoFocus}
        required={required}
        autoCorrect={type === "email" || type === "password" ? "off" : "on"}
        autoCapitalize={type === "email" ? "off" : "on"}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export const NumberedInput = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <input
        type="number"
        value={value === null ? "" : value}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange(newValue === "" ? null : Number(newValue));
        }}
      />
    </div>
  );
};

export const TextInput$ = ({
  label,
  value$,
}: {
  label: string;
  value$: Rx.BehaviorSubject<string>;
}) => {
  const value = useObservableEagerState(value$);
  return (
    <TextInput
      label={label}
      value={value}
      onChange={(value) => value$.next(value)}
    />
  );
};

export const TextAreaInput = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <textarea value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export const TextAreaInput$ = ({
  label,
  value$,
}: {
  label: string;
  value$: Rx.BehaviorSubject<string>;
}) => {
  const value = useObservableEagerState(value$);
  return (
    <TextAreaInput
      label={label}
      value={value}
      onChange={(value) => value$.next(value)}
    />
  );
};

export const FormMenuInput: React.FC<{
  buttonView: React.ReactNode;
  dropdownView: (close: () => void) => React.ReactNode;
  height?: number;
}> = ({ buttonView, dropdownView, height }) => {
  return (
    <Menu
      as="div"
      className={`relative border rounded-[12px] flex ${
        height ? `h-${height}` : ""
      }`}
    >
      <Menu.Button className="flex-1 flex items-center gap-4 p-4">
        {buttonView}
      </Menu.Button>
      <Menu.Items className="absolute left-0 border-4 z-20 bg-white rounded-lg">
        <Menu.Item>{({ close }) => <>{dropdownView(close)}</>}</Menu.Item>
      </Menu.Items>
    </Menu>
  );
};
