import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Match } from "effect";
import { useEffect, useState } from "react";

export const AppButton: React.FC<{
  onClick: () => void;
  title: string;
  maxWidthPixels?: number;
  disabled?: boolean;
  type?: "button" | "submit";
  isInvertedStyle?: boolean;
}> = ({ onClick, title, maxWidthPixels, disabled, type, isInvertedStyle }) => {
  const button = isInvertedStyle ? (
    <InversePrimaryButton onClick={onClick} title={title} disabled={disabled} />
  ) : (
    <PrimaryButton
      onClick={onClick}
      title={title}
      disabled={disabled}
      type={type}
    />
  );

  if (maxWidthPixels) {
    return <div style={{ maxWidth: `${maxWidthPixels}px` }}>{button}</div>;
  }

  return button;
};

export const PrimaryButton: React.FC<{
  onClick?: () => void;
  title: string;
  maxWidthPixels?: number;
  disabled?: boolean;
  type?: "button" | "submit";
  invertedStyle?: boolean;
  isLoading?: boolean;
  dims?: { width?: number; height?: number };
}> = ({ onClick, title, disabled: propDisabled, type, isLoading, dims }) => {
  const [isDisabled, setIsDisabled] = useState(propDisabled);
  const baseClasses = `font-sans w-full rounded-buttons p-buttons flex justify-center items-center
  ${dims?.width ? `w-[${dims.width}px]` : ""}
    `;
  const disabledClasses = "bg-vid-black-50 cursor-not-allowed";
  const enabledClasses = "bg-vid-purple text-white";
  const className = isDisabled
    ? `${baseClasses} ${disabledClasses}`
    : `${baseClasses} ${enabledClasses}`;

  useEffect(() => {
    setIsDisabled(propDisabled);
  }, [propDisabled]);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsDisabled(true); // Disable the button on click
      setTimeout(() => setIsDisabled(false), 500); // Re-enable after 500ms
    }
  };

  return (
    <button
      onClick={handleClick}
      className={className}
      disabled={isDisabled}
      type={type}
    >
      {isLoading ? <FullContainerLoadingSpinner /> : title}
    </button>
  );
};

export const InversePrimaryButton: React.FC<{
  onClick: () => void;
  title: string;
  disabled?: boolean;
  isLoading?: boolean;
  mode?: "danger" | "default";
}> = ({ onClick, title, disabled: propDisabled, isLoading, mode }) => {
  const [isDisabled, setIsDisabled] = useState(propDisabled);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsDisabled(true); // Disable the button on click
      setTimeout(() => setIsDisabled(false), 500); // Re-enable after 500ms
    }
  };
  return (
    <button
      onClick={handleClick}
      disabled={isDisabled}
      className={`lg:min-w-[200px]
      bg-white  mt-4 lg:mt-0 border rounded-buttons py-2 px-8 lg:p-buttons
      ${
        mode === "danger"
          ? "text-red-500 border-red-500"
          : "text-vid-purple border-vid-purple"
      }
      flex justify-center items-center
      ${
        isDisabled
          ? "cursor-not-allowed"
          : `hover:bg-${
              mode === "danger" ? "red-500" : "vid-purple-500"
            } hover:text-white`
      }
      `}
    >
      {isLoading ? <FullContainerLoadingSpinner /> : title}
    </button>
  );
};
