import { Switch } from "@headlessui/react";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { useMyId } from "@pages/u/u.webstatemgr";
import { AvatarCircle, AvatarCircles } from "@webapp/components/avatar.tc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/components/primitives/button";
import { ThreeDotsMenu } from "@webapp/componentsmenus/three-dots.menu";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useMutation, useQuery } from "convex/react";
import { format } from "date-fns";
import { Match } from "effect";
import { CommunityStateMgr } from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { CommunityEventDetailsPanelST } from "shared/convex/Calendar/Calendar.Types";
import type { UpcomingCommunityCalEventInstanceCard } from "shared/convex/Screens/Community/CommunityEventsScreen.Types";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";

type Mode =
  | { _tag: "COMMUNITIES" }
  | { _tag: "COMMUNITY"; communitySam: CommunityStateMgr };

type MyProfileCardProps = {
  name: string;
  profilePhoto: ImageSrc | null;
  mode: Mode;
  bio: string;
  modalities: { name: string; slug: string }[];
};

export const MyCommunitiesProfileCard: React.FC<MyProfileCardProps> = ({
  name,
  profilePhoto,
  mode,
  bio,
  modalities,
}) => {
  return (
    <div className="hidden lg:flex flex-col max-w-[345px] rounded-lg min-h-0 self-start">
      <div className="flex flex-col items-center pb-8 w-full bg-white rounded-xl border  border-vid-black-200">
        <div
          className="h-[151px] self-stretch flex flex-col justify-center items-center"
          style={{
            background:
              "linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), var(--Vidalify-Purple, #690DFF)",
          }}
        >
          <AvatarCircle mbProfilePhoto={profilePhoto} size={50} />
        </div>
        <div className="flex flex-col px-8">
          <div className="justify-center px-2 mt-4 text-2xl leading-7 text-center text-black whitespace-nowrap">
            {name}
          </div>
          {mode._tag === "COMMUNITY" && (
            <AvailableForPracticeSession sam={mode.communitySam} />
          )}
          <div className="flex flex-col self-stretch p-6 mt-4 w-full rounded-xl">
            <div className="text-sm font-medium leading-4 text-gray-900">
              About
            </div>
            <div className="mt-4 text-base leading-6 text-violet-700">
              {bio}
            </div>
          </div>
          <div className="flex flex-col self-stretch p-6 w-full text-base leading-5 text-black">
            <div className="text-sm font-medium text-gray-900">
              Modalities offered
            </div>
            <div className="flex flex-wrap gap-2">
              {modalities.map((m) => {
                return (
                  <div
                    key={m.slug}
                    className="flex gap-3.5 justify-between px-6 py-3.5 rounded-3xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)]"
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/825848ad326a0fc7a3e2e461deeea59f9c0bec51a146a512e5d339548ba3e7a0?"
                      className="w-6 aspect-square"
                    />
                    <div className="self-start mt-1">{m.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <Link
            to={editBioLink}
            className="justify-center items-center px-16 py-6 mt-4 w-full text-lg font-medium leading-5 text-center text-violet-700 whitespace-nowrap bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Purple,#690DFF)] max-w-[292px]"
          >
            Edit
          </Link> */}
        </div>
      </div>
    </div>
  );
};

interface MemberCardProps {
  index: number;
  userId: string;
  name: string;
  profilePhoto: string | null;
  isAvailable?: boolean;
  modalities: string[];
}

const AvailableForPracticeSession: React.FC<{
  sam: CommunityStateMgr;
}> = () => {
  return <></>;
};

export const MemberCard: React.FC<MemberCardProps> = ({
  userId,
  name,
  profilePhoto,
  modalities,
  index,
}) => {
  const nav = useNavigate();
  return (
    <CommunityCardContainer
      title={name}
      content={<div>{modalities.join(" | ")}</div>}
      headerBackgroundType={{ _tag: "INDEXED", index }}
      onClick={() => {
        nav(
          UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
            { userId }
          )
        );
      }}
      button={{
        title: "View",
      }}
      innerCircleComponent={
        <AvatarCircle
          size={54}
          mbProfilePhoto={profilePhoto ? ImageSrc.fromURL(profilePhoto) : null}
        />
      }
    />
  );
};

const AvailableForPracticeSwitch: React.FC<{
  onFinishedToggling: (b: boolean) => void;
  isInitiallyAvailable: boolean;
  isToggleble?: boolean;
}> = ({ isInitiallyAvailable, isToggleble, onFinishedToggling }) => {
  const [isAvailable, setIsAvailable] = useState(isInitiallyAvailable);
  const [isDisabled, setIsDisabled] = useState(false);

  const onChange = (value: boolean) => {
    if (isToggleble) {
      setIsAvailable(value);
      onFinishedToggling(value);
      setIsDisabled(true);

      setTimeout(() => {
        setIsDisabled(false);
      }, 1000);
    }
  };

  return (
    <div className="flex items-center gap-2 px-3 py-2 bg-slate-50 rounded-[30px]">
      <Switch
        checked={isAvailable}
        disabled={isDisabled}
        onChange={onChange}
        className={`${isAvailable ? "bg-green-500" : "bg-gray-400"}
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${isAvailable ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <div
        className={`text-green-500 whitespace-nowrap font-medium text-sm ${
          isAvailable ? "text-green-500" : "text-gray-400"
        }`}
      >
        {isAvailable ? "Available for practice" : "Not available"}
      </div>
    </div>
  );
};

export const AvailableForPracticeTag: React.FC = () => {
  return (
    <div className="flex items-center gap-2 justify-between mt-2 text-green-500 text-xs lg:text-base">
      <div className="w-2 h-2 rounded-full bg-green-500"></div>
      {/* <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5093556ced65e4ea6ff16911ed3bb990d1ee74469d1178b3a8405502aca84859?"
        className="self-start aspect-square w-[15px]"
      /> */}
      <div className="grow">Available for practice</div>
    </div>
  );
};

interface TopBannerProps {
  communityColorName: string;
  circleImageUrl: string | null;
  title: string;
  nextToTitleFc: React.ReactNode;
  rightView: React.ReactNode;
  isPrivate?: boolean;
}
export const TopBanner: React.FC<TopBannerProps> = ({
  communityColorName,
  circleImageUrl,
  title,
  nextToTitleFc,
  rightView,
  isPrivate,
}) => {
  return (
    <section className="flex flex-col">
      <header
        className="flex flex-col justify-center w-full  rounded-xl max-md:max-w-full"
        style={{
          background:
            CommunityStateMgr.communityColorByName(communityColorName),
        }}
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc04addd764ad8e8a96e0f2d32b56477025ed2959f96775d55c6914e349c57fe?apiKey=7548643458aa4f489ce4f6ae79dc4302&"
          alt=""
          className="w-full aspect-[3.57] max-md:max-w-full"
        />
      </header>
      <div className="flex z-10 gap-5 justify-between pr-9 -mt-10 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
        <div className="max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <aside className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
              <div className="mx-auto border-2 border-solid bg-slate-50 border-[color:var(--White,#FFF)] lg:h-[125px] rounded-full w-12 h-12 lg:w-[125px] max-md:mt-5">
                {circleImageUrl ? (
                  <img
                    src={circleImageUrl}
                    className="w-full h-full rounded-full"
                  />
                ) : (
                  <></>
                )}
              </div>
            </aside>
            <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col lg:flex-row gap-4 mt-4 lg:mt-16 font-medium whitespace-nowrap">
                <h2 className="grow text-2xl lg:text-4xl text-gray-900 leading-[57.6px]">
                  {title}
                </h2>
                {isPrivate && (
                  <div className="text-xs text-violet-400">Private</div>
                )}
                {nextToTitleFc}
              </div>
            </div>
          </div>
        </div>
        <div>{rightView}</div>
      </div>
    </section>
  );
};

export const TabView: React.FC<{
  title: string;
  isCurrent: boolean;
  linkTo: string;
  onClick?: () => void;
}> = ({ title, isCurrent, linkTo, onClick }) => {
  return (
    <Link
      to={linkTo}
      className={`
      text-gray-900 text-2xl font-normal font-['Roboto Flex'] leading-[28.80px] py-4 px-2 cursor-pointer
      ${isCurrent ? "text-vid-purple border-b-2 border-vid-purple" : ""}
      `}
      onClick={onClick ?? (() => {})}
    >
      {title}
    </Link>
  );
};

function communityColorCardItemBgColor(idx: number) {
  switch (idx) {
    case 0:
      return "#23CE24";
    case 1:
      return "#690DFF";
    case 2:
      return "#FFA8A4";
    case 3:
      return "#FFCF26";
    default:
      return "#23CE24";
  }
}

type HeaderBackgroundType =
  | { _tag: "INDEXED"; index: number }
  | { _tag: "EVENT_PURPLE" }
  | { _tag: "FIXED"; color: string }
  | { _tag: "CUSTOM"; color: string; content: React.ReactNode };

function headerBackgroundColor(type: HeaderBackgroundType): string {
  return Match.value(type).pipe(
    Match.tag("INDEXED", ({ index }) => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), ${communityColorCardItemBgColor(
        index
      )}`;
    }),
    Match.tag("EVENT_PURPLE", () => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), var(--Vidalify-Purple, #690DFF)`;
    }),
    Match.tag("FIXED", ({ color }) => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), ${CommunityStateMgr.communityColorByName(
        color
      )}`;
    }),
    Match.tag("CUSTOM", ({ color }) => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), ${CommunityStateMgr.communityColorByName(
        color
      )}`;
    }),
    Match.exhaustive
  );
}

export const CommunityCardContainer: React.FC<{
  title: string;
  content: React.ReactNode;
  headerBackgroundType: HeaderBackgroundType;
  button: {
    title: string;
    isDisabled?: boolean;
  } | null;
  isLoading?: boolean;
  innerCircleComponent?: React.ReactNode;
  minWidth?: string;
  onClick?: () => void;
}> = ({
  innerCircleComponent,
  title,
  content,
  button,
  isLoading,
  headerBackgroundType,
  minWidth = "180px",
  onClick,
}) => {
  if (isLoading) {
    return (
      <div
        className={`basis-[${minWidth}] lg:basis-[240px] grow-0 shrink-0 h-[361px] flex flex-col border-vid-black-200 rounded-[12px] border animate-pulse`}
      >
        {/* Header skeleton */}
        <div className="border-tr-1 relative aspect-3/4 lg:aspect-auto h-[150px] lg:h-[112px] border rounded-tr-[12px] rounded-tl-[12px] bg-gray-200" />

        {/* Circle skeleton if innerCircleComponent exists */}
        {innerCircleComponent && (
          <div className="absolute -bottom-[15px] left-0 right-0 h-[30px] flex justify-center items-center">
            <div className="w-[54px] h-[54px] rounded-full bg-gray-300" />
          </div>
        )}

        {/* Content skeleton */}
        <div
          className={`flex-1 flex flex-col items-center gap-4 px-4 pb-4 ${innerCircleComponent ? "pt-[56px]" : "pt-[18px]"}`}
        >
          <div className="h-4 w-24 bg-gray-300 rounded" /> {/* Title */}
          <div className="flex-1 w-full space-y-2">
            <div className="h-3 bg-gray-200 rounded w-full" />
            <div className="h-3 bg-gray-200 rounded w-3/4" />
          </div>
          {button && <div className="h-10 w-full bg-gray-300 rounded-lg" />}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`basis-[${minWidth}] lg:basis-[240px] grow-0 shrink-0 h-[361px] flex flex-col border-vid-black-200 rounded-[12px]  border
        ${onClick ? "cursor-pointer" : ""}
        `}
      onClick={onClick}
    >
      <div
        className={`
        relative h-[150px] lg:h-[112px] border rounded-tr-[12px] rounded-tl-[12px] 
        `}
        style={{
          background: headerBackgroundColor(headerBackgroundType),
        }}
      >
        {headerBackgroundType._tag === "CUSTOM" && (
          <div className="absolute inset-0 flex flex-col">
            {headerBackgroundType.content}
          </div>
        )}
        <div className="absolute -bottom-[15px] left-0 right-0 h-[30px] flex justify-center items-center">
          {innerCircleComponent}
        </div>
      </div>
      <div
        className={`
      flex-1 flex flex-col items-center text-xs lg:text-base 
      px-4 pb-4
      ${innerCircleComponent ? "pt-[56px]" : "pt-[18px]"}
      `}
      >
        <div className="font-semibold whitespace-nowrap">{title}</div>
        <div className="flex-1 flex flex-col">{content}</div>
        {button && (
          <InversePrimaryButton onClick={() => {}} title={button.title} />
        )}
      </div>
    </div>
  );
};

const PurpleSvgHeaderBg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 220 123"
    fill="none"
    preserveAspectRatio="none"
    className="w-full h-full"
  >
    <mask id="path-1-inside-1_3136_91924" fill="white">
      <path d="M0 12C0 5.37259 5.37258 0 12 0H208C214.627 0 220 5.37258 220 12V123H0V12Z" />
    </mask>
    <path
      d="M0 12C0 5.37259 5.37258 0 12 0H208C214.627 0 220 5.37258 220 12V123H0V12Z"
      fill="#690DFF"
    />
    <path
      d="M0 12C0 5.37259 5.37258 0 12 0H208C214.627 0 220 5.37258 220 12V123H0V12Z"
      fill="url(#paint0_linear_3136_91924)"
      fillOpacity="0.2"
    />
    <path
      d="M-1 12C-1 4.8203 4.8203 -1 12 -1H208C215.18 -1 221 4.8203 221 12H219C219 5.92487 214.075 1 208 1H12C5.92487 1 1 5.92487 1 12H-1ZM220 123H0H220ZM-1 123V12C-1 4.8203 4.8203 -1 12 -1V1C5.92487 1 1 5.92487 1 12V123H-1ZM208 -1C215.18 -1 221 4.8203 221 12V123H219V12C219 5.92487 214.075 1 208 1V-1Z"
      fill="#DBDAEC"
      mask="url(#path-1-inside-1_3136_91924)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3136_91924"
        x1={3.20388}
        y1={1.27393e-5}
        x2={231.045}
        y2={112.991}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export const CommunityEventCardContainer: React.FC<{
  initialCardData: typeof UpcomingCommunityCalEventInstanceCard.Encoded;
  showCommunityName?: boolean;
}> = ({ initialCardData, showCommunityName = false }) => {
  const hpState = useHpState();
  const myId = useMyId();
  const now = useOnce(() => Date.now());

  const fullCardData = useQuery(
    api.Screens.Community.CommunityEventsScreenFns.getCommunityCardData,
    {
      communitySlug: initialCardData.communitySlug,
      myUserId: myId,
      instanceId: initialCardData.instance._id as Id<"calEventInstances">,
      now,
    }
  );

  const cardData = fullCardData ?? initialCardData;
  const isLoading = fullCardData === undefined || cardData.isLoading;

  const openDetailsPanel = () => {
    hpState.dashboardState.openRightNav({
      _tag: "CUSTOM",
      topView: <></>,
      content: (
        <CommunityEventDetailsPanel
          instanceId={cardData.instance._id as Id<"calEventInstances">}
          closePanel={(mbMessage) => {
            if (mbMessage) {
              hpState.dashboardState.showBottomToast({
                msg: mbMessage.message,
                reload: true,
                closeRightNav: true,
              });
            } else {
              hpState.dashboardState.closeRightNav();
            }
          }}
        />
      ),
    });
  };

  return (
    <CommunityCardContainer
      key={cardData.instance._id}
      title={cardData.title}
      isLoading={isLoading}
      headerBackgroundType={{
        _tag: "CUSTOM",
        color: "purple",
        content: (
          <div className="w-full h-full relative flex flex-col p-4">
            <div className="absolute inset-0 flex flex-col">
              <PurpleSvgHeaderBg />
            </div>
            <div className="absolute inset-0 z-20 flex flex-col items-center justify-center">
              {cardData.isLive ? (
                <div className="text-white p-4">Live</div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ),
      }}
      onClick={() => {
        openDetailsPanel();
      }}
      content={
        <div
          className="flex flex-col items-center cursor-pointer gap-1"
          onClick={() => {
            openDetailsPanel();
          }}
        >
          <h4 className="font-bold">{cardData.occurenceMessage}</h4>
          <div className="text-md text-vid-black-500">
            {format(cardData.instance.startAt, "MMM d, yyyy h:mm a")}
          </div>
          <div className="flex flex-col gap-1 items-center mt-2">
            <AvatarCircles
              sources={cardData.participants.map((m) => m.profilePhoto)}
              size={44}
            />
          </div>
          {showCommunityName && (
            <div className="text-xs text-gray-500">
              {cardData.communitySlug}
            </div>
          )}
        </div>
      }
      button={cardData.button}
    />
  );
};

export const CommunityEventDetailsPanel: React.FC<{
  instanceId: Id<"calEventInstances">;
  closePanel: (p: { message: string } | null) => void;
}> = ({ instanceId, closePanel }) => {
  const nav = useNavigate();
  const fullyRemoveCommunityCalEvent = useMutation(
    api.Calendar.CommunityCalEventFns.fullyRemoveCommunityCalEvent
  );
  const removeCommunityCalEventSingleInstance = useMutation(
    api.Calendar.CommunityCalEventFns.removeCommunityCalEventSingleInstance
  );
  const onSubmitCta = useMutation(
    api.Screens.Community.CommunityEventsScreenFns.handleCtaSubmit
  );
  const now = useOnce(() => Date.now());

  const panelData = useQuery(
    api.Screens.Community.CommunityEventsScreenFns
      .getCommunityEventDetailsPanelData,
    {
      now,
      instanceId,
    }
  );

  const onCardClick = (
    panelData: typeof CommunityEventDetailsPanelST.Encoded
  ) => {
    onSubmitCta({
      templateId: panelData.templateId as Id<"calendarEventTemplates">,
      instanceId,
      cta: panelData.button.cta.action,
    }).then((r) => {
      console.log("RESULT OF SUBMIT CTA!!! ", r);
      const nextAction: () => void = Match.value(r).pipe(
        Match.when({ nextAction: "GO_TO_SESSION" }, ({ baseSessionId }) => {
          return () =>
            nav(
              UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                sessionId: baseSessionId,
              })
            );
        }),
        Match.when({ nextAction: "CLOSE_AND_REFRESH" }, () => {
          return () => closePanel(null);
        }),
        Match.when({ nextAction: "DO_NOTHING" }, () => {
          return () => {};
        }),
        Match.exhaustive
      );

      nextAction();
    });
  };

  if (panelData === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex justify-end px-4">
        <ThreeDotsMenu
          size={30}
          menuItems={[
            {
              label: "Remove this event from cal",
              onClick: () => {
                closePanel({ message: "Removedevent" });
                removeCommunityCalEventSingleInstance({
                  instanceId,
                }).then(() => {});
              },
            },
            {
              label: "Remove all recurring events",
              onClick: () => {
                closePanel({ message: "Removed events" });
                fullyRemoveCommunityCalEvent({
                  templateId:
                    panelData.templateId as Id<"calendarEventTemplates">,
                }).then(() => {});
              },
            },
          ]}
        />
      </div>
      <div className="text-2xl font-bold">{panelData.title}</div>
      <div className="text-md text-vid-black-500">{panelData.description}</div>
      <div className="text-md text-vid-black-500">
        {panelData.occurenceMessage}
      </div>
      <div className="text-md text-vid-black-500">{panelData.occursAtStr}</div>
      <div>
        {Match.value(panelData.button).pipe(
          Match.when({ style: "REGULAR" }, () => (
            <PrimaryButton
              title={panelData.button.title}
              onClick={() => {
                onCardClick(panelData);
              }}
            />
          )),
          Match.when({ style: "INVERSE" }, () => (
            <InversePrimaryButton
              title={panelData.button.title}
              onClick={() => {
                onCardClick(panelData);
              }}
            />
          )),
          Match.exhaustive
        )}
      </div>
    </div>
  );
};
