import { useCallStateHooks } from "@stream-io/video-react-sdk";
import { Effect, Equal, Option } from "effect";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import React, { useEffect, useMemo, useRef } from "react";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD, type TE } from "shared/base-prelude";
import type { FileSchemas } from "shared/schemas/file.schemas";
import type { ApiMgr, AuthedApi } from "./api.mgr";

export function createContextAndHook<T>(
  displayName?: string
): [React.Context<T | null>, () => T] {
  const Context = React.createContext<T | null>(null);

  function useContext() {
    const ctx = React.useContext(Context);
    if (ctx === null) {
      throw new Error(
        `use${displayName} must be used within a ${displayName ?? ""}Provider`
      );
    } else {
      return ctx as T;
    }
  }

  return [Context, useContext];
}

export function useOnce<T>(factory: () => T): T {
  const ref = useRef<T | null>(null);

  if (ref.current === null) {
    ref.current = factory();
  }

  return ref.current;
}

export function useRunSuccessEffectO$<V>(
  eff: Effect.Effect<V, never, never>,
  deps?: any[]
) {
  const mbV$ = useOnce(
    () => new Rx.BehaviorSubject<Option.Option<V>>(Option.none())
  );

  useEffect(() => {
    mbV$.next(Option.none());
    Effect.runPromise(eff).then((v) => mbV$.next(Option.some(v)));
  }, deps);

  return mbV$;
}

export function useRunSuccessEffectO<V>(
  eff: Effect.Effect<V, never, never>,
  deps?: any[]
) {
  const mbV$ = useRunSuccessEffectO$(eff, deps);
  return useObservableEagerState(mbV$);
}

export function base64ToBlob(
  base64: string,
  fileMetadata: FileSchemas.FileMetadata
): Blob {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: fileMetadata.mimeType });
}

export function useKeyOfBehaviorSubjectAsState<V, K extends keyof V>(
  obj$: Rx.BehaviorSubject<V>,
  key: K
): V[K] {
  const value$ = useKeyOfObservable(obj$, key);
  const v = useObservableEagerState(value$);

  return v;
}

export function useKeyOfObservable<V, K extends keyof V>(
  obj$: Rx.Observable<V>,
  key: K
): Rx.Observable<V[K]> {
  const value$ = useMemo(
    () => obj$.pipe(RxO.map((obj) => obj[key])),
    [obj$, key]
  );

  return value$;
}

export function useKeyOfObservableAsState<V, K extends keyof V>(
  obj$: Rx.Observable<V>,
  key: K,
  defaultValue: V[K]
): V[K] {
  const value$ = useKeyOfObservable(obj$, key);
  const v = useObservableState(value$, defaultValue);

  return v;
}

export class FetchableStateAtom<V, E = any> {
  rdValue$: Rx.BehaviorSubject<RD.RemoteData<E, V>>;

  constructor(
    private readonly fetchTE: TE.TaskEither<E, V>,
    initialValue?: V
  ) {
    this.fetchTE = fetchTE;
    this.rdValue$ = new Rx.BehaviorSubject<RD.RemoteData<E, V>>(
      initialValue === undefined ? RD.initial : RD.success(initialValue)
    );
  }

  get value$() {
    return this.rdValue$.asObservable();
  }

  fetchAndSet() {
    this.rdValue$.next(RD.pending);
    this.fetchTE().then((result) => {
      this.rdValue$.next(RD.fromEither(result));
    });
  }
}

export class FetchSuccessAtom<V> {
  rdValue$: Rx.BehaviorSubject<RD.RemoteData<never, V>>;

  mbValue$: Rx.Observable<Option.Option<V>>;

  constructor(
    private readonly p: {
      endpt: (api: AuthedApi) => Promise<V>;
      apiMgr: ApiMgr;
      initialValue?: V;
    }
  ) {
    this.rdValue$ = new Rx.BehaviorSubject<RD.RemoteData<never, V>>(
      p.initialValue === undefined ? RD.initial : RD.success(p.initialValue)
    );

    this.mbValue$ = this.rdValue$.pipe(
      RxO.map(
        RD.fold3(
          () => Option.none(),
          () => Option.none(),
          (v) => Option.some(v)
        )
      )
    );
  }

  get value$() {
    return this.rdValue$.asObservable();
  }

  fetchAndSetEff = (setPending?: boolean) =>
    Effect.gen(this, function* () {
      if (setPending) {
        this.rdValue$.next(RD.pending);
      }
      const result = yield* this.p.apiMgr.fetchSuccessOnlyEndpoint(
        this.p.endpt
      );
      this.rdValue$.next(RD.success(result));
      return result;
    });

  runFetchAndSet(setPending?: boolean) {
    Effect.runPromise(this.fetchAndSetEff(setPending)).catch((err) => {
      console.error("FETCH AND SET ERROR! ", err);
    });
  }
}

export function useRemoteParticipants$() {
  const { useRemoteParticipants } = useCallStateHooks();
  const remoteParticipants = useRemoteParticipants();
  const remoteParticipants$ = useOnce(
    () => new Rx.BehaviorSubject(remoteParticipants)
  );

  useEffect(() => {
    const sub = remoteParticipants$.subscribe();
    return () => sub.unsubscribe();
  }, [remoteParticipants$]);

  return remoteParticipants$;
}

export function getLastEmittedValue<T>(
  stream$: Rx.Observable<T>
): Effect.Effect<T, Error> {
  let sub: Rx.Subscription | undefined = undefined;
  return Effect.tryPromise(() => {
    return new Promise<T>((resolve, reject) => {
      sub = stream$.subscribe({
        next: (value) => {
          resolve(value);
        },
        error: (err) => {
          reject(err);
        },
        complete: () => {
          reject(new Error("Observable completed without emitting a value"));
        },
      });

      // Ensure subscription is cleaned up if Effect is interrupted
      return () => {
        if (sub) {
          sub.unsubscribe();
        }
      };
    });
  });
}

export const distinctUntilChangedEquals = <T>() =>
  RxO.distinctUntilChanged((a: T, b: T) => Equal.equals(a, b));
