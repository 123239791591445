import type { ConvexClient } from "convex/browser";
import { type Either } from "effect";
import { ApiMgr, type AuthedApi } from "frontend-shared/src/api.mgr";
import {
  FetchSuccessStateMgr,
  FetchWithHandleErrorStateMgr,
  type NoErrorFetchState,
} from "frontend-shared/src/fetch";
import type { FirebaseJsMgr } from "frontend-shared/src/firebase";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import { AppConfig, firebaseMgr } from "./app-config";
import { ErrorTracking } from "./error-tracking";
import { webConvex } from "./convex-cli";
import { globalErrorToastMgr } from "./global.state";

console.log("API URL! ", AppConfig.apiUrl);

export function mkWebApiMgr(p: {
  apiUrl: string;
  firebaseMgr: FirebaseJsMgr;
  convexCli: ConvexClient;
}) {
  return new ApiMgr(
    p.apiUrl,
    p.convexCli,
    p.firebaseMgr.getFirebaseTokenEff,
    fetch,
    {
      reportError: ErrorTracking.reportError,
      appActionAfterDefect: (errorMessage: string) => {
        console.error("APP ACTION AFTER DEFECT! ", errorMessage); //TODO
        console.log("SHOWING TOAST!");
        globalErrorToastMgr.showToast({
          title: errorMessage,
          message:
            "If the issue persists, please reach out to support@insightlive.space",
          durationSeconds: 20,
          severity: "error",
        });
      },
      onResolved: () => {
        globalErrorToastMgr.hideToast();
      },
      onFailToReachServerError: () => {},
    }
  );
}

export const apiMgr = mkWebApiMgr({
  apiUrl: AppConfig.apiUrl!,
  firebaseMgr,
  convexCli: webConvex, // TODO THIS IS BAD
});

export class BE {
  static Api = apiMgr.Api;

  static PublicApi = apiMgr.PublicApi();

  static fetchEndpointTE = apiMgr.fetchEndpointTE;
  static publicTE = apiMgr.publicTE;
  static fetchEndpointEff = apiMgr.fetchEndpointEff;

  static fetchSuccessOnlyEndpoint = apiMgr.fetchSuccessOnlyEndpoint;
  static fetchSuccessOnlyEndpointP = apiMgr.fetchSuccessOnlyEndpointP;
  static fetchEndpointWithHandleError = apiMgr.fetchEndpointWithHandleError;
  static fetchEndpointWithHandleErrorP = apiMgr.fetchEndpointWithHandleErrorP;
  static runFetchEndpointWithHandleError =
    apiMgr.runFetchEndpointWithHandleError;

  static useFetchSuccessStateMgr<V>(
    trpcquery: (api: AuthedApi) => Promise<V>,
    options: {
      autoFetchAndSetState: boolean;
    }
  ) {
    const mgr = useOnce(
      () => new FetchSuccessStateMgr(apiMgr, webConvex, trpcquery, options)
    );
    return mgr;
  }

  static useFetchWithErrorStateMgr<V, E extends Error>(
    trpcquery: (api: AuthedApi) => Promise<Either.Either<V, E>>,
    options: {
      autoFetchAndSetState: boolean;
    }
  ) {
    const mgr = useOnce(
      () =>
        new FetchWithHandleErrorStateMgr(apiMgr, webConvex, trpcquery, options)
    );
    return mgr;
  }

  static useSuccessFetchO = apiMgr.useSuccessFetchO;

  static useSuccessFetch<V>(
    trpcquery: (api: AuthedApi) => Promise<V>,
    deps: any[]
  ): NoErrorFetchState<V> {
    const mgr = this.useFetchSuccessStateMgr<V>(trpcquery, {
      autoFetchAndSetState: false,
    });
    const fetchState = useObservableEagerState(mgr.fetchState$);

    useEffect(() => {
      mgr.fetchAndSetState();
    }, deps);

    return fetchState;
  }
}
