import { route, string } from "react-router-typesafe-routes/dom";
import { DASHBOARD_ROUTES } from "./hp-dashboard.routes";

export const HPROUTES = route(
  "hp",
  {},
  {
    ONBOARD_CHOOSE_PROFILE_METHOD: route("choose-profile-method"),
    ONBOARD_CHOOSE_IMPORT_PROFILE_METHOD: route("choose-import-profile-method"),
    ONBOARD_SET_PROFILE: route("set-profile", {
      searchParams: {
        fromUrl: string(),
        redirectTo: string(),
      },
    }),
    ONBOARD_COMPLETE: route("complete"),
    MY: route(
      "my",
      {},
      {
        DASHBOARD: DASHBOARD_ROUTES,
      }
    ),
  }
);
