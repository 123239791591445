import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import { useQuery } from "convex/react";
import { Match, Option } from "effect";
import { signOut } from "firebase/auth";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import {
  AuthStateMgr,
  type AuthState,
} from "frontend-shared/src/mgrs/state-mgrs/auth/auth.statemgr";
import { useSetupUStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { UserPrimaryRole } from "shared/db";
import { UROUTES } from "shared/routes/u.routes";
import { useConvexCli } from "src/convex-cli";
import { StreamChat } from "stream-chat";
import { UWebStateMgr, UWebStateMgrContext } from "./u.webstatemgr";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";

export const ULayout: React.FC = () => {
  const convex = useConvexCli();
  const authStateMgr = useOnce(
    () => new AuthStateMgr({ apiMgr: apiMgr, convex })
  );
  const rdAuthState = useObservableEagerState(authStateMgr.authState$);

  useEffect(() => {
    authStateMgr.runCheckAuthState();
  }, []);

  console.log("U LAYOUT! ", rdAuthState);

  return Match.value(rdAuthState).pipe(
    Match.tag("RemoteInitial", () => <FullScreenLoadingSpinner />),
    Match.tag("RemotePending", () => <FullScreenLoadingSpinner />),
    Match.tag("RemoteFailure", () => <div>Error</div>), // TODO
    Match.tag("RemoteSuccess", (authState) => (
      <ULoadedView authState={authState.value} />
    )),
    Match.exhaustive
  );
};

export const ULoadedView: React.FC<{
  authState: AuthState;
}> = ({ authState }) => {
  const firebaseJs = useFirebaseJs();
  useEffect(() => {
    if (authState._tag === "UNAUTHORIZED") {
      signOut(firebaseJs.auth).finally(() => {
        window.location.href = "/";
      });
    }
  }, [authState]);

  console.log("ULoadedView! ", authState);

  return Match.value(authState).pipe(
    Match.tag("UNAUTHORIZED", () => <div></div>),
    Match.tag("AUTHORIZED", ({ mbPrimaryRole, me }) => (
      <AuthorizedView mbPrimaryRole={mbPrimaryRole} me={me} />
    )),
    Match.exhaustive
  );
};

const AuthorizedView: React.FC<{
  mbPrimaryRole: UserPrimaryRole | null;
  me: SimpleUserWithProfilePhoto;
}> = ({ mbPrimaryRole, me }) => {
  const myId = useQuery(api.User.UserFns.getMyId, {});

  if (myId === undefined) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <LoadedAuthorizedView mbPrimaryRole={mbPrimaryRole} me={me} myId={myId} />
  );
};

const LoadedAuthorizedView: React.FC<{
  mbPrimaryRole: UserPrimaryRole | null;
  me: SimpleUserWithProfilePhoto;
  myId: Id<"users">;
}> = ({ mbPrimaryRole, me, myId }) => {
  const location = useLocation();
  const nav = useNavigate();
  const firebaseJs = useFirebaseJs();

  const myOngoingHcSessions = useQuery(
    api.Sessions.Hc.HcSessionFns.getMyOngoingHcSessionsForHpPortal,
    {}
  );

  useEffect(() => {
    console.log("MY ONGOING HC SESSIONS! ", myOngoingHcSessions);
  }, [myOngoingHcSessions]);

  const convex = useConvexCli();

  const mbUStateMgr = useSetupUStateMgr<
    StreamChat,
    Call,
    StreamVideoClient,
    WebRtcMgr,
    UWebStateMgr
  >(
    apiMgr,
    convex,
    firebaseJs,
    me,
    myId,
    (p) => StreamChat.getInstance(p.apiKey),
    (p) => new UWebStateMgr({ ...p, baseUserId: myId })
  );

  console.log("MB U STATE MGR! ", mbUStateMgr);

  useEffect(() => {
    if (location.pathname === UROUTES.path) {
      Match.value(mbPrimaryRole).pipe(
        Match.when("client", () => {
          nav(UROUTES.CP.DASHBOARD.HOME.path);
        }),
        Match.when("hp", () => {
          nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
        }),
        Match.when(null, () => {
          nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
        }),
        Match.exhaustive
      );
    }
  }, [mbPrimaryRole, location.pathname]);

  if (Option.isNone(mbUStateMgr)) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <UWebStateMgrContext.Provider value={mbUStateMgr.value}>
      <Outlet />
    </UWebStateMgrContext.Provider>
  );
};
