import { EyeIcon } from "@webapp/componentsicons/eyeicon";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { Match } from "effect";
import { pipe } from "fp-ts/function";
import {
  messageForFirebaseAuthError,
  useFirebaseJs,
} from "frontend-shared/src/firebase";
import {
  LoginEmailPasswordFormStateMgr,
  LoginEmailPasswordFormStateMgrContext,
  useLoginEmailPasswordFormStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/auth/authenticate.statemgr";
import {
  useKeyOfBehaviorSubjectAsState,
  useOnce,
} from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { RemoteData } from "shared/remote-data";
import { ROUTES } from "shared/routes/routes";
import { UROUTES } from "shared/routes/u.routes";
import { useConvexCli } from "src/convex-cli";
import { apiMgr } from "../backend";
import { LoadingSpinner } from "../loading";
import { O, RD } from "../prelude";

export const LoginPage: React.FC = () => {
  const [{ prefilledEmail, redirectTo }] = useTypedSearchParams(ROUTES.LOGIN);
  const firebaseJs = useFirebaseJs();
  const convex = useConvexCli();
  const formMgr = useOnce(
    () =>
      new LoginEmailPasswordFormStateMgr({
        apiMgr: apiMgr,
        convex,
        firebaseJsMgr: firebaseJs,
        mbInitialData: pipe(
          prefilledEmail,
          O.fromNullable,
          O.map((email) => ({ email, password: "" }))
        ),
      })
  );

  return (
    <LoginEmailPasswordFormStateMgrContext.Provider value={formMgr}>
      <div className="flex-1 flex flex-col justify-center items-center">
        <LoginCard mbRedirectTo={redirectTo} />
      </div>
    </LoginEmailPasswordFormStateMgrContext.Provider>
  );
};

const LoginCard: React.FC<{
  mbRedirectTo?: string;
}> = ({ mbRedirectTo }) => {
  const nav = useNavigate();
  const formMgr = useLoginEmailPasswordFormStateMgr();
  const rdForgotMyPwdResult = useObservableEagerState(
    formMgr.rdSendPasswordResetResponse$
  );

  const firebaseJs = useFirebaseJs();
  const email = useKeyOfBehaviorSubjectAsState(formMgr.formData$, "email");
  const password = useKeyOfBehaviorSubjectAsState(
    formMgr.formData$,
    "password"
  );
  const rdLoginResult = useObservableEagerState(formMgr.submitResult$);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (rdLoginResult.isSuccess()) {
      console.log(
        "mbRedirectTo",
        mbRedirectTo,
        decodeURIComponent(mbRedirectTo!)
      );
      const nextRoute = mbRedirectTo
        ? decodeURIComponent(mbRedirectTo)
        : UROUTES.HP.MY.DASHBOARD.CLIENTS.path;

      console.log("nextRoute", nextRoute);
      nav(nextRoute);
      // nav(
      //   mbRedirectTo
      //     ? decodeURIComponent(mbRedirectTo)
      //     : UROUTES.HP.MY.DASHBOARD.CLIENTS.path
      // );
    }
  }, [rdLoginResult]);

  return (
    <div className="flex-1 flex flex-col justify-center items-center gap-2">
      <h1 className="font-outfit font-medium text-[54px] leading-[160%] text-center text-vid-purple w-[750px]">
        Login
      </h1>
      <form
        className="flex flex-col gap-4 w-full max-w-[400px]"
        onSubmit={(e) => {
          e.preventDefault();
          formMgr.submit();
        }}
      >
        <input
          type="email"
          placeholder="Email"
          className="text-input"
          id="email"
          value={email}
          onChange={(e) => formMgr.setFormValue("email", e.target.value)}
        />
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="text-input pr-10 w-full"
            id="password"
            value={password}
            onChange={(e) => formMgr.setFormValue("password", e.target.value)}
          />
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={() => setShowPassword(!showPassword)}
          >
            <EyeIcon showPassword={showPassword} />
          </button>
        </div>

        {RemoteData.match3(rdLoginResult, {
          onPresubmit: () => <></>,
          onSuccess: () => <div></div>,
          onFailure: (e) =>
            Match.value(e).pipe(
              Match.tag("FirebaseSigninError", (e) => (
                <div className="text-red-500 self-center">
                  {messageForFirebaseAuthError(e.error.code)}
                </div>
              )),
              Match.tag("LOGIN_VIA_API_ERROR", (e) => (
                <div className="text-red-500 self-center max-w-[400px] flex-wrap">
                  {e.error.message}
                </div>
              )),
              Match.exhaustive
            ),
        })}

        <PrimaryButton
          title={rdLoginResult.isLoading() ? "Submitting..." : "Login"}
          type="submit"
        />
        {/* <button
          type="submit"
          className="bg-default-purple text-white px-8 py-4 rounded-lg font-bold"
        >
          {RD.isPending(rdLoginResult) ? "Submitting..." : "Login"}
        </button> */}
      </form>

      <div className="flex flex-col gap-4 mt-8">
        <button
          className="underline px-8 py-0 rounded-lg font-light font-sans text-sm text-vid-gray"
          onClick={() => {
            formMgr.sendPasswordResetEmail({ firebaseJs, email });
          }}
        >
          {`Forgot password?`}
        </button>
        {pipe(
          rdForgotMyPwdResult,
          RD.fold(
            () => <></>,
            () => <LoadingSpinner />,
            (e) => <div className="text-red-400">{JSON.stringify(e)}</div>,
            (_) => (
              <div className="text-available-green font-outfit font-bold text-2xl mt-4 animate-slideup">
                {`Password reset email sent to ${email}`}
              </div>
            )
          )
        )}
      </div>
      <button
        className=" text-vid-purple px-8 py-0 rounded-lg font-light font-sans text-sm"
        onClick={() => {
          window.location.href = "/onboard/portal-selection";
        }}
      >
        {`Don't have an account? Sign up`}
      </button>
    </div>
  );
};
