import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { apiMgr, BE } from "@webapp/backend";
import { AvatarCirclesWithCountInfo } from "@webapp/componentsavatar.tc";
import { ThreeDotsMenu } from "@webapp/componentsmenus/three-dots.menu";
import { CopyLinkButton } from "@webapp/componentsmiscellaneous";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/componentsprimitives/button";
import { format } from "date-fns";
import { Match } from "effect";
import { CommunityEventInstanceStateMgr } from "frontend-shared/src/mgrs/state-mgrs/communities/community-events.statemgr";
import { useOnce } from "frontend-shared/src/util";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { E, type TE } from "shared/base-prelude";
import type {
  AttendingStatus,
  CalendarEventTemplateDTO,
} from "shared/types/calendar.types";
import {
  type FullCommunityEventInstanceData,
  type PrimaryCtaOrStatusForCommunityEvent,
} from "shared/types/community-event.types";
import { useConvexCli } from "src/convex-cli";

// TODO: Deprecate file!!!

export const ViewCommunityEventInstanceContent: React.FC<{
  communitySlug: string;
  eventInstance: FullCommunityEventInstanceData;
}> = ({ communitySlug, eventInstance }) => {
  const attendees = eventInstance.attendees.filter(
    (u) => u.attendingStatus === "attending"
  );
  const convex = useConvexCli();
  const stateMgr = useOnce(
    () =>
      new CommunityEventInstanceStateMgr(communitySlug, eventInstance, {
        apiMgr: apiMgr,
        convex,
      })
  );
  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex justify-end">
        <ThreeDotsMenu
          size={30}
          menuItems={[
            {
              label: "Remove this event from cal",
              onClick: () => {},
            },
            {
              label: "Remove all recurring events",
              onClick: () => {},
            },
          ]}
        />
      </div>
      {eventInstance.description && (
        <div className="flex items-center gap-2">
          <span className="font-semibold">Description</span>
          <span>{eventInstance.description}</span>
        </div>
      )}
      <div className="flex items-center gap-2">
        <span className="font-semibold">Start time:</span>
        <span>{format(eventInstance.startTime, "PPpp")}</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="font-semibold">Duration:</span>
        <span>{eventInstance.durationInMinutes} minutes</span>
      </div>
      {eventInstance.recurrenceRule && (
        <div className="flex items-center gap-2">
          <span className="font-semibold">Recurrence:</span>
          <span>{eventInstance.recurrenceRule.frequency}</span>
        </div>
      )}
      {eventInstance.attendees && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <span className="font-semibold">Attending Participants:</span>
            <div className="flex items-center gap-2">
              <AvatarCirclesWithCountInfo
                countText={`${attendees.length} Attending`}
                sources={attendees.map((m) => m.profilePhoto)}
                size={44}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="font-semibold">Interested Participants:</span>
            {eventInstance.attendees
              .filter((u) => u.attendingStatus === "maybe")
              .map((u) => {
                return <div className="flex items-center gap-2">{u.name}</div>;
              })}
          </div>
          <div className="self-center mt-8">
            <CopyLinkButton
              text={"Copy invite link"}
              link={eventInstance.shareableLink}
              onCopied={() => {
                // hpState.dashboardState.showBottomToast({
                //   msg: "Invite link copied to clipboard",
                // });
              }}
            />
          </div>
        </div>
      )}
      <BottomButtonSectionForCta
        communitySlug={communitySlug}
        eventTemplateId={eventInstance.eventTemplateId}
        ctaOrStatusForUser={eventInstance.primaryCtaOrStatus}
        stateMgr={stateMgr}
      />
    </div>
  );
};

const BottomButtonSectionForCta: React.FC<{
  communitySlug: string;
  eventTemplateId: string;
  ctaOrStatusForUser: PrimaryCtaOrStatusForCommunityEvent | null;
  stateMgr: CommunityEventInstanceStateMgr;
}> = ({ communitySlug, eventTemplateId, ctaOrStatusForUser }) => {
  const nav = useNavigate();
  if (ctaOrStatusForUser === null) {
    return <></>;
  }
  return Match.value(ctaOrStatusForUser.ctaOrStatus).pipe(
    Match.tag("NextUserCtaForCommunityEvent", ({ cta }) =>
      Match.value(cta).pipe(
        Match.tag("RSVP", () => {
          return (
            <div className="flex items-center gap-4">
              <RsvpButtons
                setStatusForAllInstancesTE={(status) =>
                  BE.fetchEndpointTE((Api) =>
                    Api.hp.communityEvent.setStatusForAllEventInstances.mutate({
                      communitySlug: communitySlug,
                      eventTemplateId: eventTemplateId,
                      status: status,
                    })
                  )
                }
              />
            </div>
          );
        }),
        Match.tag("EnterGroupSession", ({ entryMethod }) => {
          return (
            <PrimaryButton
              title={entryMethod === "START" ? "Start" : "Join"}
              onClick={() => {
                console.log("Entering group session");
              }}
              disabled={false}
            />
          );
        }),
        Match.exhaustive
      )
    ),
    Match.tag("PrimaryUserStatusForCommunityEvent", () => <></>),
    Match.exhaustive
  );
};

const RsvpButtons: React.FC<{
  setStatusForAllInstancesTE: (
    status: AttendingStatus
  ) => TE.TaskEither<any, any>;
}> = ({ setStatusForAllInstancesTE }) => {
  const hpState = useHpState();
  const [searchParams, setSearchParams] = useSearchParams();

  const clearShowEventInstanceRightNav = () => {
    searchParams.delete("showEventInstanceRightNav");
    setSearchParams(searchParams);
  };

  return (
    <div className="flex items-center gap-4">
      <PrimaryButton
        title="Attending"
        onClick={() => {
          console.log("Attending");
          setStatusForAllInstancesTE("attending")().then((er) => {
            if (E.isRight(er)) {
              clearShowEventInstanceRightNav();
              hpState.dashboardState.closeRightNav();
              hpState.dashboardState.showBottomToast({
                msg: "Marked as attending",
                reload: true,
              });
            }
          });
        }}
      />
      <InversePrimaryButton
        title="Interested"
        onClick={() => {
          setStatusForAllInstancesTE("maybe")().then((er) => {
            if (E.isRight(er)) {
              clearShowEventInstanceRightNav();
              hpState.dashboardState.closeRightNav();
              hpState.dashboardState.showBottomToast({
                msg: "Marked as interested",
                reload: true,
              });
            }
          });
        }}
      />
    </div>
  );
};

export const ViewCommunityEventContent: React.FC<{
  communitySlug: string;
  eventTemplate: CalendarEventTemplateDTO;
}> = ({ communitySlug, eventTemplate }) => {
  const hpState = useHpState();
  return (
    <div className="flex flex-col gap-4 p-4">
      <p className="text-gray-600">{eventTemplate.description}</p>
      <div className="flex items-center gap-2">
        <span className="font-semibold">Start time:</span>
        <span>{format(eventTemplate.startTime, "PPpp")}</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="font-semibold">Duration:</span>
        <span>{eventTemplate.durationInMinutes} minutes</span>
      </div>
      {eventTemplate.recurrenceRule && (
        <div className="flex items-center gap-2">
          <span className="font-semibold">Recurring:</span>
          <span>{eventTemplate.recurrenceRule.frequency}</span>
        </div>
      )}
      <RsvpButtons
        setStatusForAllInstancesTE={(status) =>
          BE.fetchEndpointTE((Api) =>
            Api.hp.communityEvent.setStatusForAllEventInstances.mutate({
              communitySlug: communitySlug,
              eventTemplateId: eventTemplate.id,
              status: status,
            })
          )
        }
      />

      {/* {event.r && (
        <div className="flex items-center gap-2">
          <span className="font-semibold">Recurrence:</span>
          <span>{event.recurrenceRule.frequency}</span>
        </div>
      )} */}
    </div>
  );
};
