import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import type { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import type { UserStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import React from "react";
import { StreamChat } from "stream-chat";
import { DashboardStateMgr } from "../../../components/dashboard/dashboard.state";
import { Rx } from "../../../prelude";
import type { CpDashboardRightNav } from "./right-nav";
export type CpSimpleProfile = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  profilePhoto: string | null;
  worksWithClients: boolean | null;
};

export class CpState {
  myUserId: string;
  dashboardMgr: DashboardStateMgr<CpDashboardRightNav>;
  myProfile$: Rx.BehaviorSubject<CpSimpleProfile>;

  constructor(
    readonly uMgr: UserStateMgr<StreamChat, Call, StreamVideoClient, WebRtcMgr>,
    p: { myProfile: CpSimpleProfile }
  ) {
    this.myUserId = p.myProfile.id;
    this.myProfile$ = new Rx.BehaviorSubject(p.myProfile);

    this.dashboardMgr = new DashboardStateMgr<CpDashboardRightNav>({
      pubSubNotifDisplayRsm: this.uMgr.pubSubNotifDisplayRsm,
    });
  }

  updateName = (p: { firstName?: string; lastName?: string }) => {
    const firstName = p.firstName ?? this.myProfile$.value.firstName;
    const lastName = p.lastName ?? this.myProfile$.value.lastName;
    this.myProfile$.next({
      ...this.myProfile$.value,
      firstName,
      lastName,
    });
  };
}

export const CpStateContext = React.createContext<CpState | null>(null);

export function useCpState() {
  return React.useContext(CpStateContext)!;
}
