import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import type { ConvexClient } from "convex/browser";
import { Effect, Option } from "effect";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import type { FirebaseJsMgr } from "frontend-shared/src/firebase";
import { UserStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { createContextAndHook } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import type { Id } from "shared/convex/_generated/dataModel";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import type { SimpleUserWithProfilePhoto } from "shared/types/user.types";
import { StreamChat } from "stream-chat";

export class UWebStateMgr extends UserStateMgr<
  StreamChat,
  Call,
  StreamVideoClient,
  WebRtcMgr
> {
  baseUserId: Id<"users">;
  firebaseMgr: FirebaseJsMgr;
  constructor(p: {
    me: SimpleUserWithProfilePhoto;
    baseUserId: Id<"users">;
    apiMgr: ApiMgr;
    convex: ConvexClient;
    firebaseMgr: FirebaseJsMgr;
    chatKeyAndToken: { apiKey: string; token: string };
    rtcCreds: RtcCredentials;
  }) {
    console.log("P.ME!!! ", p.me);
    super({
      ...p,
      myId: p.baseUserId,
      chatKeyAndToken: p.chatKeyAndToken,
      rtcCreds: p.rtcCreds,
      mkChatCli: ({ apiKey }) => StreamChat.getInstance(apiKey),
      mkRtcMgr: (p) => new WebRtcMgr(p),
    });
    this.baseUserId = p.baseUserId;
    this.firebaseMgr = p.firebaseMgr;
  }

  signOut = Effect.promise(() => this.firebaseMgr.signOut());
}

export const [UWebStateMgrContext, useUMgr] =
  createContextAndHook<UWebStateMgr>();

export function useRtcMgr() {
  const uMgr = useUMgr();
  return uMgr.rtcMgr;
}

export function useAssumedCurrentChannel(): Call {
  const rtcMgr = useRtcMgr();

  const mbChannelMgr = useObservableEagerState(rtcMgr.mbCurrentChannelMgr$);

  if (Option.isNone(mbChannelMgr)) {
    throw new Error("No current channel");
  }

  return mbChannelMgr.value;
}

export function useMe() {
  const uMgr = useUMgr();
  const me = useObservableEagerState(uMgr.me$);
  return me;
}

export function useMyId() {
  const uMgr = useUMgr();
  return uMgr.baseUserId;
}
