import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { FullContainerLoadingSpinner, LoadingSpinner } from "@webapp/loading";
import { Rx } from "@webapp/prelude";
import { FileUploadUtils, FileUtils } from "@webapp/utils/file.utils";
import type { ConvexClient } from "convex/browser";
import { useAction, useQuery } from "convex/react";
import { Effect, Match } from "effect";
import { BaseConvexStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { PreprocessedMediaStateMgr } from "frontend-shared/src/mgrs/state-mgrs/preprocessed-media.statemgr";
import { createContextAndHook, useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useState } from "react";
import { api } from "shared/convex/_generated/api";
import type { Id } from "shared/convex/_generated/dataModel";
import type { InMemoryFile } from "shared/schemas/file.schemas";
import { KnownCloudinaryPtrs } from "shared/schemas/known-remote-file-ptrs/known-cloudinary-ptrs.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { isNotNullOrUndefined } from "shared/util";
import { useConvexCli } from "src/convex-cli";

class LocalState extends BaseConvexStateMgr {
  postId: Id<"communityPosts">;
  content$ = new Rx.BehaviorSubject<string>("");
  inMemorySelectedFile$ = new Rx.BehaviorSubject<InMemoryFile | null>(null);
  mediaStateUploadMgr = new PreprocessedMediaStateMgr();

  constructor(readonly p: { setupRes: SetupRes; convexCli: ConvexClient }) {
    super({
      convex: p.convexCli,
    });
    this.postId = p.setupRes.postId;
    this.content$
      .pipe(
        Rx.filter((content) => content.length > 0),
        Rx.debounceTime(1000),
        Rx.distinctUntilChanged()
      )
      .subscribe((content) => {
        this.convex
          .mutation(api.Community.CommunityScreenFns.onDraftContentChanged, {
            postId: this.postId,
            newContent: content,
          })
          .catch();
      });
  }

  setContent(content: string) {
    this.content$.next(content);
  }

  setMedia(file: InMemoryFile) {
    this.inMemorySelectedFile$.next(file);
  }

  onSubmitPostP = () => {
    return this.convex.mutation(api.Community.CommunityScreenFns.onSubmitPost, {
      postId: this.postId,
    });
  };

  onMediaSelected(imf: InMemoryFile) {
    this.setMedia(imf);
    this.uploadMediaToCloudinary(imf).then((r) => {
      console.log("UPLOADED TO CLOUDINARY");
      if (r !== null) {
        console.log("Uploaded media to cloudinary: ", r);
      }
    });
  }

  uploadMediaToCloudinary = async (file: InMemoryFile): Promise<void> => {
    const uploadTask = Match.value(file.fileMetadata.mediaType).pipe(
      Match.when("video", () => {
        return () =>
          Effect.runPromise(
            FileUploadUtils.uploadInMemoryFileToCloudinary({
              secureUploadUrl: this.p.setupRes.videoUploadUrl,
              file,
            })
          );
      }),
      Match.when("image", () => {
        return () =>
          Effect.runPromise(
            FileUploadUtils.uploadInMemoryFileToCloudinary({
              secureUploadUrl: this.p.setupRes.imageUploadUrl,
              file,
            })
          );
      }),
      Match.when("audio", () => {
        return () => Promise.resolve(null);
      }),
      Match.exhaustive
    );

    this.mediaStateUploadMgr.setProcessing({ inMemoryFile: file });
    const res = await uploadTask();
    if (res !== null) {
      this.mediaStateUploadMgr.setProcessed({
        uploadResult: res,
        originalFileMetadata: file.fileMetadata,
        inMemoryFile: file,
        confirmedUploadedPtrEncoding:
          KnownCloudinaryPtrs.CommunityPostMediaVirtualPtr.from({
            public_id: res.public_id,
            asset_id: res.asset_id,
            mediaType: file.fileMetadata.mediaType,
          }).encodeSelf(),
      });
      await this.convex.mutation(
        api.Community.CommunityScreenFns
          .onMediaSuccessfullyUploadedToCloudinary,
        {
          postId: this.postId,
          publicId: res.public_id,
          format: file.fileMetadata.mimeType.split("/")[1],
          mediaType: file.fileMetadata.mediaType,
        }
      );
    }
  };
}

const [LocalStateContext, useLocalState] = createContextAndHook<LocalState>();

interface SetupRes {
  postId: Id<"communityPosts">;
  imageUploadUrl: string;
  videoUploadUrl: string;
}

export const CreateCommunityPostFormConvex: React.FC<{
  communitySlug: string;
}> = ({ communitySlug }) => {
  const [setupRes, setSetupRes] = useState<SetupRes | undefined>(undefined);

  const onOpenCreatePostForm = useAction(
    api.Community.CommunityScreenFns.onOpenCreatePostForm
  );

  useEffect(() => {
    onOpenCreatePostForm({
      communitySlug,
    }).then((r) => {
      setSetupRes(r);
    });
  }, []);

  if (setupRes === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <LoadedPostView setupRes={setupRes} />;
};

const LoadedPostView: React.FC<{
  setupRes: SetupRes;
}> = ({ setupRes }) => {
  const hpState = useHpState();
  const convex = useConvexCli();
  const localStateMgr = useOnce(
    () => new LocalState({ setupRes, convexCli: convex })
  );

  const postMedia = useQuery(api.Community.CommunityScreenFns.getPostMedia, {
    postId: setupRes.postId,
  });

  useEffect(() => {
    console.log("POST MEDIA CACHE FETCH?!: ", postMedia);
    // For performance, we make a cloudinary fetch once the image is processed in order to push it inot the cloudinary CDN cache
    if (isNotNullOrUndefined(postMedia) && postMedia.cachedDownloadUrl) {
      console.log("POST MEDIA: ", postMedia);

      fetch(postMedia.cachedDownloadUrl).then((r) => {
        console.log("FETCHED POST MEDIA: ", r);
      });
    }
  }, [postMedia]);

  return (
    <div className="flex flex-col gap-4 p-6 mt-8">
      <LocalStateContext.Provider value={localStateMgr}>
        <EnterTextSection />
        <MediaInputSection />
        <PrimaryButton
          title="Submit"
          onClick={() =>
            localStateMgr.onSubmitPostP().then((_) => {
              hpState.dashboardState.showBottomToast({
                msg: "Post submitted successfully!",
                duration: { _tag: "SECONDS", seconds: 3 },
                // reload: true,
                closeRightNav: true,
              });
            })
          }
        />
      </LocalStateContext.Provider>
    </div>
  );
};

const EnterTextSection: React.FC = () => {
  const localStateMgr = useLocalState();
  const content = useObservableEagerState(localStateMgr.content$);

  return (
    <div className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100">
      <div className="flex items-center gap-2">
        <h4 className="text-vid-black-900">Write something</h4>
      </div>
      <textarea
        value={content}
        onChange={(e) => localStateMgr.setContent(e.target.value)}
        className="w-full border rounded-lg px-4 py-2 h-[96px] bg-vid-black-100"
      />
    </div>
  );
};

const MediaInputSection: React.FC = () => {
  const localStateMgr = useLocalState();
  const mediaState = useObservableEagerState(
    localStateMgr.mediaStateUploadMgr.state$
  );

  return (
    <div className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100">
      {Match.value(mediaState).pipe(
        Match.tag("INITIAL", () => <AddMediaSection />),
        Match.tag("UNPROCESSED", ({ inMemoryFile }) => (
          <div className="relative h-[200px] w-full">
            <img
              src={ImageSrc.fromInMemoryFile(inMemoryFile).src}
              className="w-full object-cover"
            />
            <div className="absolute inset-0 bg-vid-black-200 opacity-50 flex items-center justify-center">
              <LoadingSpinner />
              <span className="text-vid-purple">Processing...</span>
            </div>
          </div>
        )),
        Match.tag("PROCESSED", ({ inMemoryFile }) => (
          <div>
            <img
              src={ImageSrc.fromInMemoryFile(inMemoryFile).src}
              className="w-full object-cover"
            />
          </div>
        )),
        Match.exhaustive
      )}
    </div>
  );
};

const AddMediaSection: React.FC = () => {
  const localStateMgr = useLocalState();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100"
      onClick={() => fileInputRef.current?.click()}
    >
      <AddMediaIcon />
      <span className="text-sm text-vid-black-900">Add a photo or video</span>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*,video/*"
        className="hidden"
        onChange={(e) => {
          const file = e.target.files?.[0];

          if (file) {
            Effect.runPromise(FileUtils.fileAsInMemoryFile(file)).then(
              (imf) => {
                localStateMgr.onMediaSelected(imf);
              }
            );
          }
        }}
      />
    </div>
  );
};

const AddMediaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 5H21.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.5 7.75V2.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2.67188 18.9496L7.60187 15.6396C8.39187 15.1096 9.53187 15.1696 10.2419 15.7796L10.5719 16.0696C11.3519 16.7396 12.6119 16.7396 13.3919 16.0696L17.5519 12.4996C18.3319 11.8296 19.5919 11.8296 20.3719 12.4996L22.0019 13.8996"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LeftIndentTextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 4.5H21"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.5H12.47"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 14.5H21"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 19.5H12.47"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
