import * as S from "@effect/schema/Schema";
import { isNotNullOrUndefined, isNullOrUndefined } from "../../../util";
import { KnownToolTag } from "../ongoing/known-tool.schemas";
import type { SessionAttendingParticipantsST } from "../ongoing/participant/participant-attendance.schemas";
import type { SessionParticipantDisplayST } from "../ongoing/participant/participant-display.schemas";
import type { Id } from "../../../convex/_generated/dataModel";

const ContentScreensizeModeTag = S.Union(
  S.Literal("TOP_PREVIEW"),
  S.Literal("FULL_SCREEN")
);
type ContentScreensizeMode = typeof ContentScreensizeModeTag.Type;

class ContentScreensizeModeST extends S.Class<ContentScreensizeModeST>(
  "ContentScreensizeMode"
)({
  mode: ContentScreensizeModeTag,
}) {
  static asTopPreview = () =>
    ContentScreensizeModeST.make({ mode: "TOP_PREVIEW" });

  static asFullScreen = () =>
    ContentScreensizeModeST.make({ mode: "FULL_SCREEN" });
}

const GroupViewMode = S.Union(
  S.Struct({ tag: S.Literal("GALLERY") }),
  S.Struct({ tag: S.Literal("SPOTLIGHT_SPEAKER") })
);

class GroupViewModeST extends S.Class<GroupViewModeST>("GroupViewMode")({
  mode: GroupViewMode,
}) {
  static asSpotlightSpeaker = () =>
    GroupViewModeST.make({ mode: { tag: "SPOTLIGHT_SPEAKER" } });

  static asGallery = () => GroupViewModeST.make({ mode: { tag: "GALLERY" } });
}

const ParticipantsViewMode = S.Union(
  S.Struct({ tag: S.Literal("SELF_VIEW") }),
  S.Struct({ tag: S.Literal("ONE_ON_ONE"), otherParticipantId: S.String }),
  S.Struct({ tag: S.Literal("GROUP"), groupViewMode: GroupViewModeST })
);
export type ParticipantsViewMode = typeof ParticipantsViewMode.Type;

class ParticipantsViewModeST extends S.Class<ParticipantsViewModeST>(
  "ParticipantsViewMode"
)({
  mode: ParticipantsViewMode,
}) {
  static asSelfOnlyView = () =>
    ParticipantsViewModeST.make({
      mode: { tag: "SELF_VIEW" },
    });

  static asOneOnOneView = (p: { otherParticipantId: string }) =>
    ParticipantsViewModeST.make({
      mode: { tag: "ONE_ON_ONE", otherParticipantId: p.otherParticipantId },
    });

  static asGroupView = (groupViewMode: typeof GroupViewModeST.Type) =>
    ParticipantsViewModeST.make({
      mode: { tag: "GROUP", groupViewMode },
    });
}

const StageLayoutMode = S.Union(
  S.Struct({
    tag: S.Literal("PARTICIPANTS_VIEW"),
    participantsViewMode: ParticipantsViewModeST,
  }),
  S.Struct({
    tag: S.Literal("CONTENT"),
    content: KnownToolTag,
    contentScreensizeMode: ContentScreensizeModeST,
  })
);

export class SessionStageLayoutST extends S.Class<SessionStageLayoutST>(
  "SessionStageLayout"
)({
  mode: StageLayoutMode,
}) {
  static asParticipantsView = (
    participantsViewMode: typeof ParticipantsViewModeST.Type
  ) =>
    SessionStageLayoutST.make({
      mode: { tag: "PARTICIPANTS_VIEW", participantsViewMode },
    });

  static asContentViewMode = (p: {
    knownTool: KnownToolTag;
    contentScreensizeMode: typeof ContentScreensizeModeST.Encoded;
  }) =>
    SessionStageLayoutST.make({
      mode: {
        tag: "CONTENT",
        content: p.knownTool,
        contentScreensizeMode: p.contentScreensizeMode,
      },
    });

  static default = () =>
    SessionStageLayoutST.make({
      mode: {
        tag: "PARTICIPANTS_VIEW",
        participantsViewMode: ParticipantsViewModeST.asSelfOnlyView(),
      },
    });

  static from = (p: {
    forBaseUserId: Id<"users">;
    attendingParticipants: SessionAttendingParticipantsST;
    participantDisplay: SessionParticipantDisplayST;
  }): SessionStageLayoutST => {
    console.log(
      "NUMBER OF ATTENDING PARTICIPANTS",
      p.attendingParticipants.participants.length
    );

    const participantsInMainRoom = p.attendingParticipants.asThoseInMainRoom;

    const myContentDisplayMode = p.participantDisplay.contentDisplayMode;

    if (isNotNullOrUndefined(p.participantDisplay.contentInFocus)) {
      return SessionStageLayoutST.asContentViewMode({
        knownTool: p.participantDisplay.contentInFocus,
        contentScreensizeMode: ContentScreensizeModeST.make({
          mode: myContentDisplayMode,
        }),
      });
    }

    if (participantsInMainRoom.length <= 1) {
      return SessionStageLayoutST.make({
        mode: {
          tag: "PARTICIPANTS_VIEW",
          participantsViewMode: ParticipantsViewModeST.asSelfOnlyView(),
        },
      });
    }

    if (participantsInMainRoom.length === 2) {
      const otherParticipant = participantsInMainRoom.find(
        (sp) => sp.baseUserId !== p.forBaseUserId
      );

      console.log("OTHER PARTICIPANT", otherParticipant);

      if (isNullOrUndefined(otherParticipant)) {
        return SessionStageLayoutST.asParticipantsView(
          ParticipantsViewModeST.asSelfOnlyView()
        ); // TODO: Should this be an error?
      }

      return SessionStageLayoutST.asParticipantsView(
        ParticipantsViewModeST.asOneOnOneView({
          otherParticipantId: otherParticipant.baseUserId,
        })
      );
    }

    return SessionStageLayoutST.asParticipantsView(
      ParticipantsViewModeST.asGroupView(GroupViewModeST.asSpotlightSpeaker())
    );
  };

  get encoded(): typeof SessionStageLayoutST.Encoded {
    return S.encodeUnknownSync(SessionStageLayoutST)(this);
  }
}
